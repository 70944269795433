<!-- Main Sidebar Container -->
<aside class="main-sidebar elevation-4 sidebar-light-warning" style="overflow-y: auto; max-height: 30.5em;" >
    <!-- Brand Logo -->
    <a href="index.html" class="brand-link">
        <img src="../../../../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
            class="brand-image img-circle elevation-3" style="opacity: .8">
        <span class="brand-text font-weight-light">Parc Auto</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="image">
                <img src="{{completedUser?.user?.avatar}}" class="img-circle elevation-2" alt="User Image">
            </div>
            <div class="info">
                <a [routerLink]="['/profile/all']" class="d-block">{{user?.name}}</a>
            </div>
        </div>

        <!-- Sidebar Menu -->
        <nav class="mt-4">
            <ul class="todo-list-wrapper list-group list-group-flush">
                <li class="list-group-item">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <!-- Add icons to the links using the .nav-icon class
                        with font-awesome or any other icon font library -->
                        <li class="nav-item has-treeview">
                            <a href="#" class="nav-link">
                                <i class=" nav-icon fas fa-car"> </i>
                                <p>Parc automobile
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-car"> </i>
                                        <p>Vehicules
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item ">
                                            <a [routerLink]="['vehicles/add']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-plus-circle nav-icon"></i>
                                                <p>Ajouter</p>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a [routerLink]="['vehicles/list']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-eye nav-icon"></i>
                                                <p>Consulter</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-chart-pie"></i>
                                        <p>
                                            Affectation Véhicule
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item">
                                            <a [routerLink]="['/affectations/add']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-plus-circle nav-icon"></i>
                                                <p>Ajouter</p>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a [routerLink]="['/affectations/all']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-eye nav-icon"></i>
                                                <p>Consulter</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-book"></i>
                                        <p>
                                            Docs Administratifs
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item" *ngFor="let type of paperTypes" style="margin-left: 5%;">
                                            <a type="button" (click)="listType(type.id)" class="nav-link">
                                                <i class="nav-icon fas fa-copy"></i>
                                                <p>{{type.name}}</p>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a type="button" (click)="assurance()" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="nav-icon fas fa-copy"></i>
                                                <p>Assurances</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-chart-pie"></i>
                                        <p>
                                            Fiche vehicule
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item">
                                            <a [routerLink]="['/type_fiche_all']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-eye nav-icon"></i>
                                                <p>Consulter</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <!--  <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        <i class="nav-icon fas fa-chart-pie"></i>
                                        <p>
                                            Alertes et RCV
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item">
                                            <a href="pages/charts/chartjs.html" class="nav-link">
                                                <i class="far fa-circle nav-icon"></i>
                                                <p>Ajouter</p>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="pages/charts/flot.html" class="nav-link">
                                                <i class="far fa-circle nav-icon"></i>
                                                <p>Consulter</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul> -->
                        </li>

                        <li class="nav-item has-treeview">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-chart-pie"></i>
                                <p>
                                    Mouvement
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-chart-pie"></i>
                                        <p>
                                            Mission
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item">
                                            <a [routerLink]="['/mission/add']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-plus-circle nav-icon"></i>
                                                <p>Ajouter</p>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a [routerLink]="['mission/all']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-eye nav-icon"></i>
                                                <p>Consulter</p>
                                            </a>
                                        </li>
                                    </ul>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item">
                                            <a [routerLink]="['/transfer/add']" class="nav-link" style="margin-left: 2.5em;">
                                                <i class="fas fa-plus-circle nav-icon"></i>
                                                <p>Transferer mission</p>
                                            </a>
                                        </li>
                                    </ul>

                                </li>

                            </ul>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-chart-pie"></i>
                                        <p>
                                            Type Incident 
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item">
                                            <a [routerLink]="['/type-incident/add']" class="nav-link" style="margin-left: 2.5em;">
                                                <i class="fas fa-plus-circle nav-icon"></i>
                                                <p>Ajouter</p>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a [routerLink]="['/type-incident/all']" class="nav-link" style="margin-left: 2.5em;">
                                                <i class="fas fa-eye nav-icon"></i>
                                                <p>Consulter</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-chart-pie"></i>
                                        <p>
                                            Type Document 
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item">
                                            <a [routerLink]="['/type-document-mission/add']" class="nav-link" style="margin-left: 2.5em;">
                                                <i class="fas fa-plus-circle nav-icon"></i>
                                                <p>Ajouter</p>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a [routerLink]="['/type-document-mission/all']" class="nav-link" style="margin-left: 2.5em;">
                                                <i class="fas fa-eye nav-icon"></i>
                                                <p>Consulter</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item has-treeview">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-tools"></i>
                                <p>
                                    Maintenance
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp; <i class="nav-icon fas fa-chart-pie"></i>
                                        <p>
                                            Entretien
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp; <i class="nav-icon fas fa-chart-pie"></i>
                                                <p>
                                                    Type Entretien
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/type-entretient/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['type-entretient/all']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-chart-pie"></i>
                                                <p>
                                                    Gamme Entretien
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/gamme-entretien/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['gamme-entretien/all']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-chart-pie"></i>
                                                <p>
                                                    Programme Entretien
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/programme-entretient/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['programme-entretient/list']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp; <i class="nav-icon fas fa-chart-pie"></i>
                                        <p>
                                            Interventions
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-chart-pie"></i>
                                                <p>
                                                    Type Intervention
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/intervention-type/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['/intervention-type/list']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-chart-pie"></i>
                                                <p>
                                                    Intervention
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/intervention/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['/intervention/list']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-chart-pie"></i>
                                                <p>
                                                    Fiche Intervention
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/fiche-intervention/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['fiche-intervention/all']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp; <i class="nav-icon fas fa-chart-pie"></i>
                                        <p>
                                            Panne/Reparation
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp; <i class="nav-icon fas fa-chart-pie"></i>
                                                <p>
                                                    Panne
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/panne/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['/panne/list']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;<i class="nav-icon fas fa-chart-pie"></i>
                                                <p>
                                                    Programme Reparation
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/programme-reparation/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['/programme-reparation/all']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                            <!-- <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/programme-reparation/add']" class="nav-link" style="margin-left: 5em;">
                                                        <i class="far fa-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['programme-reparation/all']" class="nav-link" style="margin-left: 5em;">
                                                        <i class="far fa-circle nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul> -->
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item has-treeview">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-cog"></i>
                                <p>
                                    Pneumatique
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-chart-pie"></i>
                                        <p>
                                            Operation
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item">
                                            <a [routerLink]="['/operation/add']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-plus-circle nav-icon"></i>
                                                <p>Ajouter</p>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a [routerLink]="['/operation/all']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-eye nav-icon"></i>
                                                <p>Consulter</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-chart-pie"></i>
                                        <p>
                                            Pneu
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item">
                                            <a [routerLink]="['/pneu/add']" class="nav-link" style="margin-left: 2.5em;">
                                                <i class="fas fa-plus-circle nav-icon"></i>
                                                <p>Ajouter</p>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a [routerLink]="['/pneu/all']" class="nav-link" style="margin-left: 2.5em;">
                                                <i class="fas fa-eye nav-icon"></i>
                                                <p>Consulter</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a [routerLink]="['/inventaire']" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="fas fa-eye nav-icon"></i>
                                        <p>Inventaires</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item has-treeview">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-users"></i>
                                <p>
                                    Ressources Humaines
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp; <i class="nav-icon fas fa-user"></i>
                                        <p>
                                            Employés
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-user"></i>
                                                <p>Utilisateurs</p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/user-add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['/user-all']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-user"></i>
                                                <p>
                                                    Chauffeurs
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/chauffeur/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['/chauffeur/all']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-user"></i>
                                                <p>
                                                    Convoyeurs
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/convoyeur/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['/convoyeur/all']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-user"></i>
                                                <p>
                                                    Mécaniciens
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/mecanicien/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['/mecanicien/all']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp; <i class="nav-icon fas fa-file"></i>
                                        <p>
                                            Contrats
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-file"></i>
                                                <p>
                                                    Type Contrat
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/type-contrat/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['/type-contrat/all']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-file"></i>
                                                <p>
                                                    Contrat
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/contrat/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['/contrat/all']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp; <i class="nav-icon fas fa-folder"></i>
                                        <p>
                                            Sanctions
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-folder"></i>
                                                <p>
                                                    Type Sanction
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/type-sanction/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['/type-sanction/all']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-folder"></i>
                                                <p>
                                                    Sanction
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/sanction/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['/sanction/all']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-cloud"></i>
                                        <p>
                                            Formation
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item">
                                            <a [routerLink]="['/formation/add']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-plus-circle nav-icon"></i>
                                                <p>Ajouter</p>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a [routerLink]="['/formation/all']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-eye nav-icon"></i>
                                                <p>Consulter</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-folder"></i>
                                        <p>
                                            Notes Internes
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item">
                                            <a [routerLink]="['/note-critique/add']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-plus-circle nav-icon"></i>
                                                <p>Ajouter</p>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a [routerLink]="['/note-critique/all']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-eye nav-icon"></i>
                                                <p>Consulter</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-sms"></i>
                                        <p>
                                            Messages
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item">
                                            <a [routerLink]="['/message/add']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-plus-circle nav-icon"></i>
                                                <p>Ajouter</p>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a [routerLink]="['/message/all']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-eye nav-icon"></i>
                                                <p>Consulter</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        <li class="nav-item has-treeview">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-book"></i>
                                <p>
                                    Produire un Document
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <a [routerLink]="['document-production/add']" class="nav-link"
                                        style="margin-left: 2.5em;">
                                        <i class="fas fa-plus-circle nav-icon"></i>
                                        <p>Produire</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="['document-production/upload']" class="nav-link"
                                        style="margin-left: 2.5em;">
                                        <i class="fas fa-edit nav-icon"></i>
                                        <p title="precharger un fichier depuis votre PC">Uploader</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a [routerLink]="['document-production/list']" class="nav-link"
                                        style="margin-left: 2.5em;">
                                        <i class="fas fa-download nav-icon"></i>
                                        <p>Telecharger</p>
                                    </a>
                                </li>

                            </ul>
                        </li>
                        <li class="nav-item has-treeview">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-cogs"></i>

                                <p>
                                    Paramètres
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp; <i class="nav-icon fas fa-car"></i>
                                        <p>
                                            Véhicules
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-car"></i>
                                                <p>
                                                    Marques de véhicule
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['setting/forms/marque/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['setting/forms/marque/list']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-car"></i>
                                                <p>
                                                    Modèles de véhicule
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['setting/forms/model/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['setting/forms/model/list']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-car"></i>
                                                <p>
                                                    Types de véhicule
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['setting/forms/type-vehicule/add']"
                                                        class="nav-link" style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['setting/forms/type-vehicule/list']"
                                                        class="nav-link" style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-car"></i>
                                                <p>
                                                    Carosseries de véhicule
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['setting/forms/carosserie/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['setting/forms/carosserie/list']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye  nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>

                                        </li>
                                    </ul>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-car"></i>
                                                <p>
                                                    marque de pneu
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['mark-pneu/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['mark-pneu/all']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-folder"></i>
                                        <p>
                                            Type De Document
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item">
                                            <a [routerLink]="['type_fiche_add']" type="button" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-plus-circle nav-icon"></i>
                                                <p>Ajouter</p>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a [routerLink]="['type_fiche_all']" type="button" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-eye nav-icon"></i>
                                                <p>Consulter</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp; <i class="nav-icon fas fa-elementor"></i>
                                        <p>
                                            Intervenants
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-chart-pie"></i>
                                                <p>
                                                    Types intervenants
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/intervening-type/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['/intervening-type/list']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item has-treeview">
                                            <a href="#" class="nav-link" style="margin-left: 2.5em;">
                                                &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-elementor"></i>
                                                <p>
                                                    Eléments intervenants
                                                    <i class="right fas fa-angle-left"></i>
                                                </p>
                                            </a>
                                            <ul class="nav nav-treeview">
                                                <li class="nav-item">
                                                    <a [routerLink]="['/intervening-element/add']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-plus-circle nav-icon"></i>
                                                        <p>Ajouter</p>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a [routerLink]="['/intervening-element/list']" class="nav-link"
                                                        style="margin-left: 5em;">
                                                        <i class="fas fa-eye nav-icon"></i>
                                                        <p>Consulter</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul class="nav nav-treeview">
                                <li class="nav-item has-treeview">
                                    <a href="#" class="nav-link">
                                        &nbsp;&nbsp;&nbsp;&nbsp;<i class="nav-icon fas fa-bullhorn"></i>
                                        <p>
                                            Role
                                            <i class="right fas fa-angle-left"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li class="nav-item">
                                            <a [routerLink]="['/role-add']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-plus-circle nav-icon"></i>
                                                <p>Ajouter</p>
                                            </a>
                                        </li>
                                        <li class="nav-item align-right">
                                            <a [routerLink]="['/role-all']" class="nav-link"
                                                style="margin-left: 2.5em;">
                                                <i class="fas fa-eye nav-icon"></i>
                                                <p>Consulter</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>