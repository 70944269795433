<div class="content-wrapper" style="min-height: 1200.88px;  margin-left: 1%; margin-right: 1%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>{{ "Message.Title" | translate }}</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                        <li class="breadcrumb-item "><a
                                [routerLink]="['/message/all']">{{ "Message.Title" | translate }}</a>
                        </li>
                        <li class="breadcrumb-item active">{{ "FileAriane.Detail" | translate }}</li>

                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="container">
        <div class="container-fluid">
            <div class="col-sm-12">
                <img style="height:400px; margin-left:50%" class="img-fluid" src="{{message?.photo}}"
                  alt="photo">
              </div>


            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Information Message</h3>
                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Message.Recipient" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{message?.user2?.name }}</td>
                                </tr>
                                <!-- <div *ngIf="bool"> -->
                                    <tr>

                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Message.Sender" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{message?.user1?.name }}</td>
                                    </tr>
                                <!-- </div> -->
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Message.Subject" | translate }}</td>
                                    <td>
                                        <p style="color: rgb(0, 89, 255);">{{message?.suject}}</p>
                                    </td>

                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Message.Content" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">
                                        <p>{{message?.content}}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <!-- <div class="col-12">
          <button type="button" class="btn btn-success float-right">Envoyez un message</button>
        </div> -->
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</div>