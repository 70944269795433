<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>{{ "Sanction.Sanction" | translate }}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/sanction/all']">{{ "Sanction.Sanction" | translate }}</a>
            </li>
            <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>

          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>

  <!-- Main content -->

  <section class="container">
    <div class="row">
      <div class="col-md-12">
        <form (submit)="onSubmit()" [formGroup]="SanctionForm">
          <div class="card card-primary">

            <div class="card-header" style="background-color: #17a2b8;">
              <h3 class="card-title">{{ "Sanction.SanctionAdd" | translate }}</h3>
            </div> 

            <div class="card-body" style="display: block;">
              <div class="form-group">
                <label>{{ "Sanction.UserLabel" | translate }} <span style="color: red;">*</span> </label>
                <select formControlName="user_id" class="form-control {{ isSubmitted && form.user_id.errors? 'is-invalid' : '' }}"
                  style="width: 100%;" tabindex="-1" aria-hidden="true">
                  <option value="">{{ "Sanction.User" | translate }}</option>
                  <option *ngFor="let driver of users?.data" value="{{driver.id}}">{{driver.name}}</option>
                </select>
              </div>
              <div class="form-group">
                <label>{{ "Sanction.TypeSanctionLabel" | translate }} <span style="color: red;">*</span> </label>
                <select formControlName="sanction_type_id" class="form-control {{ isSubmitted && form.sanction_type_id.errors? 'is-invalid' : '' }}"
                  style="width: 100%;" tabindex="-1" aria-hidden="true">
                  <option value="">{{ "Sanction.SanctionVehicle" | translate }}</option>
                  <option *ngFor="let type of typeSanctions" value="{{type.id}}">{{type.name}}</option>
                </select>
                <label>Ajouter un type de sanction en <a href="javascript:void(0)" (click)="addSanctiontype()">cliquant
                  ici</a></label>
              </div>
              <div class="form-group">
                <label for="inputName">{{ "Sanction.ReasonPenaltyLabel" | translate }} <span style="color: red;">*</span> </label>
                <textarea cols="30" rows="5" type="text" id="inputName"
                  class="form-control {{ isSubmitted && form.reason_penalty.errors? 'is-invalid' : '' }}" formControlName="reason_penalty"
                  placeholder="{{ 'Sanction.LabelReasonSanction' | translate }}"></textarea>
              </div>
              <div class="form-group">
                <label for="inputName">{{ "Sanction.StartDateLabel" | translate }} <span style="color: red;">*</span> </label>
                <input type="date" id="inputName" class="form-control {{ isSubmitted && form.start_date.errors? 'is-invalid' : '' }}"
                  formControlName="start_date" placeholder="{{ 'Sanction.LabelDateSanction' | translate }}">
              </div>
              <div class="form-group">
                <label for="inputName">{{ "Sanction.EndDateLabel" | translate }} <span style="color: red;">*</span> </label>
                <input type="date" id="inputName" class="form-control {{ isSubmitted && form.end_date.errors? 'is-invalid' : '' }}"
                  formControlName="end_date" placeholder="{{ 'Sanction.LabelEndDateSanction' | translate }}">
              </div>

            </div>
            <div class="card-footer">
              <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
            <!-- /.card-body -->
          </div>
        </form>
        <!-- /.card -->
      </div>
    </div>
  </section>
  <!-- /.content -->
</div>