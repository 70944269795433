import { Component, OnInit } from '@angular/core';
import { ConvoyeurService } from 'src/app/_services/RH-service/convoyeur/convoyeur.service';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-convoyeur-detail',
  templateUrl: './convoyeur-detail.component.html',
  styleUrls: ['./convoyeur-detail.component.scss']
})
export class ConvoyeurDetailComponent implements OnInit {
  convoyeur: any;
  url;

  constructor(
    private ConvoyeurService: ConvoyeurService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,

  ) { }

  async ngOnInit() {
    const convoyeurId = +this.route.snapshot.paramMap.get("id");
    console.log(convoyeurId);
    this.ConvoyeurService.find(convoyeurId).then(
      data => {
        this.convoyeur = data;
        console.log(this.convoyeur)
      }
    ).catch(
      error => {
        this.translate.get('Driver.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/convoyeur/all'])
      }
    )
  }
}
