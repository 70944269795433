import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ParamService } from 'src/app/_services/parc-service/param-service/param-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})




export class AddComponent implements OnInit {

  form: any;
  loading: boolean = true;
  modelForm: FormGroup;
  markForm: FormGroup;
  carosserieForm: FormGroup;
  typevehiculeForm: FormGroup;
  isModel = false;
  isMark = false;
  isCarosserie = false;
  isTypeVehicule = false;
  typemarks: any;
  forme = "Mark"

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private paramService: ParamService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) { }

  type = +this.route.snapshot.paramMap.get("form");
  ngOnInit(): void {

    
    this.getForme(this.forme);

    // initialisation de type vehicule
    if (this.type == 1) {
      this.isTypeVehicule = true;
      this.inittypevehicule();
    }

    // initialisation de model
    this.initmodel();
    if (this.type == 2) {
      this.isModel = true;
      this.initmodel();
    }

    // initialisation de marque
    if (this.type == 3) {
      this.isMark = true;
      this.initmark();
    }

    // initialisation de carosserie
    if (this.type == 4) {
      this.isCarosserie = true;
      this.initcarosserie();
    }


  }

  public initmodel() {
    this.modelForm = this.formBuilder.group({
      description: [''],
      parrain_id: ['',Validators.required],
      name: ['', Validators.required],
    });
  }

  public initmark() {
    this.markForm = this.formBuilder.group({
      description: [''],
      name: ['', Validators.required],
    });
  }

  public initcarosserie() {
    this.carosserieForm = this.formBuilder.group({
      description: [''],
      name: ['', Validators.required],
    });
  }

  public inittypevehicule() {
    this.typevehiculeForm = this.formBuilder.group({
      description: [''],
      name: ['', Validators.required],
    });
  }

  get modelform() {
    return this.modelForm.controls;
  }

  get markform() {
    return this.markForm.controls;
  }

  get carosserieform() {
    return this.carosserieForm.controls;
  }

  get typevehiculeform() {
    return this.typevehiculeForm.controls;
  }


  onSubmitModel() {

    if (this.modelForm.invalid) {
      this.toastrService.error('ERREUR', 'Les champs en rouge sont invalides!');
      return;
    }
    const formData = new FormData();
    formData.append('parrain_id', this.modelform.parrain_id.value);
    formData.append('description', '' + this.modelform.description.value);
    formData.append('name', '' + this.modelform.name.value);
    formData.append('code', '' + 'MODELE');
    this.paramService.addForm(formData)
      .then(resp => {
        this.toastrService.success('Hello world!', 'Toastr fun!');
        this.modelForm.reset();
        this.modelForm = this.formBuilder.group({
          parrain_id: ['', Validators.required],
          description: [''],
          name: ['', Validators.required],
        });
      })
      .catch(err => {
        console.log(err)
      })
      .finally();
  }

  //   getSacrament(){
  //     this.sacramentService.getSacrament().then((res)=>{
  //       this.sacrament=res;
  //       console.log(this.sacrament);
  //     },(error)=> {
  //           this.notificationService.danger("indisponible");
  //     })
  // }
  onSubmitMark() {

    const formData = new FormData();
    formData.append('description', '' + this.markform.description.value);
    formData.append('name', '' + this.markform.name.value);
    formData.append('code', '' + 'MARK');
    this.paramService.addForm(formData)
      .then(resp => {
        this.modelForm = this.formBuilder.group({
          description: [''],
          name: ['', Validators.required],
        });
      })
      .catch(err => {
        console.log(err)
      })
      .finally();
  }

  onSubmitcarosserie() {

    const formData = new FormData();
    formData.append('description', '' + this.form.type.value);
    formData.append('name', '' + this.form.names.value);
    formData.append('code', '' + 'CAROSSERIE');
    this.paramService.addForm(formData)
      .then(resp => {
        this.modelForm = this.formBuilder.group({
          description: [''],
          name: ['', Validators.required],
        });
      })
      .catch(err => {
        console.log(err)
      })
      .finally();
  }

  onSubmitTypevehicule() {

    const formData = new FormData();
    formData.append('description', '' + this.form.type.value);
    formData.append('name', '' + this.form.names.value);
    formData.append('code', '' + 'TYPE_VEHICULE');
    this.paramService.addForm(formData)
      .then(resp => {
        this.modelForm = this.formBuilder.group({
          description: [''],
          name: ['', Validators.required],
        });
      })
      .catch(err => {
        console.log(err)
      })
      .finally();
  }

  public getForme(forme) {

    this.paramService.selectForm(forme).then(
      response => {
        this.typemarks = response;
        console.log(this.typemarks)
      }
    ).catch(
      error => {
        this.toastrService.error(error.error.message);
      }
    ).finally(
      () => {
        this.loading = false;
      }
    )
  }
}












