import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { FicheInterventionService } from 'src/app/_services/maintenance-service/fiche_intervention/fiche-intervention.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-fiche-intervention-detail',
  templateUrl: './fiche-intervention-detail.component.html',
  styleUrls: ['./fiche-intervention-detail.component.scss']
})
export class FicheInterventionDetailComponent implements OnInit {

  fiche_interventions: any;

  constructor(
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private FicheInterventionService: FicheInterventionService,
  ) { }

  async ngOnInit() {
    const fiche_intervention_id = +this.route.snapshot.paramMap.get("id");
    //console.log(Junie);
    this.FicheInterventionService.find(fiche_intervention_id).then(
      data => {
        this.fiche_interventions = data;
        console.log(this.fiche_interventions)
      }
    ).catch(
      error => {
        this.translate.get('fiche_interventions.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/fiche-intervention/all'])
      }
    )
  }
}
