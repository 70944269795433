import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { FicheInterventionService } from 'src/app/_services/maintenance-service/fiche_intervention/fiche-intervention.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-fiche-intervention-update',
  templateUrl: './fiche-intervention-update.component.html',
  styleUrls: ['./fiche-intervention-update.component.scss']
})
export class FicheInterventionUpdateComponent implements OnInit {

  DataForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  user
  vehicules: any;
  mecaniciens: any;
  typeInterventions: any;
  ficheInterventions: any;
  limite: 10000;
  //file: File = null;
  myFiles: string[] = [];


  constructor(
    private FicheInterventionService: FicheInterventionService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getTypeIntervention();
    this.getMecanicien();
    this.getVehicule();
    this.initForm();
    this.user = this.authService.getUser();

    const fiche_intervention_id = +this.route.snapshot.paramMap.get("id");
    this.FicheInterventionService.find(fiche_intervention_id).then(
      data => {
        this.ficheInterventions = data;
        console.log(this.ficheInterventions);

        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('ficheInterventions.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
      }
    )
  }

  initForm(withFiche = false) {
    if (withFiche) {
      this.DataForm = this.formBuilder.group(
        {
          compteur: [this.ficheInterventions.compteur, [Validators.required]],
          degre_urgence: [this.ficheInterventions.degre_urgence],
          date_debut: [this.ficheInterventions.date_debut,],
          date_fin: [this.ficheInterventions.date_fin],
          temps_alloue: [this.ficheInterventions.temps_alloue],
          temps_indisponibilite: [this.ficheInterventions.temps_indisponibilite],
          travail_effectuer: [this.ficheInterventions.travail_effectuer],
          type_intervention_id: [this.ficheInterventions.type_intervention_id, [Validators.required]],
          vehicule_id: [this.ficheInterventions.vehicule_id, [Validators.required]],
          mecanicien_id: [this.ficheInterventions.mecanicien_id, [Validators.required]],
        });
    }
    else {
      this.DataForm = this.formBuilder.group(
        {
          compteur: ['', [Validators.required]],
          degre_urgence: ['', [Validators.required]],
          date_debut: ['', [Validators.required]],
          date_fin: ['', [Validators.required]],
          temps_alloue: ['', [Validators.required]],
          temps_indisponibilite: ['', [Validators.required]],
          travail_effectuer: ['', [Validators.required]],
          type_intervention_id: ['', [Validators.required]],
          vehicule_id: ['', [Validators.required]],
          mecanicien_id: ['', [Validators.required]],
        }
      );
    }

  }

  getTypeIntervention() {
    this.FicheInterventionService.getTypeIntervention().then(res => {
      this.typeInterventions = res;
      console.log(this.typeInterventions)
    }).catch(
      error => {
        this.notifService.danger(error.error.message)
      })
  }

  getMecanicien() {
    this.FicheInterventionService.getMecanicien().then(res => {
      this.mecaniciens = res;
      console.log(this.mecaniciens)
    }).catch(
      error => {
        this.notifService.danger(error.error.message)
      })
  }

  getVehicule() {
    this.FicheInterventionService.getVehicule().then((res) => {
      this.vehicules = res;
      console.log(this.vehicules)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  onSelectfile(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
  }


  get form() {
    return this.DataForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;


    if (this.DataForm.invalid) {

      this.translate.get('FicheIntervention.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('type_intervention_id', '' + this.form.type_intervention_id.value);
    formData.append('vehicule_id', '' + this.form.vehicule_id.value);
    formData.append('mecanicien_id', '' + this.form.mecanicien_id.value);
    formData.append('compteur', '' + this.form.compteur.value);
    formData.append('degre_urgence', '' + this.form.degre_urgence.value);
    formData.append('date_debut', '' + this.form.date_debut.value);
    formData.append('date_fin', '' + this.form.date_fin.value);
    formData.append('temps_alloue', '' + this.form.temps_alloue.value);
    formData.append('temps_indisponibilite', '' + this.form.temps_indisponibilite.value);
    for (var i = 0; i < this.myFiles.length; i++) {
      formData.append("fiche_intervention[]", this.myFiles[i]);
    }
    formData.append('travail_effectuer', '' + this.form.travail_effectuer.value);
    formData.append('create_id', '' + this.user.id);

    console.log(formData);
    this.FicheInterventionService.update(formData, this.ficheInterventions.id)
      .then(resp => {
        this.translate.get('FicheIntervention.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.DataForm.reset();
        this.router.navigate(['/fiche-intervention/all']);
      })
      .catch(err => {
        console.log(err)
        this.translate.get('FicheIntervention.SubmitErrorTypeEntretient')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }

}
