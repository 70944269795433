<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ "Position.Position" | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                    <li class="breadcrumb-item "><a [routerLink]="['/setting/forms/model/list']">{{
                            "Forms.Models.Models" |
                            translate }}</a></li>
                    <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>

                </ol>
            </div>
        </div>
    </div><!-- /.container-fluid -->
</section>

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title"> Details du modele de vehicule </h3>

            </div>
            <!-- /.card-header -->
            <div class="row">
                <div class="col-md-4">
                    <div style="margin-top:10%; margin-left:5%">
                        <label>{{ "Forms.Models.Models" | translate }}:</label>
                        <h2>&nbsp;&nbsp;&nbsp;{{model?.model}}</h2>
                        <label>{{ "Forms.Models.Description" | translate }}:</label>
                        <p>&nbsp;&nbsp;&nbsp;{{model?.description}}</p>
                    </div>
                </div>
                <div class="col-md-8 card-body table-responsive p-0">


                    <table border="1">
                        <thead>
                            <tr>
                                <th>

                                    1<sup>er</sup> ranger

                                </th>
                                <th>

                                    2<sup>eme</sup> ranger

                                </th>
                                <th>

                                    1<sup>er</sup> reserve

                                </th>

                                <th>

                                    3<sup>eme</sup> ranger

                                </th>
                                <th>

                                    4<sup>eme</sup> ranger

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows, let i = index">
                                <td *ngFor="let col of columns, let j = index">
                                    <div *ngIf="matched(i,j)" class="position" >
                                        <!-- Position({{j}}, {{i}}) -->
                                    </div>
                                </td>


                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <!-- /.card-body -->
        </div>
        <!-- /.card -->
    </div>
</div>