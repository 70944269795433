<div class="content-wrapper" style="min-height: 1200.88px;  margin-left: 1%; margin-right: 1%;">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>{{ "NoteCritique.NoteCritique" | translate }}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/note-critique/all']">{{ "NoteCritique.NoteCritique" |
                translate }}</a></li>
            <li class="breadcrumb-item active">{{ "FileAriane.Detail" | translate }}</li>

          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
  <!-- /.content-header -->

  <!-- Main content -->
  <div class="container">
    <div class="container-fluid">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">

            <div class="position-relative mb-4">
              <div class="col-sm-12">
                <img style="height:400px; margin-left:15%" class="img-fluid" src="{{note?.image}}" alt="photo">
                <!-- <img style="height:400px; margin-left:50%" class="img-fluid" src="{{note?.user?.avatar}}"
                  alt="photo"> -->
              </div>

            </div>

          </div>
          <!--  <div class="d-flex flex-row justify-content-center">

            <h2>{{note?.user?.name}}</h2>

          </div> -->
        </div>

      </div>

      <div class="col-lg-12">
        <div class="card">
          <div class="card-header border-0">
            <h3 class="card-title">Information Note Interne</h3>
          </div>
          <div class="card-body table-responsive p-0">
            <table class="table table-striped table-valign-middle">
              <tbody>
                <tr>
                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                  <td>{{ "NoteCritique.TitleLabel" | translate }}</td>
                  <td style="color: rgb(0, 89, 255);">{{note?.title }}</td>
                </tr>
                <tr>
                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                  <td>{{ "NoteCritique.ContentLabel" | translate }}</td>
                  <td style="color: rgb(0, 89, 255);">
                    <p>{{note?.content}}</p>
                  </td>

                </tr>
                <tr>
                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                  <td>{{ "NoteCritique.ImageLabel" | translate }}</td>
                  <td><a href="{{note?.image}}">Voir</a></td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>

      </div>
      <div class="col-12">
        <button type="button" class="btn btn-success float-right" style="background-color: #17a2b8;">
          <a href="javascript:void(0)" (click)="addMessage()">
            Envoyez un message
          </a>
        </button>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content -->
</div>
