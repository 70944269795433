
<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>{{ 'TYPEFICHE.TYPEFICHE' | translate }}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/type_fiche_all']">{{ 'TYPEFICHE.TYPEFICHE' | translate }}</a></li>
            <li class="breadcrumb-item active">{{ "FileAriane.List" | translate }}</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="tabs-animation" style="min-height: 1416.81px; margin-left: 1%; margin-right: 1%;">
    <div class="main-card mb-3 card">
        <section class="content">
            <div class="card-header" style="background-color: #17a2b8; color: white;" > 
            <h2 class="card-title" style="margin-left: 1%; margin-bottom: 1%; margin-top: 1%;"> {{ 'TYPEFICHE.TYPEFICHEAllLabel' | translate }}</h2>
            <button [routerLink]="['/type_fiche_add']" type="button" class="btn btn-primary float-right" style="margin-right: 1%; margin-bottom: 1%; margin-top: 1%;">
                <i class="fas fa-plus" ></i> {{ "Forms.Marques.Ajouter" | translate }}
            </button>
         </div>
        </section >
        <div class="card-body">
            <table id="example1" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th style="width: 10%;">#</th>
                        <th style="width: 30%;">{{ 'TYPEFICHE.NomLabel' | translate }}</th>
                        <th style="width: 30%;">{{ 'TYPEFICHE.DescriptionLabel' | translate }}</th>
                        <th style="width: 20%;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let typeFiche of typeFiches.data,let i = index" id="tr{{ typeFiche.id }}">
                        <td >{{ i+1 }}</td>
                        <td >{{ typeFiche.name }}</td>
                        <td >{{ typeFiche.description}}</td>

                        <td class="project-actions text-right">
                            <a class="btn btn-primary btn-sm" data-bs-placement="top" title="details" href="javascript:void(0)" (click)="detailtype(typeFiche.id)">
                                <i class="fas fa-eye"></i>
                            </a>
                            &nbsp;
                            <a class="btn btn-info btn-sm" data-bs-placement="top" title="modifier" href="javascript:void(0)" (click)="edittype(typeFiche.id)">
                                <i class="fas fa-pencil-alt">
                                </i>
                            </a>
                            &nbsp;
                            <a class="btn btn-danger btn-sm" data-bs-placement="top" title="supprimer" href="javascript:void(0)" (click)="deletetype(typeFiche)">
                                <i class="fas fa-trash">
                                </i>
                            </a>
                        </td>
                    </tr>
                </tbody>
                <tfoot *ngIf="loading && (!typeFiches || !typeFiches.length)">
                    <td colspan="7" class="text-center">
                        <div class="spinner-border spinner-sm">
                            <span class="sr-only">Patientez svp...</span>
                        </div>
                    </td>
                    <td colspan="7" *ngIf="loading" class="text-center">
                        Liste vide
                    </td>
                </tfoot>
                <tfoot>
                    <!-- <tr *ngIf="typeFiches">
                        <td colspan="7" style="margin-top: 5%">
                            <div style="width: 200px; margin: auto;">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" id="example_previous">
                                        <a (click)="getPage(typeFiches.prev_page_url)" aria-controls="example"
                                            data-dt-idx="0" tabindex="0"
                                            class="page-link">{{ 'General.Previous' | translate }}</a>
                                    </li>
                                    <li class="paginate_button page-item next" id="example_next">
                                        <a (click)="getPage(typeFiches.next_page_url)" aria-controls="example"
                                            data-dt-idx="7" tabindex="0"
                                            class="page-link">{{ 'General.Next' | translate }}</a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr> -->
                </tfoot>

            </table>
        </div>
    </div>
</div>