import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Routes from '../../../../Routes';

@Injectable({
  providedIn: 'root'
})
export class InterventionService {

  constructor(private http: HttpClient) { }

  async post(formData): Promise<any> {
    return await this.http.post(Routes.INTERVENTION, formData).toPromise();
  }

  public async put(id: number, formData): Promise<any> {
    return await this.http
      .post(`${Routes.INTERVENTION}/${id}`, formData)
      .toPromise();
  }

  public gets(page) {
    return this.http.get<any>(`${Routes.INTERVENTION}?page=${page}`)
      .toPromise();
  }

  public get(url) {
    return this.http.get<any>(`${url}`)
      .toPromise();
  }

  public async delete(id) {
    return await this.http
      .delete(`${Routes.INTERVENTION}/${id}`)
      .toPromise();
  }

  find(id: number): Promise<any> {
    return this.http.get<any>(`${Routes.INTERVENTION}/${id}`).toPromise();
  }
  public getTypeIntervention() {
    return this.http.get<any>(`${Routes.TYPEINTERVENTION}`)
      .toPromise();
  }
}
