<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>{{ "Convoyeur.Convoyeur" | translate }}</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                        <li class="breadcrumb-item "><a [routerLink]="['/convoyeur/all']">{{ "Convoyeur.Convoyeur" |
                                translate
                                }}</a></li>
                        <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>

                    </ol>
                </div>
            </div>
        </div>
        <!-- /.container-fluid -->
    </section>

    <!-- Main content -->

    <section class="container">
        <div class="row">
            <div class="col-md-12">
                <form (submit)="onSubmit()" [formGroup]="ConvoyeurForm">
                    <div class="card card-primary">

                        <div class="card-header" style="background-color: #17a2b8;">
                            <h3 class="card-title">{{ "Convoyeur.ConvoyeurAdd" | translate }}</h3>
                        </div>
                        <div class="card-body" style="display: block;">
                            <div class="form-group">
                                <label>{{ "Convoyeur.UserContratLabel" | translate }}
                                  <span style="color: red;">*</span>
                                </label>
                                <select formControlName="user_id" class="form-control {{ isSubmitted && form.user_id.errors? 'is-invalid' : '' }}" style="width: 100%;" tabindex="-1" aria-hidden="true">
                                    <option value="">{{ "Contrat.ContratDriver" | translate }}</option>
                                    <option *ngFor="let driver of users?.data" value="{{driver.id}}">{{driver.name}}
                                    </option>
                                </select>
                                <label>Ajouter un utilisateur ent<a href="javascript:void(0)" (click)="addUser()">
                                  cliquant  ici </a> </label>
                            </div>
                            <div class="form-group">
                                <label for="inputName">{{ "Convoyeur.LicenceNumberLabel" | translate }}</label>
                                <input type="text" id="inputName"
                                    class="form-control {{ isSubmitted && form.licence_number.errors ? 'is-invalid' : '' }}"
                                    formControlName="licence_number"
                                    placeholder="{{ 'Convoyeur.LabelLicenceNumber' | translate }}">
                            </div>
                            <div class="form-group">
                                <label for="inputName">{{ "Convoyeur.LicenceTypeLabel" | translate }}</label>
                                <input type="text" id="inputName"
                                    class="form-control {{ isSubmitted && form.licence_type.errors ? 'is-invalid' : '' }}"
                                    formControlName="licence_type"
                                    placeholder="{{ 'Convoyeur.LabelLicenceType' | translate }}">
                            </div>
                            <div class="form-group">
                                <label for="date_of_issue">{{ "Convoyeur.DateIssueLabel" | translate }}</label>
                                <input type="date" id="date_of_issue"
                                    class="form-control {{ isSubmitted && form.date_of_issue.errors ? 'is-invalid' : '' }}"
                                    formControlName="date_of_issue"
                                    placeholder="{{ 'Convoyeur.LabelDateIssue' | translate }}">
                            </div>
                            <div class="form-group">
                                <label for="end_of_date">{{ "Convoyeur.EndDateLabel" | translate }}</label>
                                <input type="date" id="end_of_date"
                                    class="form-control {{ isSubmitted && form.end_of_date.errors ? 'is-invalid' : '' }}"
                                    formControlName="end_of_date"
                                    placeholder="{{ 'Convoyeur.LabelEndDate' | translate }}">
                            </div>
                            <div class="form-group">
                                <label for="inputName">{{ "Convoyeur.LicenceValidityFileLabel" | translate }}</label>
                                <input type="file" id="inputName" (change)=onSelectfile2($event)
                                    class="form-control {{ isSubmitted && form.licence_validity_file.errors ? 'is-invalid' : '' }}"
                                    formControlName="licence_validity_file"
                                    placeholder="{{ 'Convoyeur.LabellicenceValidityFile' | translate }}">
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                                <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                                <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                        <!-- /.card-body -->
                    </div>
                </form>
                <div *ngIf="isMaterial" class="col-md-2" style="margin-left:85%"><button class="btn btn-danger" (click)="close()">
                    {{
                    'Material.ButtonClose' | translate }}
                  </button></div>
                <!-- /.card -->
            </div>
        </div>
    </section>
    <!-- /.content -->
</div>
