import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';
import { PneuService } from 'src/app/_services/pneumatique/Pneu/pneu.service';

@Component({
  selector: 'app-pneu-update',
  templateUrl: './pneu-update.component.html',
  styleUrls: ['./pneu-update.component.scss']
})
export class PneuUpdateComponent implements OnInit {

  dataForm: FormGroup;
  isSubmitted: boolean = false;
  isLoading = false;
  isError = false;
  isSuccess = false;
  intervening_elements: any;
  marque_pneus: any;
  pneus: any;
  user;

  constructor(
    private pneuService: PneuService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getFournisseur();
    this.getMarque();
    this.initForm();
    this.user = this.authService.getUser();

    const pneu_id = +this.route.snapshot.paramMap.get("id");
    console.log(pneu_id);

    this.pneuService.find(pneu_id).then(
      data => {
        this.pneus = data;
        console.log(this.pneus);
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('pneus.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
      }
    )
  }

  initForm(withFiche = false) {
    if (withFiche) {
      this.dataForm = this.formBuilder.group({
        no_serie: [this.pneus.no_serie, Validators.required],
        etat_achat: [this.pneus.etat_achat, Validators.required],
        etat_actuel: [this.pneus.etat_actuel, Validators.required],
        date_achat: [this.pneus.date_achat, Validators.required],
        date_fabrication: [this.pneus.date_fabrication, Validators.required],
        dimensions: [this.pneus.dimensions, Validators.required],
        valeur_achat: [this.pneus.valeur_achat, Validators.required],
        duree_de_vie: [this.pneus.duree_de_vie, Validators.required],
        indice_de_charge: [this.pneus.indice_de_charge, Validators.required],
        indice_de_vitesse: [this.pneus.indice_de_vitesse, Validators.required],
        observation: [this.pneus.observation],
        type_achat: [this.pneus.type_achat, Validators.required],
        fournisseur: [this.pneus.fournisseur_id, Validators.required],
        marque_id: [this.pneus.marque_id, Validators.required],
      });
    }
    else {
      this.dataForm = this.formBuilder.group({
        no_serie: ['', Validators.required],
        etat_achat: ['', Validators.required],
        etat_actuel: ['', Validators.required],
        date_achat: ['', Validators.required],
        date_fabrication: ['', Validators.required],
        dimensions: ['', Validators.required],
        valeur_achat: ['', Validators.required],
        duree_de_vie: ['', Validators.required],
        indice_de_charge: ['', Validators.required],
        indice_de_vitesse: ['', Validators.required],
        observation: [''],
        type_achat: ['', Validators.required],
        fournisseur: ['', Validators.required],
        marque_id: ['', Validators.required],
      });
    }

  }

  public getMarque() {

    this.pneuService.getMarque().then(response => {
      this.marque_pneus = response;
      console.log(this.marque_pneus)
    })
      .catch(error => {
        this.notifService.danger(error.error.message)
      })
  }

  public getFournisseur() {
    this.pneuService.getFournisseur().then((res) => {
      this.intervening_elements = res;
      console.log(this.intervening_elements)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }


  get form() {
    return this.dataForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.dataForm.invalid) {
      this.translate.get('Pneu.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('no_serie', '' + this.form.no_serie.value);
    formData.append('etat_achat', '' + this.form.etat_achat.value);
    formData.append('etat_actuel', '' + this.form.etat_actuel.value);
    formData.append('date_achat', '' + this.form.date_achat.value);
    formData.append('date_fabrication', '' + this.form.date_fabrication.value);
    formData.append('dimensions', '' + this.form.dimensions.value);
    formData.append('valeur_achat', '' + this.form.valeur_achat.value);
    formData.append('duree_de_vie', '' + this.form.duree_de_vie.value);
    formData.append('indice_de_charge', '' + this.form.indice_de_charge.value);
    formData.append('observation', '' + this.form.observation.value);
    formData.append('type_achat', '' + this.form.type_achat.value);
    formData.append('indice_de_vitesse', '' + this.form.indice_de_vitesse.value);
    formData.append('fournisseur', '' + this.form.fournisseur.value);
    formData.append('marque_id', '' + this.form.marque_id.value);
    formData.append('create_id', '' + this.user.id);

    console.log(formData);
    this.pneuService.update(formData, this.pneus.id)
      .then(resp => {
        this.translate.get('Pneu.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.dataForm.reset();
        this.router.navigate(['/pneu/all/'])
      })
      .catch(err => {
        console.log(err)
        this.translate.get('Pneu.SubmitErrorPneu')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }

}
