import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { GammeEntretienService } from 'src/app/_services/maintenance-service/gamme-entretient/gamme-entretien.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-gamme-entretien-update',
  templateUrl: './gamme-entretien-update.component.html',
  styleUrls: ['./gamme-entretien-update.component.scss']
})
export class GammeEntretienUpdateComponent implements OnInit {

  GammeEntretientForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  typeEntretiens: any;
  mecaniciens: any;
  interventions: any;
  gammeEntretiens: any;
  limite: 10000;
  user;

  constructor(
    private GammeEntretienService: GammeEntretienService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.getTypeEntretien();
    this.getMecanicien();
    this.getIntervention();
    this.initForm();
    this.user = this.authService.getUser();

    const gamme_entretien_id = +this.route.snapshot.paramMap.get("id");
    this.GammeEntretienService.find(gamme_entretien_id).then(
      data => {
        this.gammeEntretiens = data;
        console.log(data);
        
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('gammeEntretiens.'+error.error.code)
        .subscribe(val => this.notifService.danger(val));
      }
    )
  }

  getTypeEntretien() {
    this.GammeEntretienService.getTypeEntretien().then(res => {
      this.typeEntretiens = res;
      console.log(this.typeEntretiens)
    }).catch( 
      error => {
        this.notifService.danger(error.error.message)
    })
  }

  getMecanicien() {
    this.GammeEntretienService.getMecanicien().then(res => {
      this.mecaniciens = res;
      console.log(this.mecaniciens)
    }).catch(
      error => {
      this.notifService.danger(error.error.message)
    })
  }

  getIntervention() {
    this.GammeEntretienService.getIntervention().then((res) => {
      this.interventions = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  } 

  initForm(withGammeEntretien = false) {
    if(withGammeEntretien){
      this.GammeEntretientForm = this.formBuilder.group(
        {
          type_entretien_id:[this.gammeEntretiens.type_entretien_id,[Validators.required]],
          intervention_id:[this.gammeEntretiens.intervention_id,[Validators.required]],
          mecanicien_id:[this.gammeEntretiens.mecanicien_id,[Validators.required]],
          prix:[this.gammeEntretiens.prix,[Validators.required]],
          frequence:[this.gammeEntretiens.frequence,[Validators.required]],
          nom:[this.gammeEntretiens.nom,[Validators.required]],
        }
      );
      }
    else{
      this.GammeEntretientForm = this.formBuilder.group(
        {
          type_entretien_id:['',[Validators.required]],
          intervention_id:['',[Validators.required]],
          mecanicien_id:['',[Validators.required]],
          prix:['',[Validators.required]],
          frequence:['',[Validators.required]],
          nom:['',[Validators.required]],
        }
      );
    }

  }

  get form() {
    return this.GammeEntretientForm.controls;
  }
  
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;
    

    if (this.GammeEntretientForm.invalid) {
    
      this.translate.get('GammeEntretien.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('type_entretien_id', ''+this.form.type_entretien_id.value);
    formData.append('intervention_id', ''+this.form.intervention_id.value);
    formData.append('mecanicien_id', ''+this.form.mecanicien_id.value);
    formData.append('prix', ''+this.form.prix.value);
    formData.append('frequence', ''+this.form.frequence.value);
    formData.append('nom', ''+this.form.nom.value);
    formData.append('create_id', ''+this.user.id);
  
      console.log(formData);
      this.GammeEntretienService.update(formData, this.gammeEntretiens.id)
      .then(resp => {
        this.translate.get('GammeEntretien.SubmitSuccess')
        .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.GammeEntretientForm.reset();
        this.router.navigate(['/gamme-entretien/all']);

      })
      .catch(err => {
        console.log(err)
        this.translate.get('GammeEntretien.SubmitErrorGammmeEntretien')
        .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }


}
