<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>{{ "Message.Title" | translate }}</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                        <li class="breadcrumb-item "><a
                                [routerLink]="['/message/all']">{{ "Message.Title" | translate }}</a>
                        </li>
                        <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>

                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->

    <section class="container">
        <div class="row">
            <div class="col-md-12">
                <form (submit)="onSubmit()" [formGroup]="MessageForm">
                    <div class="card card-primary">

                        <div class="card-header" style="background-color: #17a2b8;">
                            <h3 class="card-title">{{ "Message.MessageAdd" | translate }}</h3>
                        </div>

                        <div class="card-body" style="display: block;">
                            <div class="form-group">
                                <label>{{ "Message.Recipient" | translate }}
                                  <span style="color: red">*</span>
                                </label>
                                <select formControlName="user_id_2"
                                    class="form-control" style="width: 100%;"
                                    tabindex="-1" aria-hidden="true">
                                    <option value="">{{ "Message.Choix" | translate }}</option>
                                    <option *ngFor="let user of users?.data" value="{{user.id}}">{{user.name}} </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="inputName">{{ "Message.Subject" | translate }}
                                  <span style="color: red">*</span>
                                </label>
                                <input type="text" id="inputName"
                                    class="form-control {{ isSubmitted && form.suject.errors? 'is-invalid' : '' }}" formControlName="suject"
                                    placeholder="{{ 'Message.SubjectAdd' | translate }}">
                            </div>
                            <div class="form-group">
                                <label for="inputName">{{ "Message.Content" | translate }}
                                  <span style="color: red">*</span>
                                </label>
                                <textarea cols="30" rows="10" type="text" id="inputName"
                                    class="form-control {{ isSubmitted && form.content.errors? 'is-invalid' : '' }}"
                                    formControlName="content"
                                    placeholder="{{ 'Message.ContentAdd' | translate }}"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="inputName">{{ "Message.FileLabel" | translate }}</label>
                                <input type="file" id="inputName" (change)=onSelectfile($event)
                                  class="form-control" formControlName="file"
                                  placeholder="{{ 'Message.LabelFile' | translate }}">
                              </div>

                        </div>
                        <div class="card-footer">
                            <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                                <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                                <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                            </button>
                        </div>
                        <!-- /.card-body -->
                    </div>
                </form>
                <!-- /.card -->
            </div>
            <div *ngIf="isMaterial" class="col-md-2" style="margin-left:85%">
                <button class="btn btn-danger" (click)="onClose()">
                    {{'Material.ButtonClose' | translate }}
                </button>
            </div>
        </div>
    </section>
    <!-- /.content -->
</div>
