<!-- 
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 -->
<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 1%; margin-right: 1%;">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>{{ "Affectation.AffectationDetail" | translate }}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/affectations/all']">{{ "Affectation.Affectation" |
                translate }}</a></li>
            <li class="breadcrumb-item active">{{ "FileAriane.Detail" | translate }}</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>

  <!-- Main content -->
  <section class="content">

    <!-- Default box -->
    <div class="card card-solid">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="col-md-12">
              <!-- USERS LIST -->
              <div class="card">
                <div class="card-body p-0" style="margin-left: 40%;">
                  <ul class="users-list clearfix text-center" style="width: 200%; height: 50%;">
                    <li>
                      <img src="{{driver?.driver_photo}}" alt="Driver Image">
                      <h3 style="font-size: 200%; color:rgb(0, 89, 255);">{{driver?.driver_name}}</h3>
                    </li>
                  </ul>
                  <!-- /.users-list -->
                </div>
                <!-- /.card-body -->
              </div>
              <!--/.card -->
            </div>
            <div class="col-12">
              <img src="{{defautlImage}}" class="product-image" alt="photo before vehicle" style="height: 550px;">
            </div>
            <div class="col-12 product-image-thumbs">
              <div class="row center">


                <div class="product-image-thumb col-12" (click)=onClick(driver.photo_before_vehicle)
                  title="Image Avant"><img src="{{driver?.photo_before_vehicle}}" alt="photo before vehicle"
                    style="height: 60px; width: 100px; "></div>
                <div class="product-image-thumb col-12" (click)=onClick(driver.photo_rear_vehicle)
                  title="Image arrière"><img src="{{driver?.photo_rear_vehicle}}" alt="photo rear vehicle"
                    style="width: 100px; height: 60px;">
                </div>
                <div class="product-image-thumb col-12 col-md-6" (click)=onClick(driver.photo_leftside_vehicle)
                  title="Image côté gauche"><img src="{{driver?.photo_leftside_vehicle}}" alt="photo leftside vehicle"
                    style="width: 100px; height: 60px;"></div>
                <div class="product-image-thumb col-12 col-md-6" (click)=onClick(driver.photo_rightside_vehicle)
                  title="Image côté droit"><img src="{{driver?.photo_rightside_vehicle}}" alt="photo rightside vehicle"
                    style="width: 100px; height: 60px;"></div>
                <div class="product-image-thumb col-12 col-md-6" (click)=onClick(driver.car_registration_photo)
                  title="Image immatriculation des véhicules"><img src="{{driver?.car_registration_photo}}"
                    alt="car registration photo" style="width: 100px; height: 60px;"></div>
              </div>


            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="card card-primary card-outline">
              <div class="card-header">
                <h3 class="card-title">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">{{ "Affectation.Message" | translate }}</font>
                  </font>
                </h3>
                <!-- /.card-tools -->
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <div class="table-responsive mailbox-messages">
                  <table class="table table-hover table-striped">
                    <tbody>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.vehicle_registration" | translate
                                }}</font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.vehicle_registration}}</font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.vehicle_condition" | translate }}
                              </font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.vehicle_condition}}</font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.color" | translate }}</font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.color}}</font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.date_of_affectation" | translate }}
                              </font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.date_of_affectation}}</font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.end_date" | translate }}
                              </font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.end_date}}
                              <span *ngIf="driver?.end_date == null">{{ "Affectation.end_dateFaillure" | translate
                                }}</span>
                            </font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.place_number" | translate }}</font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.place_number}}</font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.power" | translate }}</font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.power}}</font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.milage" | translate }}</font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.milage}}</font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.max_consumption" | translate }}
                              </font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.max_consumption}}</font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.volume" | translate }}</font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.volume}}</font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.total_load_weight" | translate }}
                              </font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.total_load_weight}}</font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.purchase_value" | translate }}
                              </font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.purchase_value}}</font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.length" | translate }}</font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.length}}</font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.width" | translate }}</font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.width}}</font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.height" | translate }}</font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.height}}</font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.empty_weight" | translate }}</font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.empty_weight}}</font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.payload" | translate }}</font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.payload}}</font>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                        <td class="mailbox-name"><a style="color: rgb(0, 89, 255);">
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">{{ "Affectation.required_working_time" | translate
                                }}</font>
                            </font>
                          </a></td>
                        <td class="mailbox-date">
                          <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{driver?.required_working_time}}</font>
                          </font>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- /.table -->
                </div>
                <!-- /.mail-box-messages -->
              </div>
              <!-- /.card-body -->


            </div>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->

  </section>
  <!-- /.content -->
</div>