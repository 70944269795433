import { Component, OnInit } from '@angular/core';
import { MecanicienService } from 'src/app/_services/RH-service/mecanicien/mecanicien.service';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-mecanicien-detail',
  templateUrl: './mecanicien-detail.component.html',
  styleUrls: ['./mecanicien-detail.component.scss']
})
export class MecanicienDetailComponent implements OnInit {


  mecanicien: any;
  url;

  constructor(
    private MecanicienService: MecanicienService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,

  ) { }

  async ngOnInit() {
    const mecanicienId = +this.route.snapshot.paramMap.get("id");
    console.log(mecanicienId);
    this.MecanicienService.find(mecanicienId).then(
      data => {
        this.mecanicien = data;
        console.log(this.mecanicien)
      }
    ).catch(
      error => {
        this.translate.get('mecanicien.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/mecanicien/all'])
      }
    )
  }

}
