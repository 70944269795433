<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'Assurance.Assurance' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">Home</a></li>
                    <li class="breadcrumb-item active"><a [routerLink]="['/all_assurrance']">{{ 'Assurance.Assurance' |
                            translate }}</a>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="container">
    <div class="container-fluid">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">

                    <div class="position-relative mb-4">
                        <!-- <div class="chartjs-size-monitor">
                                <div class="chartjs-size-monitor-expand">
                                    <div class=""></div>
                                </div>
                                <div class="chartjs-size-monitor-shrink">
                                    <div class=""></div>
                                </div>
                            </div> -->
                        <div class="col-sm-12" >
                            <img style="height:400px; margin-left:10%" class="img-fluid" class="center"
                                src="{{assurance?.attachment_file}}" alt="photo">
                        </div>

                    </div>

                </div>
            </div>

        </div>
        <div class="row">

            <div class="col-12">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">{{ "Assurance.Detail" | translate}}</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Assurance.vehicle_registration" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{assurance?.vehicle_registration}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Assurance.created_at" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{assurance?.created_at}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Assurance.insurance_policy_number" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{assurance?.insurance_policy_number}}</td>
                                </tr>

                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Assurance.insurance_policy_value" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{assurance?.insurance_policy_value}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Assurance.Validity" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);"> {{ "Assurance.ValidityLabel" | translate }}
                                        {{assurance?.validity_start_at | date: 'yy-mm-dd'}} {{ "Assurance.Between" |
                                        translate }} {{assurance?.validity_end_at | date : 'yy-mm-dd'}}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>


            <!-- /.col-md-6 -->
        </div>

    </div>
    <!-- /.container-fluid -->
</div>
