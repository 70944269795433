import { Component, OnInit } from '@angular/core';
import { ContratService } from 'src/app/_services/RH-service/contrat/contrat.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { CreateUsersComponent } from '../../../users/create-users/create-users.component';
import { TypeContratAddComponent } from '../../type-contrat/type-contrat-add/type-contrat-add.component';



@Component({
  selector: 'app-contrat-add',
  templateUrl: './contrat-add.component.html',
  styleUrls: ['./contrat-add.component.scss']
})
export class ContratAddComponent implements OnInit {

  ContratForm: FormGroup;
  typeContrats: any;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  contract_file: File = null;
  user;
  users;

  constructor(
    private ContratService: ContratService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private userDialog: MatDialog,
    private ContractTypeDialog: MatDialog
  ) { }

  ngOnInit() {
    this.getTypeContrat();
    this.getUsers();
    this.user = this.authService.getUser();

    this.ContratForm = this.formBuilder.group({
      user_id: ['', [Validators.required]],
      contract_type_id: ['', [Validators.required]],
      department: ['', [Validators.required]],
      non_taxable_bonus: ['', [Validators.required]],
      start_date: ['', [Validators.required]],
      end_date: ['', [Validators.required]],
      basic_salary: ['', [Validators.required]],
      extra_cost_per_hour: ['',[Validators.required]],
      supervisor: ['', [Validators.required]],
      prime_bonus: [''],
      contract_file: [''],
      bank_name: [''],
      bank_account_number: [''],


    });
  }

  getTypeContrat() {
    this.ContratService.getTypeContrat().then((res) => {
      this.typeContrats = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  getUsers() {
    this.ContratService.getUser().then((res) => {
      this.users = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  get form() {
    return this.ContratForm.controls;
  }

  onSelectfile(event) {
    this.contract_file = event.target.files[0];
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    let pipe = new DatePipe('en-US');
    let date = new Date();
    let currentDate = pipe.transform(date, 'yyyy-MM-dd');


    if (this.ContratForm.invalid) {
      this.translate.get('Contrat.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('user_id', '' + this.form.user_id.value);
    formData.append('contract_type_id', '' + this.form.contract_type_id.value);
    formData.append('department', '' + this.form.department.value);
    formData.append('bank_name', '' + this.form.bank_name.value);
    formData.append('bank_account_number', '' + this.form.bank_account_number.value);
    if (this.form.non_taxable_bonus.value > 0) {
      formData.append('non_taxable_bonus', '' + this.form.non_taxable_bonus.value);
    } else {
      this.translate.get('Form.non_taxable_bonusError')
        .subscribe(val => this.notifService.danger(val));
      this.isLoading = false;
      return;
    }
    if (this.form.basic_salary.value > 0) {
      formData.append('basic_salary', '' + this.form.basic_salary.value);
    } else {
      this.translate.get('Form.basic_salaryError')
        .subscribe(val => this.notifService.danger(val));
      this.isLoading = false;
      return;
    }
    if (this.form.extra_cost_per_hour.value > 0) {
      formData.append('extra_cost_per_hour', '' + this.form.extra_cost_per_hour.value);
    } else {
      this.translate.get('Contrat.extra_cost_per_hourError')
        .subscribe(val => this.notifService.danger(val));
      this.isLoading = false;
      return;
    }
    formData.append('prime_bonus', '' + this.form.prime_bonus.value);

   /*  if (this.form.prime_bonus.value > 0) {
      formData.append('prime_bonus', '' + this.form.prime_bonus.value);
    } else {
      this.translate.get('Contrat.prime_bonusError')
        .subscribe(val => this.notifService.danger(val));
      this.isLoading = false;
      return;
    } */
    formData.append('supervisor', '' + this.form.supervisor.value);
    if (currentDate > this.form.start_date.value) {
      this.translate.get('Contrat.StartDateError')
        .subscribe(val => this.notifService.danger(val));
      this.isLoading = false;
      return;
    } else {
      formData.append('start_date', '' + this.form.start_date.value);
    }

    if (this.form.start_date.value > this.form.end_date.value) {
      this.translate.get('Contrat.EndDateError')
        .subscribe(val => this.notifService.danger(val));
      this.isLoading = false;
      return;
    } else {
      formData.append('end_date', '' + this.form.end_date.value);
    }
    if (this.contract_file != null)
      formData.append('contract_file', this.contract_file, this.contract_file.name);


    console.log(formData);
    this.ContratService.post(formData)
      .then(resp => {
        this.translate.get('Contrat.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.ContratForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('Contrat.SubmitErrorContrat')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }

  addUser() {
    var modelDialogef =  this.userDialog.open(CreateUsersComponent,{
       width: '1000px',
       height: '700px',
       disableClose: true
     });

     modelDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);

       this.ngOnInit();
     });
   }

   addContactType(){
    var modelDialogef =  this.userDialog.open(TypeContratAddComponent,{
      width: '700px',
      height: '700px',
      disableClose: true
    });

    modelDialogef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      this.ngOnInit();
    });


   }

}
