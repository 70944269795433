import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { AutoService } from 'src/app/_services/parc-service/auto-service/auto-service';
import { MecanicienService } from 'src/app/_services/RH-service/mecanicien/mecanicien.service';
import { ProgrammesEntretientService } from 'src/app/_services/maintenance/entretients/programmes-entretient.service';
import { GammeEntretienService } from 'src/app/_services/maintenance-service/gamme-entretient/gamme-entretien.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MecanicienAddComponent } from '../../../../human-R/mecanicien/mecanicien-add/mecanicien-add.component';
import { AutoAddComponent } from '../../../../auto-parc/auto/auto-add/auto-add.component';
import { GammeEntretienAddComponent } from '../../../gamme-entretien/gamme-entretien-add/gamme-entretien-add.component';


@Component({
  selector: 'app-programmes-entretient-add',
  templateUrl: './programmes-entretient-add.component.html',
  styleUrls: ['./programmes-entretient-add.component.scss']
})
export class ProgrammesEntretientAddComponent implements OnInit {

  user:any;
  ProgrammesEntretientForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  page=1;
  gammes_entretient:any;
  vehicules:any;
  mecaniciens:any;
  pipe = new DatePipe('en-US');
  date = new Date();
  currentDate = this.pipe.transform(this.date, 'yyyy-MM-dd');
  isMaterial: boolean = false;

  constructor(
    private autoService: AutoService,
    private mecanicienService: MecanicienService,
    private gammeEntretientService: GammeEntretienService,
    private programmeEntretientService: ProgrammesEntretientService,

    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private userDialog: MatDialog,
    private ref: MatDialog,
  ) {}

  ngOnInit() {

    this.getVehicules();
    this.getMecaniciens(this.page);
    this.getgamme();
    this.ProgrammesEntretientForm = this.formBuilder.group({
      compteur:['',[Validators.required]],
      date_entretient:['',[Validators.required]],
      km_prevu:['',[Validators.required]],
      heure_prevu:['',[Validators.required]],
      cout:['',[Validators.required]],
      gamme_entretient:['',[Validators.required]],
      vehicule:['',[Validators.required]],
      mecanicien:['',[Validators.required]],
      next_date_entretient:[''],
      next_km_prevu:[''],
      next_heure_prevu: [''],
      description:[''],
    }),
    this.user = this.authService.getUser(),
    this.isMaterial = '/programme-entretient/add' != location.pathname;
  }

  get form() {
    return this.ProgrammesEntretientForm.controls;
  }
  getMecaniciens(page) {
    this.mecanicienService.gets(page).then((res) => {
      this.mecaniciens = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  getVehicules() {
    this.autoService.all().then((res) => {
      this.vehicules = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  getgamme() {
    this.gammeEntretientService.gets(this.page).then((res) => {
      this.gammes_entretient = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  verifNext(){
  /*   if(this.form.next_date_entretient || this.form.next_km_prevu || this.form.next_heure_prevu){
      this.ProgrammesEntretientForm = this.formBuilder.group({
      next_date_entretient:['',[Validators.required]],
      next_km_prevu:['',[Validators.required]],
      next_heure_prevu: ['',[Validators.required]],
      })
      if(this.form.next_date_entretient.value < this.form.date_entretient.value){
        this.translate.get('ProgrammesEntretient.NextDateEntretientError')
        .subscribe(val => this.notifService.danger(val));
      return;
      }
    } */
  }
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    this.verifNext();
    if (this.ProgrammesEntretientForm.invalid) {
      this.translate.get('ProgrammesEntretient.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }
    console.log(this.form.date_entretient.value, this.currentDate)
    if(this.form.date_entretient.value < this.currentDate){
      this.translate.get('ProgrammesEntretient.DateEntretientError')
      .subscribe(val => this.notifService.danger(val));
      return;
    }
    console.log(this.form.next_date_entretient.value)
    this.isLoading = true;
    const formData = new FormData();
    formData.append('compteur', ''+this.form.compteur.value);
    formData.append('date_entretien', ''+this.form.date_entretient.value);
    formData.append('km_prevu', ''+this.form.km_prevu.value);
    formData.append('date_prochain_entretien', ''+this.form.next_date_entretient.value);
    formData.append('heure_prevu', ''+this.form.heure_prevu.value);
    formData.append('heure_prevu_next', ''+this.form.next_heure_prevu.value);
    formData.append('km_prevu_next', ''+this.form.next_km_prevu.value);
    formData.append('cout_intervention', ''+this.form.cout.value);
    formData.append('gamme_entretien_id', ''+this.form.gamme_entretient.value);
    formData.append('vehicule_id', ''+this.form.vehicule.value);
    formData.append('mecanicien_id', ''+this.form.mecanicien.value);
    formData.append('description', ''+this.form.description.value);
    formData.append('create_id', ''+this.user.id);


      console.log(formData);
      this.programmeEntretientService.post(formData)
      .then(resp => {
        this.translate.get('ProgrammesEntretient.SubmitSuccess')
        .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.ProgrammesEntretientForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('ProgrammesEntretient.SubmitErrorIntervention')
        .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }
onClose() {
    this.ref.closeAll();
    // location.reload();
  }
  addMecano() {
    var modelDialogef =  this.userDialog.open(MecanicienAddComponent,{
       width: '1000px',
       height: '700px',
       disableClose: true
     });
 
     modelDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);
 
       this.ngOnInit();
     });
   }
   addAuto() {
    var modelDialogef =  this.userDialog.open(AutoAddComponent,{
       width: '1000px',
       height: '700px',
       disableClose: true
     });
 
     modelDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);
 
       this.ngOnInit();
     });
   }
   addGamme() {
    var modelDialogef =  this.userDialog.open(GammeEntretienAddComponent,{
       width: '1000px',
       height: '700px',
       disableClose: true
     });
 
     modelDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);
 
       this.ngOnInit();
     });
   }

}
