<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>{{ "Formation.Formation" | translate }}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/formation/all']">{{ "Formation.Formation" | translate
                }}</a></li>
            <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>

          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>

  <!-- Main content -->

  <section class="container">
    <div class="row">
      <div class="col-md-12">
        <form (submit)="onSubmit()" [formGroup]="FormationForm">
          <div class="card card-primary" >

            <div class="card-header" style="background-color: #17a2b8;"> 
              <h3 class="card-title">{{ "Formation.FormationAdd" | translate }}</h3>
            </div>
            <div class="card-body" style="display: block;">
              <div class="form-group">
                <label>{{ "Formation.DriverLabel" | translate }} <span style="color: red;">*</span> </label>
                <select formControlName="user_id" class="form-control {{ isSubmitted && form.user_id.errors? 'is-invalid' : '' }}"
                  style="width: 100%;" tabindex="-1" aria-hidden="true">
                  <option value="">{{ "Formation.LabelDriver" | translate }}</option>
                  <option *ngFor="let driver of users?.data" value="{{driver.id}}">{{driver.name}}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="inputName">{{ "Formation.NameLabel" | translate }} <span style="color: red;">*</span> </label>
                <input type="text" id="inputName" class="form-control {{ isSubmitted && form.name.errors? 'is-invalid' : '' }}"
                  formControlName="name" placeholder="{{ 'Formation.LabelName' | translate }}">
              </div>
              <div class="form-group">
                <label for="inputName">{{ "Formation.DescriptionLabel" | translate }}</label>
                <textarea cols="30" rows="5" id="inputName" class="form-control"
                  formControlName="description" placeholder="{{ 'Formation.LabelDescription' | translate }}"></textarea>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">
                    {{ "Formation.DateDebutFormationLabel" | translate
                    }}

                </label>
                <input type="date"
                    class="form-control {{ isSubmitted && form.date_debut.errors ? 'is-invalid' : '' }}"
                    id="exampleInputEmail1" formControlName="date_debut"
                    placeholder="{{ 'Formation.LabelDateDebutFormation' | translate }}">
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">
                  {{ "Formation.DateFinFormationLabel" | translate
                  }}


                </label>
                <input type="date"
                  class="form-control {{ isSubmitted && form.date_fin.errors ? 'is-invalid' : '' }}"
                  id="exampleInputEmail1" formControlName="date_fin"
                  placeholder="{{ 'Formation.LabelDateFinFormation' | translate }}">
              </div>
              <div class="form-group">
                <label for="inputName">{{ "Formation.FileLabel" | translate }}</label>
                <input type="file" id="inputName" (change)=onSelectfile($event)
                  class="form-control" formControlName="file"
                  placeholder="{{ 'Formation.LabelFile' | translate }}">
              </div>
            </div>
            <div class="card-footer">
              <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
            <!-- /.card-body -->
          </div>
        </form>
        <!-- /.card -->
      </div>
    </div>
  </section>
  <!-- /.content -->
</div>