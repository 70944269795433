import { Component, OnInit } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2'
import { TypeSanctionService } from 'src/app/_services/RH-service/sanction/type-sanction.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-type-sanction-update',
  templateUrl: './type-sanction-update.component.html',
  styleUrls: ['./type-sanction-update.component.scss']
})
export class TypeSanctionUpdateComponent implements OnInit {

  TypeSanctionForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  logo:File=null;

  typeSanctions:any;
 

  constructor(
    private TypeSanctionService: TypeSanctionService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
   }

  ngOnInit() {
    this.initForm();
    const type_sanction_id = +this.route.snapshot.paramMap.get("id");
    console.log(type_sanction_id);
    this.TypeSanctionService.find(type_sanction_id).then(
      data => {
        this.typeSanctions = data;
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('TypeSanctions.'+error.error.code)
        .subscribe(val => this.notifService.danger(val));
      }
    )
  }

     //initialisation du formulaire
     initForm(withTypeSanction = false) {
      if ( withTypeSanction ){
        this.TypeSanctionForm = this.formBuilder.group(
          {
            name: [this.typeSanctions.name, [Validators.required]],
            description: [this.typeSanctions.description],
          }
        );
        }
      else{
        this.TypeSanctionForm = this.formBuilder.group(
          {
            name: ['', [Validators.required]],
            description: [''],
          }
        );
      }
    }

  get form() {
    return this.TypeSanctionForm.controls;
  }
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.TypeSanctionForm.invalid) {
      this.translate.get('TypeSanction.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('name', ''+this.form.name.value);
    formData.append('description', ''+this.form.description.value);

      console.log(formData);
      this.TypeSanctionService.update(formData, this.typeSanctions.id)
      .then(resp => {
        this.translate.get('TypeSanction.SubmitSuccess')
        .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.TypeSanctionForm.reset();
        this.router.navigate(['/type-sanction/all'])

      })
      .catch(err => {
        console.log(err)
        this.translate.get('TypeSanction.SubmitErrorTypeSanction')
        .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }

 
}
