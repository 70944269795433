import { Component, OnInit } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2'
import { DocService } from '../../../../../../_services/parc-service/doc-service/doc-service';
import { TranslateService } from '@ngx-translate/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { of, Observable } from 'rxjs';


@Component({
  selector: 'app-official-paper-all',
  templateUrl: './official-paper-all.component.html',
  styleUrls: ['./official-paper-all.component.scss']
})
export class OfficialPaperAllComponent implements OnInit {

  id :any
  papers: any;
  assur: any;
  loading: boolean = true;
  detail=false;
  type : any;
 
  @BlockUI() blockUI: NgBlockUI;

  //SweetAlert Text
  areYouSure = ' etes vous sure';
  warning = 'warning'
  yes = 'yes';
  no = 'no';
  deleted = 'deleted';
  deletedMessage = 'deletedMessage';
  cancelled = 'cancelled';
  cancelledMessage = 'cancelledMessage';
  canCreate = false;
  canUpdate = false;
  canDelete = false;

  constructor(
    private docService: DocService,
    //private notifService: NotifService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
  ) { 
    this.translate.get(
      ['SweetAlert.AreYouSure', 'SweetAlert.Warning', 'SweetAlert.Yes', 'SweetAlert.No', 'SweetAlert.Deleted',
        'SweetAlert.DeletedMessage', 'SweetAlert.Cancelled', 'SweetAlert.CancelledMessage'],
      { data: 'cet element'  })
      .subscribe(val => {
        this.areYouSure = val['SweetAlert.AreYouSure'];
        this.warning = val['SweetAlert.Warning'];
        this.yes = val['SweetAlert.Yes'];
        this.no = val['SweetAlert.No'];
        this.deleted = val['SweetAlert.Deleted'];
        this.deletedMessage = val['SweetAlert.DeletedMessagePro'];
        this.cancelled = val['SweetAlert.Cancelled'];
        this.cancelledMessage = val['SweetAlert.CancelledMessage'];
      });
  }

  ngOnInit(): void {
    const paper_id = +this.route.snapshot.paramMap.get("id")

    const type_id = of(paper_id);

    type_id.subscribe((value) => {
      console.log('')
    });

    this.docService.findType(paper_id).then(
      data => {
        this.type = data;
  }).catch(
    error => {
      this.toastr.warning('opertion echoue');
    }
  );
  this.getPaper();

  }

  getPaper(){
    this.docService.allOfficialPaper().then(resp =>{
      this.papers = resp ;
      console.log(this.papers)
    }).catch(
      error => {
        this.toastr.error('L Operation a echoue');
      }
    ).finally(
      () => {
        this.loading = false;
      }
    )
  }


  getPage(url){
    this.docService.getPage(url).then(resp =>{
      this.papers = resp ;
    }).catch(
      error => {
        this.toastr.warning('Page Terminer');
      }
    ).finally(
      () => {
        this.loading = false;
      }
    )
  }

  editPaper(id) {
    this.router.navigate(['official_update/', id]);
  }

  ajout(id){
    this.router.navigate(['official_add/' , id]);
  }

  detailPaper(paper) {
    //this.router.navigate(['paper_update/', id])
    this.assur = paper;
    this.detail=true;
  }

  deletePaper(paper) {
    Swal.fire({
      title: this.areYouSure,
      text: this.warning,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.yes,
      cancelButtonText: this.no
    }).then((result) => {
      if (result.value) {
        this.blockUI.start('Loading...');
        this.docService.deleteOfficialPaper(paper.id).then(
          data => {
            this.blockUI.stop();
            Swal.fire(
              this.deleted,
              this.deletedMessage,
              'success'
            )
            this.getPaper();
          }
        ).catch(
          error => {
            console.log(error)
            this.blockUI.stop();
            this.toastr.error('L Operation a echoue');
          }
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.cancelledMessage,
          'error'
        )
      }
    })
  }


  cancel(){
    this.detail=false;
  }

}
