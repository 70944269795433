import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PanneServices } from 'src/app/_services/main-service/panne-services/panne-services';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-panne-auto-update',
  templateUrl: './panne-auto-update.component.html',
  styleUrls: ['./panne-auto-update.component.scss']
})
export class PanneAutoUpdateComponent implements OnInit {

  forme = "Mark"
  panne: any;
  // panneForm: FormGroup;
  typemarks: any;
  isSubmitted: boolean = false;
  isLoading = false;
  vehicules: any;
  user;
  myFiles:string [] = [];
  missions:any;
  isError = false;
  isSuccess = false;
  dataForm: FormGroup;



  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private panneService: PanneServices,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private authService: AuthService,
    private notifService: NotifService


  ) { }

  ngOnInit(): void {
    this.getVehicules();
    this.getMissions();
    this.user = this.authService.getUser();
    this.initForm();
    const mod_id = +this.route.snapshot.paramMap.get("id");
    this.panneService.find(mod_id).then(
      data => {
        this.panne = data;
        this.initForm(true);
        console.log(this.panne)
      }
    ).catch(
      error => {
        this.translate.get('panne.'+error.code)
        .subscribe();
      }
    )
  }

  initForm(withpanne = false) {
    if(withpanne) {
      this.dataForm = this.formBuilder.group({
        vehicule_id: [this.panne.vehicule_id, [Validators.required]],
        mission_id: [this.panne.mission_id, [Validators.required]],
        gerer: [this.panne.gerer],
        lieu: [this.panne.lieu, [Validators.required]],
        nature: [this.panne.nature, [Validators.required]],
        description: [this.panne.description],
        files: [this.panne.files]
        
      });
    }else {
      this.dataForm = this.formBuilder.group({
        lieu: ['', [Validators.required]],
        nature: ['', [Validators.required]],
        mission_id: ['', [Validators.required]],
        gerer: [''],
        vehicule_id: ['', [Validators.required]],
        description: [''],
        files: [''],
      }); 
    }
  }

  get form() {
    return this.dataForm.controls;
  }


  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.dataForm.invalid) {
      this.translate.get('Panne.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }
    this.isLoading = true;
    const formData = new FormData();
    formData.append('vehicule_id', '' + this.form.vehicule_id.value);
    formData.append('mission_id', '' + this.form.vehicule_id.value);
    formData.append('gerer', '' + this.form.gerer.value);
    formData.append('description', '' + this.form.description.value);
    formData.append('nature', '' + this.form.nature.value);
    formData.append('lieu', '' + this.form.lieu.value);
    for (var i = 0; i < this.myFiles.length; i++){
      formData.append("files[]", this.myFiles[i]);
    }
    formData.append('create_id', '' + this.user.id);
    formData.append('driver_id','1' );
    // this.panneService.update(formData, this.panne.id)
    //   .then(resp => {
    //     this.translate.get('panne.updatePanne')
    //     .subscribe();
    //     this.router.navigate(['/panne/list'])
    //   })
    //   .catch(err => {
    //     console.log(err)
    //     this.isSubmitted = false;
    //     this.isLoading = true;
    //   })
    //   .finally(() => this.isLoading = false); 
    console.log(formData);
    this.panneService.update(formData, this.panne.id)
      .then(resp => {
        this.translate.get('Panne.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.dataForm.reset();
        this.router.navigate(['/panne/list'])
      })
      .catch(err => {
        console.log(err)
        this.translate.get('panne.SubmitErrorPanne')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }


  public getVehicules() {

    this.panneService.getvehicle().then(
      response => {
        this.vehicules = response;
        console.log(this.vehicules)
      })
      .catch(
      error => {
        this.toastrService.error(error.message);
      })
    .finally(() => {})
  }

  getMissions() {
    this.panneService.getMissions().then((res) => {
      this.missions = res;
      console.log(this.missions)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  detectFile(event){ 
    for (var i = 0; i < event.target.files.length; i++){
      this.myFiles.push(event.target.files[i]);
    }
  }

}
