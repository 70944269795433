<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ "Forms.Models.Models" | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <!-- 
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item">Forms</li>
            <li class="breadcrumb-item active">Liste</li> -->

                    <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                    <li class="breadcrumb-item "><a [routerLink]="['/setting/forms/model/list']">{{
                            "Forms.Models.Models" | translate }}</a></li>
                    <li class="breadcrumb-item active">{{ "FileAriane.List" | translate }}</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Ajouter un modèle -->
<div class="container-fluid">
    <!-- Main content -->
    <section class="content">
        <div class="card card-info">
            <section class="content">
                <div class="card-header" style="background-color: #17a2b8; color: white;">
                    <h2 class="card-title" style="margin-left: 1%; margin-bottom: 1%; margin-top: 1%;">List des modèles
                    </h2>
                    <button *ngIf="canCreate" [routerLink]="['/setting/forms/model/add']" type="button"
                        class="btn btn-primary float-right"
                        style="margin-right: 1%; margin-bottom: 1%; margin-top: 1%;">
                        <i class="fas fa-plus"></i> {{ "Forms.Marques.Ajouter" | translate }}
                    </button>
                </div>
            </section>


            <div class="card-body p-1">
                <table id="example1" class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th style="width: 10%;">#</th>
                            <th style="width: 20%;">{{ "Forms.Models.Nom" | translate }}</th>
                            <th style="width: 30%;">{{ "Forms.Models.Description" | translate }}</th>
                            <th style="width: 20%;">{{ "Forms.Models.Date" | translate }}</th>
                            <th style="width: 20%;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let modele of modeles,let i = index" id="{{ modele.id }}">
                            <td>{{i+1}}</td>
                            <td>{{modele.name}} </td>
                            <td>{{modele.description | slice:0:60}}</td>
                            <td>{{modele.created_at | date: 'dd-mm-yyyy'}}</td>
                            <!--  <td class="text-right py-0 align-middle">
                            <div class="btn-group btn-group-sm">
                                <a class="btn btn-primary btn-sm" (click)="detailsModel(modele)"><i class="fas fa-folder" ></i></a>
                                <a class="btn btn-info btn-sm"  (click)="editModel(modele)"><i class="fas fa-pencil-alt"></i></a>
                                <a class="btn btn-danger btn-sm" (click)="deleteModel(modele)"><i class="fas fa-trash"> </i></a>
                            </div>
                        </td> -->
                            <td class="project-actions text-right">
                                <a class="btn btn-primary btn-sm" (click)="detailsModel(modele)"
                                    href="javascript:void(0)" data-bs-placement="top" title="details">
                                    <i class="fas fa-eye">
                                    </i>
                                </a>
                                &nbsp;
                                <a *ngIf="canUpdate" class="btn btn-info btn-sm" (click)="editModel(modele)"
                                    href="javascript:void(0)" data-bs-placement="top" title="modifier">
                                    <i class="fas fa-pencil-alt">
                                    </i>
                                </a>
                                &nbsp;
                                <a *ngIf="canDelete" class="btn btn-danger btn-sm" (click)="deleteModel(modele)"
                                    href="javascript:void(0)" data-bs-placement="top" title="supprimer">
                                    <i class="fas fa-trash">
                                    </i>
                                </a>
                                &nbsp;
                                <a class="btn btn-danger btn-sm" (click)="modelPneu(modele)" href="javascript:void(0)"
                                    data-bs-placement="top" title="position">
                                    <i class="fas fa-location"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->
    </section>
    <!-- /.content -->
</div>