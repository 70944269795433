<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'Pneu.Pneu' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">{{ "FileAriane.Home" | translate }}</a></li>
                    <li class="breadcrumb-item active"><a [routerLink]="['/pneu/all']">{{ 'Pneu.Pneu' | translate }}</a>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="container">
    <div class="container-fluid">
        <div class="row">

            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header" style="background-color: #17a2b8;">
                        <h3 class="card-title">Informations Detaillées sur le pneu</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Pneu.MarqueLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{pneus?.marque}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Pneu.FournisseurLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{pneus?.fournisseur}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Pneu.NoSerieLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{pneus?.no_serie}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Pneu.Etat_achatLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{pneus?.etat_achat}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Pneu.Date_achatLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{pneus?.date_achat}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Pneu.Valeur_achatLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{pneus?.valeur_achat}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Pneu.Type_achatLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{pneus?.type_achat}}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header" style="background-color: #17a2b8;">
                        <h3 class="card-title">Informations Detaillées sur le pneu</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Pneu.Date_fabricationLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{pneus?.date_fabrication}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Pneu.Etat_actuelLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{pneus?.etat_actuel}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Pneu.Duree_de_vieLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{pneus?.duree_de_vie}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Pneu.DimensionsLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{pneus?.dimensions}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Pneu.Indice_de_chargeLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{pneus?.indice_de_charge}}<i>Kg</i></td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Pneu.Indice_de_vitesseLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{pneus?.indice_de_vitesse}}<i>Km</i></td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Pneu.ObservationLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">
                                        <p>{{pneus?.observation}}</p>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <!-- /.col-md-6 -->
        </div>

    </div>
    <!-- /.container-fluid -->
</div>