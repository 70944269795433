<div class="content-wrapper" style="min-height: 1200.88px;  margin-left: 1%; margin-right: 1%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>{{ "Formation.Formation" | translate }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
              <li class="breadcrumb-item "><a [routerLink]="['/formation/all']">{{ "Formation.Formation" | translate }}</a>
              </li>
              <li class="breadcrumb-item active">{{ "FileAriane.Detail" | translate }}</li>
  
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content-header -->
  
    <!-- Main content -->
    <div class="container">
      <div class="container-fluid">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
                <div class="col-sm-12">
                  <img style="height:400px; margin-left:50%" class="img-fluid" src="{{formation?.driver_photo}}"
                    alt="photo">
                </div>
  
            </div>
            <div class="d-flex flex-row justify-content-center">
  
              <h2 style="color: rgb(0, 89, 255);">{{formation?.driver_name}}</h2>
  
            </div>
          </div>
  
        </div> 
  
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header border-0">
              <h3 class="card-title">Information formation</h3>
            </div>
            <div class="card-body table-responsive p-0">
              <table class="table table-striped table-valign-middle">
                <tbody>
                  <tr>
                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
  
                    <td>{{ "Formation.DriverLabel" | translate }}</td>
                    <td style="color: rgb(0, 89, 255);">{{formation?.driver_name}}</td>
                  </tr>
                  <tr>
                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
  
                    <td>{{ "Formation.NameLabel" | translate }}</td>
                    <td>
                      <p style="color: rgb(0, 89, 255);">{{formation?.name}}</p>
                    </td>
  
                  </tr>
                  <tr>
                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
  
                    <td>{{ "Formation.DescriptionLabel" | translate }}</td>
                    <td style="color: rgb(0, 89, 255);">{{formation?.description}}</td>
                  </tr>
                  <tr>
                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
  
                    <td>{{ "Formation.DateDebutFormationLabel" | translate }}</td>
                    <td style="color: rgb(0, 89, 255);">{{formation?.description}}</td>
                  </tr>
                  <tr>
                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
  
                    <td>{{ "Formation.DateFinFormationLabel" | translate }}</td>
                    <td style="color: rgb(0, 89, 255);">{{formation?.description}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
  
        </div>

      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</div>