<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>{{ "Affectation.Affectation" | translate }}</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                        <li class="breadcrumb-item "><a [routerLink]="['/affectations/all']">{{
                                "Affectation.Affectation" |
                                translate }}</a></li>
                        <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>

                    </ol>
                </div>
            </div>
        </div>
        <!-- /.container-fluid -->
    </section>

    <!-- Main content -->

    <section class="container">
        <div class="row">
            <div class="col-md-12">
                <form (submit)="onSubmit()" [formGroup]="AffectationForm">
                    <div class="card card-primary">

                        <div class="card-header" style="background-color:#17a2b8;">
                            <h3 class="card-title">{{ "Affectation.AffectationAdd" | translate }}</h3>
                        </div>
                        <div class="card-body" style="display: block;">
                            <div class="form-group">
                                <label for="inputName">{{ "Affectation.DateAffectationLabel" | translate }}
                                    <span style="color: red;">*</span>
                                </label>
                                <input type="date" id="inputName"
                                    class="form-control {{ isSubmitted && form.date_of_affectation.errors? 'is-invalid' : '' }}"
                                    formControlName="date_of_affectation"
                                    placeholder="{{ 'Affectation.LabelDateAffectation' | translate }}">
                            </div>

                            <div class="form-group">
                                <label for="endDate">{{ "Affectation.EndDateAffectationLabel" | translate }}
                                </label>
                                <input type="date" id="endDate"
                                    class="form-control {{ isSubmitted && form.date_of_affectation.errors? 'is-invalid' : '' }}"
                                    formControlName="end_date"
                                    placeholder="{{ 'Affectation.EndDateAffectationLabel' | translate }}">
                            </div>
                            <div class="form-group">
                                <label>{{ "Affectation.AffectationVehicleLabel" | translate }}
                                    <span style="color: red;">*</span>
                                </label>
                                <select formControlName="vehicle_id"
                                    class="form-control {{ isSubmitted && form.vehicle_id.errors? 'is-invalid' : '' }}"
                                    style="width: 100%;" tabindex="-1" aria-hidden="true">
                                    <option value="">{{ "Affectation.AffectationVehicle" | translate }}</option>
                                    <option *ngFor="let vehicle of vehicles" value="{{vehicle.id}}">
                                        {{vehicle.vehicle_registration}}
                                    </option>
                                </select>
                                <label>Ajouter un vehicule <a href="javascript:void(0)" (click)="addAuto()">cliquant
                                        ici</a></label>
                            </div>
                            <div class="form-group">
                                <label>{{ "Affectation.AffectationDriverLabel" | translate }}
                                    <span style="color: red;">*</span>
                                </label>
                                <select formControlName="driver_id"
                                    class="form-control {{ isSubmitted && form.driver_id.errors? 'is-invalid' : '' }}"
                                    style="width: 100%;" tabindex="-1" aria-hidden="true">
                                    <option value="">{{ "Affectation.AffectationDriver" | translate }}</option>
                                    <option *ngFor="let driver of drivers?.data" value="{{driver.id}}">
                                        {{driver.driver_name}}</option>
                                </select>
                                <label>Ajouter un chauffeur <a href="javascript:void(0)" (click)="addDriver()">cliquant
                                        ici</a></label>
                            </div>
                            <div class="form-group">
                                <label>{{ "Affectation.NameConvoyeurLabel" | translate }} <span
                                        style="color:red;">*</span> </label>
                                <select formControlName="convoyeur_id"
                                    class="form-control form-control {{ isSubmitted && form.convoyeur_id.errors? 'is-invalid' : '' }}"
                                    style="width: 100%;" tabindex="-1" aria-hidden="true">
                                    <option value="">{{ "Affectation.NameConvoyeur" | translate }}</option>
                                    <option *ngFor="let convoyeur of convoyeurs?.data" value="{{convoyeur.id}}">
                                        {{convoyeur.convoyeur_name}}</option>
                                </select>
                                <label>Ajouter un convoyeur <a href="javascript:void(0)"
                                        (click)="addConvoyeur()">cliquant
                                        ici</a></label>
                            </div>
                            <div class="form-group">
                                <label for="inputName">{{ "Affectation.ReasonLabel" | translate }} <span
                                        style="color:red;">*</span> </label>
                                <textarea cols="30" rows="5" type="text" id="inputName" class="form-control"
                                    formControlName="reason"
                                    placeholder="{{ 'Affectation.LabelReasonAffectation' | translate }}"></textarea>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="isLoading" class="btn btn-primary" style="background-color:#17a2b8;">
                                <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                                <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                        <!-- /.card-body -->
                    </div>
                </form>
                <div *ngIf="isMaterial" class="col-md-2" style="margin-left:85%"><button class="btn btn-danger"
                        (click)="close()">
                        {{
                        'Material.ButtonClose' | translate }}
                    </button></div>
                <!-- /.card -->
            </div>
        </div>
    </section>
    <!-- /.content -->
</div>