<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>{{ "TypeEntretient.TypeEntretient" | translate }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
              <li class="breadcrumb-item "><a [routerLink]="['/type-contrat/all']">{{ "TypeEntretient.TypeEntretient" |
                  translate }}</a></li>
              <li class="breadcrumb-item active">{{ "FileAriane.Update" | translate }}</li>
  
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="container">
      <div class="row">
        <div class="col-md-12">
          <form (submit)="onSubmit()" [formGroup]="TypeEntretientForm">
            <div class="card card-primary">
  
              <div class="card-header"  style="background-color: #17a2b8;">
                <h3 class="card-title">{{ "TypeEntretient.TypeEntretientUpdate" | translate }}</h3>
              </div>
              <div class="card-body" style="display: block;">
                <div class="form-group">
                  <label for="inputName">{{ "TypeEntretient.NameLabel" | translate }} <span
                    style="color: red;">*</span> </label>
                  <input type="text" id="inputName" class="form-control {{ isSubmitted && form.nom.errors? 'is-invalid' : '' }}"
                    formControlName="nom" placeholder="{{ 'TypeEntretient.LabelNameTypeEntretient' | translate }}">
                </div>
                <div class="form-group">
                  <label for="inputName">{{ "TypeEntretient.DescriptionLabel" | translate }}</label>
                  <input type="text" id="inputName" class="form-control"
                    formControlName="description"
                    placeholder="{{ 'TypeEntretient.LabelNameTypeEntretient' | translate }}">
                </div>
              </div>
              <div class="card-footer">
                <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                  <p *ngIf="!isLoading">{{ "General.update" | translate }}</p>
                  <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
              <!-- /.card-body -->
            </div>
          </form>
          <!-- /.card -->
        </div>
      </div>
    </section>
    <!-- /.content -->
  </div>