import { HttpClient } from '@angular/common/http';
import * as Routes from 'src/Routes';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransferService {

  constructor(private http: HttpClient) { }

  async add(formData): Promise<any> {
    return await this.http.post(Routes.TRANSFER, formData).toPromise();
  }

  public getMission() {
    return this.http.get<any>(`${Routes.MISSION}/missionEnCours`)
      .toPromise();
  }

  public getChauffeur() {
    return this.http.get<any>(`${Routes.CHAUFFEUR}`)
      .toPromise();
  }

  public getVehicule() {
    return this.http.get<any>(`${Routes.VEHICULEALL}`)
      .toPromise();
  }  

  /* public getConvoyeur() {
    return this.http.get<any>(`${Routes.CONVOYEUR}`)
      .toPromise();
  }  
 */
}
