<h1 style="margin-top: 1%;" *ngIf="!detail"><u> </u></h1>
<div class="tabs-animation" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <div class="main-card mb-3 card">
        <div class="card-header" style="background-color:#17a2b8;color:white;"  >
            <div *ngIf="!detail" >
              <h3 class="card-title">{{ 'OFFICIALPAPER.ListeAllLabel' | translate }}{{type.name}}(s)</h3>
              <i _ngcontent-lhs-c2="" class="metismenu-icon pe-7s-plane"></i>
              <div class="row">
                <div class="col-md-12" style="margin-left: 85%;">
                    <div class="page-title-actions">
                        <a  type="button" (click)="ajout(type.id)" data-toggle="tooltip" title="ajout" data-placement="bottom" class="mb-2 mr-2 btn-icon btn-shadow btn-outline-2x btn btn-outline-primary" style="padding-top: 5px; padding-bottom: 5px; padding-left: 20px; padding-right: 20px; flex-direction: row; background-color:#007bff; color: white;">
                            <!-- <i class="pe-7s-diskette btn-icon-wrapper" style="font-size: 1.2em;"></i> -->
                            <p style="font-size: 1.2em; margin-top: 7%;">{{ 'OFFICIALPAPER.AjouterLabel' | translate }}</p>
                        </a>
                    </div>
                </div>
               </div>
           </div>
        </div>
        <div class="card-body"  *ngIf="!detail">
            <table style="width: 100%;" id="example1" class="table table-hover table-striped table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{{ 'OFFICIALPAPER.VehiculeLabel' | translate }}</th>
                        <th>{{ 'OFFICIALPAPER.Start_DateLabel' | translate }}</th>
                        <th>{{ 'OFFICIALPAPER.End_DateLabel' | translate }}</th>
                        <th>{{ 'OFFICIALPAPER.MontantLabel' | translate }}</th>
                        <th>{{ 'OFFICIALPAPER.NumRecuLabel' | translate }}</th>
                    </tr>
                </thead>
                <tbody >
                    <tr *ngFor="let paper of papers.data,let i = index" >
                        <td *ngIf="paper.paper_type_id === type.id">{{ i+1 }}</td>
                        <td style="width: 20%" *ngIf="paper.paper_type_id === type.id">{{ paper.vehicle_registration }}</td>
                        <td style="width: 20%" *ngIf="paper.paper_type_id === type.id">{{ paper.validity_start_at }}</td>
                        <td style="width: 15%" *ngIf="paper.paper_type_id === type.id">{{ paper.validity_end_at }}</td>
                        <td style="width: 15%" *ngIf="paper.paper_type_id === type.id">{{ paper.amount }}</td>
                        <td style="width: 15%" *ngIf="paper.paper_type_id === type.id">{{ paper.receipt_number}}</td>

                        <td class="project-actions text-right" *ngIf="paper.paper_type_id === type.id">
                            <a class="btn btn-primary btn-sm" (click)="detailPaper(paper)">
                                <i class="fas fa-eye"></i>
                            </a>
                            <a class="btn btn-info btn-sm" (click)="editPaper(paper.id)">
                                <i class="fas fa-pencil-alt">
                                </i>
                            </a>
                            <a class="btn btn-danger btn-sm" (click)="deletePaper(paper)">
                                <i class="fas fa-trash">
                                </i>
                            </a>
                        </td>
                    </tr>
                </tbody>
                <tfoot *ngIf="loading && (!papers || !papers.length)">
                    <td colspan="7" class="text-center">
                        <div class="spinner-border spinner-sm">
                            <span class="sr-only">Patientez svp...</span>
                        </div>
                    </td>
                    <td colspan="7" *ngIf="!loading " class="text-center">
                        Liste vide
                    </td>
                </tfoot>
                <tfoot>
                    <tr *ngIf="papers">
                        <td colspan="7" style="margin-top: 5%">
                            <div style="width: 200px; margin: auto;">
                                <ul class="pagination">
                                    <li class="paginate_button page-item previous" id="example_previous">
                                        <a (click)="getPage(papers.prev_page_url)" aria-controls="example"
                                            data-dt-idx="0" tabindex="0"
                                            class="page-link">{{ 'General.Previous' | translate }}</a>
                                    </li>
                                    <li class="paginate_button page-item next" id="example_next">
                                        <a (click)="getPage(papers.next_page_url)" aria-controls="example"
                                            data-dt-idx="7" tabindex="0"
                                            class="page-link">{{ 'General.Next' | translate }}</a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tfoot>

            </table>
        </div>
        <div class="card-body"  *ngIf="detail">
            <div class="card card-primary" >
                <div class="card-header">
                    <h3 class="card-title"> {{ 'OFFICIALPAPER.ListeDetailLabel' | translate }} {{type.name}}</h3>
                    <div class="wiget-heading">
                        <button type="button" (click)="cancel()" class="btn-shadow btn btn-danger"
                        title="" style="margin-left: 90%;"><i
                            class="pe-7s-back mr-3"></i>{{ 'OFFICIALPAPER.RetourLabel' | translate }}
                        </button>
                    </div>
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">{{ 'OFFICIALPAPER.VehiculeLabel' | translate }} :</div>
                                    <div class="widget-subheading">{{assur.vehicle_registration}}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">{{ 'OFFICIALPAPER.Start_DateLabel' | translate }} :</div>
                                    <div class="widget-subheading">{{assur.validity_start_at}}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">{{ 'OFFICIALPAPER.End_DateLabel' | translate }} :</div>
                                    <div class="widget-subheading">{{assur.validity_end_at}}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">{{ 'OFFICIALPAPER.MontantLabel' | translate }} :</div>
                                    <div class="widget-subheading">{{assur.amount}}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">{{ 'OFFICIALPAPER.DoneByLabel' | translate }} :</div>
                                    <div class="widget-subheading">{{assur.done_by}}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left">
                                    <div class="widget-heading">{{ 'OFFICIALPAPER.Num_recuLabel' | translate }} :</div>
                                    <div class="widget-subheading">{{assur.receipt_number}}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                                <div class="wiget-heading">
                                    <div class="widget-subheading" style="margin-left: 2%;">{{ 'OFFICIALPAPER.FichiersLabel' | translate }} :</div>
                                    <a type="button" href="{{assur.attachment_file}}" class="btn-shadow btn btn-danger"
                                    title="" style="margin-left: 80%;"><i
                                        class="pe-7s-back mr-3"></i>telecharger
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                
            </div>
        </div>
    </div>
</div>