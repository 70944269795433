<div class="content-wrapper" style="min-height: 1200.88px;  margin-left: 1%; margin-right: 1%;">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>{{ "Sanction.Sanction" | translate }}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/sanction/all']">{{ "Sanction.Sanction" | translate }}</a>
            </li>
            <li class="breadcrumb-item active">{{ "FileAriane.Detail" | translate }}</li>

          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
  <!-- /.content-header -->

  <!-- Main content -->
  <div class="container">
    <div class="container-fluid">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">

            <div class="position-relative mb-4">
              <!-- <div class="chartjs-size-monitor">
                                <div class="chartjs-size-monitor-expand">
                                    <div class=""></div>
                                </div>
                                <div class="chartjs-size-monitor-shrink">
                                    <div class=""></div>
                                </div>
                            </div> -->
              <div class="col-sm-12 center">
                <img style="height:400px;" class="img-fluid" src="{{sanction?.user?.avatar}}"
                  alt="photo">
              </div>

            </div>

          </div>
          <div class="d-flex flex-row justify-content-center">

            <h2 style="color: rgb(0, 89, 255);">{{sanction?.user?.name}}</h2>

          </div>
        </div>

      </div>

      <div class="col-lg-12">
        <div class="card">
          <div class="card-header border-0">
            <h3 class="card-title">Information Santion</h3>
          </div>
          <div class="card-body table-responsive p-0">
            <table class="table table-striped table-valign-middle">
              <tbody>
                <tr>
                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                  <td>{{ "Sanction.TypeSanctionLabel" | translate }}</td>
                  <td style="color: rgb(0, 89, 255);">{{sanction?.sanction_type?.name }}</td>
                </tr>
                <tr>
                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                  <td>{{ "Sanction.ReasonPenaltyLabel" | translate }}</td>
                  <td>
                    <p style="color: rgb(0, 89, 255);">{{sanction?.reason_penalty}}</p>
                  </td>

                </tr>
                <tr>
                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                  <td>{{ "Sanction.StartDateLabel" | translate }}</td>
                  <td style="color: rgb(0, 89, 255);">{{sanction?.start_date}}</td>
                </tr>
                <tr>
                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                  <td>{{ "Sanction.EndDateLabel" | translate }}</td>
                  <td style="color: rgb(0, 89, 255);">{{sanction?.end_date}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
      <!-- <div class="col-12">
        <button type="button" class="btn btn-success float-right">Envoyez un message</button>
      </div> -->
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content -->
</div>