import { Component, OnInit } from '@angular/core';
import { ConvoyeurService } from 'src/app/_services/RH-service/convoyeur/convoyeur.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { CreateUsersComponent } from '../../users/create-users/create-users.component';

@Component({
  selector: 'app-convoyeur-update',
  templateUrl: './convoyeur-update.component.html',
  styleUrls: ['./convoyeur-update.component.scss']
})
export class ConvoyeurUpdateComponent implements OnInit {
  ConvoyeurForm: FormGroup;
  users: any;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  medical_file: File = null;
  licence_validity_file: File = null;
  pipe = new DatePipe('en-US');
  date = new Date();
  currentDate = this.pipe.transform(this.date, 'yyyy-MM-dd');
  convoyeur;


  constructor(
    private ConvoyeurService: ConvoyeurService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private userDialog: MatDialog,

  ) { }


  ngOnInit(): void {
    this.getusers();
    const conv = +this.route.snapshot.paramMap.get("id");
    this.ConvoyeurService.find(conv).then(
      data => {
        this.convoyeur = data;
        this.initForm(true);
        console.log(this.convoyeur.user_id)
      }
    ).catch(
      error => {
        this.translate.get('Convoyeur.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/convoyeur/all']);

      }
    )
  }

  //initialisation du formulaire
  initForm(withContrat = false) {
    if (withContrat) {
      this.ConvoyeurForm = this.formBuilder.group(
        {
          user_id: [this.convoyeur.user_id, [Validators.required]],
          licence_number: [this.convoyeur.licence_number],
          licence_type: [this.convoyeur.licence_type],
          date_of_issue: [this.convoyeur.date_of_issue],
          end_of_date: [this.convoyeur.end_of_date],
          licence_validity_file: [],
        }
      );
    } else {
      this.ConvoyeurForm = this.formBuilder.group(
        {
          user_id: ['', [Validators.required]],
          licence_number: [''],
          licence_type: [''],
          date_of_issue: [''],
          end_of_date: [''],
          licence_validity_file: [''],
        }
      );
    }

  }

  getusers() {
    this.ConvoyeurService.getUser().then((res) => {
      this.users = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  onSelectfile2(event) {
    this.licence_validity_file = event.target.files[0];
  }

  get form() {
    return this.ConvoyeurForm.controls;
  }
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.ConvoyeurForm.invalid) {
      this.translate.get('Convoyeur.SubmitErrorConvoyeur')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('user_id', '' + this.form.user_id.value);
    formData.append('licence_number', '' + this.form.licence_number.value);
    formData.append('licence_type', '' + this.form.licence_type.value);
    if(this.form.licence_number.value != ''){
      if (this.form.end_of_date.value < this.form.date_of_issue.value) {
        this.translate.get('Forms.CompareDateError')
        .subscribe(val => this.notifService.danger(val));
        this.isLoading = false;
        return;
      }
      if (this.currentDate < this.form.date_of_issue.value) {
        this.translate.get('Forms.StartDateError')
        .subscribe(val => this.notifService.danger(val));
        this.isLoading = false;
        return;
      }
      if (this.currentDate > this.form.end_of_date.value) {
        this.translate.get('Forms.EndDateError')
        .subscribe(val => this.notifService.danger(val));
        this.isLoading = false;
        return;
      }
    }
    formData.append('date_of_issue', '' + this.form.date_of_issue.value);
    formData.append('end_of_date', '' + this.form.end_of_date.value);
    if (this.licence_validity_file != null)
      formData.append('licence_validity_file', this.licence_validity_file, this.licence_validity_file.name);
    console.log(formData);
    this.ConvoyeurService.update(formData, this.convoyeur.id)
      .then(resp => {
        this.translate.get('Convoyeur.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.ConvoyeurForm.reset();
        this.router.navigate(['/convoyeur/all']);

      })
      .catch(err => {
        console.log(err)
        this.translate.get('Convoyeur.SubmitErrorConvoyeur')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }
  addUser() {
    var modelDialogef =  this.userDialog.open(CreateUsersComponent,{
       width: '1000px',
       height: '700px',
       disableClose: true
     });

     modelDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);

       this.ngOnInit();
     });
   }

}
