<!-- Navbar -->
<nav class="main-header navbar navbar-expand navbar-dark navbar-primary">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a [routerLink]="['']" class="nav-link">Home</a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a href="#" class="nav-link">Contact</a>
        </li>
    </ul>

    <!-- SEARCH FORM -->
    <!-- <form class="form-inline ml-3">
      <div class="input-group input-group-sm">
        <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
        <div class="input-group-append">
          <button class="btn btn-navbar" type="submit">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </form>   -->

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown">
            <a class="nav-link" data-toggle="dropdown" href="javascript:void(0)">
                <i class="flag-icon flag-icon-{{ lan == 'fr' ? 'fr' : 'us' }}"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right p-0" style="left: inherit; right: 0px;">
                <a href="javascript:void(0)" class="dropdown-item" (click)="changeLanguage('fr')">
                    <i class="flag-icon flag-icon-fr mr-2"></i> French
                </a>
                <a href="javascript:void(0)" class="dropdown-item active" (click)="changeLanguage('en')">
                    <i class="flag-icon flag-icon-us mr-2"></i> English
                </a>
                <!-- <a href="#" class="dropdown-item">
                    <i class="flag-icon flag-icon-de mr-2"></i> German
                </a>
                <a href="#" class="dropdown-item">
                    <i class="flag-icon flag-icon-es mr-2"></i> Spanish
                </a> -->
            </div>
        </li>
        <!-- Messages Dropdown Menu -->
        <li class="nav-item dropdown">

            <a class="nav-link" data-toggle="dropdown" href="#">
                <i class="far fa-comments"></i>
                <span class="badge badge-warning navbar-badge">{{messages?.messageNonLU?.total}}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span class="dropdown-item dropdown-header">{{messages?.messageNonLU?.total}} Messages</span>
                <div class="dropdown-divider"></div>
                <div class="dropdown-divider"></div>
                <div *ngFor="let message of messages?.data?.data">
                    <a class="dropdown-item" (click)="detailleMessage(message.id)">
                        <!-- Message Start -->
                        <div class="media">
                            <p><img [src]="message?.user1?.avatar" class="img-circle elevation-2" alt="sender Image"
                                    style="width: 50px;"></p>
                            <div class="media-body">
                                <h3 class="dropdown-item-title">
                                    {{message?.user1?.name}}
                                    <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
                                </h3>
                                <p class="text-sm">{{message.content}}</p>
                                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i>
                                    {{messsage?.duree}}</p>
                            </div>
                        </div>
                        <!-- Message End -->
                    </a>
                </div>

                <div class="dropdown-divider"></div>
                <a [routerLink]="['/message/all']" class="dropdown-item dropdown-footer">Voir tous les messages</a>
            </div>
        </li>
        <!-- Notifications Dropdown Menu -->
        <li class="nav-item dropdown">
            <a class="nav-link" data-toggle="dropdown" href="#">
                <i class="far fa-bell"></i>
                <span class="badge badge-warning navbar-badge">{{notifications?.messageNonLU?.total}}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span class="dropdown-item dropdown-header">{{notifications?.messageNonLU?.total}} Notifications</span>
                <div class="dropdown-divider"></div>
                <div class="dropdown-divider"></div>
                <div *ngFor="let notif of notifications?.data?.data">
                    <a class="dropdown-item" (click)=detaillenotecritique(notif.reference_id)>
                        <!-- Message Start -->
                        <div class="media">
                            <div class="media-body">
                                <h3 class="dropdown-item-title">
                                    {{notif.title}}
                                    <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
                                </h3>
                                <p class="text-sm">{{notif.title}}</p>
                                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i>
                                    {{notif?.duree}}</p>
                            </div>
                        </div>
                        <!-- Message End -->
                    </a>
                </div>

                <div class="dropdown-divider"></div>
                <a [routerLink]="['/note-critique/all']" class="dropdown-item dropdown-footer">Voir tous les
                    notifications</a>
            </div>
        </li>
        <!--User's info-->
        <li class="nav-item dropdown user user-menu">
            <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
                <img src="{{userId?.user?.avatar}}" style="float: left;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                margin-right: 10px;
                margin-top: -2px" class="img-circle elevation-2" alt="User Image">
                <span class="hidden-xs">{{userId?.user?.name}}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <!-- User image -->
                <li class="user-header bg-primary">
                    <img src="{{userId?.user?.avatar}}" class="img-circle elevation-2" alt="User Image">
                    <p>
                        {{userId?.user?.name}}
                        <small>{{userId?.user?.birth_date}}</small>
                    </p>
                </li>
                <!-- Menu Body -->
                <li class="user-body">
                    <div class="row">
                        <div class="col-4 text-center">
                            <a href="#">{{userId?.user?.marital_status}}</a>
                        </div>
                        <div class="col-4 text-center">
                            <a href="#">{{userId?.user?.sexe}}</a>
                        </div>
                        <div class="col-4 text-center">
                            <a href="#">{{userId?.user?.birth_place}}</a>
                        </div>
                    </div>
                    <!-- /.row -->
                </li> 
                <!-- Menu Footer-->
                <li class="user-footer">
                    <div style="float: left;">
                        <a [routerLink]="['/profile/all']" class="btn btn-default btn-flat">Profile</a>
                    </div>
                    <div style="float: right;">
                        <a href="javascript:void(0)" class="btn btn-default btn-flat" (click)="logout()">Deconnexion</a>
                    </div>
                </li>
            </ul>
        </li>
        <li class="nav-item">
            <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
                <i class="fas fa-th-large"></i>
            </a>
        </li>
    </ul>
</nav>

<!-- /.navbar -->