import { ToastrService } from 'ngx-toastr';
import { ParamService } from 'src/app/_services/parc-service/param-service/param-service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
declare const $

@Component({
  selector: 'app-test-data-table',
  templateUrl: './test-data-table.component.html',
  styleUrls: ['./test-data-table.component.scss']
})
export class TestDataTableComponent implements OnInit {

  /*   data: any;
    dtOptions: any = {};
    constructor(private paramService: ParamService, private toastrService: ToastrService,
    ) {
  
  
    }
    forme: any = "MARK";
  
    async ngOnInit() {
      this.getForme(this.forme);
  
      setTimeout(() => {
        this.dtOptions = {
          pagingType: 'full_numbers',
          pageLength: 5,
          lengthMenu: [5, 10, 25],
          processing: true
        };
      }, 100);
  
  
    }
  
  */
  marks: any[] = [];
  datatable = false;



  public getForme(forme) {

    this.paramService.selectForm(forme).then(
      response => {
        this.marks = response;
        this.datatable = true;
        console.log(this.marks);
        $(function () {
          $("#example1").DataTable({
            "responsive": true,
            "autoWidth": false,
          });
        });
      }
    ).catch(
      error => {
        this.toastrService.error(error.error.message);
      }
    ).finally(
      () => {
      }
    )
  }
  forme: any = "MARK";
  data: any;
  constructor(private paramService: ParamService, private toastrService: ToastrService,
  ) {

    //this.getForme(this.forme);

  }

  getData() {
    this.data = [
      { name: 'Ajay', lastname: 'Ajay', email: 'therichpost@gmail.com', website: 'therichpost.com' },
      { name: 'Jas', lastname: 'Ajay', email: 'therichpost@gmail.com', website: 'therichpost.com' },
      { name: 'therichpost', lastname: 'Ajay', email: 'therichpost@gmail.com', website: 'therichpost.com' },
      { name: 'therichpost', lastname: 'Ajay', email: 'therichpost@gmail.com', website: 'therichpost.com' },
      { name: 'Jas', lastname: 'Ajay', email: 'therichpost@gmail.com', website: 'therichpost.com' },
      { name: 'therichpost', lastname: 'Ajay', email: 'therichpost@gmail.com', website: 'therichpost.com' },
      { name: 'therichpost', lastname: 'Ajay', email: 'therichpost@gmail.com', website: 'therichpost.com' },
      { name: 'Jas', lastname: 'Ajay', email: 'therichpost@gmail.com', website: 'therichpost.com' },
      { name: 'therichpost', lastname: 'Ajay', email: 'therichpost@gmail.com', website: 'therichpost.com' },
      { name: 'therichpost', lastname: 'Ajay', email: 'therichpost@gmail.com', website: 'therichpost.com' },
    ];
    console.log(this.data)
  }

  //dtOptions: DataTables.Settings = {};
  ngOnInit() {

    this.getForme(this.forme);
    this.getData();
    /* setTimeout(() => {
       this.dtOptions = {
         pagingType: 'full_numbers',
         pageLength: 5,
         lengthMenu: [5, 10, 25],
         processing: true
       };
     }, 60000); */

  }
}
