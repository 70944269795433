import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ParamService } from 'src/app/_services/parc-service/param-service/param-service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';

declare const $

@Component({
  selector: 'app-list-mark-auto',
  templateUrl: './list-mark-auto.component.html',
  styleUrls: ['./list-mark-auto.component.scss']
})
export class ListMarkAutoComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  //SweetAlert Text
  areYouSure = '';
  warning = ''
  yes = '';
  no = '';
  deleted = '';
  deletedMessage = '';
  cancelled = '';
  cancelledMessage = '';
  posts;
  buttons: string[];

  constructor(

    private paramService: ParamService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private router: Router,
  ) {

    this.translate.get(
      ['SweetAlert.AreYouSure', 'SweetAlert.Warning', 'SweetAlert.Yes', 'SweetAlert.No', 'SweetAlert.Deleted',
        'SweetAlert.DeletedMessage', 'SweetAlert.Cancelled', 'SweetAlert.CancelledMessage'],
      { data: 'model' })
      .subscribe(val => {
        this.areYouSure = val['SweetAlert.AreYouSure'];
        this.warning = val['SweetAlert.Warning'];
        this.yes = val['SweetAlert.Yes'];
        this.no = val['SweetAlert.No'];
        this.deleted = val['SweetAlert.Deleted'];
        this.deletedMessage = val['SweetAlert.DeletedMessage'];
        this.cancelled = val['SweetAlert.Cancelled'];
        this.cancelledMessage = val['SweetAlert.CancelledMessage'];
      });
  }


  marks: any;
  markss: any;
  forme: any = "MARK";
  detailform: any;

  ngOnInit(): void {

    this.getForme(this.forme);

  }

  public getForme(forme) {

    this.paramService.selectForm(forme).then(
      response => {
        this.marks = response;
        console.log(this.marks)
        $(function () {
          $("#example1").DataTable({
            "responsive": true,
            "lengthChange": false,
            "autoWidth": false,
            "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
          }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        });
      }
    ).catch(
      error => {
        this.toastrService.error(error.error.message);
      }
    ).finally(
      () => {
      }
    )
  }


  editModel(model: any) {
    this.router.navigate(['setting/forms/marque/update/' + model.id])
  }



  deleteModel(model: any) {
    Swal.fire({
      title: 'Alert',
      text: 'voulez-vous supprimer cette marque ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then((result) => {
      if (result.value) {
        this.blockUI.start('Loading...');
        console.log(model.id)
        this.paramService.deleteForm(model.id).then(

          data => {
            this.blockUI.stop();
            Swal.fire(
              'Marque',
              'Supprimé avec succes',
              'success'
            )
/*             this.getForme(this.forme);
 */            location.reload()

            console.log(model.id)
          }
        ).catch(
          error => {
            console.log(error)
            this.blockUI.stop();
            this.toastrService.error('Echec de suppression');
          }
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Suppression annulée',
          'La suppression a été annulée',
          'error'
        )
      }
    })
  }

  detailsModel(model: any) {
    this.paramService.findForm(model.id).then(

      data => {
        this.detailform = data
        Swal.fire({
          title: data.name,
          text: data.description,
          icon: 'info',
          confirmButtonText: 'Retour',
        })
      }

    ).catch(
      error => {
        console.log(error)
        this.blockUI.stop();
        this.toastrService.error('Echec de suppression');
      }
    )
  }

}




