<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>{{ 'Assurance.Assurance' | translate }}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/all_assurrance']">{{ 'Assurance.Assurance' | translate }}</a></li>
            <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
<div class="row" style="margin-top: 1%;min-height: 1416.81px; margin-left: 1%; margin-right: 1%;">
    <div class="col-md-12" id="form">
        <div class="tabs-animation">
            <div class="card card-primary">
                <div class="card-header" style="background-color:#17a2b8;">
                    <h3 class="card-title">
                        {{ 'OFFICIALPAPER.AssuranceAddLabel' | translate }}
                    </h3>
                </div>
                <form (submit)="onSubmit()" [formGroup]="assuranceForm">
                    <div class="card-body">
                        <div class="form-group">
                            <label for="assureur" class="">{{ 'OFFICIALPAPER.AssureurLabel' | translate }}
                              <span style="color: red;">*</span>
                            </label>
                            <select formControlName="insurer_id" class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}" >
                                <option [value]="assureur.id" *ngFor="let assureur of assureurs" >{{assureur.name}}</option>
                            </select>
                            <label>Ajouter un element intervenant <a href="javascript:void(0)" (click)="addAssureur()">cliquant
                                ici</a></label>
                        </div>
                        <div class="form-group">
                            <label for="vehicule" class="">{{ 'OFFICIALPAPER.VehiculeLabel' | translate }}
                              <span style="color: red;">*</span>
                            </label>
                            <select formControlName="vehicle_id" class="form-control {{ isSubmitted && form.vehicle_id.errors ? 'is-invalid' : '' }}" >
                                <option [value]="vehicle.id" *ngFor="let vehicle of vehicules" >{{vehicle.vehicle_registration}}</option>
                            </select>
                            <label>Ajouter un  vehicule <a href="javascript:void(0)" (click)="addAuto()">cliquant
                                ici</a></label>
                        </div>
                        <div class="form-group">
                            <label for="date1" class="">{{ 'OFFICIALPAPER.Start_DateLabel' | translate }}</label>
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <i class="fa fa-calendar-alt"></i>
                                </div>
                                <input  id="date1"  type="date" value="" formControlName="validity_start_at"  class="form-control {{ isSubmitted && form.validity_start_at.errors ? 'is-invalid' : '' }}">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="date2">{{ 'OFFICIALPAPER.End_DateLabel' | translate }}</label>
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <i class="fa fa-calendar-alt"></i>
                                </div>
                                <input  id="date2"  type="date" value="" formControlName="validity_end_at"  class="form-control {{ isSubmitted && form.validity_end_at.errors ? 'is-invalid' : '' }}">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="numero1">{{ 'OFFICIALPAPER.Num_recuLabel' | translate }}
                              <span style="color: red;">*</span>
                            </label>
                            <input placeholder="veuillez entrer un numero" id="numero1"  type="text" formControlName="insurance_policy_number"
                            class="form-control {{ isSubmitted && form.insurance_policy_number.errors ? 'is-invalid' : '' }}">
                        </div>
                        <div class="form-group">
                            <label for="numero2">{{ 'OFFICIALPAPER.Val_policeLabel' | translate }}
                              <span style="color: red;">*</span>
                            </label>
                            <input placeholder="veuillez entrer un nombre" id="numero2"  type="number" formControlName="insurance_policy_value"
                             class="form-control {{ isSubmitted && form.insurance_policy_value.errors ? 'is-invalid' : '' }}">
                        </div>
                        <div class="form-group">
                            <label for="exampleFile">{{ 'OFFICIALPAPER.PieceLabel' | translate }}</label>
                            <input  id="exampleFile"  type="file" (change)=detectfile($event)  class="form-control-file">
                        </div>
                        <div class="card-footer" >
                            <button [disabled]="isLoading" style="background-color: #17a2b8;">
                                <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                                <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </button>
                        </div>
                    </div>
               </form>
            </div>
        </div>
    </div>

</div>
