import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';
import { PositionService } from 'src/app/_services/pneumatique/Position/position.service';

@Component({
  selector: 'app-position-details',
  templateUrl: './position-details.component.html',
  styleUrls: ['./position-details.component.scss']
})
export class PositionDetailsComponent implements OnInit {

  positions: any;

  constructor(
    private PositionService: PositionService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    const position_id = +this.route.snapshot.paramMap.get("id");
    console.log(position_id);
    this.PositionService.find(position_id).then(
      data => {
        this.positions = data;
        console.log(this.positions)
      }
    ).catch(
      error => {
        this.translate.get('positions.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/position/all'])
      }
    )
  }

}
