import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { TypeDocumentMissionService } from 'src/app/_services/movement-service/type-document-mission.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-type-document-update',
  templateUrl: './type-document-update.component.html',
  styleUrls: ['./type-document-update.component.scss']
})
export class TypeDocumentUpdateComponent implements OnInit {

  DataForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  typeDocuments: any;

  constructor(
    private TypeDocumentMissionService: TypeDocumentMissionService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.initForm();

    const type_Document_id = +this.route.snapshot.paramMap.get("id");
    this.TypeDocumentMissionService.find(type_Document_id).then(
      data => {
        this.typeDocuments = data;
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('typeDocuments.'+error.error.code)
        .subscribe(val => this.notifService.danger(val));
      }
    )
  }

  initForm(withTypeEntretient = false) {
    if(withTypeEntretient){
      this.DataForm = this.formBuilder.group(
        {
          nom: [this.typeDocuments.nom, [Validators.required]],
          description: [this.typeDocuments.description],
        }
      );
      }
    else{
      this.DataForm = this.formBuilder.group(
        {
          nom: ["", [Validators.required]],
          description: [""],
        }
      );
    }

  }

  get form() {
    return this.DataForm.controls;
  }
  
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.DataForm.invalid) {
      this.translate.get('TypeDocumentMission.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('nom', ''+this.form.nom.value);
    formData.append('description', ''+this.form.description.value);

      console.log(formData);
      this.TypeDocumentMissionService.update(this.typeDocuments.id, formData)
      .then(resp => {
        this.translate.get('TypeDocumentMission.SubmitSuccess')
        .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.DataForm.reset();
        this.router.navigate(['/type-document-mission/all'])

      })
      .catch(err => {
        console.log(err)
        this.translate.get('TypeDocumentMission.SubmitErrorTypeDocumentMission')
        .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }

}
