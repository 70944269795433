<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ "Document.Title" | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">Home</a></li>
                    <li class="breadcrumb-item active"><a [routerLink]="['/document-production/list']">{{ "Document.Title" | translate }}</a></li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>
<div class="card card-primary">
    <div class="card-header" style="background-color:#17a2b8;">
        <h3 class="card-title">
            {{ "Document.AddDescription" | translate }}
        </h3>
    </div>
    <!-- /.card-header -->
    <!-- form start -->
    <form (submit)="onSubmit()" [formGroup]="dataForm" role="form">
        <div class="card-body">
            <div class="form-group">
                <label for="exampleInputEmail1">{{ "Document.Name" | translate }} <span style="color: red;">*</span> </label>
                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter name" formControlName="file_name">
            </div>
            <div class="form-group">
                <label for="exampleInputFile">{{ "Document.File" | translate }}</label>
                <div class="input-group">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" id="exampleInputFile" (change)="detectfile($event)" formControlName="file">
                        <label class="custom-file-label" for="exampleInputFile">Choose file</label>
                    </div>
                    <div class="input-group-append">
                        <span class="input-group-text" id="">Upload</span>
                    </div>
                </div>
            </div>

        </div>
        <!-- /.card-body -->

        <div class="card-footer">
            <button [disabled]="isLoading" class="btn btn-primary" style="background-color:#17a2b8;">
                <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </button>
        </div>
    </form>
</div>