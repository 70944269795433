import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';
import { PneuService } from 'src/app/_services/pneumatique/Pneu/pneu.service';

@Component({
  selector: 'app-pneu-details',
  templateUrl: './pneu-details.component.html',
  styleUrls: ['./pneu-details.component.scss']
})
export class PneuDetailsComponent implements OnInit {

  pneus: any;

  constructor(
    private PneuService: PneuService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    const pneu_id = +this.route.snapshot.paramMap.get("id");
    console.log(pneu_id);
    this.PneuService.find(pneu_id).then(
      data => {
        this.pneus = data;
        console.log(this.pneus)
      }
    ).catch(
      error => {
        this.translate.get('pneus.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/pneu/all'])
      }
    )
  }

}

