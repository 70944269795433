import { Component, OnInit } from '@angular/core';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { ProgrammesEntretientService } from 'src/app/_services/maintenance/entretients/programmes-entretient.service';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-programmes-entretient-details',
  templateUrl: './programmes-entretient-details.component.html',
  styleUrls: ['./programmes-entretient-details.component.scss']
})
export class ProgrammesEntretientDetailsComponent implements OnInit {

  user:any;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  page=1;
  programme_entretient:any;
  pipe = new DatePipe('en-US');
  date = new Date();
  currentDate = this.pipe.transform(this.date, 'yyyy-MM-dd');
  id:any;

  constructor(
    private programmeEntretientService: ProgrammesEntretientService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) { }

   ngOnInit() {
    this.id = +this.route.snapshot.paramMap.get("id");
    this.programmeEntretientService.find(this.id).then(
      data => {
        this.programme_entretient = data;
        console.log(this.programme_entretient);
      }
    ).catch(
      error => {
        this.translate.get('ProgrammesEntretient.'+error.error.code)
        .subscribe(val => this.notifService.danger(val));
      }
    )
  }
  edit(id) {
    this.router.navigate(['/programme-entretient/update/' + id]);
  }
}
