import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NotifService } from 'src/app/_services/notif.service';
import { UserService } from 'src/app/_services/users/user.service';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-profile-all',
  templateUrl: './profile-all.component.html',
  styleUrls: ['./profile-all.component.scss']
})
export class ProfileAllComponent implements OnInit {
  user: any;
  infos: any;
  completedUser: any;
  roles;
  permissions;
  permissions_tmp;
  Profile;

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private notifService: NotifService,
    private userService: UserService,
    private route: ActivatedRoute



  ) { }

  // ngOnInit(): void {



  //   this.user = this.authService.getUser();

  //   this.findUser(this.user.id);

  // }
  async ngOnInit() {
    const user = this.authService.getUser();
    this.userService.find(user.id).then(
      response => {
        this.user = response;
        console.log(response);
        console.log(this.user);




        this.permissions = response.permissions;
        console.log(this.permissions);

        this.permissions_tmp = response.permissions;
        this.Profile = response;
        console.log(this.Profile);


      }
    );
    this.roles = user.roles;
    console.log(this.roles);
    this.findUser(user.id)
  }
  // userInformation(id){
  //   this.userService.userInfo(id).then(
  //     response =>{
  //       this.infos = response;

  //     }
  //   ).catch(
  //     error => {
  //       this.notifService.danger(error.message)
  //     }
  //   )
  // } 

  search(event) {
    this.permissions = this.permissions_tmp;
    this.permissions = this.permissions_tmp.filter(permission => permission.display_name.toLowerCase().includes(event.target.value.toLowerCase()));
  }

  findUser(id) {
    this.userService.userInfo(id).then(
      response => {
        console.log("completed user");
        console.log(response);


        this.completedUser = response;



      }
    ).catch(
      error => {
        this.notifService.danger(error.message)
      }
    )
  }

}
