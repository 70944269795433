import { Component, OnInit } from '@angular/core';
import { DocService } from '../../../../../_services/parc-service/doc-service/doc-service';
//import { NotifService } from 'src/app/_services/notif.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//import { TranslateService } from '@ngx-translate/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-type-fiche-update',
  templateUrl: './type-fiche-update.component.html',
  styleUrls: ['./type-fiche-update.component.scss']
})
export class TypeFicheUpdateComponent implements OnInit {

  typeFicheForm : FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  typefiche : any ;

  constructor(
    private docService: DocService,
    //private notifService: NotifService,
    private formBuilder: FormBuilder,
    //private translate: TranslateService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.initForm();
    const typefiche_id = +this.route.snapshot.paramMap.get("id");
    this.docService.findType(typefiche_id).then(
      data => {
        this.typefiche = data;
        this.initForm(true);
  }).catch(
    error => {
      this.toastr.error('L Operation a echoue');
    }
  )
}

initForm(withTypeFiche = false) {
  if(withTypeFiche) {
    this.typeFicheForm = this.formBuilder.group({
      name: [this.typefiche.name, [Validators.required]],
      description: [this.typefiche.description]
      
    });
  }else {
    this.typeFicheForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
    });
  }
}

get form() {
  return this.typeFicheForm.controls;
}

onSubmit() {
  this.isSubmitted = true;
  this.isError = false;
  this.isSuccess = false;
  this.isLoading = false
  
  if (this.typeFicheForm.invalid) {
    this.toastr.error('ERREUR', 'Les champs en rouge sont obligatoires!');
    return;
  }

  this.isLoading = true;
  const formData = new FormData();
  formData.append('name', '' + this.form.name.value);
  formData.append('description', '' + this.form.description.value);
  this.docService.updateType(formData, this.typefiche.id)
    .then(resp => {
      this.toastr.success('L Operation effectue avec success');
      this.router.navigate(['type_fiche_all'])
    })
    .catch(err => {
      console.log(err)
      this.toastr.error('L Operation a echoue');
    })
    .finally(() => this.isLoading = false);
}


}