import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { InterveningElementService } from 'src/app/_services/transport-element/intervening-element/intervening-element.service';
@Component({
  selector: 'app-intervening-element-update',
  templateUrl: './intervening-element-update.component.html',
  styleUrls: ['./intervening-element-update.component.scss']
})
export class InterveningElementUpdateComponent implements OnInit {

  data: any;
  element: any;
  dataForm : FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  myfile: File = null;

  constructor(
    private translate: TranslateService,
    private dataService: InterveningElementService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getTypes();
    const element_id = +this.route.snapshot.paramMap.get("id");
    this.dataService.find(element_id).then(
      data => {
        console.log(data)
        this.data = data;
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('data.' + error.error.code)
          .subscribe(val => this.toastrService.error(val));
      }
    )

  }

  initForm(withElement = false) {
    if (withElement) {
      this.dataForm = this.formBuilder.group({
        name: [this.data.name, [Validators.required]],
        intervening_type_id: [this.data.intervening_type_id, [Validators.required]],
        email: [this.data.email, [Validators.required]],
        location: [this.data.location, [Validators.required]],
        tel1: [this.data.tel1, [Validators.required]],
        tel2: [this.data.tel2],
        description: [this.data.description],
        contact_person: [this.data.contact_person, [Validators.required]],
        tel_person: [this.data.tel_person, [Validators.required]],
        //intervening_file: [this.data.intervening_file, [Validators.required]],
      });
    } else {
      this.dataForm = this.formBuilder.group({
        name : ['', Validators.required],
        intervening_type_id : ['', Validators.required],
        email : ['', Validators.required],
        location : ['', Validators.required],
        tel1 : ['', Validators.required],
        tel2 : [''],
        description : ['', Validators.required],
        contact_person : ['', Validators.required],
        tel_person : ['', Validators.required],
        //intervening_file : ['']
      })
    }

  }

  get form(){
    return this.dataForm.controls
  }

  detectfile(event) {
    this.myfile = event.target.files[0];
    console.log(this.myfile)
  }

  onSubmit(){
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.dataForm.invalid) {
      this.toastrService.error('ERREUR', 'Les champs en rouge sont obligatoires!');
      return;
    }
    this.isLoading = true;
    const formData = new FormData();
    const data = this.form;
    for (const k in data) {
      if (k) {
        // if (k === 'intervening_file') {
        //   formData.append(k + '', this.myfile, data[k].value);
        // } else {
        //   formData.append(k + '', data[k].value);  
        // }
        formData.append(k + '', data[k].value);  

      }
    }
    
    this.dataService.put(this.data.id, formData).then(resp => {
      this.toastrService.success('element mise a jour avec success');
      this.isSubmitted = false;
      this.dataForm.reset();
      this.router.navigate(['/intervening-element/list']);
    })
    .catch(
      err => {
        console.log(err);
        this.toastrService.error('l\'operation a echouer');
      }
    )
    .finally(() => this.isLoading = false);
  }


  getTypes(){
    this.dataService.getTypes().then(
      (res) => {
      this.element = res;
      console.log(this.element);
    }).catch((error)=>{
      this.toastrService.error(error.error.message);
    })
  }

}
