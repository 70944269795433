import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';
import { OperationService } from 'src/app/_services/pneumatique/Operation/operation.service';
declare const $;

@Component({
  selector: 'app-inventaires',
  templateUrl: './inventaires.component.html',
  styleUrls: ['./inventaires.component.scss']
})
export class InventairesComponent implements OnInit {
  inventaires: any;
  loading: boolean = true;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private OperationService: OperationService,
    private authService: AuthService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getInventaire();
  }

  getInventaire() {
    this.loading = true;
    this.OperationService.inventaires().then(
      response => {
        this.inventaires = response;
        console.log(this.inventaires);
        $(function () {
          //$("#example1").DataTable().fnDestroy()
          $("#example1").DataTable({
            "responsive": true, "lengthChange": false, "autoWidth": false,
            "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
          }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        });
      }
    ).catch(
      error => {
        this.notifService.danger(error.error.message)
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  getPage(url) {
    if (url) {
      this.OperationService.get(url).then((res) => {
        this.inventaires = res;
        console.log(this.inventaires);
      }).catch(
        error => {
          this.notifService.danger(error.error.message);
        }
      ).finally(
        () => {
          this.loading = false;
        }
      )
    }
  }


}
