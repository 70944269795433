<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ "Forms.Marques.Marques" | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">


                    <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                    <li class="breadcrumb-item "><a [routerLink]="['/setting/forms/marque/list']">{{
                            "Forms.Marques.Marques" | translate }}</a></li>
                    <li class="breadcrumb-item active">{{ "FileAriane.List" | translate }}</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <section class="content">
        <div class="card card-info">
            <section class="content">
                <div class="card-header" style="background-color: #17a2b8; color: white;" >
                <h2 class="card-title" style="margin-left: 1%; margin-bottom: 1%; margin-top: 1%;">List des marques {{
                    "Forms.Marques.Lister" | translate }}</h2>
                <button [routerLink]="['/setting/forms/marque/add']" type="button" class="btn btn-primary float-right"
                    style="margin-right: 1%; margin-bottom: 1%; margin-top: 1%;">
                    <i class="fas fa-plus"></i> {{ "Forms.Marques.Ajouter" | translate }}
                </button>
                </div>
            </section>
            
            <div class="card-body p-1">
                <table id="example1" class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{{ "Forms.Marques.Nom" | translate }}</th>
                            <th>{{ "Forms.Marques.Description" | translate }}</th>
                            <th>{{ "Forms.Marques.Date" | translate }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let mark of marks,let i = index" id="{{ mark.id }}">
                            <td>{{i+1}}</td>
                            <td>{{mark.name}} </td>
                            <td>{{mark.description | slice:0:60}}</td>
                            <td>{{mark.created_at | date: 'dd-mm-yyyy'}}</td>

                            <td class="project-actions text-right">
                                <a class="btn btn-primary btn-sm" (click)="detailsModel(mark)" href="javascript:void(0)"
                                    data-bs-placement="top" title="details">
                                    <i class="fas fa-eye">
                                    </i>
                                </a>
                                &nbsp;
                                <a class="btn btn-info btn-sm" (click)="editModel(mark)" href="javascript:void(0)"
                                    data-bs-placement="top" title="modifier">
                                    <i class="fas fa-pencil-alt">
                                    </i>
                                </a>&nbsp;
                                <a class="btn btn-danger btn-sm" (click)="deleteModel(mark)" href="javascript:void(0)"
                                    data-bs-placement="top" title="supprimer">
                                    <i class="fas fa-trash">
                                    </i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</div>