import { Component, OnInit } from '@angular/core';
import { AffectationService } from 'src/app/_services/affectation/affectation.service';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as Routes from 'src/Routes';

@Component({
  selector: 'app-detaille-affectation',
  templateUrl: './detaille-affectation.component.html',
  styleUrls: ['./detaille-affectation.component.scss']
})
export class DetailleAffectationComponent implements OnInit {
  drivers: any[] = [];
  drivers_tmp: any[] = [];

  driver:any;
  url;
  defautlImage:any;

  server = Routes.ROOT;

  constructor(
    private affectationService: AffectationService,
    private notifService: NotifService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  async ngOnInit() {
    const affectation_id = +this.route.snapshot.paramMap.get("id");
     console.log(affectation_id);
    this.affectationService.findaff(affectation_id).then(
      data => {
        this.driver = data;
        console.log(this.driver)
      this.defautlImage=this.driver.photo_before_vehicle;
      console.log(this.driver.photo_before_vehicle)
      }
    ).catch(
      error => {
        this.translate.get('Affactation.'+error.error.code)
        .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/affectations/all'])
      }
    )
  }
  onChangeImage(url){
    this.defautlImage = url;
    console.log(this.defautlImage)
  }
  
  onClick($image){
    this.url=$image;
    console.log(this.url)
    this.onChangeImage(this.url);

  }

  search(event) {
    this.drivers = this.drivers_tmp;
    this.drivers = this.drivers_tmp.filter( driver => driver.display_name.toLowerCase().includes(event.target.value.toLowerCase()));
  }

}
