<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>{{ "Chauffeur.Chauffeur" | translate }}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/chauffeur/all']">{{ "Chauffeur.Chauffeur" | translate
                }}</a></li>
            <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>

          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>

  <!-- Main content -->

  <section class="container">
    <div class="row">
      <div class="col-md-12">
        <form (submit)="onSubmit()" [formGroup]="ChauffeurForm">
          <div class="card card-primary">

            <div class="card-header" style="background-color: #17a2b8;">
              <h3 class="card-title">{{ "Chauffeur.ChauffeurUpdate" | translate }}</h3>
            </div>
            <div class="card-body" style="display: block;">
              <div class="form-group">
                <label>{{ "Chauffeur.UserContratLabel" | translate }}
                  <span style="color: red;">*</span>
                </label>
                <select formControlName="user_id"
                  class="form-control {{ isSubmitted && form.user_id.errors ? 'is-invalid' : '' }}" style="width: 100%;"
                  tabindex="-1" aria-hidden="true">
                  <option value="">{{ "Chauffeur.ContratDriver" | translate }}</option>
                  <option *ngFor="let driver of users?.data" value="{{driver.id}}">{{driver.name}}</option>
                </select>
                <label>En cliquant<a href="javascript:void(0)" (click)="addUser()">
                   ici </a>vous créez un utilisateur</label>
              </div>
              <div class="form-group">
                <label for="licence_number">{{ "Chauffeur.LicenceNumberLabel" | translate }}
                  <span style="color: red;">*</span>
                </label>
                <input type="text" id="licence_number"
                  class="form-control {{ isSubmitted && form.licence_number.errors ? 'is-invalid' : '' }}"
                  formControlName="licence_number" placeholder="{{ 'Chauffeur.LabelLicenceNumber' | translate }}">
              </div>
              <div class="form-group">
                <label for="licence_type">{{ "Chauffeur.LicenceTypeLabel" | translate }}
                  <span style="color: red;">*</span>
                </label>
                <input type="text" id="licence_type"
                  class="form-control {{ isSubmitted && form.licence_type.errors ? 'is-invalid' : '' }}"
                  formControlName="licence_type" placeholder="{{ 'Chauffeur.LabelLicenceType' | translate }}">
              </div>
              <div class="form-group">
                <label for="date_of_issue">{{ "Chauffeur.DateIssueLabel" | translate }}
                  <span style="color: red;">*</span>
                </label>
                <input type="date" id="date_of_issue"
                  class="form-control {{ isSubmitted && form.date_of_issue.errors? 'is-invalid' : '' }}"
                  formControlName="date_of_issue" placeholder="{{ 'Chauffeur.LabelDateIssue' | translate }}">
              </div>
              <div class="form-group">
                <label for="end_of_date">{{ "Chauffeur.EndDateLabel" | translate }}
                  <span style="color: red;">*</span>
                </label>
                <input type="date" id="end_of_date"
                  class="form-control {{ isSubmitted && form.end_of_date.errors ? 'is-invalid' : '' }}"
                  formControlName="end_of_date" placeholder="{{ 'Chauffeur.LabelEndDate' | translate }}">
              </div>
              <div class="form-group">
                <label for="licence_validity_file">{{ "Chauffeur.LicenceValidityFileLabel" | translate }}</label>
                <input type="file" id="licence_validity_file" (change)=onSelectfile2($event)
                  class="form-control {{ isSubmitted && form.licence_validity_file.errors ? 'is-invalid' : '' }}"
                  formControlName="licence_validity_file"
                  placeholder="{{ 'Chauffeur.LabellicenceValidityFile' | translate }}">
              </div>
              <div class="form-group">
                <label for="medical_file">{{ "Chauffeur.MedicalFileLabel" | translate }}</label>
                <input type="file" id="medical_file" (change)=onSelectfile1($event)
                  class="form-control {{ isSubmitted && form.medical_file.errors ? 'is-invalid' : '' }}"
                  formControlName="medical_file" placeholder="{{ 'Chauffeur.LabelMedicalFileChauffeur' | translate }}">
              </div>
            </div>
            <div class="card-footer">
              <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                <p *ngIf="!isLoading">{{ "General.update" | translate }}</p>
                <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
            <!-- /.card-body -->
          </div>
        </form>
        <!-- /.card -->
      </div>
    </div>
  </section>
  <!-- /.content -->
</div>
