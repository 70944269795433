<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>{{ 'TYPEFICHE.TYPEFICHE' | translate }}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/type_fiche_all']">{{ 'TYPEFICHE.TYPEFICHE' | translate }}</a></li>
            <li class="breadcrumb-item active">{{ "FileAriane.Detaille" | translate }}</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
<div class="row" style="min-height: 1416.81px; margin-left: 1%; margin-right: 1%;">
    <div class="col-md-12">
        <div class="card card-success">
            <div class="card card-outline card-success">
                <div class="card-header">
                    <h3 class="card-title">{{ 'TYPEFICHE.TYPEFICHEDetailLabel' | translate }}</h3>
                   
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <ul class="list-group list-group-flush">

                                        <li class="list-group-item">
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left mr-3">
                                                        <div class="icon-wrapper border-light rounded m-0">
                                                            <div class="icon-wrapper-bg bg-light"></div>
                                                            <i class="lnr-cog icon-gradient bg-happy-itmeo"></i></div>
                                                    </div>
                                                    <div class="widget-content-left row">
                                                        <div class="widget-heading col-5"><h3>{{ 'TYPEFICHE.NomLabel' | translate }} :</h3></div>
                                                        <div class="float-right col-5">{{ typefiche.name }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="list-group-item">
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left mr-3">
                                                        <div class="icon-wrapper border-light rounded m-0">
                                                            <div class="icon-wrapper-bg bg-light"></div>
                                                            <i class="lnr-cog icon-gradient bg-happy-itmeo"></i>
                                                        </div>
                                                    </div>
                                                    <div class="widget-content-left row">
                                                        <div class="widget-heading col-5"><h3> {{ 'TYPEFICHE.DescriptionLabel' | translate }} :</h3></div>
                                                        <div class="float-right col-5">{{ typefiche.description }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>