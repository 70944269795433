import { Component, OnInit } from '@angular/core';
import { ConvoyeurService } from 'src/app/_services/RH-service/convoyeur/convoyeur.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateUsersComponent } from '../../users/create-users/create-users.component';

@Component({
  selector: 'app-convoyeur-add',
  templateUrl: './convoyeur-add.component.html',
  styleUrls: ['./convoyeur-add.component.scss']
})
export class ConvoyeurAddComponent implements OnInit {


  ConvoyeurForm: FormGroup;
  users: any;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  licence_validity_file: File = null;
  pipe = new DatePipe('en-US');
  date = new Date();
  currentDate = this.pipe.transform(this.date, 'yyyy-MM-dd');
  isMaterial: boolean = false;


  constructor(
    private ConvoyeurService: ConvoyeurService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private userDialog: MatDialog,
    private ref: MatDialog
  ) { }

  ngOnInit() {
    this.getusers();
    this.isMaterial = '/convoyeur/add' != location.pathname;
    this.ConvoyeurForm = this.formBuilder.group({
      user_id: ['', [Validators.required]],
      licence_number: [''],
      licence_type: [''],
      date_of_issue: [''],
      end_of_date: [''],
      licence_validity_file: [''],
    });
  }

  getusers() {
    this.ConvoyeurService.getUser().then((res) => {
      this.users = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  onSelectfile2(event) {
    this.licence_validity_file = event.target.files[0];
  }

  get form() {
    return this.ConvoyeurForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.ConvoyeurForm.invalid) {
      this.translate.get('Convoyeur.SubmitErrorConvoyeur')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('user_id', '' + this.form.user_id.value);
    formData.append('licence_number', '' + this.form.licence_number.value);
    formData.append('licence_type', '' + this.form.licence_type.value);
    if (this.licence_validity_file != null)
      formData.append('licence_validity_file', this.licence_validity_file, this.licence_validity_file.name);
    if(this.form.licence_number.value != ''){
      if (this.form.end_of_date.value < this.form.date_of_issue.value) {
        this.translate.get('Forms.CompareDateError')
        .subscribe(val => this.notifService.danger(val));
        this.isLoading = false;
        return;
      }
      if (this.currentDate < this.form.date_of_issue.value) {
        this.translate.get('Forms.StartDateError')
        .subscribe(val => this.notifService.danger(val));
        this.isLoading = false;
        return;
      }
      if (this.currentDate > this.form.end_of_date.value) {
        this.translate.get('Forms.EndDateError')
        .subscribe(val => this.notifService.danger(val));
        this.isLoading = false;
        return;
      }
    }
    formData.append('date_of_issue', '' + this.form.date_of_issue.value);
    formData.append('end_of_date', '' + this.form.end_of_date.value);
    console.log(formData);
    this.ConvoyeurService.post(formData)
      .then(resp => {
        this.translate.get('Convoyeur.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.ConvoyeurForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('Convoyeur.SubmitErrorConvoyeur')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }

  addUser() {
    var modelDialogef =  this.userDialog.open(CreateUsersComponent,{
       width: '1000px',
       height: '800px',
       disableClose: true
     });

     modelDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);

       this.ngOnInit();
     });
   }

   close(){
     this.ref.closeAll();
   }

}
