import { HttpClient } from '@angular/common/http';
import * as Routes from 'src/Routes';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GammeEntretienService {

  constructor(private http: HttpClient) { }

  add(formData: FormData): Promise<any> {
    return this.http.post<any>(Routes.GAMME_ENTRETIEN, formData).toPromise();
  }

  update(formData: FormData, id): Promise<any> {
    return this.http.post<any>(`${Routes.GAMME_ENTRETIEN}/${id}`, formData).toPromise();
  }

  delete(id: number) {
    return this.http.delete(`${Routes.GAMME_ENTRETIEN}/${id}`).toPromise();

  }

  find(id: number): Promise<any> {
    return this.http.get<any>(`${Routes.GAMME_ENTRETIEN}/${id}`).toPromise();
  }
  public gets(page) {
    return this.http.get<any>(`${Routes.GAMME_ENTRETIEN}?page=${page}`)
      .toPromise();
  }

  public get(url) {
    return this.http.get<any>(`${url}`)
      .toPromise();
  }

  public getTypeEntretien() {
    return this.http.get<any>(`${Routes.TYPE_ENTRETIENT}`)
      .toPromise();
  }

  public getMecanicien() {
    return this.http.get<any>(`${Routes.MECANICIEN}`)
      .toPromise();
  }

  public getIntervention() {
    return this.http.get<any>(`${Routes.INTERVENTION}`)
      .toPromise();
  }

  /* public getUser() {
    return this.http.get<any>(`${Routes.USER}`)
      .toPromise();
  } */
}
