<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>{{ "Mecanicien.Mecanicien" | translate }}</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                        <li class="breadcrumb-item "><a [routerLink]="['/mecanicien/all']">{{ "Mecanicien.Mecanicien" |
                                translate
                                }}</a></li>
                        <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>

                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->

    <section class="container">
        <div class="row">
            <div class="col-md-12">
                  <form (submit)="onSubmit()" [formGroup]="MecanicienForm">
                    <div class="card card-primary">

                        <div class="card-header" style="background-color: #17a2b8;">
                            <h3 class="card-title">{{ "Mecanicien.MecanicienAdd" | translate }}</h3>
                        </div>
                        <div class="card-body" style="display: block;">
                            <div class="form-group">
                                <label>{{ "Mecanicien.UserContratLabel" | translate }}
                                  <span style="color: red;">*</span>
                                </label>
                                <select formControlName="user_id"
                                    class="form-control {{ isSubmitted && form.user_id.errors ? 'is-invalid' : '' }}" style="width: 100%;"
                                    tabindex="-1" aria-hidden="true">
                                    <option value="">{{ "Mecanicien.ContratDriver" | translate }}</option>
                                    <option *ngFor="let driver of users?.data" value="{{driver.id}}">{{driver.name}}
                                    </option>
                                </select>
                                <label>Ajouter un utilisateur en <a href="javascript:void(0)" (click)="addUser()">cliquant
                                    ici</a></label>
                            </div>
                            <div class="form-group">
                                <label for="inputName">{{ "Mecanicien.SpecialityLabel" | translate }}
                                  <span style="color: red;">*</span>
                                </label>
                                <input type="text" id="inputName"
                                    class="form-control {{ isSubmitted && form.speciality.errors ? 'is-invalid' : '' }}"
                                    formControlName="speciality"
                                    placeholder="{{ 'Mecanicien.LabelSpeciality' | translate }}">
                            </div>
                            <div class="form-group">
                                <label for="inputName">{{ "Mecanicien.AnneExpertLabel" | translate }}
                                  <span style="color: red;">*</span>
                                </label>
                                <input type="number" id="inputName"
                                    class="form-control {{ isSubmitted && form.anne_expert.errors ? 'is-invalid' : '' }}"
                                    formControlName="anne_expert"
                                    placeholder="{{ 'Mecanicien.LabelAnneExpert' | translate }}">
                            </div>
                            <div class="form-group">
                                <label for="inputName">{{ "Mecanicien.LieuTravailLabel" | translate }}
                                  <span style="color: red;">*</span>
                                </label>
                                <input type="text" id="inputName"
                                    class="form-control {{ isSubmitted && form.lieu_travail.errors ? 'is-invalid' : '' }}"
                                    formControlName="lieu_travail"
                                    placeholder="{{ 'Mecanicien.LabelLieuTravail' | translate }}">
                            </div>
                            <div class="form-group">
                                <label for="inputName">{{ "Mecanicien.MecanicienAttestationFileLabel" | translate
                                    }} <span style="color: red;">*</span>
                                </label>
                                <input type="file" id="inputName" (change)=onSelectfile2($event) class="form-control"
                                    formControlName="mecanicien_attestation_file"
                                    placeholder="{{ 'Mecanicien.LabelMecanicienAttestationFile' | translate }}">
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                                <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                                <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                            </button>
                        </div>
                        <!-- /.card-body -->
                    </div>
                </form>
                <!-- /.card -->
            </div>
            <div  *ngIf="isMaterial"  class="col-md-2" style="margin-left:85%"><button class="btn btn-danger" (click)="onClose()">
            {{
            'Material.ButtonClose' | translate }}
          </button></div>
        </div>
    </section>
    <!-- /.content -->
</div>
