import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/_services/RH-service/message/message.service';
import { UserService } from 'src/app/_services/users/user.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.scss']
})
export class MessageDetailComponent implements OnInit {


  message: any;
  url;
  user: any;

  constructor(
    private MessageService: MessageService,
    private UserService: UserService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    const messageId = +this.route.snapshot.paramMap.get("id");
    console.log(messageId);
    this.MessageService.find(messageId).then(
      data => {
        this.message = data;
        console.log(this.message)
      }
    ).catch(
      error => {
        this.translate.get('Message.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/message/all'])
      }
    )

   
  }

}
