
  <div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;" >
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-1">
          <div class="col-sm-6">
            <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ "TypeIntervention.TypeIntervention" | translate }}</font></font></h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
              <li class="breadcrumb-item "><a [routerLink]="['/intervention-type/list']">{{ "TypeIntervention.TypeIntervention" | translate }}</a></li>
              <li class="breadcrumb-item active">{{ "FileAriane.List" | translate }}</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">

      <!-- Default box -->


      <div class="card">
        <div class="card-header " style="background-color: #17a2b8; color:#fff;">
          <h3 class="card-title"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> {{ "TypeIntervention.TypeInterventionAll" | translate }}</font></font></h3>
          <button *ngIf="canCreate" [routerLink]="['/intervention-type/add']" type="button" class="btn btn-primary float-right" style="margin-right: 1%; margin-bottom: 1%; margin-top: 1%;">
            <i class="fas fa-plus" ></i> {{ "Forms.Marques.Ajouter" | translate }}
        </button>
        </div>
        <div class="card-body p-0">
          <table id="example1" class="table table-bordered table-striped">
              <thead>
                  <tr>
                      <th style="width: 15%"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">
                          #
                      </font></font></th>

                      <th style="width: 30%"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">
                        {{ "TypeIntervention.NameLabel" | translate }}
                      </font></font></th>
                      <th style="width: 30%"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">
                        {{ "TypeIntervention.DescriptionLabel" | translate }}
                      </font></font></th>
                      <th style="width: 25%"></th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let affect of type_interventions?.data,let i = index" id="{{ affect.id }}">
                      <td><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">
                        {{ i+1 }}
                      </font></font></td>
                      <td>
                        <a><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">
                          {{affect.nom}}
                         </font></font></a>
                    </td>
                      <td>
                        <a><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">
                          {{affect.description}}
                         </font></font></a>
                    </td>

                      <td class="project-actions text-right">

                        <a class="btn btn-primary btn-sm" href="javascript:void(0)" data-bs-placement="top" title="details"  (click)="details(affect)">
                          <i class="fas fa-eye">
                          </i>
                        </a>
                        &nbsp;
                        <a *ngIf="canUpdate" class="btn btn-info btn-sm" href="javascript:void(0)" data-bs-placement="top" title="modifier"  (click)="editTypeIntervention(affect.id)">
                            <i class="fas fa-pencil-alt">
                            </i>
                        </a>
                        &nbsp;
                        <a *ngIf="canDelete" class="btn btn-danger btn-sm" href="javascript:void(0)" data-bs-placement="top" title="supprimer"  (click)="deleteTypeIntervention(affect.id)">
                            <i class="fas fa-trash">
                            </i>
                        </a>
                    </td>
                  </tr>

              </tbody>
          </table>
          <div class="card-footer">
            <!-- <nav aria-label="Navigation dans la page des contacts">
              <ul class="pagination justify-content-center m-0">
                <li class="page-item" ><a (click)="getPage(type_interventions.prev_page_url)" class="page-link" ><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ 'General.Previous' | translate }}</font></font></a></li>
                <li class="page-item"><a (click)="getPage(type_interventions.next_page_url)" class="page-link" ><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ 'General.next' | translate }}</font></font></a></li>
              </ul>
            </nav> -->
          </div>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->

    </section>
    <!-- /.content -->
  </div>
