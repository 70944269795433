import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Toast, ToastrService } from 'ngx-toastr';
import { PanneServices } from 'src/app/_services/main-service/panne-services/panne-services';
import Swal from 'sweetalert2';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthService } from 'src/app/_services/auth/auth.service';
declare const $;

@Component({
  selector: 'app-panne-auto-list',
  templateUrl: './panne-auto-list.component.html',
  styleUrls: ['./panne-auto-list.component.scss']
})
export class PanneAutoListComponent implements OnInit {
  loading: boolean = true;
  assur: any;
  detail = false;

  @BlockUI() blockUI: NgBlockUI;
  //SweetAlert Text

  areYouSure = '';
  warning = 'Voulez-vous vraiment supprimer?'
  yes = 'Oui';
  no = 'Non';
  deleted = 'Suppression';
  deletedMessage = 'Opération  effectuée avec succes';
  cancelled = 'Annulation';
  cancelledMessage = 'Annulation effectuée  avec succes';
  canCreate = false;
  canUpdate = false;
  canDelete = false;

  idItem: any;
  pannes: any;
  page = 1;
  limit = 20;

  constructor(
    private panneService: PanneServices,
    private authService: AuthService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router

  ) { }
  ngOnInit(): void {

    this.getPannes();
    const permissionSuffix = 'pannes';
    this.canCreate = this.authService.hasPermission(`${permissionSuffix}-create`);
    this.canUpdate = this.authService.hasPermission(`${permissionSuffix}-update`);
    this.canDelete = this.authService.hasPermission(`${permissionSuffix}-delete`);
  }

  public getPannes() {

    this.panneService.all().then(
      response => {
        this.pannes = response;
        console.log(this.pannes)
        $(function () {
          $("#example1").DataTable({
            "responsive": true, "lengthChange": false, "autoWidth": false,
            "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
          }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        });
      }
    ).catch(
      error => {
        this.toastrService.error(error.error.message);
      }
    ).finally(
      () => {
      }
    )
  }

  editpannes(id) {
    this.router.navigate(['panne/update/:id' + id])
  }

  detailspannes(id) {
    this.router.navigate(['panne/detail/:id' + id])
  }


  deletepannes(pannes_id: number) {
    Swal.fire({
      title: this.areYouSure,
      text: this.warning,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.yes,
      cancelButtonText: this.no
    }).then((result) => {
      if (result.value) {
        this.blockUI.start('Loading...');
        this.panneService.delete(pannes_id).then(
          data => {
            this.blockUI.stop();
            Swal.fire(
              this.deleted,
              this.deletedMessage,
              'success'
            )
            this.getPannes();
          }
        ).catch(
          error => {
            console.log(error)
            this.blockUI.stop();
            this.toastr.error('L Operation a echouée');
          }
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.cancelledMessage,
          'error'
        )
      }
    })
  }

}