import { Component, OnInit } from '@angular/core';
import { TypeInterventionService } from 'src/app/_services/maintenance/interventions/type-intervention.service';
import { InterventionService } from 'src/app/_services/maintenance/interventions/intervention.service';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-intervention-add',
  templateUrl: './intervention-add.component.html',
  styleUrls: ['./intervention-add.component.scss']
})
export class InterventionAddComponent implements OnInit {

  user:any;
  InterventionForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  page=1;
  type_interventions:any;
  isMaterial: boolean = false;


  constructor(
    private InterventionService: InterventionService,
    private typeInterventionService: TypeInterventionService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private userDialog: MatDialog,
    private ref: MatDialog,
  ) {}

  ngOnInit() {

    this.getTypeIntervention();
    this.InterventionForm = this.formBuilder.group({
      name:['',[Validators.required]],
      typeIntervention:['',[Validators.required]],
      description:[''],
    }),
    this.user = this.authService.getUser()
    this.isMaterial = '/intervention/add' != location.pathname;
  }

  get form() {
    return this.InterventionForm.controls;
  }
  getTypeIntervention() {
    this.typeInterventionService.gets(this.page).then((res) => {
      this.type_interventions = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;


    if (this.InterventionForm.invalid) {
      this.translate.get('Intervention.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('nom', ''+this.form.name.value);
    formData.append('type_intervention_id', ''+this.form.typeIntervention.value);
    formData.append('description', ''+this.form.description.value);
    formData.append('create_id', ''+this.user.id);

      console.log(formData);
      this.InterventionService.post(formData)
      .then(resp => {
        this.translate.get('Intervention.SubmitSuccess')
        .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.InterventionForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('Intervention.SubmitErrorIntervention')
        .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }
onClose() {
    this.ref.closeAll();
    // location.reload();
  }

}
