import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';
import { UserService } from 'src/app/_services/users/user.service';
import Swal from 'sweetalert2';
declare const $;

@Component({
	selector: 'app-list-users',
	templateUrl: './list-users.component.html',
	styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent implements OnInit {

	users;
	public users_tmp: any[] = [];
	public loading: boolean = true;
	@BlockUI() blockUI: NgBlockUI;

	//SweetAlert Text
	public areYouSure = '';
	public warning = ''
	public yes = '';
	public no = '';
	public deleted = '';
	public deletedMessage = '';
	public cancelled = '';
	public page = 1;
	public cancelledMessage = '';
	canCreate = false;
	canUpdate = false;
	canDelete = false;


	constructor(
		private authService: AuthService,
		private userService: UserService,
		private notifService: NotifService,
		private translate: TranslateService,
		private router: Router
	) {

		this.translate.get(
			['SweetAlert.AreYouSure', 'SweetAlert.Warning', 'SweetAlert.Yes', 'SweetAlert.No', 'SweetAlert.Deleted',
				'SweetAlert.DeletedMessage', 'SweetAlert.Cancelled', 'SweetAlert.CancelledMessage'],
			{ data: 'cet utilisateur' })
			.subscribe(val => {
				this.areYouSure = val['SweetAlert.AreYouSure'];
				this.warning = val['SweetAlert.Warning'];
				this.yes = val['SweetAlert.Yes'];
				this.no = val['SweetAlert.No'];
				this.deleted = val['SweetAlert.Deleted'];
				this.deletedMessage = val['SweetAlert.DeletedMessage'];
				this.cancelled = val['SweetAlert.Cancelled'];
				this.cancelledMessage = val['SweetAlert.CancelledMessage'];
			});
	}

	ngOnInit() {
		this.getUsers(this.page);
		const permissionSuffix = 'users';
		this.canCreate = this.authService.hasPermission(`${permissionSuffix}-create`);
		this.canUpdate = this.authService.hasPermission(`${permissionSuffix}-update`);
		this.canDelete = this.authService.hasPermission(`${permissionSuffix}-delete`);
	}

	getUsers(page) {
		this.loading = true;
		this.userService.all(page).then(
			response => {
				this.users = response;
				console.log(this.users);
				$(function () {
          			//$("#example1").DataTable().fnDestroy()
					$("#example1").DataTable({
					  "responsive": true,
					  "autoWidth": false,
					});
				});
			}
		).catch(
			error => {
				this.notifService.danger(error.error.message)
			}
		).finally(
			() => {
				this.loading = false;
			}
		)
	}

	getPage(url) {
		if (url) {
			this.userService.get(url).then((res) => {
				this.users = res;
				console.log(this.users)
			}).catch(
				error => {
					this.notifService.danger(error.error.message)
				}
			).finally(
				() => {
					this.loading = false;
				}
			)
		}
	}

	editUser(id) {
		this.router.navigate(['/user-update/' + id])
	}

	detailsUser(id) {
		this.router.navigate(['/user-detail/' + id])
	}

	deleteUser(id) {

		Swal.fire({
			title: this.areYouSure,
			text: this.warning,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: this.yes,
			cancelButtonText: this.no
		}).then((result) => {
			if (result.value) {
				this.blockUI.start('Loading...');
				this.userService.delete(id).then(
					data => {
						this.blockUI.stop();
						Swal.fire(
							this.deleted,
							this.deletedMessage,
							'success'
						)
						this.getUsers(this.page);
					}
				).catch(
					error => {
						console.log(error)
						this.blockUI.stop();
						this.translate.get('User.' + error.error.code)
							.subscribe(val => this.notifService.danger(val));
					}
				)

			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					this.cancelled,
					this.cancelledMessage,
					'error'
				)
			}
		})
	}

	public computeDate(user: any) {
		let date: any = new Date(user.created_at);
		date = this.pad(date.getDate(), 2, '0') + '-' + this.pad(date.getMonth() + 1, 2, '0') + '-' + date.getFullYear() + ' at ' + date.getHours() + ':' + date.getMinutes();
		user.created_at = date;
	}

	public pad(s, width, character) {
		return new Array(width - s.toString().length + 1).join(character) + s;
	}
  show(src){
    Swal.fire({
      imageUrl: src,
      imageHeight: 300,
      imageAlt: "Custom image",
      animation: false
  });
  }
}
