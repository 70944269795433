import { NotifService } from 'src/app/_services/notif.service';
import { NotificationsService } from 'src/app/_services/notification/notification.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/_services/RH-service/message/message.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { LangService } from 'src/app/_services/lang/lang.service';
import {UserService} from '../../../_services/users/user.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  lan;
  profile;
  user;
  notifications;
  limit = 8;
  messages;
  user_id_2;
  userId;

  constructor(
    private authService: AuthService,
    private MessageService: MessageService,
    private notificationService: NotificationsService,
    private translateService: TranslateService,
    private langService: LangService,
    private notifService: NotifService,
    private userService:UserService,
    // private userService:UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.user = this.authService.getUser();
    console.log(this.user)
    this.lan = this.translateService.currentLang;
    console.log(this.lan);
    //this.getNotification(this.limit);
    this.findUser(this.user.id);
    this.filterRecipient(this.user.id)

    // this.userService.find(this.user.id).then(
    //   response => {
    //     this.profile = response;
    //     console.log(response)
    //   }
    // );
  }

  logout() {
    this.authService.logout();
  }

  changeLanguage(value) {
    this.langService.setLang(value);
    this.lan = value;
  }

  /* getNotification(limit) {
    this.notificationService.gets(limit).then(
      response => {
        this.notifications = response;
       
      }
    ).catch(
      error => {
        this.notifService.danger(error.message)
      }
    )
  } */
  findUser(id) {
    this.userService.userInfo(id).then(
      response => {
        this.userId = response;
        console.log("Affichage de user info");
        console.log(this.userId);
        
        
       
      }
    ).catch(
      error => {
        this.notifService.danger(error.message)
      }
    )
  }

  detaillenotecritique(id) {
   
    this.router.navigate(['/note-critique/detail/' + id]);
  }

  filterRecipient(user_id_2) {
    this.MessageService.filterRecipient(user_id_2).then((res) => {
      this.messages = res;
      
    }, (error) => {
      this.notifService.danger(error.message)

    });
  }

  detailleMessage(id) {
   
    this.router.navigate(['/message/detail/' + id]);
  }


}
