<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'Vehicle.ListVehicle' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                    <li class="breadcrumb-item "><a
                            [routerLink]="['/vehicles/list']">{{ 'Vehicle.list' | translate }}</a></li>
                    <li class="breadcrumb-item active">{{ "FileAriane.List" | translate }}</li>
                </ol>
            </div>
        </div> 
    </div> 
    <!-- /.container-fluid -->
</section>

<div class="tabs-animation" style="min-height: 1416.81px; margin-left: 2%; margin-right: 2%;">
    <div class="main-card mb-3 card">
        <section class="content" >  
         <div class="card-header" style="background-color: #17a2b8; color: white;" >
            <h2 class="card-title" style="margin-left: 1%; margin-bottom: 1%; margin-top: 1%;">
                {{ 'Vehicle.ListVehicleLabel' | translate }}</h2>
            <button *ngIf="canCreate" [routerLink]="['/vehicles/add']" type="button" class="btn btn-primary float-right"
                style="margin-right: 1%; margin-bottom: 1%; margin-top: 1%;">
                <i class="fas fa-plus"></i> {{ "Forms.Marques.Ajouter" | translate }}
            </button>
         </div>
        </section>
        <!-- /.card-header -->
        <div class="card-body p-0">
            <div class="table-responsive">
                <table id="example1" class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th style="width: 5%">
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">
                                        #
                                    </font>
                                </font>
                            </th>
                            <th style="width: 20%">
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">
                                        {{ 'Vehicle.VehicleRegistration' | translate }} </font>
                                </font>
                            </th>
                            <th style="width: 20%">
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">{{ 'Vehicle.vehicleBrand' | translate }}
                                    </font>
                                </font>
                            </th>
                            <th style="width: 20%">
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">{{ 'Vehicle.vehiclemodel' | translate }}
                                    </font>
                                </font>
                            </th>
                            <th style="width: 20%">
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">{{ 'Vehicle.owner' | translate }} </font>
                                </font>
                            </th>
                           <!--  <th>
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">{{ 'Vehicle.Operations' | translate }}
                                    </font>
                                </font>
                            </th> -->
                            <th style="width: 15%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let vehicle of vehicles?.data,let i = index" id="{{ vehicle.id }}">
                            <td>
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">
                                        {{ i+1 }}
                                    </font>
                                </font>
                            </td>
                            <td>
                                <a href="pages/examples/invoice.html">
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;"> {{vehicle.vehicle_registration}}</font>
                                    </font>
                                </a>
                            </td>
                            <td>
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;"> {{vehicle?.vehicle_brand?.name}}</font>
                                </font>
                            </td>
                            <td><span>
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;"> {{vehicle.vehicle_brand?.name}}</font>
                                    </font>
                                </span></td>
                            <td>
                                <div class="sparkbar" data-color="#00a65a" data-height="20">
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;"> {{vehicle.owner}}</font>
                                    </font>
                                </div>
                            </td>
                            <td class="project-actions text-right">
                                <a class="btn btn-primary btn-sm" data-bs-placement="top" title="details" href="javascript:void(0)"
                                    (click)="detailsVehicle(vehicle)">
                                    <i class="fas fa-eye"></i>
                                </a>
                                &nbsp;
                                <a *ngIf="canUpdate" class="btn btn-info btn-sm" data-bs-placement="top" title="modifier" href="javascript:void(0)" (click)="editVehicle(vehicle)">
                                    <i class="fas fa-pencil-alt">
                                    </i>
                                </a>
                                &nbsp;
                                <a *ngIf="canDelete" class="btn btn-danger btn-sm" data-bs-placement="top" title="supprimer" href="javascript:void(0)"
                                    (click)="deleteVehicle(vehicle.id)">
                                    <i class="fas fa-trash">
                                    </i>
                                </a>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- /.table-responsive -->
        </div>
    </div>
</div>