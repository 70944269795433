import { Component, OnInit } from '@angular/core';
import { TransferService } from 'src/app/_services/movement-service/Transfer/transfer.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';
import { AutoService } from 'src/app/_services/parc-service/auto-service/auto-service';
import { ConvoyeurService } from 'src/app/_services/RH-service/convoyeur/convoyeur.service';

@Component({
  selector: 'app-tranfer-add',
  templateUrl: './tranfer-add.component.html',
  styleUrls: ['./tranfer-add.component.scss']
})
export class TranferAddComponent implements OnInit {
  DataForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  vehicules: any;
  chauffeurs: any;
  convoyeurs: any;
  missions: any;

  constructor(
    private TransferService: TransferService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private autoService: AutoService,
    private convoyeurService: ConvoyeurService,

  ) { }

  ngOnInit(): void {
    this.getMission();
    this.getChauffeur();
    this.getVehicule();
    this.getConvoyeur();

    this.DataForm = this.formBuilder.group({
      motif: ['', [Validators.required]],
      cause: ['', [Validators.required]],
      chauffeur_id: ['', [Validators.required]],
      convoyeur_id: ['', [Validators.required]],
      vehicule_id: ['', [Validators.required]],
      mission_id: ['', [Validators.required]],
    });
  }

  getMission() {
    this.TransferService.getMission().then(res => {
      this.missions = res;
      console.log(this.missions)
    }).catch(
      error => {
        this.notifService.danger(error.error.message)
      })
  }

  getChauffeur() {
    this.TransferService.getChauffeur().then(res => {
      this.chauffeurs = res;
      console.log(this.chauffeurs)
    }).catch(
      error => {
        this.notifService.danger(error.error.message)
      })
  }

  getVehicule() {
    this.autoService.all().then((res) => {
      this.vehicules = res;
      console.log(this.vehicules)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  getConvoyeur() {
    this.convoyeurService.getConvoyeur().then((res) => {
      this.convoyeurs = res;
      console.log(this.convoyeurs)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
 
  get form() {
    return this.DataForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.DataForm.invalid) {

      this.translate.get('Transfer.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    } 

    this.isLoading = true;
    const formData = new FormData();
    formData.append('mission_id', '' + this.form.mission_id.value);
    formData.append('vehicule_id', '' + this.form.vehicule_id.value);
    formData.append('chauffeur_id', '' + this.form.chauffeur_id.value);
    formData.append('convoyeur_id', '' + this.form.convoyeur_id.value);
    formData.append('motif', '' + this.form.motif.value);
    formData.append('cause', '' + this.form.cause.value);    

    console.log(formData);
    this.TransferService.add(formData)
      .then(resp => {
        this.translate.get('Transfer.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.DataForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('Transfer.SubmitErrorTransfer')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }

}
