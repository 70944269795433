import { UserService } from 'src/app/_services/users/user.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-listdetail-user',
  templateUrl: './listdetail-user.component.html',
  styleUrls: ['./listdetail-user.component.scss']
})
export class ListdetailUserComponent implements OnInit {

  user;
  constructor(
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
  ) { }

  async ngOnInit() {
    const userId = +this.route.snapshot.paramMap.get("id");
    console.log(userId);
    this.userService.find(userId).then(
      data => {
        this.user = data;
        console.log(this.user)
      }
    ).catch(
      error => {
        this.translate.get('user.' + error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/user-all'])
      }
    )
  }
}
