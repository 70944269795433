<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'User.Users' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">Home</a></li>
                    <li class="breadcrumb-item active"><a [routerLink]="['/user-all']">{{ 'User.User' | translate }}</a>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">
    <div class="container-fluid">
        <!-- left column -->
        <div class="row" >
            <div class="col-md-7" >
                <!-- general form elements -->
                <div class="card card-primary">
                    <div class="card-header" style="background-color: #17a2b8;" >
                        <h3 class="card-title">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">{{"User.UserAdd" | translate}}</font>
                            </font>
                        </h3>
                    </div>
                    <!-- /.card-header -->
                    <!-- form start -->
                    <form role="form" (submit)="onSubmit()" [formGroup]="userForm">
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">{{ "User.NameUserLabel" | translate
                                                }}
                                            </font>
                                        </font>
                                        <span style="color: red">*</span>
                                      </label>
                                    <input type="text"
                                        class="form-control {{ isSubmitted && form.name.errors ? 'is-invalid' : '' }}"
                                        id="exampleInputEmail1" formControlName="name"
                                        placeholder="{{ 'User.LabelNameUser' | translate }}">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">{{ "User.emailUserLabel" | translate
                                                }}
                                            </font>
                                        </font>
                                        <span style="color: red">*</span>
                                    </label>
                                    <input type="email"
                                        class="form-control {{ isSubmitted && form.email.errors ? 'is-invalid' : '' }}"
                                        id="exampleInputEmail1" formControlName="email"
                                        placeholder="{{ 'User.LabelemailUser' | translate }}">
                                </div>
                            </div>

                            <!--   <div class="form-group col-md-7">
                                        <label for="exampleInputPassword1">
                                            <font style="vertical-align: inherit;">
                                                <font style="vertical-align: inherit;">{{ "User.PasswordUserLabel" | translate }}
                                                </font>
                                            </font>
                                        </label>
                                        <input type="password"
                                            class="form-control {{ isSubmitted && form.password.errors ? 'is-invalid' : '' }}"
                                            id="exampleInputPassword1" formControlName="password" placeholder="Mot de passe">
                                    </div> -->
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">{{ "User.CNI_numberUserLabel" |
                                                translate
                                                }}
                                            </font>
                                        </font>
                                        <span style="color: red">*</span>
                                    </label>
                                    <input type="text"
                                        class="form-control {{ isSubmitted && form.CNI_number.errors ? 'is-invalid' : '' }}"
                                        id="exampleInputEmail1" formControlName="CNI_number"
                                        placeholder="{{ 'User.LabelCNI_numberUser' | translate }}">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputFile">
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">{{ "User.picture_CNIUserLabel" |
                                                translate }}
                                            </font>
                                        </font>
                                    </label>
                                    <div class="input-group">
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="exampleInputFile"
                                                (change)=onSelectfile2($event) formControlName="picture_CNI">
                                            <label class="custom-file-label" for="exampleInputFile">
                                                <font style="vertical-align: inherit;">
                                                    <font style="vertical-align: inherit;">Choisir le fichier</font>
                                                </font>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">{{ "User.sexeUserLabel" | translate
                                                }}
                                            </font>
                                        </font>
                                    </label>
                                    <!--     <input type="text" class="form-control" id="exampleInputEmail1" formControlName="sexe"
                                                                            placeholder="{{ 'User.LabelsexeUser' | translate }}"> -->
                                    <select name="select" class="form-control" id="exampleInputEmail1"
                                        formControlName="sexe">
                                        <option value="M">Homme</option>
                                        <option value="F">Femme</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputFile">
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">{{ "User.avatarUserLabel" | translate
                                                }}
                                            </font>
                                        </font>
                                    </label>
                                    <div class="input-group">
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="exampleInputFile"
                                                (change)=onSelectfile($event) formControlName="avatar">
                                            <label class="custom-file-label" for="exampleInputFile">
                                                <font style="vertical-align: inherit;">
                                                    <font style="vertical-align: inherit;">Choisir le fichier</font>
                                                </font>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">{{ "User.birth_dateUserLabel" |
                                                translate
                                                }}
                                            </font>
                                        </font>
                                    </label>
                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                        formControlName="birth_date"
                                        placeholder="{{ 'User.Labelbirth_dateUser' | translate }}">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">{{ "User.birth_placeUserLabel" |
                                                translate }}
                                            </font>
                                        </font>
                                    </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        formControlName="birth_place"
                                        placeholder="{{ 'User.Labelbirth_placeUser' | translate }}">
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">
                                                {{ "User.phone_number_1UserLabel" | translate }}</font>
                                        </font>
                                    </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        formControlName="phone_number_1"
                                        placeholder="{{ 'User.Labelphone_number_1User' | translate }}">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">
                                                {{ "User.phone_number_2UserLabel" | translate }}</font>
                                        </font>
                                    </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        formControlName="phone_number_2"
                                        placeholder="{{ 'User.Labelphone_number_2User' | translate }}">
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">
                                                {{ "User.marital_statusUserLabel" | translate }}</font>
                                        </font>
                                    </label>
                                    <!--  <input type="text" class="form-control" id="exampleInputEmail1"
                                                                            formControlName="marital_status"
                                                                            placeholder="{{ 'User.Labelmarital_statusUser' | translate }}">
                                             -->
                                    <select name="select" class="form-control" id="exampleInputEmail1"
                                        formControlName="marital_status">
                                        <option value="SINGLE">CÉLIBATAIRE</option>
                                        <option value="MARRIED">MARIÉE</option>
                                        <option value="WIDOWED">WIDOWED</option>
                                        <option value="DIVORCED">DIVORCÉE</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">{{ "User.childrenUserLabel" |
                                                translate
                                                }}
                                            </font>
                                        </font>
                                    </label>
                                    <input type="number" min="0" class="form-control" id="exampleInputEmail1"
                                        formControlName="children"
                                        placeholder="{{ 'User.LabelchildrenUser' | translate }}">
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">{{ "User.adressUserLabel" | translate
                                                }}
                                            </font>
                                        </font>
                                    </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        formControlName="adress" placeholder="{{ 'User.LabeladressUser' | translate }}">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">
                                                {{ "User.residence_townUserLabel" | translate }}</font>
                                        </font>
                                    </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        formControlName="residence_town"
                                        placeholder="{{ 'User.Labelresidence_townUser' | translate }}">
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">
                                                {{ "User.contact_personUserLabel" | translate }}</font>
                                        </font>
                                    </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        formControlName="contact_person"
                                        placeholder="{{ 'User.Labelcontact_personUser' | translate }}">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">
                                                {{ "User.emergency_phoneUserLabel" | translate }}</font>
                                        </font>
                                    </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        formControlName="emergency_phone"
                                        placeholder="{{ 'User.Labelemergency_phoneUser' | translate }}">
                                </div>
                            </div>


                        </div>
                        <!-- /.card-body -->

                        <div class="card-footer">
                            <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                                <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                                <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-5 mt-2" >
                <form class="">
                    <div class="position-relative form-group">
                        <label class="">
                            {{ "Role.SearchLabel" | translate }} ({{ roles.length }})
                            <div class="widget-content-left mr-2">
                                <div class="custom-checkbox custom-control">
                                    <input type="checkbox" id="selectAll" class="custom-control-input"
                                        (change)="selectAllRole($event)">
                                    <label class="custom-control-label" for="selectAll">&nbsp;</label>
                                </div>
                            </div>
                        </label>
                    </div>
                    <input placeholder="{{ 'Role.SearchText' | translate }}" type="text" (keyup)="search($event)"
                        class="form-control">
                        <div style="height: 500px;overflow: scroll;">

                            <ul class="todo-list-wrapper list-group list-group-flush"
                                style="overflow-y: auto; max-height: 1000px;">
                                <li class="list-group-item" *ngFor="let role of roles">
                                    <div class="todo-indicator bg-warning"></div>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-2">
                                                <div class="custom-checkbox custom-control">
                                                    <input type="checkbox" id="{{ role.id }}" [checked]="isChecked(role.id)"
                                                        class="custom-control-input" (change)="onChecked(role, $event)">
                                                    <label class="custom-control-label" for="{{ role.id }}">&nbsp;</label>
                                                </div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="widget-heading">{{ role.display_name }}
                                                </div>
                                                <div class="widget-subheading"><i>{{ role.description }}</i></div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                </form>

            </div>
        </div>
        <div  *ngIf="isMaterial"  class="col-md-2" style="margin-left:85%"><button class="btn btn-danger" (click)="onClose()">
            {{
            'Material.ButtonClose' | translate }}
          </button></div>


        <!-- /.row -->

    </div><!-- /.container-fluid -->
</section>
