<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-1">
          <div class="col-sm-6">
            <h1>
              <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">{{ "FicheIntervention.FicheIntervention" | translate }}</font>
              </font>
            </h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
              <li class="breadcrumb-item "><a [routerLink]="['/fiche-intervention/all']">{{ "FicheIntervention.FicheIntervention" | translate }}</a></li>
              <li class="breadcrumb-item active">{{ "FileAriane.List" | translate }}</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
  
    <!-- Main content -->
    <section class="content">
  
      <!-- Default box -->
  
  
      <div class="card">
        <div class="card-header" style="background-color: #17a2b8; color:white;">
          <h1 class="card-title">
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;" > {{ "FicheIntervention.FicheInterventionAll" | translate }}</font>
            </font>
          </h1>
          <button *ngIf="canCreate" [routerLink]="['/fiche-intervention/add']" type="button" class="btn btn-primary float-right">
            <i class="fas fa-plus"></i> {{ "Forms.Marques.Ajouter" | translate }}
          </button>
        </div>
        
        <div class="card-body p-0">
          <table id="example1" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th style="width: 1%">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      #
                    </font>
                  </font>
                </th>
                <th style="width: 15%">
                  <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">
                          {{ "FicheIntervention.fileFicheInterventionLabel" | translate }}
                      </font>
                  </font>
              </th>
  
                <th style="width: 15%">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ "FicheIntervention.TypeInterventionLabel" | translate }}
                    </font>
                  </font>
                </th>

                <th style="width: 10%">
                    <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">
                        {{ "FicheIntervention.VehiculeLabel" | translate }}
                      </font>
                    </font>
                </th>

                <th style="width: 10%">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ "FicheIntervention.MecanicienLabel" | translate }}
                    </font>
                  </font>
                </th>
                <th style="width: 10%">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ "FicheIntervention.DegreUregenceLabel" | translate }}
                    </font>
                  </font>
                </th>
  
                <th style="width: 10%">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ "FicheIntervention.TempsAlloueLabel" | translate }}
                    </font>
                  </font>
                </th>
                <th style="width: 10%">
                    <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">
                        {{ "FicheIntervention.TravailEffectuerLabel" | translate }}
                      </font>
                    </font>
                </th>
                <th style="width: 15%;"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let affect of fiche_interventions?.data,let i = index" id="{{ affect.id }}">
                <td>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ i+1 }}
                    </font>
                  </font>
                </td>
                <td>
                  <a>
                      <font style="vertical-align: inherit;">
                          <font style="vertical-align: inherit;">
                            <a (click)="show(affect?.file)">
                                <img height="50" src="{{ affect.file }}" alt="">
                            </a>
                          </font>
                      </font>
                  </a>
              </td>
                <td>
                  <a>
                    <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">
                        {{ affect.nom_type_intervention }}
                      </font>
                    </font>
                  </a>
                </td>
                <td>
                    <a>
                      <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">
                          {{ affect.immatriculation }}
                        </font>
                      </font>
                    </a>
                </td>
                <td>
                  <a>
                    <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">
                        {{ affect.nom_mecanicien }}
                      </font>
                    </font>
                  </a>
                </td>
                <td>
                  <a>
                    <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">
                        {{ affect.degre_urgence }}
                      </font>
                    </font>
                  </a>
                </td>
                <td>
                  <a>
                    <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">
                        {{ affect.temps_alloue }}
                      </font>
                    </font>
                  </a>
                </td>
                <td>
                    <a>
                      <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">
                          {{ affect.travail_effectuer }}
                        </font>
                      </font>
                    </a>
                  </td>
  
                <td class="project-actions text-right">
                  <a class="btn btn-primary btn-sm" href="javascript:void(0)" data-bs-placement="top" title="details"  (click)="detailsFicheIntervention(affect.id)"> 
                    <i class="fas fa-eye"></i>
                  </a>
                  &nbsp;
                  <a *ngIf="canUpdate" class="btn btn-info btn-sm" href="javascript:void(0)" data-bs-placement="top" title="modifier"  (click)="editFicheIntervention(affect.id)">
                    <i class="fas fa-pencil-alt">
                    </i>
                  </a>
                  &nbsp;
                  <a *ngIf="canDelete" class="btn btn-danger btn-sm" href="javascript:void(0)" data-bs-placement="top" title="supprimer"  (click)="deleteFicheIntervention(affect.id)">
                    <i class="fas fa-trash">
                    </i>
                  </a>
                </td>
              </tr>
  
            </tbody>
          </table>
          <div class="card-footer">
            <!-- <nav aria-label="Navigation dans la page des contacts">
              <ul class="pagination justify-content-center m-0">
                <li class="page-item"><a (click)="getPage(fiche_interventions.prev_page_url)" class="page-link">
                    <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">{{ 'General.Previous' | translate }}</font>
                    </font>
                  </a></li>
                <li class="page-item"><a (click)="getPage(fiche_interventions.next_page_url)" class="page-link">
                    <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">{{ 'General.next' | translate }}</font>
                    </font>
                  </a></li>
              </ul>
            </nav> -->
          </div>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
  
    </section>
    <!-- /.content -->
  </div>

