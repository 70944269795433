import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hr-list',
  templateUrl: './hr-list.component.html',
  styleUrls: ['./hr-list.component.scss']
})
export class HRListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
