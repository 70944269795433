import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-amende-update',
  templateUrl: './amende-update.component.html',
  styleUrls: ['./amende-update.component.scss']
})
export class AmendeUpdateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
