<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'FicheIntervention.FicheIntervention' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">{{ "FileAriane.Home" | translate }}</a></li>
                    <li class="breadcrumb-item active"><a [routerLink]="['/fiche-intervention/all']">{{ 'FicheIntervention.FicheIntervention' | translate }}</a>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="container">
    <div class="container-fluid">
        <!-- <div class="col-lg-12">
            <div class="card">
                <div class="card-body">

                    <div class="position-relative mb-4">
    
                        <div class="col-sm-12">
                            <img style="height:400px; margin-left:40%" class="img-fluid" src="{{user?.avatar}}"
                                alt="photo">
                        </div>

                    </div>

                </div>
            </div>

        </div> -->
        <div class="row">

            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header bg-primary border-0">
                        <h3 class="card-title">Informations Detaillées sur la fiche</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "FicheIntervention.TypeInterventionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{fiche_interventions?.nom_type_intervention}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "FicheIntervention.VehiculeLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{fiche_interventions?.immatriculation}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "FicheIntervention.MecanicienLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{fiche_interventions?.nom_mecanicien}}</td>
                                </tr>
                                <!-- <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Chauffeur.picture_CNILabel" | translate }}</td>
                                    <td><a href="{{user?.picture_CNI}}">Parcourir</a></td>
                                </tr> -->
                                <!-- <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "FicheIntervention.DateLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{fiche_interventions?.date}}</td>
                                </tr> -->
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "FicheIntervention.CompteurLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{fiche_interventions?.compteur}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "FicheIntervention.DegreUregenceLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{fiche_interventions?.degre_urgence}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "FicheIntervention.DateDebutLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{fiche_interventions?.date_debut}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header bg-primary border-0">
                        <h3 class="card-title bg-primary">Informations Detaillées sur la fiche</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "FicheIntervention.DateFinLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{fiche_interventions?.date_fin}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "FicheIntervention.TempsAlloueLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{fiche_interventions?.temps_alloue}}<i>h</i></td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "FicheIntervention.TempsIndisponibiliteLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{fiche_interventions?.temps_indisponibilite}}<i>h</i></td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "FicheIntervention.FicheInterventionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{fiche_interventions?.fiche_intervention}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "FicheIntervention.TravailEffectuerLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{fiche_interventions?.travail_effectuer}}</td>
                                </tr>
                                <!-- <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                                  -->

                            </tbody>
                        </table>
                    </div>

                </div>
                <!-- <div class="card-footer">
                    <button [routerLink]="['/fiche-intervention/all']" type="button" class="btn btn-primary float-right">
                        <p><i class="fa fa-long-arrow-left" aria-hidden="true">&#x27F5;</i></p>
                    </button>
                </div> -->
            </div>
            <!-- /.col-md-6 -->
        </div>

    </div>
    <!-- /.container-fluid -->
</div>
