import { Injectable } from '@angular/core';
import * as Routes from 'src/Routes';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private http: HttpClient) {}


  post(formData: FormData): Promise<any> {
    return this.http.post<any>(Routes.MESSAGE, formData).toPromise();
  }

  update(formData: FormData, id): Promise<any> {
    return this.http.post<any>(`${Routes.MESSAGE}/${id}`, formData).toPromise();
  }
  
  delete(id: number) {
    return this.http.delete(`${Routes.MESSAGE}/${id}`).toPromise();

  }
  
  find(id: number): Promise<any> {
    return this.http.get<any>(`${Routes.MESSAGE}/${id}`).toPromise();
  }
  public gets(id: number) {
    return this.http.get<any>(`${Routes.MESSAGE}/${id}/messageUser`)
      .toPromise();
  }

  public filterSender(id: number) {
    return this.http.get<any>(`${Routes.MESSAGE}/${id}/senderMessage`)
      .toPromise();
  }


  public filterRecipient(id: number) {
    return this.http.get<any>(`${Routes.MESSAGE}/${id}/reciverMessage`)
      .toPromise();
  }


  public get(url) {
    return this.http.get<any>(`${url}`)
      .toPromise();
  }


  public getUser() {
    return this.http.get<any>(`${Routes.USER}`)
      .toPromise();
  }

}
