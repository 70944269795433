import { Super } from './super.model';

export class Vehicle extends Super<Vehicle>{

    public id:number;
    public vehicle_registration:string;
    public vehicle_brand_id:string;
    public vehicle_model_id:string;
    public vehicle_type_id:string;
    public vehicle_body_id:string;
    public number_frame:string;
    public owner:string;
    public purchase_value:string
    public date_circulation:string;
    public color:string;
    public vehicle_condition:string;
    public place_number:string;
    public parking_place:string;
    public length:string;
    public width:string;
    public height:File;
    public empty_weight:string;

    public payload:string;
    public total_load_weight:string;
    public power:string
    public volume:string;
    public number_semi_trailer_axles:string;
    public fuel:string;
    public milage:string;
    public min_consumption:string;
    public max_consumption:string;
    public working_day:string;
    public required_working_time:File;
    public equipement:string;

    public vehicle_use:string;
    public photo_before_vehicle:string;
    public photo_rear_vehicle:string;
    public photo_leftside_vehicle:string;
    public photo_rightside_vehicle:string;
    public car_registration_photo:File;
}


