import { ParamService } from './../../../../../_services/parc-service/param-service/param-service';
import { PositionService } from './../../../../../_services/pneumatique/Position/position.service';
import { Position } from './../../../../../_models/position.model';
import { Component, OnInit, ɵNG_ELEMENT_ID } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';
import { elementAt } from 'rxjs/operators';


@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.scss']
})
export class PositionComponent implements OnInit {

  user: any = null;
  modele: any = null;
  model_id: any = null;
  isLoading = false;
  positions: Position[];

  constructor(private positionService: PositionService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private paramService: ParamService,

    private router: Router,
  ) { }


  indice_line: number;
  indice_colone: number;
  selectedRow: number = -1;
  selectedColumn: number = -1;
  indiceRow: number = 0;
  indiceCol: number = 0;

  rowLength: number = 5;
  colLength: number = 5;
  rows: any[] = [];
  columns: any[] = [];
  dataRow: any[] = [];
  dataCol: any[] = [];
  data: any[] = [];
  name: string;

  ngOnInit() {
    this.user = this.authService.getUser();
    this.model_id = +this.route.snapshot.paramMap.get("id");
    this.paramService.findForm(this.model_id).then(
      data => {
        this.modele = data;
      }
    ).catch(
      error => {
        this.translate.get('modele.' + error.error.code)
          .subscribe();
      }
    )
    for (var i = 0; i < this.rowLength; i++) {
      this.rows[i] = i;
    }

    for (var i = 0; i < this.colLength; i++) {
      this.columns[i] = i;

    }
    for (var i = 0; i < this.rowLength; i++) {
      for (var j = 0; j < this.colLength; j++) {
        this.selected[i][j] = false;
      }
    }
  }
  selected(e) {
    if (e.target.checked) {
      console.log('selected')
      e.currentTarget.parentNode.style.backgroundColor = 'pink'
      const parent = e.currentTarget.parentNode;
      parent.children[1].style.backgroundColor = 'pink';
    }
    else {
      console.log('selected selected error')
      e.currentTarget.parentNode.style.backgroundColor = 'white'
      const parent = e.currentTarget.parentNode;
      parent.children[1].style.backgroundColor = 'white';
    }
  }
  onClick(row: number, col: number) {

    console.log("cell in row " + row + ", column " + col);

    /* for (var i = 0; i < this.rowLength; i++) {
      for (var j = 0; j < this.colLength; j++) {
        if (row == i && col == j) { */
    //console.log("cell in row " + i + ", column " + j);

    if ((this.selectedRow != row && this.selectedColumn != col) || (this.selectedRow == row && this.selectedColumn != col) || (this.selectedRow != row && this.selectedColumn == col)) {
      this.selectedRow = row;
      this.selectedColumn = col;
      this.indice_line = row;
      this.indice_colone = col;
      console.log("cell in row " + this.indice_line + ", column " + this.indice_colone);
      this.dataRow.push(this.indice_line);
      this.dataCol.push(this.indice_colone);
      console.log(this.dataRow)
      console.log(this.dataCol)

    } else {
      console.log('error')
      for (var i = 0; i < this.dataRow.length; i++) {
        for (var j = 0; j < this.dataCol.length; j++) {
          console.log('indice de ligne ' + this.dataRow.indexOf(this.dataRow[i]) + ' indice de colonne ' + this.dataCol.indexOf(this.dataCol[j]))

          if ((this.dataRow[i] == row && this.dataCol[j] == col)) {
            if (this.dataRow.indexOf(this.dataRow[i]) >= this.dataCol.indexOf(this.dataCol[j])) {
              this.indiceCol = this.dataRow.indexOf(this.dataRow[i])
              this.indiceRow = this.dataRow.indexOf(this.dataRow[i])
            } else if (this.dataRow.indexOf(this.dataRow[i]) <= this.dataCol.indexOf(this.dataCol[j])) {
              this.indiceCol = this.dataRow.indexOf(this.dataCol[j])
              this.indiceRow = this.dataRow.indexOf(this.dataCol[j])
            }
            console.log("error error")
            console.log(this.dataRow[i])
            console.log(this.dataCol[j])
            console.log('indice de ligne ' + this.dataRow.indexOf(this.dataRow[i]) + ' indice de colonne ' + this.dataCol.indexOf(this.dataCol[j]))
            this.dataRow.splice(this.indiceRow, 1);
            this.dataCol.splice(this.indiceCol, 1);
            this.selectedRow = this.dataRow[i - 1];
            this.selectedColumn = this.dataCol[j - 1];

            console.log(this.dataRow)
            console.log(this.dataCol)
          }
        }
      }
    }
    /*  }
   }
 } */
  }

  colorize() {

  }

  changeRow(input: any) {
    console.log(input.value);
    this.rowLength = input.value;
    input.value = '';
    this.ngOnInit()
  }

  changeCol(input: any) {
    console.log(input.value);
    this.colLength = input.value;
    input.value = '';
    this.ngOnInit()
  }

  onSubmit() {
    this.isLoading = false;
    if (this.dataRow.length == 0) {

      this.translate.get('Position.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }
    this.isLoading = true;


    for (var i = 0; i < this.dataRow.length; i++) {

      console.log("cell in row " + this.dataRow[i] + ", column " + this.dataCol[i]);

      const formData = new FormData();
      formData.append('modele_id', '' + this.model_id);
      formData.append('create_id', '' + this.user.id);

      formData.append('indice_colone', '' + this.dataCol[i]);
      formData.append('indice_ligne', '' + this.dataRow[i]);


      console.log(formData);
      this.positionService.add(formData)
        .then(resp => {
          if (i == this.dataRow.length) {
            this.translate.get('Position.SubmitSuccess')
              .subscribe(val => this.notifService.success(val));
          }
        })
        .catch(err => {
          console.log(err)
          this.translate.get('Position.SubmitErrorPosition')
            .subscribe(val => this.notifService.danger(val));
        })
        .finally(() => this.isLoading = false);
    }
    this.ngOnInit();


  }

}




