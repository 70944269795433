import { Component, OnInit } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2'
import { DocService } from '../../../../../_services/parc-service/doc-service/doc-service';
//import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare const $;
@Component({
  selector: 'app-type-fiche-all',
  templateUrl: './type-fiche-all.component.html',
  styleUrls: ['./type-fiche-all.component.scss']
})
export class TypeFicheAllComponent implements OnInit {

  typeFiches: any;
  loading: boolean = true;

  @BlockUI() blockUI: NgBlockUI;

  //SweetAlert Text
  areYouSure = '';
  warning = ''
  yes = '';
  no = '';
  deleted = '';
  deletedMessage = '';
  cancelled = '';
  cancelledMessage = '';
  canCreate = false;
  canUpdate = false;
  canDelete = false;

  constructor(
    private docService: DocService,
    //private notifService: NotifService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.translate.get(
      ['SweetAlert.AreYouSure', 'SweetAlert.Warning', 'SweetAlert.Yes', 'SweetAlert.No', 'SweetAlert.Deleted',
        'SweetAlert.DeletedMessage', 'SweetAlert.Cancelled', 'SweetAlert.CancelledMessage'],
      { data: ' ce Type' })
      .subscribe(val => {
        this.areYouSure = val['SweetAlert.AreYouSure'];
        this.warning = val['SweetAlert.Warning'];
        this.yes = val['SweetAlert.Yes'];
        this.no = val['SweetAlert.No'];
        this.deleted = val['SweetAlert.Deleted'];
        this.deletedMessage = val['SweetAlert.DeletedMessagePro'];
        this.cancelled = val['SweetAlert.Cancelled'];
        this.cancelledMessage = val['SweetAlert.CancelledMessage'];
      });
  }

  ngOnInit(): void {
    this.getType();
  }

  getType() {
    this.docService.allType().then(resp => {
      this.typeFiches = resp;
      console.log("on a tous les types");
      $(function () {
        $("#example1").DataTable().fnDestroy()
        $("#example1").DataTable({
          "responsive": true, "lengthChange": false, "autoWidth": false,
          "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
        }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
      });
    }).catch(
      error => {
        this.toastr.error('L Operation a echoue');
      }
    ).finally(
      () => {
        this.loading = false;
      }
    )
  }


  getPage(url) {
    this.docService.getPage(url).then(resp => {
      this.typeFiches = resp;
    }).catch(
      error => {
        this.toastr.warning('Page Terminée');
      }
    ).finally(
      () => {
        this.loading = false;
      }
    )
  }

  edittype(id) {
    this.router.navigate(['type_fiche_update/', id])
  }

  detailtype(id) {
    this.router.navigate(['type_fiche_detail/', id])
  }

  deletetype(typeFiche) {
    Swal.fire({
      title: this.areYouSure,
      text: this.warning,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.yes,
      cancelButtonText: this.no
    }).then((result) => {
      if (result.value) {
        this.blockUI.start('Loading...');
        this.docService.deleteType(typeFiche.id).then(
          data => {
            this.blockUI.stop();
            Swal.fire(
              this.deleted,
              this.deletedMessage,
              'success'
            )
            this.getType();
          }
        ).catch(
          error => {
            console.log(error)
            this.blockUI.stop();
            this.toastr.error('L operation a echoue');
          }
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.cancelledMessage,
          'error'
        )
      }
    })
  }

}
