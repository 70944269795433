import { Component, OnInit } from '@angular/core';
import { ContratService } from 'src/app/_services/RH-service/contrat/contrat.service';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contrat-detail',
  templateUrl: './contrat-detail.component.html',
  styleUrls: ['./contrat-detail.component.scss']
})
export class ContratDetailComponent implements OnInit {


  contrat:any;
  url;
  
  constructor(
    private ContratService: ContratService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,

  ) { }

  async ngOnInit() {
    const contratId = +this.route.snapshot.paramMap.get("id");
     console.log(contratId);
    this.ContratService.find(contratId).then(
      data => {
        this.contrat = data;
        console.log(this.contrat)
      }
    ).catch(
      error => {
        this.translate.get('Contrat.'+error.error.code)
        .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/contrat/all'])
      }
    )
  }
}
