import { TypeDocumentMissionService } from '../../../../../_services/movement-service/type-document-mission.service';
import { MissionService } from '../../../../../_services/movement-service/mission-service/mission-service';
import { InterveningElementService } from 'src/app/_services/transport-element/intervening-element/intervening-element.service';
import { AutoService } from 'src/app/_services/parc-service/auto-service/auto-service';
import { ConvoyeurService } from 'src/app/_services/RH-service/convoyeur/convoyeur.service';
import { ChauffeurService } from 'src/app/_services/RH-service/chauffeur/chauffeur.service';
import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-mission-add',
  templateUrl: './mission-add.component.html',
  styleUrls: ['./mission-add.component.scss']
})
export class MissionAddComponent implements OnInit {

  dataForm: FormGroup;
  isSubmitted: boolean = false;
  isLoading = false;
  isError = false;
  isSuccess = false;
  clients;
  chauffeurs;
  vehicules;
  convoyeurs;
  fournisseurs
  types;
  mission_file: string[] = [];


  constructor(
    private missionService: MissionService,
    private typeDocumentMissionService: TypeDocumentMissionService,
    private chauffeurService: ChauffeurService,
    private convoyeurService: ConvoyeurService,
    private autoService: AutoService,
    private anterveningElementService: InterveningElementService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getVehicule();
    this.getClients();
    this.getFournisseur();
    this.getConvoyeur();
    this.getChauffeur();
    this.getTypeDocumentation();

    this.dataForm = this.formBuilder.group({
      lieu_depart: ['', [Validators.required]],
      date_depart: ['', [Validators.required]],
      destination: ['', [Validators.required]],
      index_depart: ['', [Validators.required]],
      description: [''],
      parcours_theorique: ['', [Validators.required]],
      vehicule_id: ['', [Validators.required]],
      convoyeur_id: ['', [Validators.required]],
      chauffeur_id: ['', [Validators.required]],
      element_intervenant_id1: ['', [Validators.required]],
      element_intervenant_id2: ['', [Validators.required]],
    });
  }

  getClients() {
    this.anterveningElementService.getClient().then((res) => {
      this.clients = res;
      console.log(this.clients)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  getFournisseur() {
    this.anterveningElementService.getFournisseur().then((res) => {
      this.fournisseurs = res;
      console.log(this.fournisseurs)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  getConvoyeur() {
    this.convoyeurService.getConvoyeur().then((res) => {
      this.convoyeurs = res;
      console.log(this.convoyeurs)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  getChauffeur() {
    this.chauffeurService.getChauffeur().then((res) => {
      this.chauffeurs = res;
      console.log(this.chauffeurs)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  getVehicule() {
    this.autoService.all().then((res) => {
      this.vehicules = res;
      console.log(this.vehicules)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  getTypeDocumentation() {
    this.typeDocumentMissionService.gets().then((res) => {
      this.types = res;
      console.log(this.types)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  onSelectfile1(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.mission_file.push(event.target.files[i]);
    }
  }

  get form() {
    return this.dataForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;


    if (this.dataForm.invalid) {

      this.translate.get('Mission.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('lieu_depart', '' + this.form.lieu_depart.value);
    formData.append('date_depart', '' + this.form.date_depart.value);
    formData.append('destination', '' + this.form.destination.value);
    formData.append('index_depart', '' + this.form.index_depart.value);
    formData.append('description', '' + this.form.description.value);
    formData.append('parcours_theorique', '' + this.form.parcours_theorique.value);
    formData.append('vehicule_id', '' + this.form.vehicule_id.value);
    formData.append('convoyeur_id', '' + this.form.convoyeur_id.value);
    formData.append('chauffeur_id', '' + this.form.chauffeur_id.value);
    formData.append('element_intervenant_id1', '' + this.form.element_intervenant_id1.value);
    formData.append('element_intervenant_id2', '' + this.form.element_intervenant_id2.value);

    console.log(formData);
    this.missionService.add(formData)
      .then(resp => {
        console.log(resp);
        const formData1 = new FormData();
        formData1.append('type_document_id', '' + this.types.data[0].id);
        formData1.append('mission_id', '' + resp.id);

        for (var i = 0; i < this.mission_file.length; i++) {
          formData1.append("piece_jointes[]", this.mission_file[i]);
        }

        /* if (this.mission_file != null)
          formData1.append('piece_jointe', this.mission_file, this.mission_file.name); */
        this.missionService.document(formData1)

        this.translate.get('Mission.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.dataForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('Mission.SubmitErrorMission')
          .subscribe(val => this.notifService.danger(val));
      })


      .finally(() => this.isLoading = false);
  }
}
