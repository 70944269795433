<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'Role.Roles' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">Home</a></li>
                    <li class="breadcrumb-item active"><a [routerLink]="['/role-all']">Roles</a></li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>
<section class="container">
    <div class="container-fluid">
        <div class="card card-primary">
            <div class="card-header" style="background-color: #17a2b8;">
                <h3 class="card-title">{{ "Role.RoleTitle" | translate }}</h3>
            </div>
            <div class="row">
                <div class="col-md-7">
                    <!-- general form elements -->
                    <!-- <div class="card-header">
                        <i class="header-icon pe-7s-shield icon-gradient bg-love-kiss"> </i> {{ 'Role.Roles' | translate }}
                    </div> -->
                    <!-- /.card-header -->
                    <!-- form start -->
                    <form (submit)="onSubmit()" [formGroup]="roleForm" role="form">
                        <div class="card-body">
                            <div class="col-sm-12">
                                <!-- text input -->
                                <div class="form-group">
                                    <label>{{ "Role.LabelLabel" | translate }} <span style="color: red;">*</span> </label>
                                    <input name="email" id="exampleEmail"
                                        placeholder="{{ 'Role.LabelField' | translate }}" type="text"
                                        formControlName="label" (keyup)="computeName($event)"
                                        class="form-control {{ isSubmitted && form.label.errors ? 'is-invalid' : '' }}">
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <!-- text input -->
                                <div class="form-group">
                                    <label>{{ "Role.NameLabel" | translate }} <span style="color: red;">*</span> </label>
                                    <input placeholder="{{ 'Role.NameField' | translate }}" disabled type="text"
                                     formControlName="name" value="{{ role_name }}"
                                        class="form-control {{ isSubmitted && form.name.errors ? 'is-invalid' : '' }}">
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <!-- text input -->
                                <div class="form-group">
                                    <label>{{ "Role.DescriptionLabel" | translate }}</label>
                                    <textarea name="text" id="exampleText" class="form-control" 
                                        placeholder="{{ 'Role.LabelDescription' | translate }}" formControlName="description">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-body -->


                        <div class="card-footer">
                            <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8">
                                <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                                <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </button>
                        </div>
                    </form>
                </div>
                <!-- /.card -->
                <div class="col-md-4 mt-2">
                    <form class="">
                        <div class="position-relative form-group">
                            <label class="">
                                {{ "Role.SearchLabel" | translate }} ({{ permissions.length }})
                                <div class="widget-content-left mr-2">
                                    <div class="custom-checkbox custom-control">
                                        <input type="checkbox" id="selectAll" class="custom-control-input"
                                            (change)="selectAllPermission($event)">
                                        <label class="custom-control-label" for="selectAll">&nbsp;</label>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <input placeholder="{{ 'Role.SearchText' | translate }}" type="text" (keyup)="search($event)"
                            class="form-control">
                         <ul class="todo-list-wrapper list-group list-group-flush"
                            style="overflow-y: auto; max-height: 200px;">
                            <li class="list-group-item" *ngFor="let permission of permissions">
                                <div class="todo-indicator bg-warning"></div>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-2">
                                            <div class="custom-checkbox custom-control">
                                                <input type="checkbox" id="{{ permission.id }}"
                                                    [checked]="isChecked(permission.id)" class="custom-control-input"
                                                    (change)="onChecked(permission, $event)">
                                                <label class="custom-control-label"
                                                    for="{{ permission.id }}">&nbsp;</label>
                                            </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">{{ permission.display_name }}</div>
                                            <div class="widget-subheading"><i>{{ permission.description }}</i></div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                         </ul>
                    </form>

                </div>
            </div>
        </div>

    </div>
</section>