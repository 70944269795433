import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { ProgrammeReparationService } from 'src/app/_services/maintenance-service/programme_reparation/programme-reparation.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-programme-reparation-update',
  templateUrl: './programme-reparation-update.component.html',
  styleUrls: ['./programme-reparation-update.component.scss']
})
export class ProgrammeReparationUpdateComponent implements OnInit {

  DataForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  user
  vehicules: any;
  mecaniciens: any;
  pannes: any;
  programmeReparations: any;
  limite: 10000;

  constructor(private ProgrammeReparationService: ProgrammeReparationService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.getPanne();
    this.getMecanicien();
    this.getVehicule();
    this.initForm();
    this.user = this.authService.getUser();

    const programme_id = +this.route.snapshot.paramMap.get("id");
    this.ProgrammeReparationService.find(programme_id).then(
      data => {
        this.programmeReparations = data;
        console.log(data);
        
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('programmeReparations.'+error.error.code)
        .subscribe(val => this.notifService.danger(val));
      }
    )
  }

  getPanne() {
    this.ProgrammeReparationService.getPanne().then(res => {
      this.pannes = res;
      console.log(this.pannes)
    }).catch(
      error => {
        this.notifService.danger(error.error.message)
      })
  }

  getMecanicien() {
    this.ProgrammeReparationService.getMecanicien().then(res => {
      this.mecaniciens = res;
      console.log(this.mecaniciens)
    }).catch(
      error => {
        this.notifService.danger(error.error.message)
      })
  }

  getVehicule() {
    this.ProgrammeReparationService.getVehicule().then((res) => {
      this.vehicules = res;
      console.log(this.vehicules)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  initForm(withProgramme = false) {
    if(withProgramme){
      this.DataForm = this.formBuilder.group(
        {
          vehicule_id:[this.programmeReparations.vehicule_id,[Validators.required]],
          panne_id:[this.programmeReparations.panne_id,[Validators.required]],
          mecanicien_id:[this.programmeReparations.mecanicien_id,[Validators.required]],
          description:[this.programmeReparations.description],
          date_debut:[this.programmeReparations.date_debut,[Validators.required]],
          date_fin:[this.programmeReparations.date_fin,[Validators.required]],
        }
      );
      }
    else{
      this.DataForm = this.formBuilder.group({
        description: [''],
        date_debut: ['', [Validators.required]],
        date_fin: ['', [Validators.required]],
        panne_id: ['', [Validators.required]],
        vehicule_id: ['', [Validators.required]],
        mecanicien_id: ['', [Validators.required]],
      });
    }

  }

  get form() {
    return this.DataForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;


    if (this.DataForm.invalid) {

      this.translate.get('ProgrammeReparation.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('panne_id', '' + this.form.panne_id.value);
    formData.append('vehicule_id', '' + this.form.vehicule_id.value);
    formData.append('mecanicien_id', '' + this.form.mecanicien_id.value);
    formData.append('description', '' + this.form.description.value);
    formData.append('date_debut', '' + this.form.date_debut.value);
    if (this.form.date_debut.value > this.form.date_fin.value) {
      console.log('Junie');
      this.translate.get('ProgrammeReparation.DateErreur')
        .subscribe(val => this.notifService.danger(val));
      this.isLoading = false;
      return;
    } else {
      formData.append('date_fin', '' + this.form.date_fin.value);
    }
    formData.append('create_id', '' + this.user.id);

    console.log(formData);
    this.ProgrammeReparationService.update(formData, this.programmeReparations.id)
      .then(resp => {
        this.translate.get('ProgrammeReparation.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.DataForm.reset();
        this.router.navigate(['/programme-reparation/all']);
      })
      .catch(err => {
        console.log(err)
        this.translate.get('ProgrammeReparation.SubmitErrorFicheIntervention')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }


}
