<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'Forms.Breakdown.ListBreakdown' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                  <li class="breadcrumb-item "><a [routerLink]="['/panne/list']">{{ "Forms.Breakdown.Breakdown" |
                      translate }}</a></li>
                  <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>
                </ol>
              </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="tabs-animation" style="min-height: 1416.81px; margin-left: 2%; margin-right: 2%;">
    <div class="main-card mb-3 card">
        <section class="content  bg-primary">
            <div class="card-header" style="background-color: #17a2b8; color: white;">
                <h2 class="card-title" style="margin-left: 1%; margin-bottom: 1%; margin-top: 1%;">
                {{ 'Forms.Breakdown.ListBreakdown' | translate }}</h2>
                <button *ngIf="canCreate" [routerLink]="['/panne/add']" type="button" class="btn btn-primary float-right"
                    style="margin-right: 1%; margin-bottom: 1%; margin-top: 1%;">
                    <i class="fas fa-plus"></i> {{ "Forms.Marques.Ajouter" | translate }}
                </button>
            </div>
        </section>
        <!-- /.card-header -->
        <div class="card-body p-0">
            <div class="card-body p-1">
                <table id="example1" class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                                {{ 'Forms.Breakdown.Vehicules' | translate }}
                            </th>
                            <th>
                                {{ 'Forms.Breakdown.Missions' | translate }}
                            </th>
                            <th>
                                {{ 'Forms.Breakdown.Gerer' | translate }}
                            </th>
                            <th>
                                {{ 'Forms.Breakdown.Nature' | translate }}
                            </th>
                            <th>
                                {{ 'Forms.Breakdown.Lieu' | translate }}
                            <th>
                                {{ 'Forms.Breakdown.Description' | translate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let panne of pannes?.data,let i = index" id="{{panne.id}}">
                            <td>
                                {{i+1}}
                            </td>
                            <td>
                                {{panne.immatriculation}}
                            </td>
                            <td>
                                {{panne.mission_id}}
                            </td>
                            <td>
                                {{panne.gerer}}
                            </td>
                            <td>
                                {{panne.nature}}
                            </td>
                            <td> {{panne.lieu}}</td>
                            <td> {{panne.description}}</td>

                            <!-- <td>
                
                <a class="btn btn-app" style="background-color:lightyellow;"  title="editer" (click)="editVehicle(vehicle)">
                  <i class="fas fa-pencil-alt"  ></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"></font></font>
                </a>
                <a class="btn btn-app"  style="background-color:lightslategray;" title="Détail" (click)="detailsVehicle(vehicle)">
                  <i class="fas fa-folder"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"></font></font>
        
               </a>               
                <a class="btn btn-app" style="background-color:indianred;" title="Supprimer" (click)="deleteVehicle(vehicle.id)">
                  <i class="fas fa-trash" ></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"></font></font>
        
                </a>           
              </td> -->
                            <td class="project-actions text-right">
                                <a class="btn btn-primary btn-sm" href="javascript:void(0)" data-placement="top"
                                    title="details" (click)="detailspannes(panne.id)">
                                    <i class="fas fa-eye"></i>
                                </a>
                                &nbsp;
                                <a *ngIf="canUpdate" class="btn btn-info btn-sm" href="javascript:void(0)"
                                    data-placement="top" title="modifier" (click)="editpannes(panne.id)">
                                    <i class="fas fa-pencil-alt">
                                    </i>
                                </a>
                                &nbsp;
                                <a *ngIf="canDelete" class="btn btn-danger btn-sm" href="javascript:void(0)"
                                    data-placement="top" title="supprimer" (click)="deletepannes(panne.id)">
                                    <i class="fas fa-trash">
                                    </i>
                                </a>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- /.table-responsive -->
        </div>

    </div>
</div>