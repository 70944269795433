import { Component, OnInit } from '@angular/core';
import { DocService } from '../../../../../_services/parc-service/doc-service/doc-service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({   
  selector: 'app-type-fiche-add',
  templateUrl: './type-fiche-add.component.html',
  styleUrls: ['./type-fiche-add.component.scss']
})
export class TypeFicheAddComponent implements OnInit {

  typeFicheForm : FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;

  constructor(
    private docService: DocService,
    //private notifService: NotifService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    //private translate: TranslateService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.typeFicheForm =this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
    })
  }
   
  get form() {
    return this.typeFicheForm.controls;
  }


  onSubmit(){
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.typeFicheForm.invalid) {
      this.toastr.error('ERREUR', 'Les champs en rouge sont obligatoires!');
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('name', '' + this.form.name.value);
    formData.append('description', '' + this.form.description.value);
    this.docService.addType(formData)
      .then(resp => {
        this.toastr.success("Enregistrer avec success");
        this.isSubmitted = false;
        this.typeFicheForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.toastr.error("Echec lors de l'enregistrement");
      })
      .finally(() => this.isLoading = false);
  }

}


