<div class="content-wrapper" style="min-height: 1200.88px;  margin-left: 1%; margin-right: 1%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>{{ "Mecanicien.Mecanicien" | translate }}</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                        <li class="breadcrumb-item "><a [routerLink]="['/mecanicien/all']">{{ "Mecanicien.Mecanicien" |
                            translate
                            }}</a></li>
                        <li class="breadcrumb-item active">{{ "FileAriane.Detail" | translate }}</li>

                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="container">
        <div class="container-fluid">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <div class="position-relative mb-4">
                            <!-- <div class="chartjs-size-monitor">
                                <div class="chartjs-size-monitor-expand">
                                    <div class=""></div>
                                </div>
                                <div class="chartjs-size-monitor-shrink">
                                    <div class=""></div>
                                </div>
                            </div> -->
                            <div class="col-sm-12">
                                <img style="height:400px; margin-left:40%" class="img-fluid"
                                    src="{{mecanicien?.avatar}}" alt="photo">
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <div class="row">

                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-header border-0">
                            <h3 class="card-title">Information personnel</h3>

                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-striped table-valign-middle">
                                <tbody>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                                        <td>{{ "Mecanicien.nameLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{mecanicien?.name}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Mecanicien.emailLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{mecanicien?.email}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Mecanicien.CNI_numberLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{mecanicien?.CNI_number}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Mecanicien.picture_CNILabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);"><a
                                                href="{{mecanicien?.picture_CNI}}">Parcourir</a></td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Mecanicien.sexeLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{mecanicien?.sexe}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Mecanicien.birth_dateLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{mecanicien?.birth_date}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Mecanicien.birth_placeLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{mecanicien?.birth_place}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Mecanicien.phone_number_1Label" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{mecanicien?.phone_number_1}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Mecanicien.phone_number_2Label" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{mecanicien?.phone_number_2}}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-header border-0">
                            <h3 class="card-title">Information personnel</h3>

                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-striped table-valign-middle">
                                <tbody>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Mecanicien.marital_statusrLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{mecanicien?.marital_status}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Mecanicien.childrenLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{mecanicien?.children}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Mecanicien.residence_townLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{mecanicien?.residence_town}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Mecanicien.contact_personLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{mecanicien?.contact_person}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Mecanicien.emergency_phoneLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{mecanicien?.emergency_phone}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Mecanicien.adressLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{mecanicien?.adress}}</td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

                <!-- /.col-md-6 -->
            </div>

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Information Mecanicien</h3>
                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mecanicien.SpecialityLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mecanicien?.speciality}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mecanicien.AnneExpertLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mecanicien?.anne_expert}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mecanicien.LieuTravailLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mecanicien?.lieu_travail}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mecanicien.MecanicienAttestationFileLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);"><a
                                            href="{{mecanicien?.mecanicien_attestation_file}}">Parcourir</a></td>
                                </tr>
                                <!-- <tr>
                                    <td>Avez-vous une formation autre que le permis?</td>

                                    <th>Oui</th>
                                    <th>Non</th>

                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</div>