<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>{{ "Intervention.Intervention" | translate }}</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
          <li class="breadcrumb-item "><a [routerLink]="['/intervention/list']">{{ "Intervention.Intervention" | translate }}</a></li>
        </ol>
      </div>
    </div>
  </div><!-- /.container-fluid -->
</section>

<!-- Main content -->

<section class="container">
  <div class="row">
    <div class="col-md-12">
      <form (submit)="onSubmit()" [formGroup]="InterventionForm">
        <div class="card card-primary">

          <div class="card-header" style="background-color: #17a2b8;">
            <h3 class="card-title">{{ "Intervention.InterventionUpdate" | translate }}</h3>
          </div>

          <div class="form-group">
            <label for="typeIntervention">{{ "Intervention.TypeIntervention" | translate }}
              <span style="color: red;">*</span>
            </label>
            <select formControlName="typeIntervention" name="typeIntervention" id="typeIntervention"
              class="form-control {{ isSubmitted && form.typeIntervention.errors ? 'is-invalid' : '' }}">
              <option value="" disabled>{{ "Intervention.TypeIntervention" | translate }}</option>
              <option *ngFor="let ti of type_interventions?.data" value="{{ti.id}}">{{ti.nom}}</option>
            </select>
          </div>
           <div class="card-body" style="display: block;">
            <div class="form-group">
              <label for="name">{{ "Intervention.NameLabel" | translate }}
                <span style="color: red;">*</span>
              </label>
              <input type="text" id="name" class="form-control {{ isSubmitted && form.name.errors ? 'is-invalid' : '' }}"
                formControlName="name" placeholder="{{ 'Intervention.LabelNameIntervention' | translate }}">
            </div>
            <div class="form-group">
              <label>{{ "Intervention.DescriptionLabel" | translate }}</label>
              <textarea class="form-control" rows="3" placeholder="{{ 'Intervention.LabelDescriptionIntervention' | translate }}"
              formControlName="description"></textarea>
            </div>

          </div>
          <div class="card-footer">
            <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
              <p *ngIf="!isLoading">{{ "General.update" | translate }}</p>
              <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
          <!-- /.card-body -->
        </div>
      </form>
      <!-- /.card -->
    </div>
  </div>
</section>
<!-- /.content -->
