<div class="login-page">
    <div class="login-box">
        <div class="login-logo">
            <a href="#"><b>E</b>-Transparc</a>
        </div>
        <!-- /.login-logo -->
        <div class="card">
            <div class="card-body login-card-body">
                <p class="login-box-msg">Sign in to start your session</p>

                <form (submit)="onSubmit()" [formGroup]="loginForm">
                    <div class="input-group mb-3">
                        <input type="email" class="form-control" placeholder="Email" formControlName="email">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="password" class="form-control" placeholder="Password" formControlName="password">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <div class="icheck-primary">
                                <input type="checkbox" id="remember" (change)="onCheckboxClick($event)">
                                <label for="remember">
                                    Remember Me
                                </label>
                            </div>
                        </div>
                        <div class="col-4">
                            <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                                <p *ngIf="!isLoading">{{ "Login.SubmitButton" | translate }}</p>
                                <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>

                <!-- /.social-auth-links -->

                <p class="mb-1">
                    <a href="forgot-password.html">I forgot my password</a>
                </p>
                <p class="mb-0">
                    <a href="register.html" class="text-center">Register a new membership</a>
                </p>
            </div>
            <!-- /.login-card-body -->
        </div>
    </div>
</div>