import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { InterveningTypeService } from "src/app/_services/transport-element/intervening-type/intervening-type.service";

@Component({
  selector: 'app-intervening-type-add',
  templateUrl: './intervening-type-add.component.html',
  styleUrls: ['./intervening-type-add.component.scss']
})
export class InterveningTypeAddComponent implements OnInit {

  dataForm : FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  myfile: File = null;
  isMaterial = false;

  constructor(
    private translate: TranslateService,
    private dataService: InterveningTypeService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private ref: MatDialog
  ) { }

  ngOnInit(): void {
    this.initForm();

    this.isMaterial = '/intervening-type/add' != location.pathname;
  }

  initForm(){
    this.dataForm = this.formBuilder.group({
      intervening_type_name : ['', Validators.required],
      description : ['']
    })
  }

  get form(){
    return this.dataForm.controls
  }

  onSubmit(){
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.dataForm.invalid) {
      this.toastrService.error('ERREUR', 'Les champs en rouge sont obligatoires!');
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    const data = this.form;
    for (const k in data) {
      if (k) {
        formData.append(k + '', data[k].value);
      }
    }

    this.dataService.post(formData).then(resp => {
      this.toastrService.success('l\'element creer avec success');
      this.isSubmitted = false;
      this.dataForm.reset();
    })
    .catch(
      err => {
        console.log(err);
        this.toastrService.error('l\'operation a echouer');
      }
    )
    .finally(() => this.isLoading = false);
  }

  close(){
    this.ref.closeAll();
  }

}
