<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'Position.Position' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">{{ "FileAriane.Home" | translate }}</a></li>
                    <li class="breadcrumb-item active"><a [routerLink]="['/position/all']">{{ 'Position.Position' | translate }}</a>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</section>

<div class="container">
    <div class="container-fluid">
        <div class="row">

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header" style="background-color: #17a2b8;">
                        <h3 class="card-title">Informations Detaillées sur la position</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Position.ModelLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{positions?.model}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Position.ColoneLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{positions?.indice_colone}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Position.MonterLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{positions?.indice_ligne}}</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>

    </div>
    <!-- /.container-fluid -->
</div>
