import { HttpClient } from '@angular/common/http';
import * as Routes from 'src/Routes';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgrammeReparationService {

  constructor(private http: HttpClient) { }

  add(formData: FormData): Promise<any> {
    return this.http.post<any>(Routes.PROGRAMME_REPARATION, formData).toPromise();
  }

  update(formData: FormData, id): Promise<any> {
    return this.http.post<any>(`${Routes.PROGRAMME_REPARATION}/${id}`, formData).toPromise();
  }

  delete(id: number) {
    return this.http.delete(`${Routes.PROGRAMME_REPARATION}/${id}`).toPromise();

  }

  find(id: number): Promise<any> {
    return this.http.get<any>(`${Routes.PROGRAMME_REPARATION}/${id}`).toPromise();
  }
  public gets(page) {
    return this.http.get<any>(`${Routes.PROGRAMME_REPARATION}?page=${page}`)
      .toPromise();
  }

  public get(url) {
    return this.http.get<any>(`${url}`)
      .toPromise();
  }

  public getPanne() {
    return this.http.get<any>(`${Routes.PANNE}`)
      .toPromise();
  }

  public getMecanicien() {
    return this.http.get<any>(`${Routes.MECANICIEN}`)
      .toPromise();
  }

  public getVehicule() {
    return this.http.get<any>(`${Routes.VEHICULEALL}`)
      .toPromise();
  }


}

