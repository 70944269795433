import { Component, OnInit } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2'
import { ProgrammesEntretientService } from 'src/app/_services/maintenance/entretients/programmes-entretient.service';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
declare const $;

@Component({
  selector: 'app-programmes-entretient-all',
  templateUrl: './programmes-entretient-all.component.html',
  styleUrls: ['./programmes-entretient-all.component.scss']
})
export class ProgrammesEntretientAllComponent implements OnInit {

  programmes_entretient: any;
  loading: boolean = true;
  @BlockUI() blockUI: NgBlockUI;

  //SweetAlert Text
  areYouSure = '';
  warning = ''
  yes = '';
  no = '';
  deleted = '';
  deletedMessage = '';
  cancelled = '';
  cancelledMessage = '';
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  page = 1;
  data: any;
  driverSelectId: any;
  idDriver;

  constructor(
    private programmesEntretientService: ProgrammesEntretientService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService
  ) {
    this.translate.get(
      ['SweetAlert.AreYouSure', 'SweetAlert.Warning', 'SweetAlert.Yes', 'SweetAlert.No', 'SweetAlert.Deleted',
        'SweetAlert.DeletedMessage', 'SweetAlert.Cancelled', 'SweetAlert.CancelledMessage'],
      { data: "ce type de " })
      .subscribe(val => {
        this.areYouSure = val['SweetAlert.AreYouSure'];
        this.warning = val['SweetAlert.Warning'];
        this.yes = val['SweetAlert.Yes'];
        this.no = val['SweetAlert.No'];
        this.deleted = val['SweetAlert.Deleted'];
        this.deletedMessage = val['SweetAlert.DeletedMessage'];
        this.cancelled = val['SweetAlert.Cancelled'];
        this.cancelledMessage = val['SweetAlert.CancelledMessage'];
      });
  }

  ngOnInit() {
    this.getPage(this.page);
    const permissionSuffix = 'programme_entretiens';
    this.canCreate = this.authService.hasPermission(`${permissionSuffix}-create`);
		this.canUpdate = this.authService.hasPermission(`${permissionSuffix}-update`);
		this.canDelete = this.authService.hasPermission(`${permissionSuffix}-delete`);
  }

  getPage(page) {
    this.loading = true;
    this.programmesEntretientService.gets(page).then(
      response => {
        this.programmes_entretient = response;
        console.log(this.programmes_entretient);
        $(function () {
          //$("#example1").DataTable().fnDestroy()
          $("#example1").DataTable({
            "responsive": true, "lengthChange": false, "autoWidth": false,
            "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
          }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        });
      }
    ).catch(
      error => {
        this.notifService.danger(error.error.message)
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  edit(id) {
    this.router.navigate(['/programme-entretient/update/' + id]);
  }
  details(id) {
    this.router.navigate(['/programme-entretient/details/' + id]);
  }
  delete(id) {
    Swal.fire({
      title: this.areYouSure,
      text: this.warning,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.yes,
      cancelButtonText: this.no
    }).then((result) => {
      if (result.value) {
        this.blockUI.start('Loading...');
        this.programmesEntretientService.delete(id).then(
          data => {
            this.blockUI.stop();
            Swal.fire(
              this.deleted,
              this.deletedMessage,
              'success'
            );
            this.getPage(1);

          }
        ).catch(
          error => {
            console.log(error);
            this.blockUI.stop();
            this.translate.get('ProgrammesEntretient.' + error.error.code)
              .subscribe(val => this.notifService.danger(val));
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.cancelledMessage,
          'error'
        );
      }
    });
  }

}
