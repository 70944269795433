import { Component, OnInit } from '@angular/core';
import { SanctionService } from 'src/app/_services/RH-service/sanction/sanction.service';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sancton-detail',
  templateUrl: './sancton-detail.component.html',
  styleUrls: ['./sancton-detail.component.scss']
})
export class SanctonDetailComponent implements OnInit {

  sanction:any;
  url;
  
  constructor(
    private SanctionService: SanctionService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,

  ) { }

  async ngOnInit() {
    const sanctionId = +this.route.snapshot.paramMap.get("id");
     console.log(sanctionId);
    this.SanctionService.find(sanctionId).then(
      data => {
        this.sanction = data;
        console.log(this.sanction)
      }
    ).catch(
      error => {
        this.translate.get('Sanction.'+error.error.code)
        .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/sanction/all'])
      }
    )
  }
} 
