<block-ui>
    <ng-container *ngIf="isAuthenticated">

        <div class="wrapper">

            <!-- Navbar -->
            <app-nav-bar></app-nav-bar>
            <!-- /.navbar -->

            <!-- Main Sidebar Container -->
            <app-side-bar></app-side-bar>
            <!-- /.Main Sidebar Container -->
            <!--  <app-asside></app-asside> -->

            <!-- content-wrapper -->
            <div class="content-wrapper">
                <router-outlet></router-outlet>
            </div>
            <!-- /.content-wrapper -->

            <!-- Control Sidebar -->
            <!-- /.control-sidebar -->

            <footer class="main-footer">
                <div class="float-right d-none d-sm-block">
                    <b>Version</b> 1.0
                </div>
                <strong>Tous droits réservés .</strong> V_CAM
            </footer>
        </div>
    </ng-container>
    <ng-container *ngIf="!isAuthenticated">
        <router-outlet></router-outlet>
    </ng-container>
</block-ui>