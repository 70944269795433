<div class="content-wrapper" style="min-height: 1244.06px; margin-left: auto;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>{{ "Document.Title" | translate }}</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a [routerLink]="['']">Home</a></li>
                        <li class="breadcrumb-item active"><a [routerLink]="['/document-production/list']">{{
                                "Document.Title" | translate }}</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-info">
                    <div class="card-header" style="background-color:#17a2b8; color:white;" >
                        <h3 class="card-title">
                            {{ "Document.AddDescription" | translate }}
                        </h3>
                        <!-- tools box -->
                       
                        <!-- /. tools -->
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body pad">
                        <form (submit)="onSubmit()" [formGroup]="dataForm" role="form">
                            <div class="mb-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">{{ "Document.Name" | translate }} <span style="color: red;">*</span> </label>
                                    <input class="form-control" id="exampleInputEmail1" placeholder="Enter name"
                                        formControlName="file_name">
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="exampleInputEmail1">{{ "Document.Terms" | translate}}</label>
                                <!-- <textarea class="textarea" placeholder="Place some text here"  style="width: 100%; height: 200px; font-size: 14px; line-height: 18px; border: 1px solid #dddddd; padding: 10px;">
                                </textarea> -->
                                <ckeditor [editor]="Edito" formControlName="terms"></ckeditor>

                            </div>
                            <div class="card-footer">
                                <button [disabled]="isLoading" class="btn btn-primary" style="background-color:#17a2b8;">
                                    <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                                    <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                                      <span class="sr-only">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- /.col-->
        </div>
        <!-- ./row -->
    </section>
    <!-- /.content -->
</div>