<aside class="control-sidebar control-sidebar-dark" style="top: 57px; height: 892px; display: block;">
    <!-- Control sidebar content goes here -->
    <div class="p-3 control-sidebar-content os-host os-theme-light os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-overflow os-host-overflow-y os-host-transition"
        style="height: 892px;">
        <div class="os-resize-observer-host observed">
            <div class="os-resize-observer" style="left: 0px; right: auto;"></div>
        </div>
        <div class="os-size-auto-observer observed" style="height: calc(100% + 1px); float: left;">
            <div class="os-resize-observer"></div>
        </div>
        <div class="os-content-glue" style="margin: -16px; width: 249px; height: 891px;"></div>
        <div class="os-padding">
            <div class="os-viewport os-viewport-native-scrollbars-invisible" style="overflow-y: scroll;">
                <div class="os-content" style="padding: 16px; height: 100%; width: 100%;">
                    <h5>Customize AdminLTE</h5>
                    <hr class="mb-2">
                    <div class="mb-1"><input type="checkbox" value="1" class="mr-1"><span>No Navbar border</span></div>
                    <div class="mb-1"><input type="checkbox" value="1" class="mr-1"><span>Body small text</span></div>
                    <div class="mb-1"><input type="checkbox" value="1" class="mr-1"><span>Navbar small text</span></div>
                    <div class="mb-1"><input type="checkbox" value="1" class="mr-1"><span>Sidebar nav small text</span>
                    </div>
                    <div class="mb-1"><input type="checkbox" value="1" class="mr-1"><span>Footer small text</span></div>
                    <div class="mb-1"><input type="checkbox" value="1" class="mr-1"><span>Sidebar nav flat style</span>
                    </div>
                    <div class="mb-1"><input type="checkbox" value="1" class="mr-1"><span>Sidebar nav legacy
                            style</span></div>
                    <div class="mb-1"><input type="checkbox" value="1" class="mr-1"><span>Sidebar nav compact</span>
                    </div>
                    <div class="mb-1"><input type="checkbox" value="1" class="mr-1"><span>Sidebar nav child
                            indent</span></div>
                    <div class="mb-1"><input type="checkbox" value="1" class="mr-1"><span>Main Sidebar disable
                            hover/focus auto expand</span></div>
                    <div class="mb-4"><input type="checkbox" value="1" class="mr-1"><span>Brand small text</span></div>
                    <h6>Navbar Variants</h6>
                    <div class="d-flex">
                        <div class="d-flex flex-wrap mb-3">
                            <div class="bg-primary elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-secondary elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-info elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-success elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-danger elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-indigo elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-purple elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-pink elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-navy elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-lightblue elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-teal elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-cyan elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-dark elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-gray-dark elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-gray elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-light elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-warning elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-white elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                            <div class="bg-orange elevation-2"
                                style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                            </div>
                        </div>
                    </div>
                    <h6>Accent Color Variants</h6>
                    <div class="d-flex"></div>
                    <div class="d-flex flex-wrap mb-3">
                        <div class="bg-primary elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-warning elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-info elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-danger elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-success elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-indigo elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-lightblue elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-navy elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-purple elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-fuchsia elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-pink elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-maroon elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-orange elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-lime elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-teal elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-olive elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                    </div>
                    <h6>Dark Sidebar Variants</h6>
                    <div class="d-flex"></div>
                    <div class="d-flex flex-wrap mb-3">
                        <div class="bg-primary elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-warning elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-info elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-danger elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-success elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-indigo elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-lightblue elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-navy elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-purple elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-fuchsia elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-pink elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-maroon elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-orange elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-lime elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-teal elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-olive elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                    </div>
                    <h6>Light Sidebar Variants</h6>
                    <div class="d-flex"></div>
                    <div class="d-flex flex-wrap mb-3">
                        <div class="bg-primary elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-warning elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-info elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-danger elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-success elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-indigo elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-lightblue elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-navy elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-purple elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-fuchsia elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-pink elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-maroon elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-orange elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-lime elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-teal elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-olive elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                    </div>
                    <h6>Brand Logo Variants</h6>
                    <div class="d-flex"></div>
                    <div class="d-flex flex-wrap mb-3">
                        <div class="bg-primary elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-secondary elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-info elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-success elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-danger elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-indigo elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-purple elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-pink elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-navy elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-lightblue elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-teal elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-cyan elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-dark elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-gray-dark elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-gray elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-light elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-warning elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-white elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div>
                        <div class="bg-orange elevation-2"
                            style="width: 40px; height: 20px; border-radius: 25px; margin-right: 10px; margin-bottom: 10px; opacity: 0.8; cursor: pointer;">
                        </div><a href="javascript:void(0)">clear</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden">
            <div class="os-scrollbar-track">
                <div class="os-scrollbar-handle" style="transform: translate(0px, 0px); width: 100%;"></div>
            </div>
        </div>
        <div class="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden">
            <div class="os-scrollbar-track">
                <div class="os-scrollbar-handle" style="transform: translate(0px, 0px); height: 69.906%;"></div>
            </div>
        </div>
        <div class="os-scrollbar-corner"></div>
    </div>
</aside>