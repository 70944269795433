import { HttpClient } from '@angular/common/http';
import * as Routes from 'src/Routes';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  constructor(private http: HttpClient) { }

  add(formData: FormData): Promise<any> {
    return this.http.post<any>(Routes.OPERATION, formData).toPromise();
  }

  public async update(formData: FormData, id: number): Promise<any> {
    return await this.http.post<any>(`${Routes.OPERATION}/${id}`, formData).toPromise();
  }

  delete(id: number) {
    return this.http.delete(`${Routes.OPERATION}/${id}`).toPromise();

  }

  find(id: number): Promise<any> {
    return this.http.get<any>(`${Routes.OPERATION}/${id}`).toPromise();
  }
  public gets(page) {
    return this.http.get<any>(`${Routes.OPERATION}?page=${page}`)
      .toPromise();
  }

  public inventaires(){
    return this.http.get<any>(`${Routes.INVENTAIRE}`).toPromise();
  }
  
  public get(url) {
    return this.http.get<any>(`${url}`)
      .toPromise();
  }

  public getPneu() {
    return this.http.get<any>(`${Routes.PNEU}`)
      .toPromise();
  }

  public getPosition() {
    return this.http.get<any>(`${Routes.POSITION}`)
      .toPromise();
  }

  public getVehicule() {
    return this.http.get<any>(`${Routes.VEHICULEALL}`)
      .toPromise();
  } 
}
