<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'GammeEntretien.GammeEntretien' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">{{ "FileAriane.Home" | translate }}</a></li>
                    <li class="breadcrumb-item active"><a [routerLink]="['/gamme-entretien/all']">{{ 'GammeEntretien.GammeEntretien' | translate }}</a></li>
                    <li class="breadcrumb-item">Détail</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="container">
    <div class="container-fluid">
        <div class="row">

            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Informations Detaillées sur une Gamme d'entretient</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "GammeEntretien.TypeEntretientLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{gamme_entretient.nom_type_entretien }}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "GammeEntretien.InterventionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{gamme_entretient.nom }}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "GammeEntretien.MecanicienLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{gamme_entretient.nom_mecanicien }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Informations Detaillées sur une Gamme d'entretient</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "GammeEntretien.NameLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{gamme_entretient?.nom}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "GammeEntretien.FrequenceLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{gamme_entretient?.frequence}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "GammeEntretien.PriceLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{gamme_entretient?.prix}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <!-- /.col-md-6 -->
        </div>

    </div>
    <!-- /.container-fluid -->
</div>

