<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>{{ "Transfer.Transfer" | translate }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
              <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>
  
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
  
    <!-- Main content -->
  
    <section class="container">
      <div class="row">
        <div class="col-md-12">
          <form (submit)="onSubmit()" [formGroup]="DataForm">
            <div class="card card-primary">    
              <div class="card-header" style="background-color: #17a2b8;">
                <h3 class="card-title">{{ "Transfer.TransferAdd" | translate }}</h3>              
              </div>
  
              <div class="card-body" style="display: block;">
                <div class="form-group">
                  <label>{{ "Transfer.MissionLabel" | translate }} <span
                    style="color: red;">*</span></label>
                  <select formControlName="mission_id" class="form-control {{ isSubmitted && form.mission_id.errors? 'is-invalid' : '' }}"
                    style="width: 100%;" tabindex="-1" aria-hidden="true">
                    <option value="">{{ "Transfer.LabelMission" | translate }}</option>
                    <option *ngFor="let item of missions" value="{{item.id}}">{{item?.element_intervenant_id2.name}}&nbsp;{{item.lieu_depart}}&nbsp;{{item.destination}}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>{{ "Transfer.VehiculeLabel" | translate }} <span
                    style="color: red;">*</span></label>
                  <select formControlName="vehicule_id" class="form-control {{ isSubmitted && form.vehicule_id.errors? 'is-invalid' : '' }}"
                    style="width: 100%;" tabindex="-1" aria-hidden="true">
                    <option value="">{{ "Transfer.LabelVehicule" | translate }}</option>
                    <option *ngFor="let item of vehicules?.data" value="{{item.id}}">{{item.vehicle_registration}}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>{{ "Transfer.ChauffeurLabel" | translate }} <span
                    style="color: red;">*</span></label>
                  <select formControlName="chauffeur_id" class="form-control {{ isSubmitted && form.chauffeur_id.errors? 'is-invalid' : '' }}"
                    style="width: 100%;" tabindex="-1" aria-hidden="true">
                    <option value="">{{ "Transfer.LabelChauffeur" | translate }}</option>
                    <option *ngFor="let item of chauffeurs?.data" value="{{item.id}}">{{item.driver_name}}</option>
                  </select>
                </div>
                <div class="form-group">
                    <label>{{ "Transfer.ConvoyeurLabel" | translate }} <span
                      style="color: red;">*</span></label>
                    <select formControlName="convoyeur_id" class="form-control {{ isSubmitted && form.convoyeur_id.errors? 'is-invalid' : '' }}"
                      style="width: 100%;" tabindex="-1" aria-hidden="true">
                      <option value="">{{ "Transfer.LabelConvoyeur" | translate }}</option>
                      <option *ngFor="let item of convoyeurs?.data" value="{{item.id}}">
                        {{item.convoyeur_name}}
                      </option>
                    </select>
                </div>
                <div class="form-group">
                  <label for="inputName">{{ "Transfer.MotifLabel" | translate }} <span
                    style="color: red;">*</span></label>
                  <input type="text" id="inputName" class="form-control {{ isSubmitted && form.motif.errors? 'is-invalid' : '' }}"
                    formControlName="motif" placeholder="{{ 'Transfer.LabelMotif' | translate }}">
                </div>
                <div class="form-group">
                  <label for="inputName">{{ "Transfer.CauseLabel" | translate }} <span
                    style="color: red;">*</span></label>
                  <input type="text" id="inputName" class="form-control {{ isSubmitted && form.cause.errors? 'is-invalid' : '' }}"
                    formControlName="cause" placeholder="{{ 'Transfer.LabelCause' | translate }}">
                </div>
              </div>
              <div class="card-footer">
                <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                  <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                  <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
              <!-- /.card-body -->
            </div>
          </form>
          <!-- /.card -->
        </div>
      </div>
    </section>
    <!-- /.content -->
</div>