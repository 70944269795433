import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Routes from '../../../Routes';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
  ) { }

  add(formData: FormData): Promise<any> {
    return this.http.post<any>(Routes.USERS, formData).toPromise();
  }

  update(formData: FormData, id: number): Promise<any> {
    return this.http.post<any>(`${Routes.USERS}/${id}`, formData).toPromise();
  }

  allUsers(): Promise<any> {
    return this.http.get<any>(Routes.USERS).toPromise();
  }

  find(id: number): Promise<any> {
    return this.http.get<any>(`${Routes.USERS}/${id}`).toPromise();
  } s

  userInfo(user_id_1: number): Promise<any> {
    return this.http.get<any>(`${Routes.USERS}/information/${user_id_1}`).toPromise();
  }


  all(page): Promise<any> {
    return this.http.get<any>(`${Routes.USERS}?page=${page}`).toPromise();
  }


  delete(id: number): Promise<any> {
    return this.http.delete<any>(`${Routes.USERS}/${id}`).toPromise();
  }

  public get(url) {
    return this.http.get<any>(`${url}`)
      .toPromise();
  }

}
