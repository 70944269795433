import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Routes from '../../../Routes';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(
    private http: HttpClient,
  ) { }

  add(formData: FormData): Promise<any> {
    return this.http.post<any>(Routes.ROLE, formData).toPromise();
  }

  update(formData: FormData, id: number): Promise<any> {
    return this.http.post<any>(`${Routes.ROLE}/${id}`, formData).toPromise();
  }

  all(page) {
    return this.http.get<any>(`${Routes.ROLE}?page=${page}`).toPromise();
  }

  public get(url) {
    return this.http.get<any>(`${url}`)
      .toPromise();
  }

  getRolesWithPermissions(): Promise<any> {
    return this.http.get<any>(Routes.ROLE + '/' + 'getRolesWithPermissions').toPromise();
  }

  permissions(): Promise<any> {
    return this.http.get<any>(Routes.PERMISSION).toPromise();
  }
  roles(limit) {
    return this.http.get<any>(`${Routes.ROLE}?limit=${limit}`).toPromise();
  }

  find(id: number): Promise<any> {
    return this.http.get<any>(`${Routes.ROLE}/${id}`).toPromise();
  }

  delete(id: number): Promise<any> {
    return this.http.delete<any>(`${Routes.ROLE}/${id}`).toPromise();
  }

  syncUserAbilities(id: number, data: { roles: string, permissions: string }) {
    return this.http.post(Routes.SYNC_USER_ABILITIES.replace('{user_id}', `${id}`), data).toPromise();
  }
}
