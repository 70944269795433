import { Component, OnInit } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2'
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FormationService } from 'src/app/_services/RH-service/formation/formation.service';
import { AuthService } from 'src/app/_services/auth/auth.service';
declare const $;
@Component({
  selector: 'app-formation-all',
  templateUrl: './formation-all.component.html',
  styleUrls: ['./formation-all.component.scss']
})
export class FormationAllComponent implements OnInit {

  formations: any;
  loading: boolean = true;
  @BlockUI() blockUI: NgBlockUI;

  //SweetAlert Text
  areYouSure = '';
  warning = ''
  yes = '';
  no = '';
  deleted = '';
  deletedMessage = '';
  cancelled = '';
  cancelledMessage = '';
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  page = 1;
  data: any;

  constructor(
    private formationService: FormationService,
    private authService: AuthService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router) {

    this.translate.get(
      ['SweetAlert.AreYouSure', 'SweetAlert.Warning', 'SweetAlert.Yes', 'SweetAlert.No', 'SweetAlert.Deleted',
        'SweetAlert.DeletedMessage', 'SweetAlert.Cancelled', 'SweetAlert.CancelledMessage'],
      { data: "cette formation" })
      .subscribe(val => {
        this.areYouSure = val['SweetAlert.AreYouSure'];
        this.warning = val['SweetAlert.Warning'];
        this.yes = val['SweetAlert.Yes'];
        this.no = val['SweetAlert.No'];
        this.deleted = val['SweetAlert.Deleted'];
        this.deletedMessage = val['SweetAlert.DeletedMessage'];
        this.cancelled = val['SweetAlert.Cancelled'];
        this.cancelledMessage = val['SweetAlert.CancelledMessage'];
      });
  }
  ngOnInit() {
    console.log('theo')
    this.getFormation(this.page);
    const permissionSuffix = 'formations';
    this.canCreate = this.authService.hasPermission(`${permissionSuffix}-create`);
    this.canUpdate = this.authService.hasPermission(`${permissionSuffix}-update`);
    this.canDelete = this.authService.hasPermission(`${permissionSuffix}-delete`);
  }

  getFormation(page) {
    this.loading = true;
    this.formationService.gets(page).then(
      response => {
        this.formations = response;
        console.log(this.formations);
        $(function () {
          //$("#example1").DataTable().fnDestroy()
          $("#example1").DataTable({
            "responsive": true, "lengthChange": false, "autoWidth": false,
            "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
          }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        });
      }
    ).catch(
      error => {
        this.notifService.danger(error.error.message)
      }
    ).finally(
      () => {
        this.loading = false;
      }
    )
  }


  getPage(url) {
    if (url) {
      this.formationService.get(url).then((res) => {
        this.formations = res;
        console.log(this.formations)
      }).catch(
        error => {
          this.notifService.danger(error.error.message)
        }
      ).finally(
        () => {
          this.loading = false;
        }
      )
    }
  }

  editformation(id) {
    console.log('bonjour');
    this.router.navigate(['/formation/update/' + id])
  }
  detailleformation(id) {
    console.log('bonjourhaut');
    this.router.navigate(['/formation/detail/' + id])
  }

  deleteformation(id) {
    Swal.fire({
      title: this.areYouSure,
      text: this.warning,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.yes,
      cancelButtonText: this.no
    }).then((result) => {
      if (result.value) {
        this.blockUI.start('Loading...');
        this.formationService.delete(id).then(
          data => {
            this.blockUI.stop();
            Swal.fire(
              this.deleted,
              this.deletedMessage,
              'success'
            )
            this.getFormation(1);

          }
        ).catch(
          error => {
            console.log(error)
            this.blockUI.stop();
            this.translate.get('Formation.' + error.error.code)
              .subscribe(val => this.notifService.danger(val));
          }
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.cancelledMessage,
          'error'
        )
      }
    })
  }
}
