<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">{{ 'Vehicle.VehicleTechnicalSheet' | translate }}</font>
                    </font>
                </h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a (click)="afficheDetail()">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;color: steelblue;"
                                    title="{{ 'Vehicle.GoBackToDetail' | translate }}">
                                    {{ 'Vehicle.Detail' | translate }}</font>
                            </font>
                        </a></li>
                    <li class="breadcrumb-item active">
                        <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{ 'Vehicle.TechnicalSheet' | translate }}</font>
                        </font>
                    </li>
                </ol>
            </div>
        </div>
    </div><!-- /.container-fluid -->
</section>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="callout callout-info">
                    <h5><i class="fas fa-info"></i>
                        <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;"> {{ 'Vehicle.note' | translate }}</font>
                        </font>
                    </h5>
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">
                            {{ 'Vehicle.text1' | translate }} </font>
                    </font>
                    <font style="vertical-align: inherit;"> </font>
                </div>


                <!-- Main content -->
                <div class="invoice p-3 mb-3">
                    <!-- title row -->

                    <!-- info row -->

                    <section class="content">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="card" class="col-md-6">
                                    <div class="form_section" class="form1">

                                        <form class="col-md-5 contant_form" #f1="ngForm" [formGroup]="firstFormGroup"
                                            class="Mode">
                                            <fieldset class="card">
                                                <div class="row">
                                                    <table class="Mode">
                                                        <tr>
                                                            <td><label for="immatri"
                                                                    class="Mode">{{ 'Vehicle.VehicleRegistration' | translate }}
                                                                </label></td>
                                                            <td>
                                                                <input class="field_custom" id="immatri" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    formControlName="immatriculation" class="Mode"
                                                                    placeholder="valeur" type="text" readonly />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td><label
                                                                    class="Mode">{{ 'Vehicle.vehicleBrand' | translate }}</label>
                                                            </td>
                                                            <td>
                                                                <input class="field_custom" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    formControlName="marque" class="Mode"
                                                                    placeholder="valeur" type="text" readonly />

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label
                                                                    for="marque">{{ 'Vehicle.vehiclemodel' | translate }}
                                                                </label></td>
                                                            <td class="Mode">
                                                                <input class="field_custom" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    formControlName="modele" class="Mode"
                                                                    placeholder="valeur" type="text" readonly />

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label>{{ 'Vehicle.vehicleType' | translate }} </label>
                                                            </td>
                                                            <td class="Mode">
                                                                <input class="field_custom" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    formControlName="type" class="Mode"
                                                                    placeholder="valeur" type="text" readonly />

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label>{{ 'Vehicle.vehicle_body' | translate }} </label>
                                                            </td>
                                                            <td class="Mode">
                                                                <input class="field_custom" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    formControlName="carosserie" class="Mode"
                                                                    placeholder="valeur" type="text" readonly />

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label for="Numéro_de_chassis"
                                                                    class="Mode">{{ 'Vehicle.number_frame' | translate }}</label>
                                                            </td>
                                                            <td>
                                                                <input class="field_custom" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    formControlName="numero_chassis" readonly
                                                                    class="Mode" id="Numéro_de_chassis"
                                                                    placeholder="valeur" type="text" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label
                                                                    for="Propriétaire">{{ 'Vehicle.owner' | translate }}
                                                                </label></td>
                                                            <td>
                                                                <input class="field_custom" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    formControlName="proprietaire" readonly class="Mode"
                                                                    id="Propriétaire" placeholder="valeur"
                                                                    type="text" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label for="Valeur_achat"
                                                                    class="Mode">{{ 'Vehicle.purchase_value' | translate }}
                                                                </label></td>
                                                            <td>
                                                                <input class="field_custom" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    formControlName="valeur_achat" readonly class="Mode"
                                                                    id="Valeur_achat" placeholder="Valeur"
                                                                    type="number" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label
                                                                    for="Date_mise_en_circulation">{{ 'Vehicle.date_circulation' | translate }}</label>
                                                            </td>
                                                            <td>
                                                                <input class="field_custom" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    readonly class="Mode"
                                                                    formControlName="date_mise_en_circulation"
                                                                    id="Date_mise_en_circulation"
                                                                    placeholder="dd/mm/yyyy" type="date" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label for="Couleur">{{ 'Vehicle.color' | translate }}
                                                                </label></td>
                                                            <td>
                                                                <input class="field_custom" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    formControlName="couleur" readonly class="Mode"
                                                                    id="Couleur" placeholder="valeur" type="text" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label
                                                                    for="Etat_véhicule ">{{ 'Vehicle.vehicle_condition' | translate }}
                                                                </label></td>
                                                            <td class="Mode">
                                                                <input class="field_custom" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    formControlName="etat_véhicule" class="Mode"
                                                                    placeholder="valeur" type="text" readonly />

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label
                                                                    for="Nombre_de_place">{{ 'Vehicle.place_number' | translate }}
                                                                </label></td>
                                                            <td>
                                                                <input class="field_custom" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    formControlName="nombre_de_place" readonly
                                                                    class="Mode" id="Nombre_de_place"
                                                                    placeholder="valeur" type="number" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label
                                                                    for="Lieu_stationnement">{{ 'Vehicle.parking_place' | translate }}
                                                                </label></td>
                                                            <td>
                                                                <input class="field_custom" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    formControlName="lieu_stationnement" class="Mode"
                                                                    readonly id="Lieu_stationnement"
                                                                    placeholder="valeur" type="text" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>

                                                    </table>

                                                </div>
                                            </fieldset>
                                        </form>

                                    </div>
                                </div>
                                <!-- /.nav-tabs-custom -->

                                <!-- /.col -->

                                <div class="card" class="col-md-6">
                                    <div class="form_section" class="form1">

                                        <form class=" col-md-5 contant_form" #f2="ngForm" [formGroup]="secondFormGroup"
                                            class="Mode">
                                            <fieldset class="card">
                                                <div class="row">
                                                    <table class="Mode">
                                                        <tr>
                                                            <td><label for="Longueur">{{ 'Vehicle.length' | translate }}
                                                                </label></td>
                                                            <td>
                                                                <input formControlName="Longueur" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    class="field_custom" id="Longueur" class="Mode"
                                                                    placeholder="valeur" type="number" readonly />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label for="Largeur">{{ 'Vehicle.width' | translate }}
                                                                </label></td>
                                                            <td>
                                                                <input formControlName="Largeur" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    class="field_custom" id="Largeur" class="Mode"
                                                                    placeholder="valeur" type="number" readonly />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label for="Hauteur">{{ 'Vehicle.height' | translate }}
                                                                </label></td>
                                                            <td>
                                                                <input formControlName="Hauteur" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    class="field_custom" id="Hauteur" class="Mode"
                                                                    placeholder="valeur" type="number" readonly />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label
                                                                    for="Poids_à_vide">{{ 'Vehicle.empty_weight' | translate }}</label>
                                                            </td>
                                                            <td>
                                                                <input formControlName="Poids_à_vide" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    class="field_custom" id="Poids_à_vide" class="Mode"
                                                                    placeholder="valeur" type="number" readonly />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label for="cu">{{ 'Vehicle.payload' | translate }}
                                                                </label></td>
                                                            <td>
                                                                <input formControlName="cu" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    class="field_custom" id="cu" class="Mode"
                                                                    placeholder="valeur" type="number" readonly />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label for="poids_total_charge"
                                                                    class="Mode">{{ 'Vehicle.total_load_weight' | translate }}
                                                                </label></td>
                                                            <td>
                                                                <input formControlName="poids_total_charge" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    class="field_custom" class="Mode"
                                                                    id="poids_total_charge" placeholder="valeur"
                                                                    type="number" readonly />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label
                                                                    for="Puissance">{{ 'Vehicle.power' | translate }}</label>
                                                            </td>
                                                            <td>
                                                                <input formControlName="Puissance" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    class="field_custom" class="Mode" id="Puissance"
                                                                    placeholder="valeur" type="number" readonly />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label for="Volume"
                                                                    class="Mode">{{ 'Vehicle.volume' | translate }}
                                                                </label></td>
                                                            <td>
                                                                <input formControlName="Volume" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    class="field_custom" class="Mode" id="Volume"
                                                                    placeholder="valeur" type="number" readonly />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label
                                                                    for="nb_essieux">{{ 'Vehicle.number_semi_trailer_axles' | translate }}
                                                                </label></td>
                                                            <td>
                                                                <input formControlName="nb_essieux" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    class="field_custom" class="Mode" id="nb_essieux"
                                                                    placeholder="valeur" type="number" readonly />
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td><label>{{ 'Vehicle.fuel' | translate }}</label></td>
                                                            <td class="Mode">
                                                                <input class="field_custom" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    formControlName="Carburant" class="Mode"
                                                                    placeholder="valeur" type="text" readonly />

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label
                                                                    for="Kilométrage">{{ 'Vehicle.milage' | translate }}
                                                                </label></td>
                                                            <td>
                                                                <input formControlName="Kilométrage" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    class="field_custom" class="Mode" id="Kilométrage"
                                                                    placeholder="valeur" type="text" readonly />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label
                                                                    for="conso_min">{{ 'Vehicle.min_consumption' | translate }}</label>
                                                            </td>
                                                            <td>
                                                                <input formControlName="conso_min" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    class="field_custom" class="Mode" id="conso_min"
                                                                    placeholder="valeur" type="number" readonly />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><label
                                                                    for="conso_max">{{ 'Vehicle.max_consumption' | translate }}
                                                                </label></td>
                                                            <td>
                                                                <input formControlName="conso_max" class="Mode"
                                                                    class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"
                                                                    class="field_custom" class="Mode" id="conso_max"
                                                                    placeholder="valeur" type="number" readonly />
                                                            </td>
                                                        </tr>

                                                    </table>

                                                </div>
                                            </fieldset>
                                        </form>

                                    </div>


                                </div>
                                <!-- /.card -->


                              

                            </div>

                            <!-- /.row -->
                        </div><!-- /.container-fluid -->
                    </section>

                    <!-- this row will not appear when printing -->
                    <div class="row no-print">
                        <div class="col-12">
                            <!-- <a href="invoice-print.html" target="_blank" class="btn btn-default"><i class="fas fa-print"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> Impression</font></font></a>
                <button type="button" class="btn btn-primary float-right" style="margin-right: 5px;">
                  <i class="fas fa-download"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> Générer un PDF
                </font></font></button>  -->
                        </div>
                    </div>
                </div>
                <!-- /.invoice -->
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</section>