<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>{{ "Forms.Models.Models" | translate }}</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <!--    <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item">Forms</li>
            <li class="breadcrumb-item active">ajouter</li> -->

          <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
          <li class="breadcrumb-item "><a [routerLink]="['/setting/forms/model/list']">{{ "Forms.Models.Models" |
              translate }}</a></li>
          <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Ajouter un modèle -->
<div class="container-fluid">
  <!-- Main content -->
  <section class="container">
    <div class="card card-primary">
      <div class="card-header" style="background-color: #17a2b8;">
        <h1 class="card-title">{{ "Forms.Models.AddModels" | translate }}</h1>
      </div>
      <!-- /.card -->
      <div class="row">
        
        <!-- cette div gere les modèles -->
        <div class="card-body">
          <div class="row">
            <!-- left column -->
            <div class="col-md-12">
              <!-- general form elements -->
              <div class="card card-primary">
                <!-- form start -->
                <form [formGroup]="modelForm" (submit)="onSubmitModel()">
                  <div class="card-body">
                    <div class="form-group">
                      <label> {{ "Forms.Models.Marques" | translate }} <span style="color: red;">*</span> </label>
                      <select class="form-control {{ isSubmitted && modelform.parent_id.errors ? 'is-invalid' : '' }}"
                        formControlName="parent_id">
                        <option value="">{{ "Forms.Models.MarqueLabel" | translate }}</option>
                        <option [value]="typemark.id" *ngFor="let typemark of typemarks " id="option{{ typemark.id }}">
                          {{typemark.name}}</option>
                      </select>
                      <label>Ajouter une marque en <a href="javascript:void(0)" (click)="add()">cliquant
                          ici</a></label>
                    </div>

                    <div class="form-group">
                      <label for="exampleName1">{{ "Forms.Models.Nom" | translate }} <span style="color: red;">*</span>
                      </label>
                      <input type="text" formControlName="name"
                        class="form-control {{ isSubmitted && modelform.name.errors ? 'is-invalid' : '' }}"
                        id="exampleName1" placeholder="Nom du Modèle ..." />
                    </div>
                    <div class="form-group">
                      <label>{{ "Forms.Models.Description" | translate }}</label>
                      <textarea
                        class="form-control {{ isSubmitted && modelform.description.errors ? 'is-invalid' : '' }}"
                        formControlName="description" rows="3" placeholder="Description ..."></textarea>
                    </div>
                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8">
                      <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                      <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                </form>
                <div *ngIf="isMaterial" class="col-md-2" style="margin-left:85%"><button class="btn btn-danger"
                    (click)="close()">
                    {{
                    'Material.ButtonClose' | translate }}
                  </button></div>

              </div>
              <!-- /.card -->
            </div>
            <!--/.col (left) -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12"></div>
      </div>
      <!-- /.card-body -->
    </div>
  </section>
  <!-- /.content -->
</div>