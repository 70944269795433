<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ "Document.Title" | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">Home</a></li>
                    <li class="breadcrumb-item active">{{ "Document.List" | translate }}</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>
<section class="content">
    <div class="container-fluid">
        <div class="col-12">
            <div class="card">
                <div class="card-header" style="background-color:#17a2b8; color: white;">
                    
                    <h3 class="card-title">{{ "Document.AllDocument" | translate }}</h3>
                    <!-- 
                    <div class="card-tools">
                        <div class="input-group input-group-sm" style="width: 150px;">
                            <input type="text" name="table_search" class="form-control float-right" placeholder="Search">

                            <div class="input-group-append">
                                <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                            </div>
                        </div>
                    </div> -->
                </div>
                
                <!-- /.card-header -->
                <div class="card-body table-responsive p-0">
                    <table id="example1" class="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th style="width: 5%">#</th>
                                <th>{{ "Document.Name" | translate }}</th>
                                <th>{{ "Document.Date" | translate }}</th>
                                <th style="width: 15%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let element of data?.data,let i = index" id="{{ element.id }}">
                                <td>
                                    {{ i+1 }}
                                </td>
                                <td>{{element.file_name}}</td>
                                <td>{{element.created_at | date:'dd/MM/yyyy'}}</td>
                                <td>
                                    <!-- <a type="button" class="btn btn-primary btn-sm" href="{{file}}" (click)="download(element.id)">
                                        <i class="fas fa-folder">
                                        </i> Telecharger
                                    </a> -->
                                    <a *ngIf="canCreate" class="btn btn-sm btn-info" href="{{element.file}}" (click)="download(element.id)" data-bs-placement="top" title="modifier" 
                                        data-toggle="tooltip" data-placement="bottom">
                                        <i class="fas fa-download"></i>
                                    </a>
                                    &nbsp;&nbsp;
                                    <a *ngIf="canDelete" class="btn btn-danger btn-sm" href="javascript:void(0)" data-placement="top" title="supprimer" 
                                        (click)="delete(element.id)">
                                        <i class="fas fa-trash">
                                        </i> 
                                    </a>
                                </td>
                                <!-- <td>
                                    <a class="btn btn-danger btn-sm" href="javascript:void(0)" data-placement="top" title="supprimer" 
                                        (click)="delete(element.id)">
                                        <i class="fas fa-trash">
                                        </i> 
                                    </a>
                                </td> -->
                            </tr>
                        </tbody>
                        <tfoot *ngIf="loading && (!data || !data.length)">
                            <td colspan="7" class="text-center">
                                <div class="spinner-border spinner-sm">
                                    <span class="sr-only">Patientez svp...</span>
                                </div>
                            </td>
                        </tfoot>
                        <tfoot *ngIf="!loading && (!data || data.length)">
                            <td colspan="7" class="text-center">
                                Liste vide
                            </td>
                        </tfoot>

                    </table>
                </div>
                <!-- /.card-body -->
                <div class="card-footer">
                    <!-- <nav aria-label="Navigation dans la page des contacts">
                        <ul class="pagination justify-content-center m-0">
                            <li class="page-item">
                                <a (click)="getPage(data?.prev_page_url)" class="page-link">
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">{{ 'Affectation.Previous' | translate }}
                                        </font>
                                    </font>
                                </a>
                            </li>
                            <li class="page-item">
                                <a (click)="getPage(data?.next_page_url)" class="page-link">
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">{{ 'Affectation.next' | translate }}
                                        </font>
                                    </font>
                                </a>
                            </li>
                        </ul>
                    </nav> -->
                </div>

            </div>
            <!-- /.card -->
        </div>
    </div>
</section>