import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { ProgrammeReparationService } from 'src/app/_services/maintenance-service/programme_reparation/programme-reparation.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-programme-reparation-details',
  templateUrl: './programme-reparation-details.component.html',
  styleUrls: ['./programme-reparation-details.component.scss']
})
export class ProgrammeReparationDetailsComponent implements OnInit {

  programme_reparations: any;

  constructor(
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private ProgrammeReparationService: ProgrammeReparationService,
  ) { }

  async ngOnInit() {
    const programme_id = +this.route.snapshot.paramMap.get("id");
    //console.log(Junie);
    this.ProgrammeReparationService.find(programme_id).then(
      data => {
        this.programme_reparations = data;
        console.log(this.programme_reparations)
      }
    ).catch(
      error => {
        this.translate.get('programme_reparations.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/programme_reparation/all'])
      }
    )
  }

}
