import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { InterveningTypeService } from "src/app/_services/transport-element/intervening-type/intervening-type.service";

@Component({
  selector: 'app-intervening-type-update',
  templateUrl: './intervening-type-update.component.html',
  styleUrls: ['./intervening-type-update.component.scss']
})
export class InterveningTypeUpdateComponent implements OnInit {

  data: any;
  dataForm : FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  myfile: File = null;

  constructor(
    private translate: TranslateService,
    private dataService: InterveningTypeService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,

  ) { }

  ngOnInit(): void {
    this.initForm();
    const element_id = +this.route.snapshot.paramMap.get("id");
    this.dataService.find(element_id).then(
      data => {
        console.log(data)
        this.data = data;
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('data.' + error.error.code)
          .subscribe(val => this.toastrService.error(val));
      }
    )

  }

  initForm(withElement = false) {
    if (withElement) {
      this.dataForm = this.formBuilder.group({
        intervening_type_name: [this.data.intervening_type_name, [Validators.required]],
        description: [this.data.description],
      });
    } else {
      this.dataForm = this.formBuilder.group({
        intervening_type_name : ['', Validators.required],
        description: [''],
      });
    }

  }

  get form(){
    return this.dataForm.controls
  }

  onSubmit(){
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.dataForm.invalid) {
      this.toastrService.error('ERREUR', 'Les champs en rouge sont obligatoires!');
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    const data = this.form;
    for (const k in data) {
      if (k) {
        formData.append(k + '', data[k].value);
      }
    }

    this.dataService.put(this.data.id, formData).then(resp => {
      this.toastrService.success('mise a jour avec success');
      this.isSubmitted = false;
      this.dataForm.reset();
      this.router.navigate(['/intervening-type/list']);
    })
    .catch(
      err => {
        console.log(err);
        this.toastrService.error('l\'operation a echouer');
      }
    )
    .finally(() => this.isLoading = false);
  }


}
