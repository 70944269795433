import { Component, OnInit } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2'
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ChauffeurService } from 'src/app/_services/RH-service/chauffeur/chauffeur.service';
import { AuthService } from 'src/app/_services/auth/auth.service';
declare const $;
@Component({
  selector: 'app-chauffeur-all',
  templateUrl: './chauffeur-all.component.html',
  styleUrls: ['./chauffeur-all.component.scss']
})
export class ChauffeurAllComponent implements OnInit {


  Chauffeurs: any;
  loading: boolean = true;
  @BlockUI() blockUI: NgBlockUI;

  //SweetAlert Text
  areYouSure = '';
  warning = ''
  yes = '';
  no = '';
  deleted = '';
  deletedMessage = '';
  cancelled = '';
  cancelledMessage = '';
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  page = 1;
  data: any;
  driverSelectId: any;
  idDriver;

  constructor(
    private ChauffeurService: ChauffeurService,
    private authService: AuthService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router) {

    this.translate.get(
      ['SweetAlert.AreYouSure', 'SweetAlert.Warning', 'SweetAlert.Yes', 'SweetAlert.No', 'SweetAlert.Deleted',
        'SweetAlert.DeletedMessage', 'SweetAlert.Cancelled', 'SweetAlert.CancelledMessage'],
      { data: "ce Chauffeur" })
      .subscribe(val => {
        this.areYouSure = val['SweetAlert.AreYouSure'];
        this.warning = val['SweetAlert.Warning'];
        this.yes = val['SweetAlert.Yes'];
        this.no = val['SweetAlert.No'];
        this.deleted = val['SweetAlert.Deleted'];
        this.deletedMessage = val['SweetAlert.DeletedMessage'];
        this.cancelled = val['SweetAlert.Cancelled'];
        this.cancelledMessage = val['SweetAlert.CancelledMessage'];
      });
  }
  ngOnInit() {
    console.log('theo')
    this.getChauffeur(this.page);
    const permissionSuffix = 'drivers';
    this.canCreate = this.authService.hasPermission(`${permissionSuffix}-create`);
    this.canUpdate = this.authService.hasPermission(`${permissionSuffix}-update`);
    this.canDelete = this.authService.hasPermission(`${permissionSuffix}-delete`);
  }

  getChauffeur(page) {
    this.loading = true;
    this.ChauffeurService.gets(page).then(
      response => {
        this.Chauffeurs = response;
        console.log(this.Chauffeurs);
        $(function () {
         // $("#example1").DataTable().fnDestroy()
          $("#example1").DataTable({
            "responsive": true, "lengthChange": false, "autoWidth": false,
            "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
          }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        });
      }
    ).catch(
      error => {
        this.notifService.danger(error.error.message)
      }
    ).finally(
      () => {
        this.loading = false;
      }
    )
  }


  getPage(url) {
    if (url) {
      this.ChauffeurService.get(url).then((res) => {
        this.Chauffeurs = res;
        console.log(this.Chauffeurs)
      }).catch(
        error => {
          this.notifService.danger(error.error.message)
        }
      ).finally(
        () => {
          this.loading = false;
        }
      )
    }
  }

  editChauffeur(id) {
    console.log('bonjour');
    this.router.navigate(['/chauffeur/update/' + id]);
  }
  detailleChauffeur(id) {
    console.log('bonjourhaut');
    this.router.navigate(['/chauffeur/detail/' + id]);
  }

  deleteChauffeur(id) {
    Swal.fire({
      title: this.areYouSure,
      text: this.warning,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.yes,
      cancelButtonText: this.no
    }).then((result) => {
      if (result.value) {
        this.blockUI.start('Loading...');
        this.ChauffeurService.delete(id).then(
          data => {
            this.blockUI.stop();
            Swal.fire(
              this.deleted,
              this.deletedMessage,
              'success'
            )
            this.getChauffeur(1);

          }
        ).catch(
          error => {
            console.log(error)
            this.blockUI.stop();
            this.translate.get('Chauffeur.' + error.error.code)
              .subscribe(val => this.notifService.danger(val));
          }
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.cancelledMessage,
          'error'
        )
      }
    })
  }
  show(src){
    Swal.fire({
      imageUrl: src,
      imageHeight: 300,
      imageAlt: "Custom image",
      animation: false
  });
  }
}
