import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { TypeDocumentMissionService } from 'src/app/_services/movement-service/type-document-mission.service';
import { NotifService } from 'src/app/_services/notif.service';
import Swal from 'sweetalert2';
declare const $;

@Component({
  selector: 'app-type-document-all',
  templateUrl: './type-document-all.component.html',
  styleUrls: ['./type-document-all.component.scss']
})
export class TypeDocumentAllComponent implements OnInit {

  type_documents: any;
  loading: boolean = true;
  @BlockUI() blockUI: NgBlockUI;

  //SweetAlert Text
  areYouSure = '';
  warning = ''
  yes = '';
  no = '';
  deleted = '';
  deletedMessage = '';
  cancelled = '';
  cancelledMessage = '';
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  page = 1;
  data: any;

  constructor(
    private TypeDocumentMissionService: TypeDocumentMissionService,
    private authService: AuthService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router,
  ) { 
    this.translate.get(
      ['SweetAlert.AreYouSure', 'SweetAlert.Warning', 'SweetAlert.Yes', 'SweetAlert.No', 'SweetAlert.Deleted',
        'SweetAlert.DeletedMessage', 'SweetAlert.Cancelled', 'SweetAlert.CancelledMessage'],
      { data: "ce type de document" })
      .subscribe(val => {
        this.areYouSure = val['SweetAlert.AreYouSure'];
        this.warning = val['SweetAlert.Warning'];
        this.yes = val['SweetAlert.Yes'];
        this.no = val['SweetAlert.No'];
        this.deleted = val['SweetAlert.Deleted'];
        this.deletedMessage = val['SweetAlert.DeletedMessage'];
        this.cancelled = val['SweetAlert.Cancelled'];
        this.cancelledMessage = val['SweetAlert.CancelledMessage'];
      });
  }

  ngOnInit(): void {
    this.getTypeDocumentMission(this.page);
  }

  getTypeDocumentMission(page) {
    this.loading = true;
    this.TypeDocumentMissionService.gets().then(
      response => {
        this.type_documents = response;
        console.log(this.type_documents);
        $(function () {
          //$("#example1").DataTable().fnDestroy()
          $("#example1").DataTable({
            "responsive": true, "lengthChange": false, "autoWidth": false,
            "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
          }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        });
      }
    ).catch(
      error => {
        this.notifService.danger(error.error.message)
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }


  getPage(url) {
    if (url) {
      this.TypeDocumentMissionService.get(url).then((res) => {
        this.type_documents = res;
        console.log(this.type_documents);
      }).catch(
        error => {
          this.notifService.danger(error.error.message);
        }
      ).finally(
        () => {
          this.loading = false;
        }
      )
    }
  }

  editTypeDocument(id) {
    console.log('bonjour');
    this.router.navigate(['/type-document-mission/update/' + id]);
  }

  deleteTypeDocument(id) {
    Swal.fire({
      title: this.areYouSure,
      text: this.warning,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.yes,
      cancelButtonText: this.no
    }).then((result) => {
      if (result.value) {
        this.blockUI.start('Loading...');
        this.TypeDocumentMissionService.delete(id).then(
          data => {
            this.blockUI.stop();
            Swal.fire(
              this.deleted,
              this.deletedMessage,
              'success'
            );
            this.getTypeDocumentMission(1);

          }
        ).catch(
          error => {
            console.log(error);
            this.blockUI.stop();
            this.translate.get('TypeDocumentMission.' + error.error.code)
              .subscribe(val => this.notifService.danger(val));
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.cancelledMessage,
          'error'
        );
      }
    });
  }

}
