<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-1">
        <div class="col-sm-6">
          <h1>
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">{{ "Affectation.Affectation" | translate }}</font>
            </font>
          </h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/affectations/all']">{{ "Affectation.Affectation" |
                translate }}</a></li>
            <li class="breadcrumb-item active">{{ "FileAriane.List" | translate }}</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>

  <!-- Main content -->
  <section class="content">

    <!-- Default box -->
   <!--  <div class="row"> 
      <div class="col-3">
        <div class="form-group">
          <label>{{ "Affectation.AffectationDriver" | translate }}</label>
          <select class="form-control" tabindex="-1" aria-hidden="true" [(ngModel)]="driverSelectId"
            (change)="chooseDriver($event)">
            <option selected="selected">choisir un driver</option>
            <option *ngFor="let driver of drivers?.data" value="{{driver.id}}">{{driver.driver_name}}</option>

          </select>
        </div>
      </div>
    </div> -->
 
    <div class="card">
      <div class="card-header" style="background-color: #17a2b8; color:white;">
        <h3 class="card-title">
          <font style="vertical-align: inherit;">
            <font style="vertical-align: inherit;"> {{ "Affectation.AffectationAll" | translate }}</font>
          </font>
        </h3>
        <button [routerLink]="['/affectations/add']" type="button" class="btn btn-primary float-right">
          <i class="fas fa-plus"></i> {{ "Forms.Marques.Ajouter" | translate }}
        </button>
      </div>
      <div class="card-body p-1">
        <table id="example1" class="table table-bordered table-striped">
          <thead>
            <tr>
              <th style="width: 5%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    #
                  </font>
                </font>
              </th>
              <th style="width: 20%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Affectation.NameDriverLabel" | translate }}
                  </font>
                </font>
              </th>
              <th style="width: 15%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Affectation.NameConvoyeurLabel" | translate }}
                  </font>
                </font>
              </th>
              <th style="width: 20%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Affectation.VehicleRegistrationLabel" | translate }}
                  </font>
                </font>
              </th>
              <th style="width: 15%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Affectation.DateAffectationLabel" | translate }}
                  </font>
                </font>
              </th>
              <th style="width: 10%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Affectation.EndDateLabel" | translate }}
                  </font>
                </font>
              </th>
              <th style="width: 15%"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let affect of affectations?.data,let i = index" id="{{ affect.id }}">
              <td>
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ i+1 }}
                  </font>
                </font>
              </td>

              <td>
                <a>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ affect.driver_name }}
                    </font>
                  </font>
                </a>
              </td>
              <td>
                <a>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ affect.convoyeur.name }}
                    </font>
                  </font>
                </a>
              </td>
              <td>
                <a>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ affect.vehicle_registration }}
                    </font>
                  </font>
                </a>
              </td>
              <td>
                <a>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ affect.date_of_affectation | date: 'yyyy-mm-d' }}
                    </font>
                  </font>
                </a>
              </td>
              <td>
                <a>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ affect.end_date }}
                    </font>
                  </font>
                </a>
              </td>

              <td class="project-actions text-right">
                <a class="btn btn-primary btn-sm" (click)="detailleaffectation(affect.id)" href="javascript:void(0)" data-bs-placement="top" title="details" >
                  <i class="fas fa-eye">
                  </i>
                </a>
                &nbsp;
                <a *ngIf="canUpdate" class="btn btn-info btn-sm" (click)="editaffectation(affect.id)" href="javascript:void(0)" data-bs-placement="top" title="modifier" >
                  <i class="fas fa-pencil-alt">
                  </i>
                </a>
                &nbsp;
                <a *ngIf="canDelete" class="btn btn-danger btn-sm" (click)="deleteaffectation(affect.id)" href="javascript:void(0)" data-bs-placement="top" title="supprimer" >
                  <i class="fas fa-trash">
                  </i>
                </a>
              </td>
            </tr>

          </tbody>
        </table>
        <div class="card-footer">
          <!-- <nav aria-label="Navigation dans la page des contacts">
            <ul class="pagination justify-content-center m-0">
              <li class="page-item"><a (click)="getPage(affectations.prev_page_url)" class="page-link">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">{{ 'Affectation.Previous' | translate }}</font>
                  </font>
                </a></li>
              <li class="page-item"><a (click)="getPage(affectations.next_page_url)" class="page-link">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">{{ 'Affectation.next' | translate }}</font>
                  </font>
                </a></li>
            </ul>
          </nav> -->
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->

  </section>
  <!-- /.content -->
</div>