import { Component, OnInit } from '@angular/core';
import { AffectationService } from 'src/app/_services/affectation/affectation.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AutoAddComponent } from 'src/app/modules/application/auto-parc/auto/auto-add/auto-add.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConvoyeurAddComponent } from 'src/app/modules/application/human-R/convoyeur/convoyeur-add/convoyeur-add.component';
import { ChauffeurAddComponent } from 'src/app/modules/application/human-R/chauffeurs/chauffeur-add/chauffeur-add.component';

@Component({
  selector: 'app-add-affectation',
  templateUrl: './add-affectation.component.html',
  styleUrls: ['./add-affectation.component.scss']
})
export class AddAffectationComponent implements OnInit {

  AffectationForm: FormGroup;
  drivers: any;
  vehicles: any;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  logo: File = null;
  limit = 1000000000000;
  convoyeurs;
  isMaterial = false;
  currentAutosAffectation:any;
  currentDriverAffectation: any;
  currentConvoyorAffectation: any;

  constructor(
    private AffectationService: AffectationService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private AutoDialog: MatDialog,
    private convoyeurDialog: MatDialog,
    private driverDialog: MatDialog,
    

  ) {

  }

  ngOnInit() {
    this.getDrivers(this.limit);
    this.getConvoyeurs(this.limit);
    this.getVehicles();
    this.AffectationForm = this.formBuilder.group({
      driver_id: ['', [Validators.required]],
      convoyeur_id: ['',[Validators.required]],
      vehicle_id: ['', [Validators.required]],
      date_of_affectation: ['', [Validators.required]],
      reason: ['', [Validators.required]],
      end_date: ['',],

    });


  }
  getDrivers(limit) {
    this.AffectationService.getdriver(limit).then((res) => {
      this.drivers = res;
      console.log(this.drivers)
    }, (error) => {
      this.notifService.danger(error.message)

    });
  }
  getConvoyeurs(limit) {
    this.AffectationService.getConvoyeur(limit).then((res) => {
      this.convoyeurs = res;
      console.log(this.convoyeurs)
    }, (error) => {
      this.notifService.danger(error.message)

    });
  }
  getVehicles() {
    this.AffectationService.getvehicle().then((res) => {
      this.vehicles = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.message)

    });
  }

  get form() {
    return this.AffectationForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.AffectationForm.invalid) {
      this.translate.get('Affectation.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('convoyeur_id', '' + this.form.convoyeur_id.value);
    formData.append('driver_id', '' + this.form.driver_id.value);
    formData.append('vehicle_id', '' + this.form.vehicle_id.value);
    formData.append('date_of_affectation', '' + this.form.date_of_affectation.value);
    formData.append('end_date', '' + this.form.end_date.value);
    formData.append('raison', '' + this.form.reason.value);
    this.getCurrentDriverAffectations(this.form.driver_id.value,this.form.date_of_affectation.value);


    console.log(formData);
    this.AffectationService.postaff(formData)
      .then(resp => {
        this.translate.get('Affectation.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.AffectationForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('Affectation.'+ err.code)
          .subscribe(val => this.notifService.danger(val));
          this.getCurrentDriverAffectations(this.form.driver_id.value,this.form.date_of_affectation.value);
          this.getCurrentConvoyorAffectations(this.form.convoyeur_id.value,this.form.date_of_affectation.value);
          this.getCurrentAutosAffectations(this.form.vehicle_id.value,this.form.date_of_affectation.value);

      })
      .finally(() => this.isLoading = false);
  }


  addAuto() {
    var modelDialogef =  this.AutoDialog.open(AutoAddComponent,{
       width: '1000px',
       height: '700px',
       disableClose: true
     });
 
     modelDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);
 
       this.ngOnInit();
     });
   }
   addConvoyeur(){
    var modelDialogef =  this.convoyeurDialog.open(ConvoyeurAddComponent,{
      width: '1000px',
      height: '700px',
      disableClose: true
    });

    modelDialogef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      this.ngOnInit();
    });
   }

   addDriver(){
    var modelDialogef =  this.driverDialog.open(ChauffeurAddComponent,{
      width: '700px',
      height: '700px',
      disableClose: true
    });

    modelDialogef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      this.ngOnInit();
    });
   }

   getCurrentDriverAffectations(driverId,affectationDate){
    this.AffectationService.getCurrentDriversAffectations(driverId,affectationDate)
    .then(response =>{
      console.log("les affectations du driver");
      console.log(response);
      if(response.length != 0){
        this.translate.get('Affectation.DriverError')
        .subscribe(val => this.notifService.danger(val));

      }
        

    })


   }

   getCurrentConvoyorAffectations(convoyorId,affectationDate){
    this.AffectationService.getCurrentConvoyorsAffectations(convoyorId,affectationDate)
    .then(response =>{
      console.log("les affectations du convoyer");
      console.log(response);
      if(response.length != 0){
        this.translate.get('Affectation.ConvoyorOccuped')
        .subscribe(val => this.notifService.danger(val));

      }
        

    })


   }

   getCurrentAutosAffectations(autoId,affectationDate){
    this.AffectationService.getCurrentAutosAffectations(autoId,affectationDate)
    .then(response =>{
      console.log("les affectations du vehicule");
      console.log(response);
      if(response.length != 0){
        this.translate.get('Affectation.AutoOccuped')
        .subscribe(val => this.notifService.danger(val));

      }
        

    })


   }


}
