<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'Mission.Missions' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">Home</a></li>
                    <li class="breadcrumb-item "><a [routerLink]="['/mission/all']">{{
                            "Mission.Missions" | translate }}</a></li>
                    <li class="breadcrumb-item active">{{ "FileAriane.Update" | translate }}</li>

                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">
    <div class="container-fluid">
        <!-- left column -->
        <div class="row">
            <div class="col-md-12">
                <!-- general form elements -->
                <div class="card card-primary">
                    <div class="card-header" style="background-color: #17a2b8;">
                        <h3 class="card-title">
                            {{"Mission.MissionUpdate" | translate}}

                        </h3>
                    </div>
                    <!-- /.card-header -->
                    <!-- form start -->
                    <form role="form" (submit)="onSubmit()" [formGroup]="dataForm">
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.FournisseurMissionLabel" | translate}}

                                    </label>
                                    <select formControlName="element_intervenant_id1"
                                        class="form-control {{ isSubmitted && form.element_intervenant_id1.errors? 'is-invalid' : '' }}"
                                        style="width: 100%;" tabindex="-1" aria-hidden="true">
                                        <option value="">{{ "Mission.Fournisseur" | translate }}</option>
                                        <option *ngFor="let four of fournisseurs?.data" value="{{four.id}}">
                                            {{four.name}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.ClientMissionLabel" | translate}}

                                    </label>
                                    <select formControlName="element_intervenant_id2"
                                        class="form-control {{ isSubmitted && form.element_intervenant_id2.errors? 'is-invalid' : '' }}"
                                        style="width: 100%;" tabindex="-1" aria-hidden="true">
                                        <option value="">{{ "Mission.Client" | translate }}</option>
                                        <option *ngFor="let client of clients?.data" value="{{client.id}}">
                                            {{client.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.VehiculMissionLabel" | translate}}

                                    </label>
                                    <select formControlName="vehicule_id"
                                        class="form-control {{ isSubmitted && form.vehicule_id.errors? 'is-invalid' : '' }}"
                                        style="width: 100%;" tabindex="-1" aria-hidden="true">
                                        <option value="">{{ "Mission.Vehicule" | translate }}</option>
                                        <option *ngFor="let four of vehicules?.data" value="{{four.id}}">
                                            {{four.vehicle_registration}}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.ChaufMissionLabel" | translate}}

                                    </label>
                                    <select formControlName="chauffeur_id"
                                        class="form-control {{ isSubmitted && form.chauffeur_id.errors? 'is-invalid' : '' }}"
                                        style="width: 100%;" tabindex="-1" aria-hidden="true">
                                        <option value="">{{ "Mission.Client" | translate }}</option>
                                        <option *ngFor="let chauf of chauffeurs?.data" value="{{chauf.id}}">
                                            {{chauf.driver_name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.ConvoyeurMissionLabel" | translate}}

                                    </label>
                                    <select formControlName="convoyeur_id"
                                        class="form-control {{ isSubmitted && form.convoyeur_id.errors? 'is-invalid' : '' }}"
                                        style="width: 100%;" tabindex="-1" aria-hidden="true">
                                        <option value="">{{ "Mission.Convoyeur" | translate }}</option>
                                        <option *ngFor="let four of convoyeurs?.data" value="{{four.id}}">
                                            {{four.convoyeur_name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.DestinationMissionLabel" | translate}}

                                    </label>
                                    <input type="text"
                                        class="form-control {{ isSubmitted && form.destination.errors ? 'is-invalid' : '' }}"
                                        id="exampleInputEmail1" formControlName="destination"
                                        placeholder="{{ 'Mission.LabelNameMission' | translate }}">
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.LieuDepartMissionLabel" | translate}}


                                        <span style="color: red">*</span>
                                    </label>
                                    <input type="text"
                                        class="form-control {{ isSubmitted && form.lieu_depart.errors ? 'is-invalid' : '' }}"
                                        id="exampleInputEmail1" formControlName="lieu_depart"
                                        placeholder="{{ 'Mission.LabelLieuMission' | translate }}">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.DateDepartMissionLabel" | translate
                                        }}


                                        <span style="color: red">*</span>
                                    </label>
                                    <input type="date"
                                        class="form-control {{ isSubmitted && form.date_depart.errors ? 'is-invalid' : '' }}"
                                        id="exampleInputEmail1" formControlName="date_depart"
                                        placeholder="{{ 'Mission.LabelemailMission' | translate }}">
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.IndexDepartMissionLabel" |
                                        translate
                                        }}


                                        <span style="color: red">*</span>
                                    </label>
                                    <input type="text"
                                        class="form-control {{ isSubmitted && form.index_depart.errors ? 'is-invalid' : '' }}"
                                        id="exampleInputEmail1" formControlName="index_depart"
                                        placeholder="{{ 'Mission.Labelindex_departMission' | translate }}">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputFile">
                                        {{ "Mission.DocumentMissionLabel" |
                                        translate }}


                                    </label>
                                    <div class="input-group">
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="exampleInputFile"
                                                (change)=onSelectfile1($event) multiple>
                                            <label class="custom-file-label" for="exampleInputFile">
                                                piece jointe

                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.ParcourTheoriqueMissionLabel" |
                                        translate
                                        }}


                                        <span style="color: red">*</span>
                                    </label>
                                    <input type="text"
                                        class="form-control {{ isSubmitted && form.parcours_theorique.errors ? 'is-invalid' : '' }}"
                                        id="exampleInputEmail1" formControlName="parcours_theorique"
                                        placeholder="{{ 'Mission.Labelparcours_theoriqueMission' | translate }}">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.DescriptionMissionLabel" | translate}}

                                    </label>
                                    <textarea class="form-control" id="exampleInputEmail1" formControlName="description"
                                        placeholder="{{ 'Mission.LabeldescriptionMission' | translate }}"></textarea>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.HeureDepartMissionLabel" | translate }}
                                    </label>
                                    <input type="time" class="form-control" id="exampleInputEmail1"
                                        formControlName="heure_depart"
                                        placeholder="{{ 'Mission.LabelHeureDepartMission' | translate }}">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.HeureRetourMissionLabel" | translate }}
                                    </label>
                                    <input type="time" class="form-control" id="exampleInputEmail1"
                                        formControlName="heure_retour"
                                        placeholder="{{ 'Mission.LabelHeureRetourMission' | translate }}">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.DureeMissionLabel" | translate }}
                                    </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        formControlName="duree"
                                        placeholder="{{ 'Mission.LabelDureeMission' | translate }}">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.IndexRetourMissionLabel" | translate }}
                                    </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        formControlName="index_retour"
                                        placeholder="{{ 'Mission.LabelIndexRetourMission' | translate }}">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.ParcoursReelMissionLabel" | translate }}
                                    </label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        formControlName="parcours_reel"
                                        placeholder="{{ 'Mission.LabelParcoursReelMission' | translate }}">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.HeureArriveSiteMissionLabel" | translate }}
                                    </label>
                                    <input type="time" class="form-control" id="exampleInputEmail1"
                                        formControlName="heure_arrive_site"
                                        placeholder="{{ 'Mission.LabelHeureArriveSiteMission' | translate }}">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">
                                        {{ "Mission.HeureArriveDestinationMissionLabel" | translate }}
                                    </label>
                                    <input type="time" class="form-control" id="exampleInputEmail1"
                                        formControlName="heure_arrive_destination"
                                        placeholder="{{ 'Mission.LabelHeureArriveDestinationMission' | translate }}">
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="isLoading" type="submit" class="btn btn-primary"
                                style="background-color: #17a2b8;">
                                <p *ngIf="!isLoading">{{ "General.update" | translate }}</p>
                                <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>


        <!-- /.row -->

    </div><!-- /.container-fluid -->
</section>