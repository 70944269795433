import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DocumentService } from 'src/app/_services/document/document.service';

@Component({
  selector: 'app-generating-document-upload',
  templateUrl: './generating-document-upload.component.html',
  styleUrls: ['./generating-document-upload.component.scss']
})
export class GeneratingDocumentUploadComponent implements OnInit {
  dataForm : FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  myfile: File = null;

  constructor(
    private translate: TranslateService,
    private dataService: DocumentService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(){
    this.dataForm = this.formBuilder.group({
      file_name : ['', Validators.required],
      file : ['', Validators.required]
    })
  }


  detectfile(event) {
    this.myfile = event.target.files[0];
    console.log(this.myfile)
  }

  get form(){
    return this.dataForm.controls
  }

  onSubmit(){
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.dataForm.invalid) {
      this.toastrService.error('ERREUR', 'Les champs en rouge sont obligatoires!')
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('file_name', '' + this.form.file_name.value);
    formData.append('file', this.myfile);

    this.dataService.upload(formData).then(resp => {
      this.toastrService.success('votre fichier a ete ajouter avec success');
      this.isSubmitted = false;
      this.dataForm.reset();
    })
    .catch(
      err => {
        console.log(err);
        this.toastrService.error('l\'operation a echouer');
      }
    )
    .finally(() => this.isLoading = false);
  }


}
