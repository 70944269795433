import { PositionService } from './../../../../../_services/pneumatique/Position/position.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-detail-model-auto',
  templateUrl: './detail-model-auto.component.html',
  styleUrls: ['./detail-model-auto.component.scss']
})
export class DetailModelAutoComponent implements OnInit {
  positions: any = null;


  indice_line: number;
  indice_colone: number;
  isSelected: boolean = false;
  selectedRow: number;
  selectedColumn: number;
  actived: boolean = false;


  rowLength: number = 10;
  colLength: number = 5;
  rows: any[] = [];
  columns: any[] = [];
  dataRow: any[] = [];
  dataCol: any[] = [];
  model: any;
  name: string;

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private positionService: PositionService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {

    const mod_id = +this.route.snapshot.paramMap.get("id");
    this.positionService.find(mod_id).then(
      data => {
        this.positions = data;
        this.model = data[0]
        console.log(this.model);

        for (var i = 0; i < this.rowLength; i++) {
          this.rows[i] = i;
        }


        for (var j = 0; j < this.colLength; j++) {
          this.columns[j] = j;

        }
      }
    ).catch(
      error => {
        this.translate.get('position.' + error.error.code)
          .subscribe();
      }
    )

  }

  matched(i, j): boolean { 
    /* if(this.positions.some(e => (e.indice_ligne === i && e.indice_colone === j))){
      return e.id;
    } */
    return this.positions.some(e => (e.indice_ligne === i && e.indice_colone === j));
  }

}
