import { ErrorInterceptor } from 'src/app/_http-interceptors/error-interceptor.helper';
import { AddTokenInterceptor } from 'src/app/_http-interceptors/add-token.interceptor';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//import de angular material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
//import { MatFormField } from '@angular/material/form-field';


//import { CUSTOM_ELEMENTS8SCHEMA } from '@angular/material';
import { HomeComponent } from './modules/general/home/home.component';
import { NotFoundComponent } from './modules/general/not-found/not-found.component';
import { SigninComponent } from './modules/general/signin/signin.component';
import { AutoUpdateComponent } from './modules/application/auto-parc/auto/auto-update/auto-update.component';
import { AutoAddComponent } from './modules/application/auto-parc/auto/auto-add/auto-add.component';
import { AutoListComponent } from './modules/application/auto-parc/auto/auto-list/auto-list.component';
import { AutoDetailComponent } from './modules/application/auto-parc/auto/auto-detail/auto-detail.component';
/* import { MissionAddComponent } from './modules/application/maintenance/mission/mission-add/mission-add.component';
import { MissionUpdateComponent } from './modules/application/maintenance/mission/mission-update/mission-update.component';
import { MissionDetailComponent } from './modules/application/maintenance/mission/mission-detail/mission-detail.component';
import { MissionListComponent } from './modules/application/maintenance/mission/mission-list/mission-list.component'; */
import { PanneAutoListComponent } from './modules/application/maintenance/panne/panne-auto-list/panne-auto-list.component';
import { PanneAutoDetailComponent } from './modules/application/maintenance/panne/panne-auto-detail/panne-auto-detail.component';
import { AmendeAddComponent } from './modules/application/mouvement/amendes/amende-add/amende-add.component';
import { AmendeListComponent } from './modules/application/mouvement/amendes/amende-list/amende-list.component';
import { AmendeDetailComponent } from './modules/application/mouvement/amendes/amende-detail/amende-detail.component';
import { AmendeUpdateComponent } from './modules/application/mouvement/amendes/amende-update/amende-update.component';
import { PanneAutoUpdateComponent } from './modules/application/maintenance/panne/panne-auto-update/panne-auto-update.component';
import { PanneDeclarerAddComponent } from './modules/application/mouvement/panne/panne-declarer-add/panne-declarer-add.component';
import { PanneDeclarerUpdateComponent } from './modules/application/mouvement/panne/panne-declarer-update/panne-declarer-update.component';
import { HRAddComponent } from './modules/application/human-R/HR/hr-add/hr-add.component';
import { HRUpdateComponent } from './modules/application/human-R/HR/hr-update/hr-update.component';
import { HRListComponent } from './modules/application/human-R/HR/hr-list/hr-list.component';
import { HRDetailComponent } from './modules/application/human-R/HR/hr-detail/hr-detail.component';
import { SideBarComponent } from './modules/general/side-bar/side-bar.component';
import { NavBarComponent } from './modules/general/nav-bar/nav-bar.component';
import { FooterComponent } from './modules/general/footer/footer.component';
import { TechnicalFactSheetVehicleComponent } from './modules/application/auto-parc/auto/technical-fact-sheet-vehicle/technical-fact-sheet-vehicle.component';
import { TypeFicheAddComponent } from './modules/application/auto-parc/typeFiche/type-fiche-add/type-fiche-add.component';
import { TypeFicheUpdateComponent } from './modules/application/auto-parc/typeFiche/type-fiche-update/type-fiche-update.component';
import { TypeFicheAllComponent } from './modules/application/auto-parc/typeFiche/type-fiche-all/type-fiche-all.component';
import { TypeFicheDetailComponent } from './modules/application/auto-parc/typeFiche/type-fiche-detail/type-fiche-detail.component';
import { BlockUIModule } from 'ng-block-ui';
import { OfficialPaperAllComponent } from './modules/application/auto-parc/ficheAdministrative/officialPaper/official-paper-all/official-paper-all.component';
import { OfficialPaperAUpdateComponent } from './modules/application/auto-parc/ficheAdministrative/officialPaper/official-paper-aupdate/official-paper-aupdate.component';
import { AssuranceAddComponent } from './modules/application/auto-parc/ficheAdministrative/assurance/assurance-add/assurance-add.component';
import { AssuranceUpdateComponent } from './modules/application/auto-parc/ficheAdministrative/assurance/assurance-update/assurance-update.component';
import { AssuranceAllComponent } from './modules/application/auto-parc/ficheAdministrative/assurance/assurance-all/assurance-all.component';
import { AssuranceDetailComponent } from './modules/application/auto-parc/ficheAdministrative/assurance/assurance-detail/assurance-detail.component';
import { OfficialPaperAddComponent } from './modules/application/auto-parc/ficheAdministrative/officialPaper/official-paper-add/official-paper-add.component';
import { AddAffectationComponent } from './modules/general/setting/affectation/add-affectation/add-affectation.component';
import { AllAffectationComponent } from './modules/general/setting/affectation/all-affectation/all-affectation.component';
import { UpdateAffectationComponent } from './modules/general/setting/affectation/update-affectation/update-affectation.component';
import { DetailleAffectationComponent } from './modules/general/setting/affectation/detaille-affectation/detaille-affectation.component';


import { AddComponent } from './modules/general/setting/form-auto/add/add.component';
import { ListingComponent } from './modules/general/setting/form-auto/list/listing.component';
import { DetailComponent } from './modules/general/setting/form-auto/detail/detail.component';
import { UpdateComponent } from './modules/general/setting/form-auto/update/update.component';
import { AddModelAutoComponent } from './modules/general/setting/modele/add-model-auto/add-model-auto.component';
import { UpdateModelAutoComponent } from './modules/general/setting/modele/update-model-auto/update-model-auto.component';
import { DetailModelAutoComponent } from './modules/general/setting/modele/detail-model-auto/detail-model-auto.component';
import { ListModelAutoComponent } from './modules/general/setting/modele/list-model-auto/list-model-auto.component';
import { ListMarkAutoComponent } from './modules/general/setting/mark/list-mark-auto/list-mark-auto.component';
import { AddMarkAutoComponent } from './modules/general/setting/mark/add-mark-auto/add-mark-auto.component';
import { UpdateMarkAutoComponent } from './modules/general/setting/mark/update-mark-auto/update-mark-auto.component';
import { DetailMarkAutoComponent } from './modules/general/setting/mark/detail-mark-auto/detail-mark-auto.component';
import { DetailCarosserieAutoComponent } from './modules/general/setting/carosserie/detail-carosserie-auto/detail-carosserie-auto.component';
import { ListCarosserieAutoComponent } from './modules/general/setting/carosserie/list-carosserie-auto/list-carosserie-auto.component';
import { AddCarosserieAutoComponent } from './modules/general/setting/carosserie/add-carosserie-auto/add-carosserie-auto.component';
import { UpdateCarosserieAutoComponent } from './modules/general/setting/carosserie/update-carosserie-auto/update-carosserie-auto.component';
import { UpdateTypeVehiculeAutoComponent } from './modules/general/setting/type-vehicule/update-type-vehicule-auto/update-type-vehicule-auto.component';
import { AddTypeVehiculeAutoComponent } from './modules/general/setting/type-vehicule/add-type-vehicule-auto/add-type-vehicule-auto.component';
import { DetailTypeVehiculeAutoComponent } from './modules/general/setting/type-vehicule/detail-type-vehicule-auto/detail-type-vehicule-auto.component';
import { ListTypeVehiculeAutoComponent } from './modules/general/setting/type-vehicule/list-type-vehicule-auto/list-type-vehicule-auto.component';
import { InterveningTypeAddComponent } from './modules/general/setting/intervening-types/intervening-type-add/intervening-type-add.component';
import { InterveningTypeListComponent } from './modules/general/setting/intervening-types/intervening-type-list/intervening-type-list.component';
import { InterveningTypeUpdateComponent } from './modules/general/setting/intervening-types/intervening-type-update/intervening-type-update.component';
import { InterveningElementAddComponent } from './modules/general/setting/intervening-elements/intervening-element-add/intervening-element-add.component';
import { InterveningElementListComponent } from './modules/general/setting/intervening-elements/intervening-element-list/intervening-element-list.component';
import { InterveningElementDetailComponent } from './modules/general/setting/intervening-elements/intervening-element-detail/intervening-element-detail.component';
import { InterveningElementUpdateComponent } from './modules/general/setting/intervening-elements/intervening-element-update/intervening-element-update.component';
import { GeneratingDocumentAddComponent } from './modules/general/setting/generating-documents/generating-document-add/generating-document-add.component';
import { GeneratingDocumentListComponent } from './modules/general/setting/generating-documents/generating-document-list/generating-document-list.component';
import { GeneratingDocumentUploadComponent } from './modules/general/setting/generating-documents/generating-document-upload/generating-document-upload.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SanctonAddComponent } from './modules/application/human-R/sanctions/sanction/sancton-add/sancton-add.component';
import { SanctonAllComponent } from './modules/application/human-R/sanctions/sanction/sancton-all/sancton-all.component';
import { SanctonUpdateComponent } from './modules/application/human-R/sanctions/sanction/sancton-update/sancton-update.component';
import { SanctonDetailComponent } from './modules/application/human-R/sanctions/sanction/sancton-detail/sancton-detail.component';
import { TypeSanctionAddComponent } from './modules/application/human-R/sanctions/type-sanction/type-sanction-add/type-sanction-add.component';
import { TypeSanctionUpdateComponent } from './modules/application/human-R/sanctions/type-sanction/type-sanction-update/type-sanction-update.component';
import { TypeContratAddComponent } from './modules/application/human-R/contrats/type-contrat/type-contrat-add/type-contrat-add.component';
import { TypeContratUpdateComponent } from './modules/application/human-R/contrats/type-contrat/type-contrat-update/type-contrat-update.component';
import { ContratAllComponent } from './modules/application/human-R/contrats/contrat/contrat-all/contrat-all.component';

import { ContratDetailComponent } from './modules/application/human-R/contrats/contrat/contrat-detail/contrat-detail.component';
import { ContratAddComponent } from './modules/application/human-R/contrats/contrat/contrat-add/contrat-add.component';
import { ContratUpdateComponent } from './modules/application/human-R/contrats/contrat/contrat-update/contrat-update.component';
import { ChauffeurAddComponent } from './modules/application/human-R/chauffeurs/chauffeur-add/chauffeur-add.component';
import { ChauffeurAllComponent } from './modules/application/human-R/chauffeurs/chauffeur-all/chauffeur-all.component';
import { ChauffeurDetailComponent } from './modules/application/human-R/chauffeurs/chauffeur-detail/chauffeur-detail.component';
import { ChauffeurUpdateComponent } from './modules/application/human-R/chauffeurs/chauffeur-update/chauffeur-update.component';
import { FormationAddComponent } from './modules/application/human-R/formations/formation-add/formation-add.component';
import { FormationAllComponent } from './modules/application/human-R/formations/formation-all/formation-all.component';
import { FormationDetailComponent } from './modules/application/human-R/formations/formation-detail/formation-detail.component';
import { FormationUpdateComponent } from './modules/application/human-R/formations/formation-update/formation-update.component';
import { NoteCritiqueAddComponent } from './modules/application/human-R/note-critiques/note-critique-add/note-critique-add.component';
import { NoteCritiqueAllComponent } from './modules/application/human-R/note-critiques/note-critique-all/note-critique-all.component';
import { NoteCritiqueUpdateComponent } from './modules/application/human-R/note-critiques/note-critique-update/note-critique-update.component';
import { NoteCritiqueDetailComponent } from './modules/application/human-R/note-critiques/note-critique-detail/note-critique-detail.component';
import { TypeContratAllComponent } from './modules/application/human-R/contrats/type-contrat/type-contrat-all/type-contrat-all.component';
import { TypeSanctionAllComponent } from './modules/application/human-R/sanctions/type-sanction/type-sanction-all/type-sanction-all.component';
import { AddRoleComponent } from './modules/general/setting/roles/add-role/add-role.component';
import { AllRolesComponent } from './modules/general/setting/roles/all-roles/all-roles.component';
import { DetailsRoleComponent } from './modules/general/setting/roles/details-role/details-role.component';
import { UpdateRoleComponent } from './modules/general/setting/roles/update-role/update-role.component';
import { CreateUsersComponent } from './modules/application/human-R/users/create-users/create-users.component';
import { UpdateUsersComponent } from './modules/application/human-R/users/update-users/update-users.component';
import { ListUsersComponent } from './modules/application/human-R/users/list-users/list-users.component';
import { ListdetailUserComponent } from './modules/application/human-R/users/listdetail-user/listdetail-user.component';
import { MessageAddComponent } from './modules/application/human-R/messages/message-add/message-add.component';
import { MessageAllComponent } from './modules/application/human-R/messages/message-all/message-all.component';
import { ThComponent } from './th/th.component';
import { MessageDetailComponent } from './modules/application/human-R/messages/message-detail/message-detail.component';
import { MessageUpdateComponent } from './modules/application/human-R/messages/message-update/message-update.component';
import { ConvoyeurAddComponent } from './modules/application/human-R/convoyeur/convoyeur-add/convoyeur-add.component';
import { ConvoyeurAllComponent } from './modules/application/human-R/convoyeur/convoyeur-all/convoyeur-all.component';
import { ConvoyeurUpdateComponent } from './modules/application/human-R/convoyeur/convoyeur-update/convoyeur-update.component';
import { ConvoyeurDetailComponent } from './modules/application/human-R/convoyeur/convoyeur-detail/convoyeur-detail.component';
import { MecanicienAddComponent } from './modules/application/human-R/mecanicien/mecanicien-add/mecanicien-add.component';
import { MecanicienAllComponent } from './modules/application/human-R/mecanicien/mecanicien-all/mecanicien-all.component';
import { MecanicienUpdateComponent } from './modules/application/human-R/mecanicien/mecanicien-update/mecanicien-update.component';
import { MecanicienDetailComponent } from './modules/application/human-R/mecanicien/mecanicien-detail/mecanicien-detail.component';
import { ProfileUpdateComponent } from './modules/application/human-R/profile/profile-update/profile-update.component';
import { ProfileAllComponent } from './modules/application/human-R/profile/profile-all/profile-all.component';
import { PanneAutoAddComponent } from './modules/application/maintenance/panne/panne-auto-add/panne-auto-add.component';
import { TypeEntretientAddComponent } from './modules/application/maintenance/type-entretient/type-entretient-add/type-entretient-add.component';
import { TypeEntretientAllComponent } from './modules/application/maintenance/type-entretient/type-entretient-all/type-entretient-all.component';
import { TypeEntretientUpdateComponent } from './modules/application/maintenance/type-entretient/type-entretient-update/type-entretient-update.component';
import { TypeEntretientDetailComponent } from './modules/application/maintenance/type-entretient/type-entretient-detail/type-entretient-detail.component';
import { GammeEntretienAddComponent } from './modules/application/maintenance/gamme-entretien/gamme-entretien-add/gamme-entretien-add.component';
import { GammeEntretienAllComponent } from './modules/application/maintenance/gamme-entretien/gamme-entretien-all/gamme-entretien-all.component';
import { GammeEntretienUpdateComponent } from './modules/application/maintenance/gamme-entretien/gamme-entretien-update/gamme-entretien-update.component';
import { TypeInterventionDetailComponent } from './modules/application/maintenance/interventions/type-intervention/type-intervention-detail/type-intervention-detail.component';
import { TypeInterventionAddComponent } from './modules/application/maintenance/interventions/type-intervention/type-intervention-add/type-intervention-add.component';
import { TypeInterventionAllComponent } from './modules/application/maintenance/interventions/type-intervention/type-intervention-all/type-intervention-all.component';
import { TypeInterventionUpdateComponent } from './modules/application/maintenance/interventions/type-intervention/type-intervention-update/type-intervention-update.component';
import { FicheInterventionAddComponent } from './modules/application/maintenance/interventions/fiche intervention/fiche-intervention-add/fiche-intervention-add.component';
import { FicheInterventionAllComponent } from './modules/application/maintenance/interventions/fiche intervention/fiche-intervention-all/fiche-intervention-all.component';
import { FicheInterventionUpdateComponent } from './modules/application/maintenance/interventions/fiche intervention/fiche-intervention-update/fiche-intervention-update.component';
import { FicheInterventionDetailComponent } from './modules/application/maintenance/interventions/fiche intervention/fiche-intervention-detail/fiche-intervention-detail.component';
import { InterventionAddComponent } from './modules/application/maintenance/interventions/intervention/intervention-add/intervention-add.component';
import { InterventionUpdateComponent } from './modules/application/maintenance/interventions/intervention/intervention-update/intervention-update.component';
import { InterventionAllComponent } from './modules/application/maintenance/interventions/intervention/intervention-all/intervention-all.component';
import { AssideComponent } from './modules/general/asside/asside.component';
import { AuthGuardService } from 'src/app/_guards/auth.guard';
import { ProgrammeReparationAddComponent } from './modules/application/maintenance/programme-reparation/programme-reparation-add/programme-reparation-add.component';
import { ProgrammeReparationAllComponent } from './modules/application/maintenance/programme-reparation/programme-reparation-all/programme-reparation-all.component';
import { ProgrammeReparationUpdateComponent } from './modules/application/maintenance/programme-reparation/programme-reparation-update/programme-reparation-update.component';
import { ProgrammeReparationDetailsComponent } from './modules/application/maintenance/programme-reparation/programme-reparation-details/programme-reparation-details.component';
//import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { ProgrammesEntretientDetailsComponent } from './modules/application/maintenance/entretients/programmes-entretient/programmes-entretient-details/programmes-entretient-details.component';
import { ProgrammesEntretientAddComponent } from './modules/application/maintenance/entretients/programmes-entretient/programmes-entretient-add/programmes-entretient-add.component';
import { ProgrammesEntretientAllComponent } from './modules/application/maintenance/entretients/programmes-entretient/programmes-entretient-all/programmes-entretient-all.component';
import { ProgrammesEntretientUpdateComponent } from './modules/application/maintenance/entretients/programmes-entretient/programmes-entretient-update/programmes-entretient-update.component';

import { TestDataTableComponent } from './test-data-table/test-data-table.component';
import { GammeEntretientDetailComponent } from './modules/application/maintenance/gamme-entretien/gamme-entretient-detail/gamme-entretient-detail.component';
import { MarkPneuAddComponent } from './modules/general/setting/mark_pneu/mark-pneu-add/mark-pneu-add.component';
import { MarkPneuAllComponent } from './modules/general/setting/mark_pneu/mark-pneu-all/mark-pneu-all.component';
import { MarkPneuDetailComponent } from './modules/general/setting/mark_pneu/mark-pneu-detail/mark-pneu-detail.component';
import { MarkPneuUpdateComponent } from './modules/general/setting/mark_pneu/mark-pneu-update/mark-pneu-update.component';
import { OperationAddComponent } from './modules/application/pneumatique/operation/operation-add/operation-add.component';
import { OperationAllComponent } from './modules/application/pneumatique/operation/operation-all/operation-all.component';
import { OperationUpdateComponent } from './modules/application/pneumatique/operation/operation-update/operation-update.component';
import { OperationDetailsComponent } from './modules/application/pneumatique/operation/operation-details/operation-details.component';
import { PositionAddComponent } from './modules/application/pneumatique/position/position-add/position-add.component';
import { PositionAllComponent } from './modules/application/pneumatique/position/position-all/position-all.component';
import { PositionUpdateComponent } from './modules/application/pneumatique/position/position-update/position-update.component';
import { PositionDetailsComponent } from './modules/application/pneumatique/position/position-details/position-details.component'
import { PositionComponent } from './modules/application/pneumatique/operation/position/position.component';
import { PneuAddComponent } from './modules/application/pneumatique/pneu/pneu-add/pneu-add.component';
import { PneuAllComponent } from './modules/application/pneumatique/pneu/pneu-all/pneu-all.component';
import { PneuDetailsComponent } from './modules/application/pneumatique/pneu/pneu-details/pneu-details.component';
import { PneuUpdateComponent } from './modules/application/pneumatique/pneu/pneu-update/pneu-update.component';
import { InventairesComponent } from './modules/application/pneumatique/operation/inventaires/inventaires.component';
import { MissionDetailComponent } from './modules/application/mouvement/mission/mission-detail/mission-detail.component';
import { MissionListComponent } from './modules/application/mouvement/mission/mission-list/mission-list.component';
import { MissionUpdateCompoment } from './modules/application/mouvement/mission/mission-update/mission-update.component';
import { MissionAddComponent } from './modules/application/mouvement/mission/mission-add/mission-add.component';
import { TypeDocumentAddComponent } from './modules/application/mouvement/type-document/type-document-add/type-document-add.component';
import { TypeDocumentAllComponent } from './modules/application/mouvement/type-document/type-document-all/type-document-all.component';
import { TypeDocumentUpdateComponent } from './modules/application/mouvement/type-document/type-document-update/type-document-update.component';
import { TypeIncidentAddComponent } from './modules/application/mouvement/type-incident/type-incident-add/type-incident-add.component';
import { TypeIncidentAllComponent } from './modules/application/mouvement/type-incident/type-incident-all/type-incident-all.component';
import { TypeIncidentUpdateComponent } from './modules/application/mouvement/type-incident/type-incident-update/type-incident-update.component';
import { TranferAddComponent } from './modules/application/mouvement/mission/tranfer-add/tranfer-add.component';



export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AddComponent,
    ListingComponent,
    DetailComponent,
    UpdateComponent,
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    SigninComponent,
    AutoUpdateComponent,
    AutoAddComponent,
    AutoListComponent,
    AutoDetailComponent,
    MissionAddComponent,
    MissionUpdateCompoment,
    MissionDetailComponent,
    MissionListComponent,
    PanneAutoListComponent,
    PanneAutoDetailComponent,
    AmendeAddComponent,
    AmendeListComponent,
    AmendeDetailComponent,
    AmendeUpdateComponent,
    PanneAutoUpdateComponent,
    PanneDeclarerAddComponent,
    PanneDeclarerUpdateComponent,
    HRAddComponent,
    HRUpdateComponent,
    HRListComponent,
    HRDetailComponent,
    ContratDetailComponent,
    ContratAddComponent,
    ContratUpdateComponent,
    SideBarComponent,
    NavBarComponent,
    FooterComponent,
    TechnicalFactSheetVehicleComponent,

    TypeFicheAddComponent,
    TypeFicheUpdateComponent,
    TypeFicheAllComponent,
    TypeFicheDetailComponent,
    OfficialPaperAllComponent,
    OfficialPaperAUpdateComponent,
    AssuranceAddComponent,
    AssuranceUpdateComponent,
    AssuranceAllComponent,
    AssuranceDetailComponent,
    OfficialPaperAddComponent,
    AddAffectationComponent,
    AllAffectationComponent,
    UpdateAffectationComponent,
    DetailleAffectationComponent,
    AddModelAutoComponent,
    UpdateModelAutoComponent,
    DetailModelAutoComponent,
    ListModelAutoComponent,
    ListMarkAutoComponent,
    AddMarkAutoComponent,
    UpdateMarkAutoComponent,
    DetailMarkAutoComponent,
    DetailCarosserieAutoComponent,
    ListCarosserieAutoComponent,
    AddCarosserieAutoComponent,
    UpdateCarosserieAutoComponent,
    UpdateTypeVehiculeAutoComponent,
    AddTypeVehiculeAutoComponent,
    DetailTypeVehiculeAutoComponent,
    ListTypeVehiculeAutoComponent,
    InterveningTypeAddComponent,
    InterveningTypeListComponent,
    InterveningTypeUpdateComponent,
    InterveningElementAddComponent,
    InterveningElementListComponent,
    InterveningElementDetailComponent,
    InterveningElementUpdateComponent,
    GeneratingDocumentAddComponent,
    GeneratingDocumentListComponent,
    GeneratingDocumentUploadComponent,
    SanctonAddComponent,
    SanctonAllComponent,
    SanctonUpdateComponent,
    SanctonDetailComponent,
    TypeSanctionAddComponent,
    TypeSanctionUpdateComponent,
    TypeContratAddComponent,
    TypeContratUpdateComponent,
    ContratAllComponent,
    ChauffeurAddComponent,
    ChauffeurAllComponent,
    ChauffeurDetailComponent,
    ChauffeurUpdateComponent,
    FormationAddComponent,
    FormationAllComponent,
    FormationDetailComponent,
    FormationUpdateComponent,
    NoteCritiqueAddComponent,
    NoteCritiqueAllComponent,
    NoteCritiqueUpdateComponent,
    NoteCritiqueDetailComponent,
    TypeContratAllComponent,
    TypeSanctionAllComponent,
    AddRoleComponent,
    AllRolesComponent,
    DetailsRoleComponent,
    UpdateRoleComponent,
    CreateUsersComponent,
    UpdateUsersComponent,
    ListUsersComponent,
    ListdetailUserComponent,
    MessageAddComponent,
    MessageAllComponent,
    ThComponent,
    MessageDetailComponent,
    MessageUpdateComponent,
    ConvoyeurAddComponent,
    ConvoyeurAllComponent,
    ConvoyeurUpdateComponent,
    ConvoyeurDetailComponent,
    MecanicienAddComponent,
    MecanicienAllComponent,
    MecanicienUpdateComponent,
    MecanicienDetailComponent,
    ProfileAllComponent,
    ProfileUpdateComponent,
    PanneAutoAddComponent,
    TypeEntretientAddComponent,
    TypeEntretientAllComponent,
    TypeEntretientUpdateComponent,
    TypeEntretientDetailComponent,
    GammeEntretienAddComponent,
    GammeEntretienAllComponent,
    GammeEntretienUpdateComponent,
    TypeInterventionDetailComponent,
    TypeInterventionAddComponent,
    TypeInterventionAllComponent,
    TypeInterventionUpdateComponent,
    FicheInterventionAddComponent,
    FicheInterventionAllComponent,
    FicheInterventionUpdateComponent,
    FicheInterventionDetailComponent,
    InterventionAddComponent,
    InterventionUpdateComponent,
    InterventionAllComponent,
    AssideComponent,
    ProgrammeReparationAddComponent,
    ProgrammeReparationAllComponent,
    ProgrammeReparationUpdateComponent,
    ProgrammeReparationDetailsComponent,
    ProgrammesEntretientDetailsComponent,
    ProgrammesEntretientAddComponent,
    ProgrammesEntretientAllComponent,
    ProgrammesEntretientUpdateComponent,
    TestDataTableComponent,
    GammeEntretientDetailComponent,
    MarkPneuAddComponent,
    MarkPneuAllComponent,
    MarkPneuDetailComponent,
    MarkPneuUpdateComponent,
    OperationAddComponent,
    OperationAllComponent,
    OperationUpdateComponent,
    OperationDetailsComponent,
    PositionAddComponent,
    PositionAllComponent,
    PositionUpdateComponent,
    PositionDetailsComponent,
    PositionComponent,
    PneuAddComponent,
    PneuAllComponent,
    PneuDetailsComponent,
    PneuUpdateComponent,
    InventairesComponent,
    TypeDocumentAddComponent,
    TypeDocumentAllComponent,
    TypeDocumentUpdateComponent,
    TypeIncidentAddComponent,
    TypeIncidentAllComponent,
    TypeIncidentUpdateComponent,
    TranferAddComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    //BlockUIModule.forRoot(),


    //Angular material
    BrowserAnimationsModule,
    MatSliderModule,
    MatExpansionModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    //MatFormField,
    CKEditorModule,

    MatDialogModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    AuthGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: AddTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
