import { HttpClient } from '@angular/common/http';
import * as Routes from 'src/Routes';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MarquePneuService {

  constructor(private http: HttpClient) { }

  add(formData: FormData): Promise<any> {
    return this.http.post<any>(Routes.MARQUE_PNEU, formData).toPromise();
  }

  update(formData: FormData, id): Promise<any> {
    return this.http.post<any>(`${Routes.MARQUE_PNEU}/${id}`, formData).toPromise();
  }

  delete(id: number) {
    return this.http.delete(`${Routes.MARQUE_PNEU}/${id}`).toPromise();

  }

  find(id: number): Promise<any> {
    return this.http.get<any>(`${Routes.MARQUE_PNEU}/${id}`).toPromise();
  }
  
  public gets(page) {
    return this.http.get<any>(`${Routes.MARQUE_PNEU}?page=${page}`)
      .toPromise();
  }

  public get(url) {
    return this.http.get<any>(`${url}`)
      .toPromise();
  }

 
}
