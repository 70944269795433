import { Component, OnInit } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2'
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SanctionService } from 'src/app/_services/RH-service/sanction/sanction.service';
import { AuthService } from 'src/app/_services/auth/auth.service';
declare const $;

@Component({
  selector: 'app-sancton-all',
  templateUrl: './sancton-all.component.html',
  styleUrls: ['./sancton-all.component.scss']
})
export class SanctonAllComponent implements OnInit {


  sanctions: any;
  loading: boolean = true;
  @BlockUI() blockUI: NgBlockUI;

  //SweetAlert Text
  areYouSure = '';
  warning = ''
  yes = '';
  no = '';
  deleted = '';
  deletedMessage = '';
  cancelled = '';
  cancelledMessage = '';
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  page=1;
  data:any;
  driverSelectId:any;
  idDriver;

  constructor(
    private SanctionService: SanctionService,
    private authService: AuthService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router) {

      this.translate.get(
        ['SweetAlert.AreYouSure', 'SweetAlert.Warning', 'SweetAlert.Yes', 'SweetAlert.No', 'SweetAlert.Deleted',
        'SweetAlert.DeletedMessage', 'SweetAlert.Cancelled', 'SweetAlert.CancelledMessage'],
        { data: "cette Sanction" })
        .subscribe(val => {
          this.areYouSure = val['SweetAlert.AreYouSure'];
          this.warning = val['SweetAlert.Warning'];
          this.yes = val['SweetAlert.Yes'];
          this.no = val['SweetAlert.No'];
          this.deleted = val['SweetAlert.Deleted'];
          this.deletedMessage = val['SweetAlert.DeletedMessage'];
          this.cancelled = val['SweetAlert.Cancelled'];
          this.cancelledMessage = val['SweetAlert.CancelledMessage'];
        });
   }
  ngOnInit() {
    console.log('theo')
    this.getSanction(this.page);
    const permissionSuffix = 'sanctions';
		this.canCreate = this.authService.hasPermission(`${permissionSuffix}-create`);
		this.canUpdate = this.authService.hasPermission(`${permissionSuffix}-update`);
		this.canDelete = this.authService.hasPermission(`${permissionSuffix}-delete`);
  }

  getSanction(page) {
    this.loading = true;
    this.SanctionService.gets(page).then(
      response => {
        this.sanctions = response;
        console.log(this.sanctions);
        $(function () {
          //$("#example1").DataTable().fnDestroy()
          $("#example1").DataTable({
            "responsive": true, "lengthChange": false, "autoWidth": false,
            "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
          }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        });
      }
    ).catch(
      error => {
        this.notifService.danger(error.error.message)
      }
    ).finally(
      () => {
        this.loading = false;
      }
    )
  }


  getPage(url) {
    if (url) {
      this.SanctionService.get(url).then((res) => {
        this.sanctions = res;
        console.log(this.sanctions)
      }).catch(
        error => {
          this.notifService.danger(error.error.message)
        }
      ).finally(
        () => {
          this.loading = false;
        }
      )
    }
  }

  editSanction(id) {
    console.log('bonjour');
    this.router.navigate(['/sanction/update/' + id]);
  }
  detailleSanction(id) {
    console.log('bonjourhaut');
    this.router.navigate(['/sanction/detail/' + id]);
  }

  deleteSanction(id) {
    Swal.fire({
      title: this.areYouSure,
      text: this.warning,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.yes,
      cancelButtonText: this.no
    }).then((result) => {
      if (result.value) {
        this.blockUI.start('Loading...');
        this.SanctionService.delete(id).then(
          data => {
            this.blockUI.stop();
            Swal.fire(
              this.deleted,
              this.deletedMessage,
              'success'
            )
            this.getSanction(1);

          }
        ).catch(
          error => {
            console.log(error)
            this.blockUI.stop();
            this.translate.get('Sanction.'+error.error.code)
            .subscribe(val => this.notifService.danger(val));
          }
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.cancelledMessage,
          'error'
        )
      }
    })
  }
}
