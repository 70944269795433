<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'Operation.Operation' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">{{ "FileAriane.Home" | translate }}</a></li>
                    <li class="breadcrumb-item active"><a [routerLink]="['/operation/all']">{{ 'Operation.Operation' | translate }}</a>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="container">
    <div class="container-fluid">
        <div class="row">

            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header" style="background-color: #17a2b8;">
                        <h3 class="card-title">Informations Detaillées sur l'opération</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                                    <td>{{ "Operation.VehiculeLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{operations?.immatriculation}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                                    <td>{{ "Operation.PneuLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{operations?.pneu}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                                    <td>{{ "Operation.EtatActuel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{operations?.etat_actuel}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                                    <td>{{ "Operation.DurreeDeVie" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{operations?.duree_de_vie}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                                    <td>{{ "Operation.ParcoursRestant" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{operations?.parcour_restant}}<i>Km</i></td>
                                </tr>                               
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header" style="background-color: #17a2b8;">
                        <h3 class="card-title">Informations Detaillées sur l'operation</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                                    <td>{{ "Operation.MonterLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{operations?.monter == 'true' ? 'monter' : 'null'}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                                    <td>{{ "Operation.DemonterLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{operations?.demonter == 'true' ? 'demonter' : 'null'}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                                    <td>{{ "Operation.DateLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{operations?.date}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                                    <td>{{ "Operation.DescriptionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{operations?.description}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
