import { Component, OnInit } from '@angular/core';
import { AutoService } from '../../../../../_services/parc-service/auto-service/auto-service';
//import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Vehicle } from 'src/app/_models/vihicles.model';
import Swal from 'sweetalert2';
//import { AuthService } from 'src/app/_services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { ParamService } from '../../../../../_services/parc-service/param-service/param-service';
declare const $;

@Component({
  selector: 'app-auto-list',
  templateUrl: './auto-list.component.html',
  styleUrls: ['./auto-list.component.scss']
})
export class AutoListComponent implements OnInit {
  vehicles: any;
  loading: boolean = true;
  assur: any;
  CompletedVehicules: any;
  forms: any;
  detail = false;

  @BlockUI() blockUI: NgBlockUI;
  //SweetAlert Text

  areYouSure = '';
  warning = 'Voulez-vous vraiment supprimer?'
  yes = 'Oui';
  no = 'Non';
  deleted = 'Suppression';
  deletedMessage = 'Opération  effectuée avec succes';
  cancelled = 'Annulation';
  cancelledMessage = 'Annulation effectuée  avec succes';
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  constructor(
    private autoservice: AutoService,
    // private notifService: NotifService,
    private translate: TranslateService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private paramService: ParamService
  ) { }

  ngOnInit(): void {
    this.getVehicles();
    const permissionSuffix = 'vehicles';
    this.canCreate = this.authService.hasPermission(`${permissionSuffix}-create`);
    this.canUpdate = this.authService.hasPermission(`${permissionSuffix}-update`);
    this.canDelete = this.authService.hasPermission(`${permissionSuffix}-delete`);
  }
  public getVehicles() {

    //this.isLoading = true;
    this.autoservice.all()
      .then(response => {
        console.log(response);
        this.vehicles = response;
        console.log("chargement1 correct");
        //this.notifService.success('Chargement des documents éffectué')
        $(function () {
          $("#example1").DataTable().fnDestroy()
          $("#example1").DataTable({
            "responsive": true, "lengthChange": false, "autoWidth": false,
            "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
          }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        });
      })
      .catch(error => {
        console.log(error);
        console.log("erreur chargement");
        // this.notifService.danger('Echec une érreur inconnue c\'est produite');
      })
      .finally(() => this.loading = false);

  }

  editVehicle(vehicle: any) {
    this.router.navigate(['/vehicles/update/' + vehicle.id])
  }

  detailsVehicle(vehicle: any) {
    this.router.navigate(['/vehicles/detail/' + vehicle.id])
  }

  addPanne(vehicle) {
    this.router.navigate(['maintenance/panne/' + vehicle.id + '/add']);
  }

  deleteVehicle(vehicle_id: number) {
    Swal.fire({
      title: this.areYouSure,
      text: this.warning,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.yes,
      cancelButtonText: this.no
    }).then((result) => {
      if (result.value) {
        this.blockUI.start('Loading...');
        this.autoservice.delete(vehicle_id).then(
          data => {
            this.blockUI.stop();
            Swal.fire(
              this.deleted,
              this.deletedMessage,
              'success'
            )
            this.getVehicles();
          }
        ).catch(
          error => {
            console.log(error)
            this.blockUI.stop();
            this.toastr.error('L Operation a echouée');
          }
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.cancelledMessage,
          'error'
        )
      }
    })
  }

 


}
