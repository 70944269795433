import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';
import { MarquePneuService } from 'src/app/_services/pneumatique/marque_pneu/marque-pneu.service';

@Component({
  selector: 'app-mark-pneu-update',
  templateUrl: './mark-pneu-update.component.html',
  styleUrls: ['./mark-pneu-update.component.scss']
})
export class MarkPneuUpdateComponent implements OnInit {

  dataForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  user

  markPneus: any;

  constructor(
    private MarquePneuService: MarquePneuService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.user = this.authService.getUser();

    const marque_id = +this.route.snapshot.paramMap.get("id");
    console.log(marque_id);
    this.MarquePneuService.find(marque_id).then(
      data => {
        this.markPneus = data;
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('markPneus.'+error.error.code)
        .subscribe(val => this.notifService.danger(val));
      }
    )

  }

  initForm(withVal = false) {
    if(withVal){
      this.dataForm = this.formBuilder.group(
        {
          name: [this.markPneus.name, [Validators.required]],
          description: [this.markPneus.description],
        }
      );
      }
    else{
      this.dataForm = this.formBuilder.group(
        {
          name: ["", [Validators.required]],
          description: [""],
        }
      );
    }

  }
  
  get form() {
    return this.dataForm.controls;
  }
  
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.dataForm.invalid) {
      this.translate.get('MarquePneu.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('name', ''+this.form.name.value);
    formData.append('description', ''+this.form.description.value);
    formData.append('create_id', '' + this.user.id);

      console.log(formData);
      this.MarquePneuService.update(formData, this.markPneus.id)
      .then(resp => {
        this.translate.get('MarquePneu.SubmitSuccess')
        .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.dataForm.reset();
        this.router.navigate(['/mark-pneu/all'])

      })
      .catch(err => {
        console.log(err)
        this.translate.get('MarquePneu.SubmitErrorMarquePneu')
        .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }

}
