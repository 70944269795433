import { Component, OnInit } from '@angular/core';
import { AutoService } from '../../../../../_services/parc-service/auto-service/auto-service';
//import { NotifService } from 'src/app/_services/notif.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Vehicle } from 'src/app/_models/vihicles.model';

@Component({
  selector: 'app-auto-detail',
  templateUrl: './auto-detail.component.html',
  styleUrls: ['./auto-detail.component.scss']
})
export class AutoDetailComponent implements OnInit {
  vehicle_id: any=null;
  vehicle:Vehicle = new Vehicle();
  mark;
  model;
  type;
  body;
  
  
  constructor(private autoservice:AutoService,
    // private notifService: NotifService,
     private formBuilder: FormBuilder,
    // private translate: TranslateService,
    private route: ActivatedRoute,
     private router: Router) { }   
   
  ngOnInit(): void {
    this.vehicle_id = +this.route.snapshot.paramMap.get("id");
    console.log(this.vehicle_id);
    this.autoservice.find(this.vehicle_id).then(
      data => {
        this.vehicle = new Vehicle(data);  
        console.log(this.vehicle.vehicle_brand_id);
        this.getMark(this.vehicle.vehicle_brand_id);
        this.getModel(this.vehicle.vehicle_model_id);
        this.getType(this.vehicle.vehicle_type_id);
        this.getBody(this.vehicle.vehicle_body_id);

      }
    ).catch(
      error => {
        //this.translate.get('Contact.' + error.error.code)
       //   .subscribe(val => this.notifService.danger(val));
       alert("Objet introuvable")
        this.router.navigate(['/vehicles/list'])
      }
    )    

  }     

  getMark(id){
    this.autoservice.getFormes(id).then(
      res => {
        this.mark = res;
        console.log(this.mark);
        
      }
    )
  }
  
  getModel(id){
    this.autoservice.getFormes(id).then(
      res => {
        this.model = res;
        console.log(this.model);
        
      }
    )
  }

  getType(id){
    this.autoservice.getFormes(id).then(
      res => {
        this.type = res;
        console.log(this.type);
        
      }
    )
  }

  getBody(id){
    this.autoservice.getFormes(id).then(
      res => {
        this.body = res;
        console.log(this.body);
        
      }
    )
  }

  affichageFiche() {
    console.log("affichage entammé")
    this.router.navigate(['/vehicles/detail/technical_fact_sheet/' + this.vehicle_id ])
  }

}
