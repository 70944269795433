import { Component, OnInit } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2'
import { DocService } from '../../../../../../_services/parc-service/doc-service/doc-service';
//import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/_services/auth/auth.service';
declare const $;

@Component({
  selector: 'app-assurance-all',
  templateUrl: './assurance-all.component.html',
  styleUrls: ['./assurance-all.component.scss']
})
export class AssuranceAllComponent implements OnInit {

  assurances: any;
  loading: boolean = true;
  assur : any;
  detail=false;

  @BlockUI() blockUI: NgBlockUI;

  //SweetAlert Text
  areYouSure = ' etes vous sure';
  warning = 'warning'
  yes = 'yes';
  no = 'no';
  deleted = 'deleted';
  deletedMessage = 'deletedMessage';
  cancelled = 'cancelled';
  cancelledMessage = 'cancelledMessage';
  canCreate = false;
  canUpdate = false;
  canDelete = false;

  constructor(
    private docService: DocService,
    private authService: AuthService,
    //private notifService: NotifService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.translate.get(
      ['SweetAlert.AreYouSure', 'SweetAlert.Warning', 'SweetAlert.Yes', 'SweetAlert.No', 'SweetAlert.Deleted',
        'SweetAlert.DeletedMessage', 'SweetAlert.Cancelled', 'SweetAlert.CancelledMessage'],
      { data: ' cet Assurance' })
      .subscribe(val => {
        this.areYouSure = val['SweetAlert.AreYouSure'];
        this.warning = val['SweetAlert.Warning'];
        this.yes = val['SweetAlert.Yes'];
        this.no = val['SweetAlert.No'];
        this.deleted = val['SweetAlert.Deleted'];
        this.deletedMessage = val['SweetAlert.DeletedMessagePro'];
        this.cancelled = val['SweetAlert.Cancelled'];
        this.cancelledMessage = val['SweetAlert.CancelledMessage'];
      });
   }

  ngOnInit(): void {
    this.getAssurance();
    const permissionSuffix = 'insurances';
    this.canCreate = this.authService.hasPermission(`${permissionSuffix}-create`);
		this.canUpdate = this.authService.hasPermission(`${permissionSuffix}-update`);
		this.canDelete = this.authService.hasPermission(`${permissionSuffix}-delete`);
  }

  getAssurance(){
    this.docService.allAssurance().then(resp =>{
      this.assurances = resp ;
      console.log(this.assurances)
      $(function () {
        $("#example1").DataTable().fnDestroy()
        $("#example1").DataTable({
          "responsive": true,
          "autoWidth": false,
        });
      });
    }).catch(
      error => {
        this.toastr.error('Aucune assurance disponible');
      }
    ).finally(
      () => {
        this.loading = false;
      }
    )
  }


  getPage(url){
    this.docService.getPage(url).then(resp =>{
      this.assurances = resp ;
    }).catch(
      error => {
        this.toastr.warning('Page Terminer');
      }
    ).finally(
      () => {
        this.loading = false;
      }
    )
  }

  editAssurance(id) {
    this.router.navigate(['assurance_update/'+id])
  }

  detailsAssurance(id) {
    this.router.navigate(['assurance_detail/'+id])
    // this.assur =assurance;
    this.detail=true;
    console.log("detail == " + this.detail )
  }

  deleteAssurance(id) {
    Swal.fire({
      title: this.areYouSure,
      text: this.warning,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.yes,
      cancelButtonText: this.no
    }).then((result) => {
      if (result.value) {
        this.blockUI.start('Loading...');
        this.docService.deleteAssurance(id).then(
          data => {
            this.blockUI.stop();
            Swal.fire(
              this.deleted,
              this.deletedMessage,
              'success'
            )
            this.getAssurance();
          }
        ).catch(
          error => {
            console.log(error)
            this.blockUI.stop();
            this.toastr.error('L Operation a echoue');
          }
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.cancelledMessage,
          'error'
        )
      }
    })
  }


  cancel(){
    this.detail=false;
  }
}
