import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ParamService } from 'src/app/_services/parc-service/param-service/param-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-update-type-vehicule-auto',
  templateUrl: './update-type-vehicule-auto.component.html',
  styleUrls: ['./update-type-vehicule-auto.component.scss']
})
export class UpdateTypeVehiculeAutoComponent implements OnInit {

  
  typevehicule: any;
  modelForm: FormGroup;
  typemarks: any;
  isSubmitted: boolean = false;
  isLoading = false;
  
  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private paramService: ParamService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private notifService: NotifService
  ) { }
  
  async ngOnInit() {
    const mod_id = +this.route.snapshot.paramMap.get("id");
    this.paramService.findForm(mod_id).then(
      data => {
        this.typevehicule = data;
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('typevehicule.'+error.code)
        .subscribe();
      }
    )
    this.initForm();
  }

  initForm(withmodel = false) {
    if(withmodel) {
      this.modelForm = this.formBuilder.group({
        name: [this.typevehicule.name, [Validators.required]],
        description: [this.typevehicule.description]
        
      });
    }else {
      this.modelForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        description: [''],
      }); 
    }
  }

  get form() {
    return this.modelForm.controls;
  }

  onSubmitTypeVehicule() {
    this.isSubmitted = true;
    this.isLoading = false;
    if (this.modelForm.invalid) {
      this.toastrService.error('ERREUR', 'Les champs en rouge sont invalides!');
      return;
    }
    this.isLoading = true;
    const formData = new FormData();
    formData.append('name', '' + this.form.name.value);
    formData.append('code', '' + 'TYPE_VEHICULE');
    formData.append('description', '' + this.form.description.value);
    this.paramService.updateForm(formData, this.typevehicule.id)
      .then(resp => {
        this.translate.get('TypeVehicule.UpdatingSuccess')
        .subscribe(val => this.notifService.success(val));
        this.router.navigate(['setting/forms/type-vehicule/list'])

      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => this.isLoading = false);
  }

}

