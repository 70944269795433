import { PneuService } from './../../../../../_services/pneumatique/Pneu/pneu.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';
import { OperationService } from 'src/app/_services/pneumatique/Operation/operation.service';
import { PositionService } from 'src/app/_services/pneumatique/Position/position.service';
import { ParamService } from 'src/app/_services/parc-service/param-service/param-service';

@Component({
  selector: 'app-operation-update',
  templateUrl: './operation-update.component.html',
  styleUrls: ['./operation-update.component.scss']
})
export class OperationUpdateComponent implements OnInit {

  dataForm: FormGroup;
  isSubmitted: boolean = false;
  isLoading = false;
  isError = false;
  isSuccess = false;
  vehicules: any;
  pneus: any;
  positions: any;
  user;

  pos: any = null;
  indice_line: number;
  indice_colone: number;
  isSelected: boolean = false;
  selectedRow: number;
  selectedColumn: number;
  actived: boolean = false;
  rowLength: number = 10;
  colLength: number = 5;
  rows: any[] = [];
  columns: any[] = [];
  dataRow: any[] = [];
  dataCol: any[] = [];
  model: any;
  name: string;
  event;
  monter: boolean =true;
  demonter: boolean =true;
  position_pneu_id: number;
  vehicule_id: number;
  operations: any;
  

  constructor(
    private OperationService: OperationService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private positionService: PositionService,
    private paramService: ParamService,
    private pneuService: PneuService,
  ) { }

  ngOnInit(): void {
    this.getPneu();
    this.getVehicule();
    this.initForm();
    this.user = this.authService.getUser();

    const operation_id = +this.route.snapshot.paramMap.get("id");
    console.log(operation_id);

    this.OperationService.find(operation_id).then(
      data => {
        this.operations = data;
        this.vehicule_id = this.operations.vehicule_id;
        this.position_pneu_id = this.operations.position_pneu_id;
        console.log(this.operations);
        this.initForm(true);
        this.updatePositionModel();
      }
    ).catch(
      error => {
        this.translate.get('operations.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
      }
    )
  }

  initForm(withFiche = false) {
    if (withFiche) {
      this.dataForm = this.formBuilder.group(
        {
          date: [this.operations.date, [Validators.required]],
          monter: [this.operations.monter],
          demonter: [this.operations.demonter],
          description: [this.operations.description],
          pneu_id: [this.operations.pneu_id, [Validators.required]],
          vehicule_id: [this.operations.vehicule_id, [Validators.required]],
          index_km: [this.operations.index_km, [Validators.required]],
        });
    }
    else {
      this.dataForm = this.formBuilder.group({
        date: ['', [Validators.required]],
        monter: [''],
        demonter: [''],
        description: [''],
        index_km: [''],
        pneu_id: ['', [Validators.required]],
        vehicule_id: ['', [Validators.required]],
      });
    }

  }

  get form() {
    return this.dataForm.controls;
  }

  getPneu() {
    this.OperationService.getPneu().then(res => {
      this.pneus = res;
      console.log(this.pneus)
    }).catch(
      error => {
        this.notifService.danger(error.message)
      })
  }

  getVehicule() {
    this.OperationService.getVehicule().then((res) => {
      this.vehicules = res;
      console.log(this.vehicules)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  public updatePositionModel() {

   this.positionService.getPosModel(this.vehicule_id).then(
     data => {
       this.positions = data;
       this.model = data[0]
       console.log(this.positions);
       this.maxRow();

       for (var i = 0; i < this.rowLength; i++) {
         this.rows[i] = i;
       }

       for (var j = 0; j < this.colLength; j++) {
         this.columns[j] = j;
       }
     }
   ).catch(
     error => {
       this.translate.get('Operation.' + error.error.code)
         .subscribe();
     }
   )
  }

  public PositionModel(event) {
    this.vehicule_id = event.target.value;
    console.log(this.vehicule_id);

   this.positionService.getPosModel(this.vehicule_id).then(
     data => {
       this.positions = data;
       this.model = data[0]
       console.log(this.positions);
       this.maxRow();

       for (var i = 0; i < this.rowLength; i++) {
         this.rows[i] = i;
       }

       for (var j = 0; j < this.colLength; j++) {
         this.columns[j] = j;
       }
     }
   ).catch(
     error => {
       this.translate.get('Operation.' + error.error.code)
         .subscribe();
     }
   )
  }

  matchedBlack(i, j): boolean { 
    if((this.operations.ligne === i) && (this.operations.colone === j)){
      return true;
    }
  } 

  matched(i, j): boolean { 
   return this.positions.some(e => (e.indice_ligne === i && e.indice_colone === j));
  } 

  onClick(row, col) {
   
    for(let i=0;i< this.positions.length ;i++){  //How to properly iterate here!!
      if((this.positions[i].indice_ligne === row) && (this.positions[i].indice_colone === col)){
       this.position_pneu_id = this.positions[i].id ;
       console.log(this.position_pneu_id);
      }
    }
  }   

  onSubmit() {
   console.log('on submit');
   this.isSubmitted = true;
   this.isError = false;
   this.isSuccess = false;
   this.isLoading = false;

    if(this.dataForm.invalid) {
     this.translate.get('Operation.SubmitError')
       .subscribe(val => this.notifService.danger(val));
      return;
   }

   this.isLoading = true;
   const formData = new FormData();
   formData.append('pneu_id', '' + this.form.pneu_id.value);
   formData.append('vehicule_id', '' + this.form.vehicule_id.value);
   formData.append('position_pneu_id', '' + this.position_pneu_id);
   formData.append('date', '' + this.form.date.value);
   formData.append('index_km', '' + this.form.index_km.value);
   formData.append('monter', '' + this.form.monter.value);
   formData.append('demonter', '' + this.form.demonter.value);
   formData.append('description', '' + this.form.description.value);
   formData.append('index_km', '' + this.form.index_km.value);
   formData.append('create_id', '' + this.user.id);

   console.log(formData);
   this.OperationService.update(formData, this.operations.id)
   .then(resp => {
       this.translate.get('Operation.SubmitSuccess')
         .subscribe(val => this.notifService.success(val));
       this.isSubmitted = false;
       this.dataForm.reset();
       this.monter = false;
       this.demonter = false;
       this.router.navigate(['/operation/all/'])
     })
     .catch(err => {
       console.log(err)
       this.translate.get('Operation.SubmitErrorOperation')
         .subscribe(val => this.notifService.danger(val));
     })
     .finally(() => this.isLoading = false);
  }

  position(name:string){
   if(name=='monter'){
     this.monter=true;
   }else if(name=='demonter'){
     this.demonter=true;
   } 
  } 

  public maxRow(){
   let max = 0;
   for(let i=0;i< this.positions.length ;i++){  //How to properly iterate here!!
     if(this.positions[i].indice_ligne > max){
       max = this.positions[i].indice_ligne;
     }
   }
   this.rowLength = max+1;
  }

}
