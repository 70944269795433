import { MessageService } from 'src/app/_services/RH-service/message/message.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-message-add',
  templateUrl: './message-add.component.html',
  styleUrls: ['./message-add.component.scss']
})
export class MessageAddComponent implements OnInit {

  MessageForm: FormGroup;
  user: any;
  isSubmitted = false;
  isError = false;
  isLoading = false;
  isSuccess = false;
  users;
  isMaterial: boolean = false;
  file: File= null; 


  constructor(

    private authService: AuthService,
    private MessageService: MessageService,
    private notifService: NotifService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private userDialog:MatDialog


  ) {}

  ngOnInit(): void {

    this.getUsers();
    this.user = this.authService.getUser();
    this.isMaterial = '/message/add' != location.pathname;



    this.MessageForm = this.formBuilder.group({
      user_id_2: ['', [Validators.required]],
      suject: ['', [Validators.required]],
      content: ['', [Validators.required]],
      file: [''],
    });

  }
  getUsers() {
    this.MessageService.getUser().then((res) => {
      this.users = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  onSelectfile(event) {
    this.file = event.target.files[0];
  }
  get form() {
    return this.MessageForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    console.log('on submit1');

    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    let pipe = new DatePipe('en-US');
    let date = new Date();
    let currentDate = pipe.transform(date, 'yyyy-MM-dd');

     if (this.MessageForm.invalid) {
       console.log(this.form.user_id_2.value);

       this.translate.get('veillez bien remplir')
         .subscribe(val => this.notifService.danger(val));
       return;
     }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('user_id_1', this.user.id);
    formData.append('user_id_2', '' + this.form.user_id_2.value);
    formData.append('suject', '' + this.form.suject.value);
    formData.append('content', '' + this.form.content.value);
    if (this.file != null)
      formData.append('file', this.file, this.file.name);


    console.log(formData);
    this.MessageService.post(formData)
      .then(resp => {
        this.translate.get('Message.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.MessageForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('Message.SubmitErrorMessage')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);


    }
    onClose(){
     this.userDialog.closeAll();
   }

}
