import { Component, OnInit } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2'
import { TypeInterventionService } from 'src/app/_services/maintenance/interventions/type-intervention.service';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';

declare const $;


@Component({
  selector: 'app-type-intervention-all',
  templateUrl: './type-intervention-all.component.html',
  styleUrls: ['./type-intervention-all.component.scss']
})
export class TypeInterventionAllComponent implements OnInit {

  type_interventions:any;
  loading: boolean = true;
  @BlockUI() blockUI: NgBlockUI;

  //SweetAlert Text
  areYouSure = '';
  warning = ''
  yes = '';
  no = '';
  deleted = '';
  deletedMessage = '';
  cancelled = '';
  cancelledMessage = '';
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  page=1;
  data:any;
  driverSelectId:any;
  idDriver;

  constructor(
    private TypeInterventionService: TypeInterventionService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService
  ) {
        this.translate.get(
          ['SweetAlert.AreYouSure', 'SweetAlert.Warning', 'SweetAlert.Yes', 'SweetAlert.No', 'SweetAlert.Deleted',
          'SweetAlert.DeletedMessage', 'SweetAlert.Cancelled', 'SweetAlert.CancelledMessage'],
          { data: "ce type d'intervention " })
          .subscribe(val => {
            this.areYouSure = val['SweetAlert.AreYouSure'];
            this.warning = val['SweetAlert.Warning'];
            this.yes = val['SweetAlert.Yes'];
            this.no = val['SweetAlert.No'];
            this.deleted = val['SweetAlert.Deleted'];
            this.deletedMessage = val['SweetAlert.DeletedMessage'];
            this.cancelled = val['SweetAlert.Cancelled'];
            this.cancelledMessage = val['SweetAlert.CancelledMessage'];
          });
   }

   ngOnInit() {
    this.getType(this.page);
    const permissionSuffix = 'type_interventions';
    this.canCreate = this.authService.hasPermission(`${permissionSuffix}-create`);
		this.canUpdate = this.authService.hasPermission(`${permissionSuffix}-update`);
		this.canDelete = this.authService.hasPermission(`${permissionSuffix}-delete`);
  }

  getType(page) {
    this.loading = true;
    this.TypeInterventionService.gets(page).then(
      response => {
        this.type_interventions = response;
        console.log(this.type_interventions);
        $(function () {
          $("#example1").DataTable().fnDestroy()
					$("#example1").DataTable({
					  "responsive": true,
					  "autoWidth": false,
					});
				});
      }
    ).catch(
      error => {
        this.notifService.danger(error.error.message)
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }


  getPage(url) {
    if (url) {
      this.TypeInterventionService.get(url).then((res) => {
        this.type_interventions = res;
        console.log(this.type_interventions);
      }).catch(
        error => {
          this.notifService.danger(error.error.message);
        }
      ).finally(
        () => {
          this.loading = false;
        }
      )
    }
  }
  getSubString(st){
    if(st.length>20){
      st = st.substring(0,20)+' ...';
    }
    return st;
  }
  details(id:any){
    Swal.fire({
      title: '<strong>'+id.nom+'</strong>',
      icon: 'info',
      html:
        id.description,
      imageAlt: 'Custom image',
    })
  }
  editTypeIntervention(id) {
    console.log('bonjour');
    this.router.navigate(['/intervention-type/update/' + id]);

  }
  deleteTypeIntervention(id) {
    Swal.fire({
      title: this.areYouSure,
      text: this.warning,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.yes,
      cancelButtonText: this.no
    }).then((result) => {
      if (result.value) {
        this.blockUI.start('Loading...');
        this.TypeInterventionService.delete(id).then(
          data => {
            this.blockUI.stop();
            Swal.fire(
              this.deleted,
              this.deletedMessage,
              'success'
            );
            this.getType(1);

          }
        ).catch(
          error => {
            console.log(error);
            this.blockUI.stop();
            this.translate.get('TypeIntervention.' + error.error.code)
            .subscribe(val => this.notifService.danger(val));
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.cancelledMessage,
          'error'
        );
      }
    });
  }

}
