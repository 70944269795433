<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>{{ "Forms.Carosserie.Carosseries" | translate }}</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <!--      <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item">Forms</li>
            <li class="breadcrumb-item active">Mise à jour</li> -->

          <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
          <li class="breadcrumb-item "><a [routerLink]="['setting/forms/carosserie/list']">{{
              "Forms.Carosserie.Carosseries" | translate }}</a></li>
          <li class="breadcrumb-item active">{{ "FileAriane.Update" | translate }}</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Ajouter un modèle -->
<div class="container-fluid">
  <!-- Main content -->
  <section class="container">
    <div class="card card-primary">
      <div class="card-header" style="background-color: #17a2b8;">
        <h1 class="card-title">{{ "Forms.Carosserie.UpdCarosserie" | translate }}</h1>
      </div>
      <!-- /.card -->
      <div class="row">
        
        <!-- cette div gere les modèles -->
        <div class="card-body">
          <div class="row">
            <!-- left column -->
            <div class="col-md-12">
              <!-- general form elements -->
              <div class="card card-primary">
                <!-- form start -->
                <form [formGroup]="modelForm" (submit)="onSubmitCarosserie()">
                  <div class="card-body">
                    <div class="form-group">
                      <label for="exampleName1">{{ "Forms.Carosserie.Nom" | translate }} <span style="color: red;">*</span> </label>
                      <input type="text" formControlName="name" value=""
                        class="form-control {{ isSubmitted && form.name.errors ? 'is-invalid' : '' }}" id="exampleName1"
                        placeholder="{{ 'Forms.Carosserie.NomLabel' | translate }}" />
                    </div>
                    <div class="form-group">
                      <label>{{ "Forms.Carosserie.Description" | translate }}</label>
                      <textarea value=""
                        class="form-control {{ isSubmitted && form.description.errors ? 'is-invalid' : '' }}"
                        formControlName="description" rows="3" placeholder="{{ 'Forms.Carosserie.DescriptionLabel' | translate }}"></textarea>
                    </div>
                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                      <p *ngIf="!isLoading">{{ "General.update" | translate }}</p>
                      <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                </form>
              </div>
              <!-- /.card -->
            </div>
            <!--/.col (left) -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12"></div>
      </div>
      <!-- /.card-body -->
    </div>
  </section>
  <!-- /.content -->
</div>