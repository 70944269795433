<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'Mission.Missions' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">{{ "FileAriane.Home" | translate }}</a></li>
                    <li class="breadcrumb-item "><a [routerLink]="['/mission/all']">{{
                            "Mission.Missions" | translate }}</a></li>
                    <li class="breadcrumb-item">Détail</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="container">

    <div class="row">
        <div class="col-md-2 btn" (click)="open('active')">
            <div class="info-box bg-info">
                <span class="info-box-icon"><i class="far fa-bookmark"></i></span>

                <div class="info-box-content">

                    <span class="progress-description">

                        Default
                    </span>
                </div>
                <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        </div>
        <div class="col-md-2 btn" (click)="open('document')">
            <div class="info-box bg-info">
                <span class="info-box-icon"><i class="far fa-bookmark"></i></span>

                <div class="info-box-content">

                    <span class="progress-description">

                        Documents
                    </span>
                </div>
                <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        </div>

        <div class="col-md-2 btn" (click)="open('incidents')">
            <div class="info-box bg-info">
                <span class="info-box-icon"><i class="far fa-bookmark"></i></span>

                <div class="info-box-content">

                    <span class="progress-description">

                        Incidents
                    </span>
                </div>
                <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        </div>
        <div class="col-md-2 btn" (click)="open('receptions')">
            <div class="info-box bg-info">
                <span class="info-box-icon"><i class="far fa-bookmark"></i></span>

                <div class="info-box-content">

                    <span class="progress-description">

                        Receptions
                    </span>
                </div>
                <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        </div>
        <div class="col-md-2 btn" (click)="open('pannes')">
            <div class="info-box bg-info">
                <span class="info-box-icon"><i class="far fa-bookmark"></i></span>

                <div class="info-box-content">

                    <span class="progress-description">

                        Pannes
                    </span>
                </div>
                <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        </div>
        <div class="col-md-2 btn" (click)="open('remarques')">
            <div class="info-box bg-info">
                <span class="info-box-icon"><i class="far fa-bookmark"></i></span>

                <div class="info-box-content">

                    <span class="progress-description">

                        Remarques
                    </span>
                </div>
                <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        </div>
        <div class="col-md-2 btn" (click)="open('transfers')">
            <div class="info-box bg-info">
                <span class="info-box-icon"><i class="far fa-bookmark"></i></span>

                <div class="info-box-content">

                    <span class="progress-description">

                        Transfers
                    </span>
                </div>
                <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        </div>
        <div class="col-md-2 btn" (click)="open('vehicule')">
            <div class="info-box bg-info">
                <span class="info-box-icon"><i class="far fa-bookmark"></i></span>

                <div class="info-box-content">

                    <span class="progress-description">

                        Vehicules
                    </span>
                </div>
                <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        </div>
        <div class="col-md-2 btn" (click)="open('convoyeur')">
            <div class="info-box bg-info">
                <span class="info-box-icon"><i class="far fa-bookmark"></i></span>

                <div class="info-box-content">

                    <span class="progress-description">

                        Convoyeurs
                    </span>
                </div>
                <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        </div>
        <div class="col-md-2 btn" (click)="open('chauffeur')">
            <div class="info-box bg-info">
                <span class="info-box-icon"><i class="far fa-bookmark"></i></span>

                <div class="info-box-content">

                    <span class="progress-description">

                        Chauffeurs
                    </span>
                </div>
                <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
        </div>



    </div>
    <div class="container-fluid" *ngIf="active">
        <div class="row">

            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Informations Detaillées sur une mission</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.FournisseurMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.element_intervenant_id2.name}}</td>

                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.ClientMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.element_intervenant_id1.name }}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.VehiculMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.vehicule_id.vehicle_registration }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.ChaufMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.chauffeur_id.user_id.name }}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.ConvoyeurMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.convoyeur_id.user_id.name }}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.DestinationMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.destination }}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.LieuDepartMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.lieu_depart }}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.DateDepartMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.date_depart }}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.IndexDepartMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.index_depart }}</td>
                                </tr>

                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.ParcourTheoriqueMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.parcours_theorique }}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Informations Detaillées sur une mission</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.HeureDepartMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.heure_depart}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.HeureRetourMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.heure_retour}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.DureeMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.duree}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.IndexRetourMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.index_retour}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.ParcoursReelMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.parcours_reel}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.StatutMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.status}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.TransfererMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.transferer}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.HeureArriveSiteMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.heure_arrive_site}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Mission.HeureArriveDestinationMissionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{mission?.heure_arrive_destination}}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <!-- /.col-md-6 -->
        </div>

    </div>
    <!-- /.container-fluid -->

    <div class="container-fluid" *ngIf="incidents">

        <div class="col-lg-12">
            <div class="card">
                <div class="card-header border-0">
                    <h3 class="card-title">Informations Detaillées sur les incidents de la mission</h3>

                </div>
                <div class="card-body table-responsive p-0">
                    <table class="table table-striped table-valign-middle">
                        <tbody>
                            <tr>
                                <th></th>
                                <th>Photo</th>
                                <th>Incident</th>
                                <th>Lieu</th>
                                <th>Chauffeur</th>
                                <th>Montant</th>
                            </tr>
                            <tr *ngFor="let mis of mission['icidents'],let i = index">
                                <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                <td><img height="90" src="{{ mis?.chauffeur_id.user_id.avatar }}"></td>
                                <td>{{ mis?.intitule }}</td>
                                <td>{{ mis?.lieu }}</td>
                                <td>{{ mis?.chauffeur_id.user_id.name }}</td>
                                <td style="color: rgb(0, 89, 255);">{{ mis?.montant }}</td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

    </div>
    <div class="container-fluid" *ngIf="document">
        <div class="row">

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Informations Detaillées sur les documents de la mission</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>Nom</th>
                                    <th>Fichier</th>
                                </tr>
                                <tr *ngFor="let mis of mission['documents'],let i = index">
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ mis?.type_document_id.nom }}</td>
                                    <td style="color: rgb(0, 89, 255);"><a href="{{mis?.piece_jointe}}">voir </a></td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>


            <!-- /.col-md-6 -->
        </div>

    </div>
    <div class="container-fluid" *ngIf="chauffeur">
        <div class="row">

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Informations Detaillées sur les chauffeurs de la mission</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <th></th>
                                <th>Image</th>
                                <th>Nom</th>
                                <tr *ngFor="let mis of mission['ancien_chauffeur'],let i = index">
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td><img height="90" src="{{ mis?.user_id.avatar }}"></td>
                                    <td style="color: rgb(0, 89, 255);">{{ mis?.user_id.name }}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>


            <!-- /.col-md-6 -->
        </div>

    </div>
    <div class="container-fluid" *ngIf="convoyeur">
        <div class="row">

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Informations Detaillées sur les convoyeurs de la mission</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>Image</th>
                                    <th>Nom</th>

                                </tr>
                                <tr *ngFor="let mis of mission['ancien_convoyeur'],let i = index">
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td><img height="90" src="{{ mis?.user_id.avatar }}"></td>
                                    <td style="color: rgb(0, 89, 255);">{{ mis?.user_id.name }}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>


            <!-- /.col-md-6 -->
        </div>

    </div>
    <div class="container-fluid" *ngIf="vehicule">
        <div class="row">

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Informations Detaillées sur les vehicules de la mission</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>Photo</th>
                                    <th>Imatriculation</th>

                                </tr>
                                <tr *ngFor="let mis of mission['ancien_vehicule'],let i = index">
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td><img height="90" src="{{ mis?.photo_before_vehicle }}"></td>
                                    <td style="color: rgb(0, 89, 255);">{{ mis?.vehicle_registration }}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>


            <!-- /.col-md-6 -->
        </div>

    </div>
    <div class="container-fluid" *ngIf="transfers">
        <div class="row">

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Informations Detaillées sur les transfers de la mission</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>Photo</th>
                                    <th>Chauffeur</th>
                                    <th>Convoyeurs</th>
                                    <th>Photo vehicule</th>
                                    <th>Vehicules</th>

                                </tr>
                                <tr *ngFor="let mis of mission['transfers'],let i = index">
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td><img height="90" src="{{ mis?.chauffeur_id.user_id.avatar }}"></td>
                                    <td>{{ mis?.chauffeur_id.user_id.name }}</td>
                                    <td>{{ mis?.convoyeur_id.user_id.name }}</td>
                                    <td><img height="90" src="{{ mis?.photo_before_vehicle }}"></td>
                                    <td style="color: rgb(0, 89, 255);">{{ mis?.vehicle_registration }}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>


            <!-- /.col-md-6 -->
        </div>

    </div>
    <div class="container-fluid" *ngIf="remarques">
        <div class="row">

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Informations Detaillées sur les remarques de la mission</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>Nature</th>

                                </tr>
                                <tr *ngFor="let mis of mission['remarques'],let i = index">
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ mis?.nature }}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>


            <!-- /.col-md-6 -->
        </div>

    </div>
    <div class="container-fluid" *ngIf="pannes">
        <div class="row">

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Informations Detaillées sur les pannes de la mission</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>Nature</th>
                                    <th>Gerer</th>
                                    <th>Lieu</th>
                                    <th>Fichier</th>
                                </tr>
                                <tr *ngFor="let mis of mission['pannes'],let i = index">
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ mis?.nature }}</td>
                                    <td>{{ mis?.gerer }}</td>
                                    <td>{{ mis?.lieu }}</td>
                                    <td style="color: rgb(0, 89, 255);"><a href="{{mis?.files}}">voir </a></td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>


            <!-- /.col-md-6 -->
        </div>

    </div>
    <div class="container-fluid" *ngIf="receptions">
        <div class="row">

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Informations Detaillées sur les receptions de la mission</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>Date de reception</th>
                                    <th>Heure de reception</th>
                                    <th>Client</th>
                                    <th>chauffeur</th>
                                </tr>
                                <tr *ngFor="let mis of mission['receptions'],let i = index">
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ mis?.date_reception }}</td>
                                    <td>{{ mis?.heure_reception }}</td>
                                    <td>{{ mis?.element_intervenant_id1.name }}</td>
                                    <td>{{ mis?.chauffeur_id.user_id.name }}</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>


            <!-- /.col-md-6 -->
        </div>

    </div>


</div>