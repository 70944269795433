import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { TypeIncidentService } from 'src/app/_services/movement-service/Incident/type-incident.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-type-incident-update',
  templateUrl: './type-incident-update.component.html',
  styleUrls: ['./type-incident-update.component.scss']
})
export class TypeIncidentUpdateComponent implements OnInit {
  DataForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  typeIncidents: any;

  constructor(
    private TypeIncidentService: TypeIncidentService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.initForm();

    const type_incident_id = +this.route.snapshot.paramMap.get("id");
    console.log('this.type_incident_id');
    
    this.TypeIncidentService.find(type_incident_id).then(
      data => {
        this.typeIncidents = data;
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('typeIncidents.'+error.error.code)
        .subscribe(val => this.notifService.danger(val));
      }
    )
  }

  initForm(withTypeEntretient = false) {
    if(withTypeEntretient){
      this.DataForm = this.formBuilder.group(
        {
          nom: [this.typeIncidents.nom, [Validators.required]],
          description: [this.typeIncidents.description],
        }
      );
      }
    else{
      this.DataForm = this.formBuilder.group(
        {
          nom: ["", [Validators.required]],
          description: [""],
        }
      );
    }

  }

  get form() {
    return this.DataForm.controls;
  }
  
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.DataForm.invalid) {
      this.translate.get('TypeIncident.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('nom', ''+this.form.nom.value);
    formData.append('description', ''+this.form.description.value);

      console.log(formData);
      this.TypeIncidentService.update(this.typeIncidents.id, formData)
      .then(resp => {
        this.translate.get('TypeIncident.SubmitSuccess')
        .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.DataForm.reset();
        this.router.navigate(['/type-incident/all'])

      })
      .catch(err => {
        console.log(err)
        this.translate.get('TypeIncident.SubmitErrorTypeIncident')
        .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }


}
