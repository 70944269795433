import { HttpClient } from '@angular/common/http';
import * as Routes from 'src/Routes';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ProgrammesEntretientService {

  constructor(private http: HttpClient) { }

  async post(formData): Promise<any> {
    return await this.http.post(Routes.PROGRAMME_ENTRETIENT, formData).toPromise();
  }

  public async put(id: number, formData): Promise<any> {
    return await this.http
      .post(`${Routes.PROGRAMME_ENTRETIENT}/${id}`, formData)
      .toPromise();
  }

  public gets(page) {
    return this.http.get<any>(`${Routes.PROGRAMME_ENTRETIENT}?page=${page}`)
      .toPromise();
  }

  public get(url) {
    return this.http.get<any>(`${url}`)
      .toPromise();
  }

  public async delete(id) {
    return await this.http
      .delete(`${Routes.PROGRAMME_ENTRETIENT}/${id}`)
      .toPromise();
  }

  find(id: number): Promise<any> {
    return this.http.get<any>(`${Routes.PROGRAMME_ENTRETIENT}/${id}`).toPromise();
  }
}
