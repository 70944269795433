import { error } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { InterveningElementService } from "src/app/_services/transport-element/intervening-element/intervening-element.service";
import { InterveningTypeAddComponent } from '../../intervening-types/intervening-type-add/intervening-type-add.component';

@Component({
  selector: 'app-intervening-element-add',
  templateUrl: './intervening-element-add.component.html',
  styleUrls: ['./intervening-element-add.component.scss']
})
export class InterveningElementAddComponent implements OnInit {

  data;
  dataForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  myfile: File = null;
  isMaterial = false;
  cameroonianPhoneNumber = false;
  cameroonianPhoneNumber2 = false;


  constructor(
    private translate: TranslateService,
    private dataService: InterveningElementService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private ElementType: MatDialog,
    private ref: MatDialog,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getTypes();
    this.isMaterial = '/intervening-element/add' != location.pathname;
  }

  initForm() {
    this.dataForm = this.formBuilder.group({
      name: ['', Validators.required],
      intervening_type_id: ['', Validators.required],
      email: ['', Validators.required],
      location: ['', Validators.required],
      tel1: ['', Validators.required],
      tel2: [''],
      description: [''],
      contact_person: ['', Validators.required],
      tel_person: ['', Validators.required],
      intervening_file: ['']
    })
  }

  get form() {
    return this.dataForm.controls
  }


  detectfile(event) {
    this.myfile = event.target.files[0];
    console.log(this.myfile)
  }

  onSubmit() {
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.dataForm.invalid) {
      this.toastrService.error('ERREUR', 'Les champs en rouge sont obligatoires!');
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    /* const data = this.form;
    for (const k in data) {
      if (k) {
        if (k === 'intervening_file') {
          formData.append(k + '', this.myfile, data[k].value);
        } else {
          formData.append(k + '', data[k].value);  
        }
        
      }
    } */
    formData.append('name', '' + this.form.name.value);
    formData.append('intervening_type_id', '' + this.form.intervening_type_id.value);
    formData.append('email', '' + this.form.email.value);
    formData.append('location', '' + this.form.location.value);
    formData.append('tel1', '' + this.form.tel1.value);
    formData.append('tel2', '' + this.form.tel2.value);
    formData.append('description', '' + this.form.description.value);
    formData.append('contact_person', '' + this.form.contact_person.value);
    formData.append('tel_person', '' + this.form.tel_person.value);
    if (this.myfile != null)
      formData.append('fiche_intervention', this.myfile, this.myfile.name);

    this.dataService.post(formData).then(resp => {
      this.toastrService.success('element creer avec success');
      this.isSubmitted = false;
      this.dataForm.reset();
    })
      .catch(
        err => {
          console.log(err);
          this.toastrService.error('l\'operation a echouer');
        }
      )
      .finally(() => this.isLoading = false);
  }

  getTypes() {
    this.dataService.getTypes().then(
      (res) => {
        this.data = res;
        console.log(this.data);
      }).catch((error) => {
        this.toastrService.error(error.error.message);
      })
  }

  addType() {
    var modelDialogef = this.ElementType.open(InterveningTypeAddComponent, {
      width: '700px',
      height: '700px',
      disableClose: true
    });

    modelDialogef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      this.ngOnInit();
    });
  }

  close() {
    this.ref.closeAll();
  }

  isCameroonianPhoneNumber(input) {

    
    var cameroonPhoneNumberRegex = /^(\+?237)[0-9]{9}/i;
    
    
    if (cameroonPhoneNumberRegex.test(input.value)) {
      input.className = 'form-control is-valid';
      console.log("valide");
      
    } else {
      input.className = 'form-control is-invalid';
      console.log("invalid");
      


    }

  }

}
