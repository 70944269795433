import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';
import { UserService } from 'src/app/_services/users/user.service';
import { MessageService } from 'src/app/_services/RH-service/message/message.service';
import Swal from 'sweetalert2';
declare const $;
@Component({
  selector: 'app-message-all',
  templateUrl: './message-all.component.html',
  styleUrls: ['./message-all.component.scss']
})
export class MessageAllComponent implements OnInit {



  messages: any;
  loading: boolean = true;
  @BlockUI() blockUI: NgBlockUI;

  //SweetAlert Text
  areYouSure = '';
  warning = ''
  yes = '';
  no = '';
  deleted = '';
  deletedMessage = '';
  cancelled = '';
  cancelledMessage = '';
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  page = 1;
  data: any;
  driverSelectId: any;
  idDriver;
  user_id_2;
  user_id_1;
  user;
  bool = false;



  constructor(
    private MessageService: MessageService,
    private UserService: UserService,
    private authService: AuthService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router) {

    this.translate.get(
      ['SweetAlert.AreYouSure', 'SweetAlert.Warning', 'SweetAlert.Yes', 'SweetAlert.No', 'SweetAlert.Deleted',
        'SweetAlert.DeletedMessage', 'SweetAlert.Cancelled', 'SweetAlert.CancelledMessage'],
      { data: "cette Message" })
      .subscribe(val => {
        this.areYouSure = val['SweetAlert.AreYouSure'];
        this.warning = val['SweetAlert.Warning'];
        this.yes = val['SweetAlert.Yes'];
        this.no = val['SweetAlert.No'];
        this.deleted = val['SweetAlert.Deleted'];
        this.deletedMessage = val['SweetAlert.DeletedMessage'];
        this.cancelled = val['SweetAlert.Cancelled'];
        this.cancelledMessage = val['SweetAlert.CancelledMessage'];
      });
  }

  ngOnInit(): void {
    this.user = this.authService.getUser();
    this.getMessage(this.user.id);
    const permissionSuffix = 'messages';
    this.canCreate = this.authService.hasPermission(`${permissionSuffix}-create`);
    this.canUpdate = this.authService.hasPermission(`${permissionSuffix}-update`);
    this.canDelete = this.authService.hasPermission(`${permissionSuffix}-delete`);

  }
  getMessage(id) {
    this.loading = true;
    this.MessageService.gets(id).then(
      response => {
        this.messages = response;
        this.bool = false;
        console.log(this.messages)
        $(function () {
          //$("#example1").DataTable().fnDestroy()
          $("#example1").DataTable({
            "responsive": true, "lengthChange": false, "autoWidth": false,
            "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
          }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        });
      }
    ).catch(
      error => {
        this.notifService.danger(error.error.message)
      }
    ).finally(
      () => {
        this.loading = false;
      }
    )
  }

  getPage(url) {
    if (url) {
      this.MessageService.get(url).then((res) => {
        this.messages = res;
        console.log(this.messages)
      }).catch(
        error => {
          this.notifService.danger(error.error.message)
        }
      ).finally(
        () => {
          this.loading = false;
        }
      )
    }
  }

  editMessage(id) {
    console.log('bonjour');
    this.router.navigate(['/message/update/' + id]);
  }
  detailleMessage(id) {
    console.log('bonjourhaut');
    this.router.navigate(['/message/detail/' + id]);
  }

  filterSender(user_id_1) {
    this.MessageService.filterSender(user_id_1).then((res) => {
      this.messages = res;
      this.bool = false;
      console.log(this.messages)
      console.log('josey1')
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  filterRecipient(user_id_2) {
    this.MessageService.filterRecipient(user_id_2).then((res) => {
      this.messages = res.data;
      this.bool = true;
      console.log(this.messages)
      console.log('josey2')
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  chooseSender(event) {
    this.user_id_1 = event.target.value;
    console.log(this.user_id_1);
    this.filterSender(this.user_id_1);
    console.log('jose1')
  }

  chooseRecipient(event) {
    this.user_id_2 = event.target.value;
    console.log(this.user_id_2);
    this.filterRecipient(this.user_id_2);
    console.log('jose2')
  }

  deleteMessage(id) {
    Swal.fire({
      title: this.areYouSure,
      text: this.warning,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.yes,
      cancelButtonText: this.no
    }).then((result) => {
      if (result.value) {
        this.blockUI.start('Loading...');
        this.MessageService.delete(id).then(
          data => {
            this.blockUI.stop();
            Swal.fire(
              this.deleted,
              this.deletedMessage,
              'success'
            )
            this.getMessage(1);

          }
        ).catch(
          error => {
            console.log(error)
            this.blockUI.stop();
            this.translate.get('Message.' + error.error.code)
              .subscribe(val => this.notifService.danger(val));
          }
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.cancelledMessage,
          'error'
        )
      }
    })
  }

}
