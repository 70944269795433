import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ParamService } from 'src/app/_services/parc-service/param-service/param-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-add-carosserie-auto',
  templateUrl: './add-carosserie-auto.component.html',
  styleUrls: ['./add-carosserie-auto.component.scss']
})
export class AddCarosserieAutoComponent implements OnInit {

  carosserieForm: FormGroup;
  isSubmitted: boolean = false;
  isLoading = false;
  isMaterial: boolean = false;
  constructor(

    private translate: TranslateService,
    private http: HttpClient,
    private paramService: ParamService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private ref: MatDialog
  ) { }

  ngOnInit(): void {

    // initialisation de type vehicule
      this.inittypevehicule();
      this.isMaterial = '/setting/forms/carosserie/add' != location.pathname;
  }



  public inittypevehicule() {
    this.carosserieForm = this.formBuilder.group({
      description: [''],
      name: ['', Validators.required],
    });
  }


  get carosserieform() {
    return this.carosserieForm.controls;
  }


  onSubmitCarosserie() {
    this.isSubmitted = true;
    this.isLoading = false;
    if (this.carosserieForm.invalid) {
      this.toastrService.error('ERREUR', 'Les champs en rouge sont invalides!');
      return;
    }
    this.isLoading = true;
    const formData = new FormData();
    formData.append('description', '' + this.carosserieform.description.value);
    formData.append('name', '' + this.carosserieform.name.value);
    formData.append('code', '' + 'CAROSSERIE');
    this.paramService.addForm(formData)
      .then(resp => {
        this.toastrService.success('ajouter avec succes!', 'Succes!');
        this.carosserieForm.reset();
        this.carosserieForm = this.formBuilder.group({
          description: [''],
          name: ['', Validators.required],
        });
        this.isSubmitted = false;
      })
      .catch(err => {
        console.log(err)
        this.toastrService.error('Veuillez vérifier votre serveur ou Cette carosserie existe déjà', 'error!');
      })
      .finally(() => this.isLoading = false);
  }

  close() {
    this.ref.closeAll();
    // location.reload();
  }
}











