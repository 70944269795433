import { Component, OnInit } from '@angular/core';
import { NoteCritiqueService } from 'src/app/_services/RH-service/note-critique/note-critique.service';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MessageAddComponent } from '../../messages/message-add/message-add.component';

@Component({
  selector: 'app-note-critique-detail',
  templateUrl: './note-critique-detail.component.html',
  styleUrls: ['./note-critique-detail.component.scss']
})
export class NoteCritiqueDetailComponent implements OnInit {

  note;
  url;

  constructor(
    private NoteCritiqueService: NoteCritiqueService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private userDialog: MatDialog,

  ) { }

  async ngOnInit() {
    const contratId = +this.route.snapshot.paramMap.get("id");
    console.log(contratId);
    this.NoteCritiqueService.find(contratId).then(
      data => {
        this.note = data;
        console.log(this.note)
      }
    ).catch(
      error => {
        this.translate.get('NoteCritique.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/note-critique/all']);
      }
    )
  }
  addMessage() {
    var modelDialogef =  this.userDialog.open(MessageAddComponent,{
       width: '1000px',
       height: '700px',
       disableClose: true
     });
 
     modelDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);
 
       this.ngOnInit();
     });
   }

}
