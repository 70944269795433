import { Component, OnInit } from '@angular/core';
import { PneuService } from 'src/app/_services/pneumatique/Pneu/pneu.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-pneu-add',
  templateUrl: './pneu-add.component.html',
  styleUrls: ['./pneu-add.component.scss']
})
export class PneuAddComponent implements OnInit {

  dataForm: FormGroup;
  isSubmitted: boolean = false;
  isLoading = false;
  isError = false;
  isSuccess = false;
  intervening_elements:any;
  marque_pneus:any;
  pneus:any;
  user;

  constructor(
    private pneuService: PneuService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    //private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getFournisseur();
    this.getMarque();
    this.user = this.authService.getUser();

    this.dataForm = this.formBuilder.group({
      no_serie: ['', Validators.required],
      etat_achat: ['', Validators.required],
      etat_actuel: ['', Validators.required],
      date_achat: ['', Validators.required],
      date_fabrication: ['', Validators.required],
      dimensions: ['', Validators.required],
      valeur_achat: ['', Validators.required],
      duree_de_vie: ['', Validators.required],
      indice_de_charge: ['', Validators.required],
      indice_de_vitesse: ['', Validators.required],
      observation: ['', Validators.required],
      type_achat: ['', Validators.required],
      fournisseur: ['', Validators.required],
      marque_id: ['', Validators.required],
    });
  }

  public getFournisseur() {

    this.pneuService.getFournisseur().then(response => {
      this.intervening_elements = response;
      console.log(this.intervening_elements)
    })
    .catch(error => {
      this.notifService.danger(error.error.message)
    })
  }

  public getMarque() {

    this.pneuService.getMarque().then(response => {
      this.marque_pneus = response;
      console.log(this.marque_pneus)
    })
    .catch(error => {
      this.notifService.danger(error.error.message)
    })
  }

  get form() {
    return this.dataForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.dataForm.invalid) {
      this.translate.get('Pneu.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('no_serie', '' + this.form.no_serie.value);
    formData.append('etat_achat', '' + this.form.etat_achat.value);
    formData.append('etat_actuel', '' + this.form.etat_actuel.value);
    formData.append('date_achat', '' + this.form.date_achat.value);
    formData.append('date_fabrication', '' + this.form.date_fabrication.value);
    formData.append('dimensions', '' + this.form.dimensions.value);
    formData.append('valeur_achat', '' + this.form.valeur_achat.value);
    formData.append('duree_de_vie', '' + this.form.duree_de_vie.value);
    formData.append('indice_de_charge', '' + this.form.indice_de_charge.value);
    formData.append('observation', '' + this.form.observation.value);
    formData.append('type_achat', '' + this.form.type_achat.value);
    formData.append('indice_de_vitesse', '' + this.form.indice_de_vitesse.value);
    formData.append('fournisseur', '' + this.form.fournisseur.value);
    formData.append('marque_id', '' + this.form.marque_id.value);
    formData.append('create_id', '' + this.user.id);

    console.log(formData);
    this.pneuService.add(formData)
      .then(resp => {
        this.translate.get('Pneu.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.dataForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('Pneu.SubmitErrorPneu')
          .subscribe(val => this.notifService.danger(val));     
     })
      .finally(() => this.isLoading = false);  
  }

}
