<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>{{ "Contrat.Contrat" | translate }}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/contrat/all']">{{ "Contrat.Contrat" | translate }}</a></li>
            <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>

          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>

  <!-- Main content -->

  <section class="container">
    <div class="row">
      <div class="col-md-12">
        <form (submit)="onSubmit()" [formGroup]="ContratForm">
          <div class="card card-primary">

            <div class="card-header" style="background-color: #17a2b8;">
              <h3 class="card-title">{{ "Contrat.ContratAdd" | translate }}</h3>
            </div>
            <div class="card-body" style="display: block;">
              <div class="row">
                <div class="form-group col-md-6">
                  <label>{{ "Contrat.TypeContratLabel" | translate }} <span style="color: red;">*</span> </label>
                  <select formControlName="contract_type_id" class="form-control {{ isSubmitted && form.contract_type_id.errors? 'is-invalid' : '' }}"
                    style="width: 100%;" tabindex="-1" aria-hidden="true">
                    <option value="">{{ "Contrat.TypeContratVehicle" | translate }}</option>
                    <option *ngFor="let type of typeContrats" value="{{type.id}}">{{type.name}}</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label>{{ "Contrat.UserContratLabel" | translate }} <span style="color: red;">*</span> </label>
                  <select formControlName="user_id" class="form-control {{ isSubmitted && form.user_id.errors? 'is-invalid' : '' }}"
                    style="width: 100%;" tabindex="-1" aria-hidden="true">
                    <option value="">{{ "Contrat.ContratUser" | translate }}</option>
                    <option *ngFor="let user of users?.data" value="{{user.id}}">{{user.name}}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputName">{{ "Contrat.DepartmentContratLabel" | translate }} <span style="color: red;">*</span> </label>
                  <input type="text" id="inputName" class="form-control {{ isSubmitted && form.department.errors? 'is-invalid' : '' }}"
                    formControlName="department" placeholder="{{ 'Contrat.LabelDepartmentContrat' | translate }}">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputName">{{ "Contrat.NonTaxableBonusContratLabel" | translate }} <span style="color: red;">*</span> </label>
                  <input type="number" id="inputName" class="form-control {{ isSubmitted && form.non_taxable_bonus.errors? 'is-invalid' : '' }}"
                    formControlName="non_taxable_bonus" placeholder="{{ 'Contrat.LabelNonTaxableContrat' | translate }}">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputName">{{ "Contrat.StartDateContratLabel" | translate }} <span style="color: red;">*</span> </label>
                  <input type="date" id="inputName" class="form-control {{ isSubmitted && form.start_date.errors? 'is-invalid' : '' }}"
                    formControlName="start_date" placeholder="{{ 'Contrat.LabelStartDateContrat' | translate }}">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputName">{{ "Contrat.EndDateContratLabel" | translate }} <span style="color: red;">*</span> </label>
                  <input type="date" id="inputName" class="form-control {{ isSubmitted && form.end_date.errors? 'is-invalid' : '' }}"
                    formControlName="end_date" placeholder="{{ 'Contrat.LabelEndDateContrat' | translate }}">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputName">{{ "Contrat.BasicSalaryContratLabel" | translate }} <span style="color: red;">*</span> </label>
                  <input type="number" id="inputName" class="form-control {{ isSubmitted && form.basic_salary.errors? 'is-invalid' : '' }}"
                    formControlName="basic_salary" placeholder="{{ 'Contrat.LabelBasicContrat' | translate }}">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputName">{{ "Contrat.ExtraCostPerHourContratLabel" | translate }}  <span style="color: red;">*</span></label>
                  <input type="number" id="inputName" class="form-control {{ isSubmitted && form.extra_cost_per_hour.errors? 'is-invalid' : '' }}"
                    formControlName="extra_cost_per_hour" placeholder="{{ 'Contrat.LabelExtraContrat' | translate }}">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputName">{{ "Contrat.SupervisorContratLabel" | translate }} <span style="color: red;">*</span> </label>
                  <input type="text" id="inputName" class="form-control {{ isSubmitted && form.supervisor.errors? 'is-invalid' : '' }}"
                    formControlName="supervisor" placeholder="{{ 'Contrat.LabelSupervisorContrat' | translate }}">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputName">{{ "Contrat.PrimeBonusContratLabel" | translate }}</label>
                  <input type="number" id="inputName" class="form-control"
                    formControlName="prime_bonus" placeholder="{{ 'Contrat.LabelPrimeContrat' | translate }}">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputName">{{ "Contrat.bank_nameLabel" | translate }}</label>
                  <input type="text" id="inputName" class="form-control"
                    formControlName="bank_name" placeholder="{{ 'Contrat.bank_nameContrat' | translate }}">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputName">{{ "Contrat.bank_account_numberLabel" | translate }}</label>
                  <input type="text" id="inputName" class="form-control"
                    formControlName="bank_account_number"
                    placeholder="{{ 'Contrat.bank_account_numberContrat' | translate }}">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputName">{{ "Contrat.ContractFileContratLabel" | translate }}</label>
                  <input type="file" (change)=onSelectfile($event) id="inputName"
                    class="form-control" formControlName="contract_file"
                    placeholder="{{ 'Contrat.LabelContractFile' | translate }}">
                </div>  
              </div>
            </div>
            <div class="card-footer">
              <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
            <!-- /.card-body -->
          </div>
        </form>
        <!-- /.card -->
      </div>
    </div>
  </section>
  <!-- /.content -->
</div>
