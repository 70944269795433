<section class="content-header">
  <div class="container-fluid">
      <div class="row mb-2">
          <div class="col-sm-6">
              <h1>{{ 'ProgrammesEntretient.ProgrammesEntretient' | translate }}</h1>
          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a [routerLink]="['']">Home</a></li>
                  <li class="breadcrumb-item active"><a [routerLink]="['/programme-entretient/list']">{{ "ProgrammesEntretient.ProgrammesEntretient" | translate }}</a>
                  </li>
              </ol>
          </div>
      </div>
  </div>
  <!-- /.container-fluid -->
</section>

<div class="container">
  <div class="container-fluid">
      <div class="row">

          <div class="col-lg-12">
              <div class="card">
                  <div class="card-header border-0">
                      <h3 class="card-title">Detrails sur le programme d'entretient</h3>

                  </div>
                  <div class="card-body table-responsive p-0">
                      <table class="table table-striped table-valign-middle">
                          <tbody>
                              <tr>
                                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                  <td>{{ "ProgrammesEntretient.CompteurLabel" | translate }}</td>
                                  <td style="color: rgb(0, 89, 255);">{{programme_entretient?.compteur}}</td>
                              </tr>
                              <tr>
                                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                  <td>{{ "ProgrammesEntretient.DateEntretientLabel" | translate }}</td>
                                  <td style="color: rgb(0, 89, 255);">{{programme_entretient?.date_entretien}}</td>
                              </tr>
                              <tr>
                                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                  <td>{{ "ProgrammesEntretient.KmPrevuLabel" | translate }}</td>
                                  <td style="color: rgb(0, 89, 255);">{{programme_entretient?.km_prevu}}</td>
                              </tr>
                              <tr>
                                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                  <td>{{ "ProgrammesEntretient.HeurePrevuLabel" | translate }}</td>
                                  <td style="color: rgb(0, 89, 255);">{{programme_entretient?.heure_prevu}}</td>
                              </tr>
                              <tr>
                                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                  <td>{{ "ProgrammesEntretient.CoutLabel" | translate }}</td>
                                  <td style="color: rgb(0, 89, 255);">{{programme_entretient?.cout_intervention}}</td>
                              </tr>
                              <tr>
                                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                  <td>{{ "ProgrammesEntretient.GammeEntretientLabel" | translate }}</td>
                                  <td style="color: rgb(0, 89, 255);">{{programme_entretient?.gamme}}</td>
                              </tr>
                              <tr>
                                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                  <td>{{ "ProgrammesEntretient.MecanicienLabel" | translate }}</td>
                                  <td style="color: rgb(0, 89, 255);">{{programme_entretient?.mecanicien}}</td>
                              </tr>
                              <tr>
                                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                  <td>{{ "ProgrammesEntretient.VehiculeLabel" | translate }}</td>
                                  <td style="color: rgb(0, 89, 255);">{{programme_entretient?.vehicule}}</td>
                              </tr>
                              <tr>
                                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                  <td>{{ "ProgrammesEntretient.NextDateEntretientLabel" | translate }}</td>
                                  <td style="color: rgb(0, 89, 255);">{{programme_entretient?.date_prochain_entretien}}</td>
                              </tr>
                              <tr>
                                <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                <td>{{ "ProgrammesEntretient.NextKmPrevuLabel" | translate }}</td>
                                <td style="color: rgb(0, 89, 255);">{{programme_entretient?.km_prevu_next}}</td>
                              </tr>
                              <tr>
                                <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                <td>{{ "ProgrammesEntretient.NextHeurePrevuLabel" | translate }}</td>
                                <td style="color: rgb(0, 89, 255);">{{programme_entretient?.heure_prevu_next}}</td>
                                </tr>
                                <tr>
                                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                  <td>{{ "ProgrammesEntretient.DescriptionLabel" | translate }}</td>
                                  <td style="color: rgb(0, 89, 255);">{{programme_entretient?.description}}</td>
                              </tr>

                          </tbody>
                      </table>
                  </div>
              </div>

          </div>
  </div>
  <!-- /.container-fluid -->
</div>
