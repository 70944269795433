import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-panne-declarer-update',
  templateUrl: './panne-declarer-update.component.html',
  styleUrls: ['./panne-declarer-update.component.scss']
})
export class PanneDeclarerUpdateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
