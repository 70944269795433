import { Component, OnInit } from '@angular/core';
import { MecanicienService } from 'src/app/_services/RH-service/mecanicien/mecanicien.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { CreateUsersComponent } from '../../users/create-users/create-users.component';

@Component({
  selector: 'app-mecanicien-update',
  templateUrl: './mecanicien-update.component.html',
  styleUrls: ['./mecanicien-update.component.scss']
})
export class MecanicienUpdateComponent implements OnInit {


  MecanicienForm: FormGroup;
  users: any;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  medical_file: File = null;
  mecanicien_attestation_file: File = null;
  pipe = new DatePipe('en-US');
  date = new Date();
  currentDate = this.pipe.transform(this.date, 'yyyy-MM-dd');
  mecanicien;
  chauf:any;


  constructor(
    private MecanicienService: MecanicienService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private userDialog: MatDialog,
    private _formBuilder: FormBuilder

  ) { }


  ngOnInit(): void {
    this.initForm();
    this.getusers();

    const chauf = +this.route.snapshot.paramMap.get("id");
    this.MecanicienService.find(chauf).then(
      data => {
        this.mecanicien = data;
        this.initForm(true);
        console.log(this.mecanicien)
      }
    ).catch(
      error => {
        this.translate.get('mecanicien.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/mecanicien/all']);

      }
    )
  }

  //initialisation du formulaire
  initForm(withContrat = false) {
    if (withContrat) {

      this.MecanicienForm = this._formBuilder.group(
        {
          user_id: [this.mecanicien.user_id, [Validators.required]],
          speciality: [this.mecanicien.speciality, [Validators.required]],
          anne_expert: [this.mecanicien.anne_expert, [Validators.required]],
          lieu_travail: [this.mecanicien.lieu_travail, [Validators.required]],
        }
      );
    } else {
      this.MecanicienForm = this.formBuilder.group({
        user_id: ['', [Validators.required]],
        speciality: ['', [Validators.required]],
        anne_expert: ['', [Validators.required]],
        lieu_travail: ['', [Validators.required]],
      });
    }

  }

  getusers() {
    this.MecanicienService.getUser().then((res) => {
      this.users = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  onSelectfile2(event) {
    this.mecanicien_attestation_file = event.target.files[0];
  }

  get form() {
    return this.MecanicienForm.controls;
  }
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.MecanicienForm.invalid) {
      this.translate.get('Mecanicien.SubmitErrorMecanicien')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('user_id', '' + this.form.user_id.value);
    formData.append('speciality', '' + this.form.speciality.value);
    formData.append('anne_expert', '' + this.form.anne_expert.value);
    formData.append('lieu_travail', '' + this.form.lieu_travail.value);
    if (this.mecanicien_attestation_file != null)
      formData.append('mecanicien_attestation_file', this.mecanicien_attestation_file, this.mecanicien_attestation_file.name);


    console.log(formData);
    this.MecanicienService.update(formData, this.mecanicien.id)
      .then(resp => {
        this.translate.get('Mecanicien.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.MecanicienForm.reset();
        this.router.navigate(['/mecanicien/all']);
      })
      .catch(err => {
        console.log(err)
        this.translate.get('Mecanicien.SubmitError')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }
  addUser() {
    var modelDialogef =  this.userDialog.open(CreateUsersComponent,{
       width: '1000px',
       height: '700px',
       disableClose: true
     });

     modelDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);

       this.ngOnInit();
     });
   }

}
