import { Component, OnInit } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2'
import { TypeContratService } from 'src/app/_services/RH-service/contrat/type-contrat.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';

@Component({
  selector: 'app-type-contrat-update',
  templateUrl: './type-contrat-update.component.html',
  styleUrls: ['./type-contrat-update.component.scss']
})
export class TypeContratUpdateComponent implements OnInit {

  user:any;
  TypeContratForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  logo:File=null;

  typeContrats:any;

  constructor(
    private TypeContratService: TypeContratService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) {
   }

  ngOnInit() {
    this.initForm();
    const type_Contrat_id = +this.route.snapshot.paramMap.get("id");
    this.TypeContratService.find(type_Contrat_id).then(
      data => {
        this.typeContrats = data;
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('TypeContrats.'+error.error.code)
        .subscribe(val => this.notifService.danger(val));
      }
    )
  }

     //initialisation du formulaire
     initForm(withTypeContrat = false) {
      if(withTypeContrat){
        this.TypeContratForm = this.formBuilder.group(
          {
            name: [this.typeContrats.name, [Validators.required]],
            description: [this.typeContrats.description],
          }
        );
        }
      else{
        this.TypeContratForm = this.formBuilder.group(
          {
            name: ['', [Validators.required]],
            description: [''],
          }
        );
      }

    }

  get form() {
    return this.TypeContratForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;
    this.user = this.authService.getUser()

    if (this.TypeContratForm.invalid) {
      this.translate.get('TypeContrat.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('name', ''+this.form.name.value);
    formData.append('description', ''+this.form.description.value);
    formData.append('create_id', ''+this.user.id);


      console.log(formData);
      this.TypeContratService.update(formData, this.typeContrats.id)
      .then(resp => {
        this.translate.get('TypeContrat.SubmitSuccess')
        .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.TypeContratForm.reset();
        this.router.navigate(['/type-contrat/all'])

      })
      .catch(err => {
        console.log(err)
        this.translate.get('TypeContrat.SubmitErrorTypeContrat')
        .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }

 }
