<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-1">
        <div class="col-sm-6">
          <h1>
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">{{ "Pneu.Pneu" | translate }}</font>
            </font>
          </h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/pneu/all']">{{ "Pneu.Pneu" | translate }}</a></li>
            <li class="breadcrumb-item active">{{ "FileAriane.List" | translate }}</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>

  <!-- Main content -->
  <section class="content">

    <!-- Default box -->


    <div class="card">
      <div class="card-header" style="background-color: #17a2b8; color:white;">
        <h1 class="card-title">
          <font style="vertical-align: inherit;">
            <font style="vertical-align: inherit;"> {{ "Pneu.PneuAll" | translate }}</font>
          </font>
        </h1>
        <button [routerLink]="['/pneu/add']" type="button" class="btn btn-primary float-right">
          <i class="fas fa-plus"></i> {{ "Forms.Marques.Ajouter" | translate }}
        </button>
      </div> <br><br>

      <div class="card-body p-0">
        <table id="example1" class="table table-bordered table-striped">
          <thead>
            <tr>
              <th style="width: 1%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    #
                  </font>
                </font>
              </th>

              <th style="width: 15%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Pneu.MarqueLabel" | translate }}
                  </font>
                </font>
              </th>

              <th style="width: 10%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Pneu.FournisseurLabel" | translate }}
                  </font>
                </font>
              </th>

              <th style="width: 10%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Pneu.NoSerieLabel" | translate }}
                  </font>
                </font>
              </th>
              <th style="width: 10%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Pneu.Etat_achatLabel" | translate }}
                  </font>
                </font>
              </th>

              <th style="width: 10%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Pneu.Etat_actuelLabel" | translate }}
                  </font>
                </font>
              </th>
              <th style="width: 10%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Pneu.Duree_de_vieLabel" | translate }}
                  </font>
                </font>
              </th>
              <th style="width: 15%;"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let affect of pneus,let i = index" id="{{ affect.id }}">

              <td>
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ i+1 }}
                  </font>
                </font>
              </td>
              <td>
                <a>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ affect.marque }}
                    </font>
                  </font>
                </a>
              </td>
              <td>
                <a>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ affect.fournisseur }}
                    </font>
                  </font>
                </a>
              </td>
              <td>
                <a>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ affect.no_serie }}
                    </font>
                  </font>
                </a>
              </td>
              <td>
                <a>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ affect.etat_achat }}
                    </font>
                  </font>
                </a>
              </td>
              <td>
                <a>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      ETAT {{ affect.etat_actuel }} 
                    </font>
                  </font>
                </a>
              </td>
              <td>
                <a>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ affect.duree_de_vie }}
                    </font>
                  </font>
                </a>
              </td>
              <td class="project-actions text-right">
                <a class="btn btn-primary btn-sm" href="javascript:void(0)" data-bs-placement="top" title="details"
                  (click)="detailsPneu(affect.id)">
                  <i class="fas fa-eye"></i>
                </a>
                &nbsp;
                <a class="btn btn-info btn-sm" href="javascript:void(0)" data-bs-placement="top" title="modifier"
                  (click)="editPneu(affect.id)">
                  <i class="fas fa-pencil-alt">
                  </i>
                </a>
                &nbsp;
                <a class="btn btn-danger btn-sm" href="javascript:void(0)" data-bs-placement="top" title="supprimer"
                  (click)="deletePneu(affect.id)">
                  <i class="fas fa-trash">
                  </i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <h1 class="col-md-6" *ngIf="somme > 0"><strong style="color: blue">Nombre de pneu en etat =
              {{nb_pneu}}</strong></h1>
          <h1 class="col-md-6" *ngIf="somme > 0"><strong style="color: blue">Prix total d'achat = {{somme}}</strong>
          </h1>
        </div>

        <div class="card-footer">
        </div>
      </div>

    </div>


  </section>

</div>