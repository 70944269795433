//export const ROOT = 'http://localhost:8000/';
export const ROOT = 'https://api.etransparc.visibilitycam.com/';
export const SERVER = ROOT + 'api/';

export const LOGIN = SERVER + 'auth/token';
//route pour gestion de vehicules
export const AUTO = SERVER + 'module-parc/vehicle';
export const MARQUEAUTO = SERVER + 'module-parc/formes/mark/code';
export const MODELEAUTO = SERVER + 'module-parc/formes/modele/code';
export const TYPEAUTO = SERVER + 'module-parc/formes/type_vehicule/code';
export const CAROSSERIEAUTO = SERVER + 'module-parc/formes/carosserie/code';

export const TYPEFICHE = SERVER + 'module-parc/paper_type';
export const ASSURANCE = SERVER + 'module-parc/insurance';
export const OFFICIALPAPER = SERVER + 'module-parc/official_paper';
export const ELEMENTINTERVENANT = SERVER + 'module-parc/intervening_element';
export const VEHICULE = SERVER + 'module-parc/vehicle';
export const TYPENOTIFICATION = SERVER + 'notifications/notification_types';
export const NOTIFICATION = SERVER + 'notifications/notifications';
export const ETAT = SERVER + 'notifications/etats';
export const AFFECTATION = SERVER + 'module-parc/affectation';
export const VEHICULEALL = SERVER + 'module-parc/vehicle/all/get';
export const DRIVERALL = SERVER + 'module-parc/driver/all/get';
export const FORM = SERVER + 'module-parc/formes';
export const DOCUMENT = SERVER + 'module-document/document';
export const DOCUMENTS = SERVER + 'module-document';
export const TYPEINTERVENANT = SERVER + 'module-parc/intervening_type';

export const SANCTION = SERVER + 'module-rh/sanction';
export const TYPE_SANCTION = SERVER + 'module-rh/sanction-type';
export const CONTRAT = SERVER + 'module-rh/contract';
export const TYPE_CONTRAT = SERVER + 'module-rh/contract-type';
export const USER = SERVER + 'users';
export const NOTECRITIQUE = SERVER + 'module-rh/internal-note';
export const FORMATION = SERVER + 'module-rh/formation';
export const CHAUFFEUR = SERVER + 'module-rh/driver';
export const UPDATE_PASSWORD = SERVER + 'auth/updatepassword';
export const USERS = SERVER + 'users';

export const ROLE = SERVER + 'roles';
export const PERMISSION = SERVER + 'permissions';
export const SYNC_USER_ABILITIES = SERVER + 'sync_user_abilities/{user_id}';
export const MESSAGE = SERVER + 'module-rh/message';
export const CONVOYEUR = SERVER + 'module-rh/convoyeur';
export const MECANICIEN = SERVER + 'module-rh/mecanicien';
export const PANNE = SERVER + 'module-maintenance/panne';
export const TYPEINTERVENTION = SERVER + 'module-maintenance/type_intervention';
export const INTERVENTION = SERVER + 'module-maintenance/intervention';
export const TYPE_ENTRETIENT = SERVER + 'module-maintenance/type_entretien';
export const GAMME_ENTRETIEN = SERVER + 'module-maintenance/gamme_entretien';
export const FICHE_INTERVENTION = SERVER + 'module-maintenance/fiche_intervention';
export const PROGRAMME_REPARATION = SERVER + 'module-maintenance/programme_reparation';
export const PROGRAMME_ENTRETIENT = SERVER + 'module-maintenance/programme_entretien';

export const OPERATION = SERVER + 'module-pneumatique/operation';
export const MARQUE_PNEU = SERVER + 'module-pneumatique/marque_pneu';
export const POSITION = SERVER + 'module-pneumatique/position';
export const PNEU = SERVER + 'module-pneumatique/pneu';
export const INVENTAIRE = SERVER + 'module-pneumatique/operation/inventaire';
export const MISSION = SERVER + 'module-mouvement/mission';
export const DOCUMENT_MISSION = SERVER + 'module-mouvement/document_mission';
export const TYPE_DOCUMENT_MISSION = SERVER + 'module-mouvement/type_document';
export const TYPE_INCIDENT = SERVER + 'module-mouvement/type_incident';
export const TRANSFER = SERVER + 'module-mouvement/transfert';
