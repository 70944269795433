import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ParamService } from 'src/app/_services/parc-service/param-service/param-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-model-auto',
  templateUrl: './update-model-auto.component.html',
  styleUrls: ['./update-model-auto.component.scss']
})
export class UpdateModelAutoComponent implements OnInit {
  
  forme = "Mark"
  modele: any;
  modelForm: FormGroup;
  typemarks: any;
  isSubmitted: boolean = false;
  isLoading = false;

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private paramService: ParamService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) { }
  
  async ngOnInit() {
    const mod_id = +this.route.snapshot.paramMap.get("id");
    this.paramService.findForm(mod_id).then(
      data => {
        this.modele = data;
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('modele.'+error.error.code)
        .subscribe();
      }
    )
    this.initForm();
    this.getForme(this.forme);
  }

  initForm(withmodel = false) {
    if(withmodel) {
      this.modelForm = this.formBuilder.group({
        parent_id: [this.modele.parent_id, [Validators.required]],
        name: [this.modele.name, [Validators.required]],
        code: [this.modele.code, [Validators.required]],
        description: [this.modele.description]
        
      });
    }else {
      this.modelForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        parent_id: ['', [Validators.required]],
        description: [''],
      }); 
    }
  }

  get form() {
    return this.modelForm.controls;
  }

  onSubmitModel() {
    this.isSubmitted = true;
    this.isLoading = false;
    if (this.modelForm.invalid) {
      this.toastrService.error('ERREUR', 'Les champs en rouge sont invalides!');
      return;
    }
    this.isLoading = true;
    const formData = new FormData();
    formData.append('name', '' + this.form.name.value);
    formData.append('parent_id', '' + this.form.parent_id.value);
    formData.append('code', '' + 'MODELE');
    formData.append('description', '' + this.form.description.value);
    this.paramService.updateForm(formData, this.modele.id)
      .then(resp => {
        this.translate.get('model.updateModel')
        .subscribe();
        this.router.navigate(['setting/forms/model/list'])

      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => this.isLoading = false);
  }

  public getForme(forme) {
    this.paramService.selectForm(forme).then(
      response => {
        this.typemarks = response;
        console.log(this.typemarks)
      }
    ).catch(
      error => {
        this.toastrService.error(error.error.message);
      }
    ).finally(
      () => {
      }
    )
  }
}
