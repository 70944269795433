<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Paramétrage du véhicule</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item">Forms</li>
          <li class="breadcrumb-item active">ajouter</li>

          <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
          <li class="breadcrumb-item "><a [routerLink]="['/affectations/all']">{{ "Affectation.Affectation" | translate
              }}</a></li>
          <li class="breadcrumb-item active">{{ "FileAriane.add" | translate }}</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Ajouter un modèle -->
<div *ngIf="isModel" class="container-fluid">
  <!-- Main content -->
  <section class="container">
    <div class="card card-primary">
      <div class="card-header">
        <h1 class="card-title">Ajouter un modèle</h1>
      </div>
      <!-- /.card -->
      <div class="row">
        <div class="col-md-12">
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
              title="Collapse">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <!-- cette div gere les modèles -->
        <div class="card-body">
          <div class="row">
            <!-- left column -->
            <div class="col-md-12">
              <!-- general form elements -->
              <div class="card card-primary">
                <!-- form start -->
                <form [formGroup]="modelForm" (submit)="onSubmitModel()">
                  <div class="card-body">
                    <div class="form-group">
                      <label>Types de Marques</label>
                      <select class="form-control" formControlName="parrain_id">
                        <option>Choisir une marque ..</option>
                        <option [value]="typemark" *ngFor="let typemark of typemarks " id="option{{ typemark.id }}">
                          {{typemark.name}}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="exampleName1">Nom</label>
                      <input type="text" formControlName="name" class="form-control" id="exampleName1"
                        placeholder="Nom du Modèle ..." />
                    </div>
                    <div class="form-group">
                      <label>Description</label>
                      <textarea class="form-control" formControlName="description" rows="3"
                        placeholder="Description ..."></textarea>
                    </div>
                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <!-- /.card -->
            </div>
            <!--/.col (left) -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12"></div>
      </div>
      <!-- /.card-body -->
    </div>
  </section>
  <!-- /.content -->
</div>

<!-- ajouter une carosserie -->
<div *ngIf="isCarosserie" class="container-fluid">
  <!-- Main content -->
  <section class="content">
    <div class="card card-primary">
      <div class="card-header">
        <h1 class="card-title">Ajouter une carosserie</h1>
      </div>
      <!-- /.card -->
      <div class="row">
        <div class="col-md-12">
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
              title="Collapse">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <!-- cette div gere les modèles -->
        <div class="card-body">
          <div class="row">
            <!-- left column -->
            <div class="col-md-12">
              <!-- general form elements -->
              <div class="card card-primary">
                <!-- form start -->
                <form role="form">
                  <div class="card-body">
                    <div class="form-group">
                      <label for="exampleName1">Name</label>
                      <input type="text" class="form-control" id="exampleName1" placeholder="Nom du Modèle ..." />
                    </div>
                    <div class="form-group">
                      <label>Description</label>
                      <textarea class="form-control" rows="3" placeholder="Description ..."></textarea>
                    </div>
                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <!-- /.card -->
            </div>
            <!--/.col (left) -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12"></div>
      </div>
      <!-- /.card-body -->
    </div>
  </section>
  <!-- /.content -->
</div>

<!-- ajouter une marque -->
<div *ngIf="isMark" class="container-fluid">
  <!-- Main content -->
  <section class="content">
    <div class="card card-primary">
      <div class="card-header">
        <h1 class="card-title">Ajouter une marque</h1>
      </div>
      <!-- /.card -->
      <div class="row">
        <div class="col-md-12">
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
              title="Collapse">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <!-- cette div gere les modèles -->
        <div class="card-body">
          <div class="row">
            <!-- left column -->
            <div class="col-md-12">
              <!-- general form elements -->
              <div class="card card-primary">
                <!-- form start -->
                <form role="form">
                  <div class="card-body">
                    <div class="form-group">
                      <label for="exampleName1">Name</label>
                      <input type="text" class="form-control" id="exampleName1" placeholder="Nom du Modèle ..." />
                    </div>
                    <div class="form-group">
                      <label>Description</label>
                      <textarea class="form-control" rows="3" placeholder="Description ..."></textarea>
                    </div>
                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <!-- /.card -->
            </div>
            <!--/.col (left) -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12"></div>
      </div>
      <!-- /.card-body -->
    </div>
  </section>
  <!-- /.content -->
</div>

<!-- ajouter un type de vehicule -->
<div *ngIf="isTypeVehicule" class="container-fluid">
  <!-- Main content -->
  <section class="content">
    <div class="card card-primary">
      <div class="card-header">
        <h1 class="card-title">Ajouter un type de véhicule</h1>
      </div>
      <!-- /.card -->
      <div class="row">
        <div class="col-md-12">
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
              title="Collapse">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <!-- cette div gere les modèles -->
        <div class="card-body">
          <div class="row">
            <!-- left column -->
            <div class="col-md-12">
              <!-- general form elements -->
              <div class="card card-primary">
                <!-- form start -->
                <form role="form">
                  <div class="card-body">
                    <div class="form-group">
                      <label for="exampleName1">Name</label>
                      <input type="text" class="form-control" id="exampleName1" placeholder="Nom du Modèle ..." />
                    </div>
                    <div class="form-group">
                      <label>Description</label>
                      <textarea class="form-control" rows="3" placeholder="Description ..."></textarea>
                    </div>
                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <!-- /.card -->
            </div>
            <!--/.col (left) -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12"></div>
      </div>
      <!-- /.card-body -->
    </div>
  </section>
  <!-- /.content -->
</div>