<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ "ElementIntervenant.Title" | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">Home</a></li>
                    <li class="breadcrumb-item active"><a [routerLink]="['/intervening-element/list']">{{ "ElementIntervenant.Title" | translate }}</a>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>
<section class="content">

    <!-- Default box -->
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">{{ "ElementIntervenant.Detail" | translate }}</h3>
        </div>
        <div class="card-body">
            <div class="row">

                <div class="col-12 col-md-12 col-lg-12 order-1 order-md-2">
                    <h2 class="text-primary"><i class="fas fa-paint-brush"></i> {{ element.name }}</h2>
                    <p class="text-muted">{{ element.description }}</p>
                    <br>
                    <div class="row" style="font-size: large;">
                        <div class="col-md-6">
                            <div class="text-muted">
                                <span class="text-secondary"><span
                                        style="font-weight: bolder;"> {{ "ElementIntervenant.IntervenantType" | translate }}:
                                    </span>
                                <p class="d-block">{{ element.type_name }}</p>
                                </span>
                                <span class="text-secondary"><span
                                        style="font-weight: bolder;">{{ "ElementIntervenant.ContactPerson" | translate }}:
                                    </span>
                                <p class="d-block">{{ element.contact_person }}</p>
                                </span>
                                <span class="text-secondary"><span
                                        style="font-weight: bolder;">{{ "ElementIntervenant.TelPerson" | translate }}:
                                    </span>
                                <p class="d-block">{{ element.tel_person }}</p>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <!-- <h2 class="mt-1 text-muted">{{ "ElementIntervenant.Address" | translate }}</h2> -->
                            <ul class="list-unstyled">
                                <li class="mb-2">
                                    <a href="javascript:void(0)" class="btn-link text-secondary"><i
                                            class="fas fa-lg fa-building"></i><span
                                            style="font-weight: bolder;">
                                        {{ "ElementIntervenant.Location" | translate }}:</span> {{ element.location }}</a>
                                </li>
                                <li class="mb-2">
                                    <a href="javascript:void(0)" class="btn-link text-secondary"><i
                                            class="fas fa-lg fa-phone"></i><span
                                            style="font-weight: bolder;"> 
                                     {{ "ElementIntervenant.Phone" | translate }} 1: </span>{{ element.tel1 }}</a>
                                </li>
                                <li class="mb-2">
                                    <a href="javascript:void(0)" class="btn-link text-secondary"><i
                                            class="fas fa-lg fa-phone"></i> <span
                                            style="font-weight: bolder;"> {{ "ElementIntervenant.Phone" | translate }}
                                        2: </span>{{ element.tel2 }}</a>
                                </li>
                                <li class="mb-2">
                                    <a href="javascript:void(0)" class="btn-link text-secondary"><i
                                            class="far fa-fw fa-envelope"></i> {{ element.email }}</a>
                                </li>
                                <li class="mb-2">
                                    <a href="{{ element.intervening_file }}" target="_blank" title="{{ 'ElementIntervenant.Click' | translate }}" class="btn-link text-secondary"><i class="fas fa-link mr-1"></i>
                                        {{ "ElementIntervenant.InterveningFile" | translate }} </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.card-body -->
    </div>
    <!-- /.card -->

</section>