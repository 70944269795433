import { Component, OnInit } from '@angular/core';
import { ChauffeurService } from 'src/app/_services/RH-service/chauffeur/chauffeur.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { CreateUsersComponent } from '../../users/create-users/create-users.component';

@Component({
  selector: 'app-chauffeur-update',
  templateUrl: './chauffeur-update.component.html',
  styleUrls: ['./chauffeur-update.component.scss']
})
export class ChauffeurUpdateComponent implements OnInit {

  ChauffeurForm: FormGroup;
  users: any;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  medical_file: File = null;
  licence_validity_file: File = null;
  pipe = new DatePipe('en-US');
  date = new Date();
  currentDate = this.pipe.transform(this.date, 'yyyy-MM-dd');
  chauffeur;


  constructor(
    private ChauffeurService: ChauffeurService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private userDialog:MatDialog

  ) { }


  ngOnInit(): void {
    this.initForm();
    this.getusers();
    const chauf = +this.route.snapshot.paramMap.get("id");
    this.ChauffeurService.find(chauf).then(
      data => {
        this.chauffeur = data;
        this.initForm(true);
        console.log(this.chauffeur.user_id)
      }
    ).catch(
      error => {
        this.translate.get('Formation.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/chauffeur/all']);

      }
    )
  }

  //initialisation du formulaire
  initForm(withContrat = false) {
    if (withContrat) {
      this.ChauffeurForm = this.formBuilder.group(
        {
          user_id: [this.chauffeur.user_id ,[Validators.required]],
          licence_number: [this.chauffeur.licence_number, [Validators.required]],
          licence_type: [this.chauffeur.licence_type, [Validators.required]],
          date_of_issue: [this.chauffeur.date_of_issue, [Validators.required]],
          end_of_date: [this.chauffeur.end_of_date, [Validators.required]],
          medical_file: [this.chauffeur.medical_file],
          licence_validity_file: [this.chauffeur.licence_validity_file],
        }
      );
    } else {
      this.ChauffeurForm = this.formBuilder.group(
        {
          user_id: [ '', [Validators.required]],
          licence_number: ['', [Validators.required]],
          licence_type: ['', [Validators.required]],
          date_of_issue: ['', [Validators.required]],
          end_of_date: ['', [Validators.required]],
          medical_file: [''],
          licence_validity_file: [''],
        }
      );
    }

  }

  getusers() {
    this.ChauffeurService.getUser().then((res) => {
      this.users = res;
      console.log(this.users)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  onSelectfile1(event) {
    this.medical_file = event.target.files[0];
  }

  onSelectfile2(event) {
    this.licence_validity_file = event.target.files[0];
  }

  get form() {
    return this.ChauffeurForm.controls;
  }
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.ChauffeurForm.invalid) {
      this.translate.get('Chauffeur.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('user_id', '' + this.form.user_id.value);
    formData.append('licence_number', '' + this.form.licence_number.value);
    formData.append('licence_type', '' + this.form.licence_type.value);
    if(this.form.licence_number.value != ''){
      if (this.form.end_of_date.value < this.form.date_of_issue.value) {
        this.translate.get('Forms.CompareDateError')
        .subscribe(val => this.notifService.danger(val));
        this.isLoading = false;
        return;
      }
      if (this.currentDate < this.form.date_of_issue.value) {
        this.translate.get('Forms.StartDateError')
        .subscribe(val => this.notifService.danger(val));
        this.isLoading = false;
        return;
      }
      if (this.currentDate > this.form.end_of_date.value) {
        this.translate.get('Forms.EndDateError')
        .subscribe(val => this.notifService.danger(val));
        this.isLoading = false;
        return;
      }
    }
    formData.append('date_of_issue', '' + this.form.date_of_issue.value);
    formData.append('end_of_date', '' + this.form.end_of_date.value);
    if (this.medical_file != null)
      formData.append('medical_file', this.medical_file, this.medical_file.name);

    if (this.licence_validity_file != null)
      formData.append('licence_validity_file', this.licence_validity_file, this.licence_validity_file.name);


    console.log(formData);
    this.ChauffeurService.update(formData, this.chauffeur.id)
      .then(resp => {
        this.translate.get('Chauffeur.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.ChauffeurForm.reset();
        this.router.navigate(['/chauffeur/all']);

      })
      .catch(err => {
        console.log(err)
        this.translate.get('Chauffeur.SubmitErrorChauffeur')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }
  addUser() {
    var modelDialogef =  this.userDialog.open(CreateUsersComponent,{
       width: '1000px',
       height: '700px',
       disableClose: true
     });

     modelDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);

       this.ngOnInit();
     });
   }

}
