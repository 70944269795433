<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-1">
          <div class="col-sm-6">
            <h1>
              <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">{{ "Operation.Inventaire" | translate }}</font>
              </font>
            </h1>
          </div>
        </div>
      </div>
    </section>
      <section class="content">  
  
      <div class="card">
        <div class="card-header" style="background-color: #17a2b8; color:white;">
          <h1 class="card-title">
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;" > {{ "Operation.Inventaires" | translate }}</font>
            </font>
          </h1>
        </div>
        
        <div class="card-body p-0">
          <table id="example1" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th style="width: 1%">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      #
                    </font>
                  </font>
                </th>
  
                <th style="width: 15%">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ "Operation.PneuLabel" | translate }}
                    </font>
                  </font>
                </th>

                <th style="width: 10%">
                    <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">
                        {{ "Operation.VehiculeLabel" | translate }}
                      </font>
                    </font>
                </th>

                <th style="width: 12%">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ "Operation.PositionLabel" | translate }}
                    </font>
                  </font>
                </th>
                <th style="width: 10%">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ "Operation.DateLabel" | translate }}
                    </font>
                  </font>
                </th>
  
                <th style="width: 10%">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ "Operation.tache" | translate }}
                    </font>
                  </font>
                </th>
                <th style="width: 10%">
                    <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">
                        {{ "Operation.EtatActuel" | translate }}
                      </font>
                    </font>
                </th>
                <th style="width: 10%">
                    <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">
                        {{ "Operation.DurreeDeVie" | translate }}
                      </font>
                    </font>
                </th>
                <th style="width: 10%">
                    <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">
                        {{ "Operation.ParcoursRestant" | translate }}
                      </font>
                    </font>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let affect of inventaires,let i = index" id="{{ affect.id }}">
                <td>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ i+1 }}
                    </font>
                  </font>
                </td>
                <td>
                  <a>
                    <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">
                        {{ affect.no_serie }}
                      </font>
                    </font>
                  </a>
                </td>
                <td>
                    <a>
                      <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">
                          {{ affect.immatriculation }}
                        </font>
                      </font>
                    </a>
                </td>
                <td>
                    <div>
                        <a>
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">
                                ligne: {{ affect.ligne }}
                              </font>
                            </font>
                          </a>
                    </div>
                    <div>
                        <a>
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">
                                colone: {{ affect.colone }}
                              </font>
                            </font>
                          </a>
                    </div>
                </td>
                <td>
                  <a>
                    <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">
                        {{ affect.date }}
                      </font>
                    </font>
                  </a>
                </td>
                <td>
                    <a>
                      <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">
                            {{ affect.monter == 'true' ? 'monter' : 'null'}}
                        </font>
                      </font>
                    </a>
                </td>
                <td>
                    <a>
                      <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">
                          {{ affect.etat_actuel }}
                        </font>
                      </font>
                    </a>
                </td>
                <td>
                    <a>
                      <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">
                          {{ affect.duree_de_vie }}
                        </font>
                      </font>
                    </a>
                </td>
                <td>
                    <a>
                      <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">
                          {{ affect.parcour_restant }} Km
                        </font>
                      </font>
                    </a>
                </td>
              </tr>  
            </tbody>
          </table>
          <div class="card-footer">
          </div>
        </div>
      </div>

    </section>
  </div>