<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-1">
                <div class="col-sm-6">
                    <h1>
                        <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">{{ "Message.Title" | translate }}</font>
                        </font>
                    </h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                        <li class="breadcrumb-item "><a
                                [routerLink]="['/message/all']">{{ "Message.Title" | translate }}</a>
                        </li>
                        <li class="breadcrumb-item active">{{ "FileAriane.List" | translate }}</li>
                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">

        <!-- Default box -->


        <div class="card">
            <div class="card-header" style="background-color: #17a2b8; color: white; ">
                <h3 class="card-title">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;"> {{ "Message.MessageAll" | translate }}</font>
                    </font>
                </h3>

                <button *ngIf="canCreate" [routerLink]="['/message/add']" type="button" class="btn btn-primary float-right">
                    <i class="fas fa-plus"></i> {{ "Forms.Marques.Ajouter" | translate }}
                </button>

                <button style="margin-left: 2%;margin-right: 2%;" (click)="filterSender(user.id)" type="button" class="btn btn-primary float-right">
                    {{ "Message.chooseSender" | translate }}
                </button>

                <button (click)="filterRecipient(user.id)" type="button" class="btn btn-primary float-right">
                    {{ "Message.chooseRecipient" | translate }}
                </button>
                <button style="margin-left: 2%;margin-right: 2%;" (click)="getMessage(user.id)" type="button" class="btn btn-primary float-right">
                    {{ "Message.chooseAll" | translate }}
                </button>
            </div>
            
            <div class="card-body p-0">
                <table id="example1" class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th style="width: 5%">
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">
                                        #
                                    </font>
                                </font>
                            </th>

                            <th style="width: 15%">
                                <div>
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">
                                            {{ "Message.Recipient" | translate }}
                                        </font>
                                    </font>
                                </div>
                            </th>
                            <div *ngIf="bool">
                                <th style="width: 20%">
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">
                                            {{ "Message.Sender" | translate }}
                                        </font>
                                    </font>
                                </th>
                            </div>
                            <th style="width: 20%">
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">
                                        {{ "Message.Subject" | translate }}
                                    </font>
                                </font>
                            </th>
                            <th style="width: 25%">
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">
                                        {{ "Message.Content" | translate }}
                                    </font>
                                </font>
                            </th>
                            <th style="width: 15%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let message of messages?.data,let i = index" id="{{ message.id }}">
                            <td>
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">
                                        {{ i+1 }}
                                    </font>
                                </font>
                            </td>
                            <td>
                                <div>
                                    <a>
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">
                                                {{ message?.user2.name }}
                                            </font>
                                        </font>
                                    </a>
                                </div>
                            </td>
                            <div *ngIf="bool">
                                <td>
                                    <a>
                                        <font style="vertical-align: inherit;">
                                            <font style="vertical-align: inherit;">
                                                {{ message?.user1.name }}
                                            </font>
                                        </font>
                                    </a>
                            </td>
                        </div>
                        <td>
                                <a>
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">
                                            {{ message.suject }}
                                        </font>
                                    </font>
                                </a>
                            </td>
                            <td>
                                <a>
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">
                                            {{ message.content }}
                                        </font>
                                    </font>
                                </a>
                            </td>
                            <td class="project-actions text-right">

                                <a class="btn btn-primary btn-sm" href="javascript:void(0)" data-bs-placement="top" title="details"
                                    (click)="detailleMessage(message.id)">
                                    <i class="fas fa-eye"></i>
                                </a>
                                &nbsp;
                              <!--   <a *ngIf="canUpdate" class="btn btn-info btn-sm" href="javascript:void(0)" data-bs-placement="top" title="modifier"
                                    (click)="editMessage(message.id)">
                                    <i class="fas fa-pencil-alt">
                                    </i>
                                </a> -->
                                &nbsp;
                                <a *ngIf="canDelete" class="btn btn-danger btn-sm" href="javascript:void(0)" data-bs-placement="top" title="supprimer"
                                    (click)="deleteMessage(message.id)">
                                    <i class="fas fa-trash">
                                    </i>
                                </a>
                            </td>
                        </tr>

                    </tbody>
                </table>
                <div class="card-footer">
                    <!-- <nav aria-label="Navigation dans la page des contacts">
                        <ul class="pagination justify-content-center m-0">
                            <li class="page-item"><a (click)="getPage(messages.prev_page_url)" class="page-link">
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">{{ 'General.Previous' | translate }}
                                        </font>
                                    </font>
                                </a></li>
                            <li class="page-item"><a (click)="getPage(messages.next_page_url)" class="page-link">
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;">{{ 'General.next' | translate }}</font>
                                    </font>
                                </a></li>
                        </ul>
                    </nav> -->
                </div>
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->

    </section>
    <!-- /.content -->
</div>