<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>{{ "Position.Position" | translate }}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/position/all']">{{ "Position.Position" |
                translate }}</a></li>
            <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>
  
          </ol>
        </div>
      </div>
    </div>
  </section>
  
  <section class="container">
    <div class="row">
      <div class="col-md-12">
        <form (submit)="onSubmit()" [formGroup]="dataForm">
          <div class="card card-primary">
  
            <div class="card-header" style="background-color: #17a2b8">
              <h3 class="card-title">{{ "Position.PositionAdd" | translate }}</h3>
            </div>
            <div class="card-body" style="display: block;">
                <div class="form-group">
                    <label>{{ "Position.ModelLabel" | translate }}
                        <span style="color: red">*</span>
                    </label>
                    <select formControlName="modele_id" class="form-control {{ isSubmitted && form.modele_id.errors? 'is-invalid' : '' }}"
                      style="width: 100%;" tabindex="-1" aria-hidden="true">
                      <option value="">{{ "Position.modelFiche" | translate }}</option>
                      <option *ngFor="let item of models" value="{{item.id}}">{{item.name}}</option>
                    </select>
                </div>

              <div class="form-group">
                <label>{{ "Position.LigneLabel" | translate }} <span
                          style="color: red;">*</span>
                </label>
                <input type="number" id="inputName" class="form-control {{ isSubmitted && form.indice_ligne.errors? 'is-invalid' : '' }}"
                  formControlName="indice_ligne" placeholder="{{ 'Position.LigneFiche' | translate }}">
              </div>
              
              <div class="form-group">
                <label>{{ "Position.ColoneLabel" | translate }}<span
                    style="color: red;">*</span></label>
                <input type="number" id="inputName" class="form-control {{ isSubmitted && form.indice_colone.errors? 'is-invalid' : '' }}"
                formControlName="indice_colone" placeholder="{{ 'Position.ColoneFiche' | translate }}">              </div>
            </div>
            <div class="card-footer">
              <button [disabled]="isLoading"  type="submit" class="btn btn-primary" style="background-color: #17a2b8;">
                <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
        <!-- <div  *ngIf="isMaterial"  class="col-md-2" style="margin-left:85%"><button class="btn btn-danger" (click)="onClose()">
            {{
            'Material.ButtonClose' | translate }}
          </button></div> -->
    </div>
</section>
