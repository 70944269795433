import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PanneServices } from 'src/app/_services/main-service/panne-services/panne-services';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';



@Component({
  selector: 'app-panne-auto-add',
  templateUrl: './panne-auto-add.component.html',
  styleUrls: ['./panne-auto-add.component.scss']
})
export class PanneAutoAddComponent implements OnInit {

  // panneForm: FormGroup;
  isSubmitted: boolean = false;
  isLoading = false;
  vehicules:any;
  user;
  myFiles:string [] = [];
  missions:any;
  dataForm: FormGroup;
  isError = false;
  isSuccess = false;

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private panneService: PanneServices,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private authService: AuthService,
    private notifService: NotifService

  ) { }

  ngOnInit(): void {
    this.getVehicules();
    this.getMissions();
    this.user = this.authService.getUser();
    this.initpanne();
    
  }


  public initpanne() {
    this.dataForm = this.formBuilder.group({
      vehicule_id: ['', Validators.required],
      mission_id: ['', Validators.required],
      gerer: [''],
      description: [''],
     lieu: ['', Validators.required],
     nature: ['', Validators.required],

    });
  }

  get form() {
    return this.dataForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.dataForm.invalid) {
      this.translate.get('Panne.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }
  // onSubmit() {
  //   this.isSubmitted = true;
  //   this.isLoading = false
  //   if (this.panneForm.invalid) {
  //     this.toastrService.error('ERREUR', 'Les champs en rouge sont invalides!');
  //     return;
  //   }
    this.isLoading = true;
    const formData = new FormData();
    formData.append('vehicule_id', '' + this.form.vehicule_id.value);
    formData.append('mission_id', '' + this.form.mission_id.value);
    formData.append('gerer', '' + this.form.gerer.value);
    formData.append('description', '' + this.form.description.value);
    formData.append('nature', '' + this.form.nature.value);
    formData.append('lieu', '' + this.form.lieu.value);
    for (var i = 0; i < this.myFiles.length; i++){
      formData.append("files[]", this.myFiles[i]);
    }
    formData.append('create_id', '' + this.user.id);
    formData.append('driver_id','1' );
    this.panneService.add(formData)
      .then(resp => {
        this.toastrService.success('ajouter avec succes!', 'Succes!');
        this.dataForm.reset();
        this.isSubmitted = false;
        console.log(resp);
        
       
      })
      .catch(err => {
        console.log(err)
        this.isSubmitted = false;
      })
      .finally(() => this.isLoading = false);  }


  public getVehicules() {

    this.panneService.getvehicle().then(
      response => {
        this.vehicules = response;
        console.log(this.vehicules)
      })
      .catch(
      error => {
        this.toastrService.error(error.message);
      })
    .finally(() => {})
  }
  getMissions() {
    this.panneService.getMissions().then((res) => {
      this.missions = res;
      console.log(this.missions)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  detectFile(event){ 
    for (var i = 0; i < event.target.files.length; i++){
      this.myFiles.push(event.target.files[i]);
    }
  }

}
