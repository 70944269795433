import { Component, OnInit } from '@angular/core';
import { MarquePneuService } from 'src/app/_services/pneumatique/marque_pneu/marque-pneu.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-mark-pneu-add',
  templateUrl: './mark-pneu-add.component.html',
  styleUrls: ['./mark-pneu-add.component.scss']
})
export class MarkPneuAddComponent implements OnInit {

  dataForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  user;
  isMaterial: boolean = false;

  constructor(
    private MarquePneuService: MarquePneuService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private userDialog: MatDialog,
    private ref: MatDialog,
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUser();

    this.dataForm = this.formBuilder.group({
      name:['',[Validators.required]],
      description:['']   
    });
    //this.isMaterial = '/type-entretient/add' != location.pathname;
  }

  get form() {
    return this.dataForm.controls;
  }
  
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;
    

    if (this.dataForm.invalid) {
    
      this.translate.get('MarquePneu.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('name', ''+this.form.name.value);
    formData.append('description', ''+this.form.description.value);
    formData.append('create_id', '' + this.user.id);

      console.log(formData);
      this.MarquePneuService.add(formData)
      .then(resp => {
        this.translate.get('MarquePneu.SubmitSuccess')
        .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.dataForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('MarquePneu.SubmitErrorMarquePneu')
        .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }
  /* onClose() {
    this.ref.closeAll();
    // location.reload();
  } */

}
