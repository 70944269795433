<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'Forms.Breakdown.Breakdown' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                  <li class="breadcrumb-item "><a [routerLink]="['/panne/list']">{{ "Forms.Breakdown.Breakdown" |
                      translate }}</a></li>
                  <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>
                </ol>
              </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="container">
    <div class="container-fluid">
        <div class="row">
            <div class="card">
                <div class="card-header" style="height:58px;">
                  <h3 class="card-title"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ 'Vehicle.pictures' | translate }}</font></font></h3>
                </div>
                <!-- /.card-header -->
                <div class="card-body" >
                  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                      <li data-target="#carouselExampleIndicators" data-slide-to="1" class=""></li>
                      <li data-target="#carouselExampleIndicators" data-slide-to="1" class=""></li>
                      <li data-target="#carouselExampleIndicators" data-slide-to="1" class=""></li>
                      <li data-target="#carouselExampleIndicators" data-slide-to="2" class=""></li>
                    </ol>
                    <div class="carousel-inner">
                      <div class="carousel-item active" >
                           <img class="d-block w-100" src="{{panne.photo_before_panne }}" style=" height:80% ;"  alt="Première diapositive"  >
                        <div style="margin-top:4%;">{{ 'Forms.Breakdown.PhotoBefore' | translate }}</div>
                      </div>
                      <div class="carousel-item ">
                        <img class="d-block w-100" src="{{panne.photo_rear_panne }}" style=" height:80% ;" alt="Deuxième diapositive">
                        <div style="margin-top:4%;">{{ 'Forms.Breakdown.PhotoRear' | translate }}</div>
                      </div>
                      <div class="carousel-item ">
                        <img class="d-block w-100" src="{{panne.photo_leftside_panne }}" style=" height:80% ;" alt="Troisième diapositive">
                        <div style="margin-top:4%;">{{ 'Forms.Breakdown.PhotoLeft' | translate }}</div>
                      </div>
                      <div class="carousel-item ">
                          <img class="d-block w-100" src="{{panne.photo_rightside_panne }}" style=" height:80% ;"  alt="Troisième diapositive">
                          <div style="margin-top:4%;">{{ 'Forms.Breakdown.PhotoRight' | translate }}</div>
                        </div>
                    
                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ 'Panne.previous' | translate }}</font></font></span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ 'Panne.next' | translate }}</font></font></span>
                    </a>
                  </div>
                </div>
              </div>   
            </div>

            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Detail panne</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Drivers" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{panne?.nom_chauffeur}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Vehicules" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{panne?.immatriculation}}</td>
                                </tr>
                                <tr>
                                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                  <td>{{ "Vehicules" | translate }}</td>
                                  <td style="color: rgb(0, 89, 255);">{{panne?.mission_id}}</td>
                              </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Gerer" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{panne?.gerer}}</td>
                                </tr>
                                <tr>
                                  <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                  <td>{{ "Nature" | translate }}</td>
                                  <td style="color: rgb(0, 89, 255);">{{panne?.nature}}</td>
                              </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Lieu" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{panne?.lieu}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Description" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{panne?.description}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            

            <!-- /.col-md-6 -->
        </div>

    </div>
    <!-- /.container-fluid -->
</div>
