import { RoleService } from './../../../../../_services/roles/role.service';
import { UserService } from 'src/app/_services/users/user.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-create-users',
  templateUrl: './create-users.component.html',
  styleUrls: ['./create-users.component.scss']
})
export class CreateUsersComponent implements OnInit {

  userForm: FormGroup;
  typeusers: any;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  avatar: File = null;
  cni: File = null;
  public loading: boolean = true;
  limit = 10000;


  roles: any[] = [];
  roles_tmp: any[] = [];
  selected_roles: number[] = [];
  role_name = '';
  isMaterial: boolean = false;  //if the content is the material we will show the close button 
  //otherwise, we have to not show it

  constructor(
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private userService: UserService,
    private roleService: RoleService,
    private ref: MatDialog,
    

  ) { }


  ngOnInit() {

    //Control on the close button
   
    this.isMaterial = '/user-add' != location.pathname;
    // console.log("*********************************")
    // console.log(location.pathname);
    // console.log(this.isMaterial)
    


    let phone_patern = "^((\\+[0-9]{3}-?))?[0-9]{8}$";
    this.getRoles(this.limit);

    this.userForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      CNI_number: ['', [Validators.required]],
      picture_CNI: [''],
      sexe: [''],
      avatar: [''],
      birth_date: [''],
      birth_place: [''],
      phone_number_1: [''],
      phone_number_2: [''],
      marital_status: [''],
      children: [''],
      adress: [''],
      residence_town: [''],
      contact_person: [''],
      emergency_phone: ['']


    });
  }

  get form() {
    return this.userForm.controls;
  }

  onSelectfile(event) {
    this.avatar = event.target.files[0];
  }

  onSelectfile2(event) {
    this.cni = event.target.files[0];
  }

  /*
    getRoles() {
      this.roleService.roles().then(
        response => {
          this.roles = response;
          this.roles_tmp = response;
          console.log(this.roles);
        }
      ).catch(
        error => {
          this.notifService.danger("Une erreur s'est produite");
        }
      )
    }
    */

  getRoles(limit) {
    this.loading = true;
    this.roleService.roles(limit).then(
      response => {
        this.roles = response.data;
        this.roles_tmp = response.data;
        console.log(this.roles)
      }
    ).catch(
      error => {
        this.notifService.danger(error.error.message)
      }
    ).finally(
      () => {
        this.loading = false;
      }
    )
  }

  computeName(event) {
    this.role_name = event.target.value.replace(/[^A-Z0-9]/ig, "_");
  }

  search(event) {
    this.roles = this.roles_tmp;
    this.roles = this.roles_tmp.filter(role => role.display_name.toLowerCase().includes(event.target.value.toLowerCase()));
  }

  selectAllRole(event: any) {
    this.selected_roles = [];
    if (event.target.checked) {
      this.roles_tmp.map(
        role => {
          this.selected_roles.push(role.id)
        }
      )
    }
  }

  onChecked(role, event) {
    if (event.target.checked) {
      this.selected_roles.push(role.id);
    } else {
      this.selected_roles.splice(this.selected_roles.indexOf(role.id), 1);
    }
  }

  isChecked(id: number) {
    return this.selected_roles.includes(id);
  }


  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    let pipe = new DatePipe('en-US');
    let date = new Date();
    let currentDate = pipe.transform(date, 'yyyy-MM-dd');

    if (this.userForm.invalid) {
      this.translate.get('User.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('name', '' + this.form.name.value);
    formData.append('email', '' + this.form.email.value);
    //    formData.append('password', '' + this.form.password.value);
    formData.append('CNI_number', '' + this.form.CNI_number.value);
    formData.append('sexe', '' + this.form.sexe.value);
    formData.append('birth_place', '' + this.form.birth_place.value);
    formData.append('phone_number_1', '' + this.form.phone_number_1.value);
    formData.append('phone_number_2', '' + this.form.phone_number_2.value);
    formData.append('marital_status', '' + this.form.marital_status.value);
    formData.append('children', '' + this.form.children.value);
    formData.append('adress', '' + this.form.adress.value);
    formData.append('residence_town', '' + this.form.residence_town.value);
    formData.append('contact_person', '' + this.form.contact_person.value);
    formData.append('emergency_phone', '' + this.form.emergency_phone.value);
    if (this.avatar != null)
      formData.append('avatar', this.avatar, this.avatar.name);
    if (this.cni != null)
      formData.append('picture_CNI', this.cni, this.cni.name);

    /* if (currentDate > this.form.birth_date.value) {
      this.translate.get('Form.birth_dateError')
        .subscribe(val => this.notifService.danger(val));
      this.isLoading = false;
      return;
    } else { */
    if(this.selected_roles.length == 0){
      this.translate.get('Role.SubmitErrorPermissions')
        .subscribe(val => this.notifService.danger(val));
        this.isLoading = false;
      return;
    }
    formData.append('birth_date', '' + this.form.birth_date.value);
    this.selected_roles.forEach(elt => {
      formData.append('roles[]', JSON.stringify(elt));
    });
    //}
    console.log(formData);
    this.userService.add(formData)
    .then(resp => {
        this.translate.get('User.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.userForm.reset();
        this.selected_roles = [];

      })
      .catch(err => {
        console.log(err)
        this.translate.get('User.SubmitErroruser')
          .subscribe(val => this.notifService.danger(val));
          this.isLoading = false;

      })
      .finally(() => this.isLoading = false );
  }

  onClose() {
    this.ref.closeAll();
    // location.reload();
  }

}
