<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>{{ 'TYPEFICHE.TYPEFICHE' | translate }}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/type_fiche_all']">{{ 'TYPEFICHE.TYPEFICHE' | translate }}</a></li>
            <li class="breadcrumb-item active">{{ "FileAriane.Update" | translate }}</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
</section><div class="row" style="min-height: 1416.81px; margin-left: 1%; margin-right: 1%;">
    <div class="col-md-12" id="form">
        <div class="tabs-animation">
            <div class="card card-primary">
                <div class="card-header" style="background-color: #17a2b8;">
                    <h3 class="card-title">
                    {{ 'TYPEFICHE.TYPEFICHEUPDATELabel' | translate }}
                    </h3>
                </div>
                <form (submit)="onSubmit()" [formGroup]="typeFicheForm">
                    <div class="card-body">
                            <div class="position-relative form-group">
                                <label for="name" class="">{{ 'TYPEFICHE.NomLabel' | translate }} <span style="color: red;">*</span> </label>
                                <input placeholder="{{ 'TYPEFICHE.NomPlaceholder' | translate }}" id="name"  type="text" formControlName="name"  class="form-control {{ isSubmitted && form.name.errors ? 'is-invalid' : '' }}">
                            </div>
                            <div class="position-relative form-group">
                                <label for="description" class="">{{ 'TYPEFICHE.DescriptionLabel' | translate }}</label>
                                <textarea name="text" placeholder="{{ 'TYPEFICHE.DescriptionPlaceholder' | translate }}" id="description"  formControlName="description" class="form-control {{ isSubmitted && form.description.errors ? 'is-invalid' : '' }}">
                                </textarea>
                            </div>
                            <div class="card-footer">
                                <button  [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                                  <p *ngIf="!isLoading">{{ 'General.update' | translate }}</p>
                                  <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                              </button>
                            </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>