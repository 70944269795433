import { HttpClient } from '@angular/common/http';
import * as Routes from 'src/Routes';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FicheInterventionService {

  constructor(private http: HttpClient) { }

  add(formData: FormData): Promise<any> {
    return this.http.post<any>(Routes.FICHE_INTERVENTION, formData).toPromise();
  }

  update(formData: FormData, id): Promise<any> {
    return this.http.post<any>(`${Routes.FICHE_INTERVENTION}/${id}`, formData).toPromise();
  }
  
  delete(id: number) {
    return this.http.delete(`${Routes.FICHE_INTERVENTION}/${id}`).toPromise();

  }
  
  find(id: number): Promise<any> {
    return this.http.get<any>(`${Routes.FICHE_INTERVENTION}/${id}`).toPromise();
  }
  public gets(page) {
    return this.http.get<any>(`${Routes.FICHE_INTERVENTION}?page=${page}`)
      .toPromise();
  }

  public get(url) {
    return this.http.get<any>(`${url}`)
      .toPromise();
  }

  public getTypeIntervention() {
    return this.http.get<any>(`${Routes.TYPEINTERVENTION}`)
      .toPromise();
  }

  public getMecanicien() {
    return this.http.get<any>(`${Routes.MECANICIEN}`)
      .toPromise();
  }

  public getVehicule() {
    return this.http.get<any>(`${Routes.VEHICULEALL}`)
      .toPromise();
  }  
  
  /* public getUser() {
    return this.http.get<any>(`${Routes.USER}`)
      .toPromise();
  } */
}
