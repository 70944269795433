<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'Role.Roles' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">Home</a></li>
                    <li class="breadcrumb-item active"><a [routerLink]="['/role-all']">Roles</a></li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>
<div class="row">
    <div class="col-md-8">
        <div class="tabs-animation">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="col-12 col-md-12 col-lg-12 order-1 order-md-2">
                                <h2 class="text-primary"><i class="fas fa-paint-brush"></i> {{ role.display_name }}</h2>
                                <p class="text-muted">{{ role.description }}</p>
                                <br>
                                <div class="row" style="font-size: large;">
                                    <div class="col-md-6">
                                        <div class="text-muted">

                                            <span class="text-secondary"><span
                                                style="font-weight: bolder;">{{ 'Role.NameLabel' | translate }}:
                                            </span>
                                            <p class="d-block">{{ role.name }}</p>
                                            </span>
                                            <span class="text-secondary"><span
                                                style="font-weight: bolder;">{{ 'Role.CreatedAtLabel' | translate }}:
                                            </span>
                                            <p class="d-block">{{ role.created_at | date:'dd/MM/yyyy'}}</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="tabs-animation">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="main-card mb-3 card">
                                <div class="card-body">
                                    <form class="">
                                        <div class="position-relative form-group">
                                            <label class="">{{ "Role.SearchLabel" | translate }} ({{ permissions.length }})</label>
                                            <input placeholder="{{ 'Role.SearchText' | translate }}" type="text" (keyup)="search($event)" class="form-control">
                                        </div>
                                        <ul class="todo-list-wrapper list-group list-group-flush" style="overflow-y: auto; max-height: 250px;">
                                            <li class="list-group-item" *ngFor="let permission of permissions">
                                                <div class="todo-indicator bg-warning"></div>
                                                <div class="widget-content p-0">
                                                    <div class="widget-content-wrapper">
                                                        <div class="widget-content-left mr-2">
                                                        </div>
                                                        <div class="widget-content-left">
                                                            <div class="widget-heading">{{ permission.display_name }}
                                                            </div>
                                                            <div class="widget-subheading"><i>{{ permission.description }}</i></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>