<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'Assurance.Assurance' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                    <li class="breadcrumb-item "><a
                            [routerLink]="['/all_assurrance']">{{ 'Assurance.Assurance' | translate }}</a></li>
                    <li class="breadcrumb-item active">{{ "Assurance.Add" | translate }}</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="tabs-animation" style="min-height: 1416.81px; margin-left: 2%; margin-right: 2%;">
    <div class="main-card mb-3 card">
        <section class="content">
            <div class="card-header" style="background-color: #17a2b8; color:white;">

                <h2 class="card-title" style="margin-left: 1%; margin-bottom: 1%; margin-top: 1%;">
                    {{ 'Assurance.ListAssuranceLabel' | translate }}
                </h2>
                <button [routerLink]="['/assurance_add']" type="button" class="btn btn-primary float-right"
                    style="margin-right: 1%; margin-bottom: 1%; margin-top: 1%;">
                    <i class="fas fa-plus"></i> {{ "Assurance.Ajouter" | translate }}
                </button>
            </div>
        </section>
        <!-- /.card-header -->
        <div class="card-body p-0">
            <div class="table-responsive">
                <table id="example1" class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th style="width: 5%">
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">
                                        #
                                    </font>
                                </font>
                            </th>
                            <th  style="width: 20%">
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">
                                        {{ 'Assurance.person' | translate }} </font>
                                </font>
                            </th>
                            <th  style="width: 20%">
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">{{ 'Assurance.vehicle_registration' | translate }}
                                    </font>
                                </font>
                            </th>
                            <th  style="width: 20%">
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">{{ 'Assurance.created_at' | translate }}
                                    </font>
                                </font>
                            </th>
                           <!--  <th>
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;"></font>
                                </font>
                            </th> -->
                            <!-- <th>
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">{{ 'Vehicle.Operations' | translate }}
                                    </font>
                                </font>
                            </th> -->
                            <th  style="width: 15%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let assurance of assurances?.data,let i = index" id="{{ assurance.id }}">
                            <td>
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">
                                        {{ i+1 }}
                                    </font>
                                </font>
                            </td>
                            <td>
                                <a href="">
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;"> {{assurance?.assurance_name}}</font>
                                    </font>
                                </a>
                            </td>
                            <td>
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;"> {{assurance?.vehicle_registration}}</font>
                                </font>
                            </td>
                            <td>
                                <span>
                                    <font style="vertical-align: inherit;">
                                        <font style="vertical-align: inherit;"> {{assurance?.created_at |date : 'yy-mm-dd'}}</font>
                                    </font>
                                </span>
                            </td>
                            <td class="project-actions text-right">
                                <a class="btn btn-primary btn-sm" href="javascript:void(0)" data-bs-placement="top" title="details"
                                    (click)="detailsAssurance(assurance.id)">
                                    <i class="fas fa-eye"></i>
                                </a>
                                &nbsp;
                                <a *ngIf="canUpdate" class="btn btn-info btn-sm" href="javascript:void(0)" data-bs-placement="top" title="modifier"
                                    (click)="editAssurance(assurance.id)">
                                    <i class="fas fa-pencil-alt">
                                    </i>
                                </a>
                                &nbsp;
                                <a *ngIf="canDelete" class="btn btn-danger btn-sm" href="javascript:void(0)" data-bs-placement="top" title="supprimer"
                                    (click)="deleteAssurance(assurance.id)">
                                    <i class="fas fa-trash">
                                    </i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- /.table-responsive -->
        </div>
    </div>
</div>
