<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'User.Users' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">Home</a></li>
                    <li class="breadcrumb-item active"><a [routerLink]="['/user-all']">{{ 'User.User' | translate }}</a>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">

    <!-- Default box -->


    <div class="card">
        <div class="card-header" style="background-color:#17a2b8; color:white;">
            <h3 class="card-title">
                <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;"> {{ "User.UserAll" | translate }}</font>
                </font>
            </h3>
            <button *ngIf="canCreate" [routerLink]="['/user-add']" type="button" class="btn btn-primary float-right">
                <i class="fas fa-plus"></i> {{ "Forms.Marques.Ajouter" | translate }}
            </button>
        </div>
        
        <div class="card-body p-0">
            <table id="example1" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th style="width: 5%">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">
                                    #
                                </font>
                            </font>
                        </th>
                        <th style="width: 15%">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">
                                    {{ "User.avatarUserLabel" | translate }}
                                </font>
                            </font>
                        </th>
                        <th style="width: 25%">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">
                                    {{ "User.NameUserLabel" | translate }}
                                </font>
                            </font>
                        </th>
                        <th style="width: 10%">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">
                                    {{ "User.sexeUserLabel" | translate }}
                                </font>
                            </font>
                        </th>
                        <th style="width: 15%">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">
                                    {{ "User.birth_dateUserLabel" | translate }}
                                </font>
                            </font>
                        </th>
                        <th style="width: 15%">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">
                                    {{ "User.phone_number_1UserLabel" | translate }}
                                </font>
                            </font>
                        </th>
                      <!--   <th>
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">
                                    {{ "User.marital_statusUserLabel" | translate }}
                                </font>
                            </font>
                        </th> -->
                        <th style="width: 15;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let affect of users?.data,let i = index" id="{{ affect.id }}">
                        <td>
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">
                                    {{ i+1 }}
                                </font>
                            </font>
                        </td>
                        <td>
                            <a>
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">
                                      <a (click)="show(affect?.avatar)">
                                          <img height="50" src="{{ affect.avatar }}" alt="">
                                      </a>
                                    </font>
                                </font>
                            </a>
                        </td>
                        <td>
                            <a>
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">
                                        {{ affect.name }}
                                    </font>
                                </font>
                            </a>
                        </td>
                        <td>
                            <a>
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">
                                        {{ affect.sexe }}
                                    </font>
                                </font>
                            </a>
                        </td>
                        <td>
                            <a>
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">
                                        {{ affect.birth_date }}
                                    </font>
                                </font>
                            </a>
                        </td>
                        <td>
                            <a>
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">
                                        {{ affect.phone_number_1 == "null" ? '' : affect.phone_number_1  }}
                                    </font>
                                </font>
                            </a>
                        </td>

                       <!--  <td>
                            <a>
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">
                                        {{ affect.marital_status }}
                                    </font>
                                </font>
                            </a>
                        </td> -->

                        <td class="project-actions text-right">

                            <a class="btn btn-primary btn-sm" href="javascript:void(0)" data-bs-placement="top" title="details"
                                (click)="detailsUser(affect.id)">
                                <i class="fas fa-eye"></i>
                            </a>
                            &nbsp;
                            <a *ngIf="canUpdate" class="btn btn-info btn-sm" href="javascript:void(0)" data-bs-placement="top" title="modifier" (click)="editUser(affect.id)">
                                <i class="fas fa-pencil-alt">
                                </i>
                            </a>
                            &nbsp;
                            <a *ngIf="canDelete" class="btn btn-danger btn-sm" href="javascript:void(0)" data-bs-placement="top" title="supprimer" (click)="deleteUser(affect.id)">
                                <i class="fas fa-trash">
                                </i>
                            </a>
                        </td>
                    </tr>

                </tbody>
            </table>
            <div class="card-footer">
               <!--  <nav aria-label="Navigation dans la page des contacts">
                    <ul class="pagination justify-content-center m-0">
                        <li class="page-item" ><a (click)="getPage(users?.prev_page_url)" class="page-link">
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">{{ 'General.Previous' | translate }}</font>
                                </font>
                            </a></li>
                        <li class="page-item"><a (click)="getPage(users?.next_page_url)" class="page-link">
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">{{ 'General.next' | translate }}</font>
                                </font>
                            </a></li>
                    </ul>
                </nav> -->
            </div>
        </div>
        <!-- /.card-body -->
    </div>
    <!-- /.card -->

</section>
