import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { AutoService } from 'src/app/_services/parc-service/auto-service/auto-service';
import { MecanicienService } from 'src/app/_services/RH-service/mecanicien/mecanicien.service';
import { ProgrammesEntretientService } from 'src/app/_services/maintenance/entretients/programmes-entretient.service';
import { GammeEntretienService } from 'src/app/_services/maintenance-service/gamme-entretient/gamme-entretien.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-programmes-entretient-update',
  templateUrl: './programmes-entretient-update.component.html',
  styleUrls: ['./programmes-entretient-update.component.scss']
})
export class ProgrammesEntretientUpdateComponent implements OnInit {

  user:any;
  dataForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  page=1;
  programme_entretient:any;
  gammes_entretient:any;
  vehicules:any;
  mecaniciens:any;
  pipe = new DatePipe('en-US');
  date = new Date();
  currentDate = this.pipe.transform(this.date, 'yyyy-MM-dd');
  id:any;

  constructor(
    private autoService: AutoService,
    private mecanicienService: MecanicienService,
    private gammeEntretientService: GammeEntretienService,
    private programmeEntretientService: ProgrammesEntretientService,

    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.initForm();

    this.id = +this.route.snapshot.paramMap.get("id");
    this.programmeEntretientService.find(this.id).then(
      data => {
        this.programme_entretient = data;
        console.log(this.programme_entretient);
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('ProgrammesEntretient.'+error.error.code)
        .subscribe(val => this.notifService.danger(val));
      }
    )
    this.getVehicules();
    this.getMecaniciens(this.page);
    this.getgamme();
    this.user = this.authService.getUser()
  }
  initForm(programme_intervention = false) {
    if(programme_intervention){
      this.dataForm = this.formBuilder.group(
        {
          compteur:[this.programme_entretient.compteur,[Validators.required]],
          date_entretient:[this.programme_entretient.date_entretien,[Validators.required]],
          km_prevu:[this.programme_entretient.km_prevu,[Validators.required]],
          heure_prevu:[this.programme_entretient.heure_prevu,[Validators.required]],
          cout:[this.programme_entretient.cout_intervention,[Validators.required]],
          gamme_entretient:[this.programme_entretient.gamme_entretien_id,[Validators.required]],
          vehicule:[this.programme_entretient.vehicule_id,[Validators.required]],
          mecanicien:[this.programme_entretient.mecanicien_id,[Validators.required]],
          next_date_entretient:[this.programme_entretient.date_prochain_entretien],
          next_km_prevu:[this.programme_entretient.km_prevu_next],
          next_heure_prevu: [this.programme_entretient.heure_prevu_next],
          description:[this.programme_entretient.description],
        }
      );
      }
    else{
      this.dataForm = this.formBuilder.group(
        {
          compteur:['',[Validators.required]],
          date_entretient:['',[Validators.required]],
          km_prevu:['',[Validators.required]],
          heure_prevu:['',[Validators.required]],
          cout:['',[Validators.required]],
          gamme_entretient:['',[Validators.required]],
          vehicule:['',[Validators.required]],
          mecanicien:['',[Validators.required]],
          next_date_entretient:[''],
          next_km_prevu:[''],
          next_heure_prevu: [''],
          description:[''],
        }
      );
    }

  }

  get form() {
    return this.dataForm.controls;
  }
  getMecaniciens(page) {
    this.mecanicienService.gets(page).then((res) => {
      this.mecaniciens = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  getVehicules() {
    this.autoService.all().then((res) => {
      this.vehicules = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  getgamme() {
    this.gammeEntretientService.gets(this.page).then((res) => {
      this.gammes_entretient = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  verifNext(){
  /*   if(this.form.next_date_entretient || this.form.next_km_prevu || this.form.next_heure_prevu){
      this.ProgrammesEntretientForm = this.formBuilder.group({
      next_date_entretient:['',[Validators.required]],
      next_km_prevu:['',[Validators.required]],
      next_heure_prevu: ['',[Validators.required]],
      })
      if(this.form.next_date_entretient.value < this.form.date_entretient.value){
        this.translate.get('ProgrammesEntretient.NextDateEntretientError')
        .subscribe(val => this.notifService.danger(val));
      return;
      }
    } */
  }
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    this.verifNext();
    if (this.dataForm.invalid) {
      this.translate.get('ProgrammesEntretient.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }
    console.log(this.form.date_entretient.value, this.currentDate)
    if(this.form.date_entretient.value < this.currentDate){
      this.translate.get('ProgrammesEntretient.DateEntretientError')
      .subscribe(val => this.notifService.danger(val));
      return;
    }
    console.log(this.form.next_date_entretient.value)
    this.isLoading = true;
    const formData = new FormData();
    formData.append('compteur', ''+this.form.compteur.value);
    formData.append('date_entretien', ''+this.form.date_entretient.value);
    formData.append('km_prevu', ''+this.form.km_prevu.value);
    formData.append('date_prochain_entretien', ''+this.form.next_date_entretient.value);
    formData.append('heure_prevu', ''+this.form.heure_prevu.value);
    formData.append('heure_prevu_next', ''+this.form.next_heure_prevu.value);
    formData.append('km_prevu_next', ''+this.form.next_km_prevu.value);
    formData.append('cout_intervention', ''+this.form.cout.value);
    formData.append('gamme_entretien_id', ''+this.form.gamme_entretient.value);
    formData.append('vehicule_id', ''+this.form.vehicule.value);
    formData.append('mecanicien_id', ''+this.form.mecanicien.value);
    formData.append('description', ''+this.form.description.value);
    formData.append('create_id', ''+this.user.id);


      console.log(formData);
      this.programmeEntretientService.put(this.id, formData)
      .then(resp => {
        this.translate.get('ProgrammesEntretient.SubmitSuccess')
        .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.dataForm.reset();
        this.router.navigate(['/programme-entretient/list']);
      })
      .catch(err => {
        console.log(err)
        this.translate.get('ProgrammesEntretient.SubmitErrorIntervention')
        .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }


}
