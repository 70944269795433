import { Component, OnInit } from '@angular/core';
import { AutoService } from '../../../../../_services/parc-service/auto-service/auto-service';
import { NotifService } from 'src/app/_services/notif.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { AddMarkAutoComponent } from 'src/app/modules/general/setting/mark/add-mark-auto/add-mark-auto.component';
import { AddModelAutoComponent } from 'src/app/modules/general/setting/modele/add-model-auto/add-model-auto.component';
import { AddTypeVehiculeAutoComponent } from 'src/app/modules/general/setting/type-vehicule/add-type-vehicule-auto/add-type-vehicule-auto.component';
import { AddCarosserieAutoComponent } from 'src/app/modules/general/setting/carosserie/add-carosserie-auto/add-carosserie-auto.component';
@Component({
  selector: 'app-auto-add',
  templateUrl: './auto-add.component.html',
  styleUrls: ['./auto-add.component.scss']
})
export class AutoAddComponent implements OnInit {
  vehicle_id: any = null;
  marques: any = null;
  modeles: any = null;
  types: any = null;
  carosseries: any = null;
  etats: any = null;
  carburants: any = null;

  //gestion des steppers
  step1Selected: boolean = true;
  step2Selected: boolean = false;
  step3Selected: boolean = false;
  stepIndexSelected = 0;
  isLinear = false;
  //gestion des formgroup
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  //
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  //variable pour la recuperation de image
  Photo_avant: File = null;
  Photo_arriere: File = null;
  Photo_gauche: File = null;
  Photo_droit: File = null;
  Photo_carte_grise: File = null;
  isMaterial: boolean = false;

  constructor(
    private autoservice: AutoService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private markDialog: MatDialog,
    private modelDilog: MatDialog,
    private typeDialog: MatDialog,
    private closureDialog: MatDialog,
    private ref: MatDialog
  ) { }

  ngOnInit(): void {
    this.initform();
    this.initform2();
    this.initform3();
    this.isMaterial = '/vehicles/add' != location.pathname;

    //initialisation des selects avec des valeurs

    this.autoservice.listMarque().then(resp => {
      this.marques = resp;

      this.isSubmitted = false;
      console.log("marques listées  avec succes");
      console.log(resp);
    })
      .catch(err => {
        console.log(err);
        console.log("probleme listing marques");
      })
      .finally(() => this.isLoading = false);



    this.autoservice.listModele().then(resp => {
      this.isSubmitted = false;
      this.modeles = resp;

      console.log("modeles listés avec succes");
      console.log(resp);
    })
      .catch(err => {
        console.log(err);
        console.log("probleme listing modeles");
      })
      .finally(() => this.isLoading = false);



    this.autoservice.listType().then(resp => {
      this.isSubmitted = false;
      this.types = resp;

      console.log("Types listés avec succes");
      console.log(resp);
    })
      .catch(err => {
        console.log(err);
        console.log("probleme listing types");
      })
      .finally(() => this.isLoading = false);



    this.autoservice.listCarosserie().then(resp => {
      this.isSubmitted = false;
      this.carosseries = resp;

      console.log("Carosseries listées avec succes");
      console.log(resp);
    })
      .catch(err => {
        console.log(err);
        console.log("probleme listing Carosseries");
      })
      .finally(() => this.isLoading = false);



  }


  get form() {
    return this.firstFormGroup.controls;
  }
  get form1() {
    return this.secondFormGroup.controls;
  }
  get form2() {
    return this.thirdFormGroup.controls;
  }

  //init form
  initform() {
    this.firstFormGroup = this.formBuilder.group({
      immatriculation: ['', [Validators.required]],
      marque: ['', [Validators.required]],
      modele: ['', [Validators.required]],
      type: ['', [Validators.required]],
      carosserie: ['', [Validators.required]],
      numero_chassis: ['', [Validators.required]],
      proprietaire: ['', [Validators.required]],
      valeur_achat: ['', [Validators.required]],
      date_mise_en_circulation: ['', [Validators.required]],
      couleur: ['', Validators.required],
      etat_véhicule: ['', [Validators.required]],
      nombre_de_place: ['', [Validators.required]],
      lieu_stationnement: ['', [Validators.required]],
    });
  }


  initform2() {
    this.secondFormGroup = this.formBuilder.group({
      Longueur: '',
      Largeur: '',
      Hauteur: '',
      Poids_à_vide: '',
      cu: '',
      poids_total_charge: '',
      Puissance: '',
      Volume: '',
      nb_essieux: '',
      Carburant: '',
      Kilométrage: '',
      conso_min: '',
      conso_max: '',
    });
  }
  initform3() {
    this.thirdFormGroup = this.formBuilder.group({
      Jour_travail_requis: '',
      Temps_travail_requis: '',
      Equipement: '',
      Usage_vehicule: '',
    });
  }
  //submit the form
  onSubmit1() {
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false
    // Si la validation a echoué, on arrete l'execution de la fonction

    if (this.firstFormGroup.invalid) {
      this.translate.get('Contact.SubmitError')
      //  .subscribe(val => this.notifService.danger(val));
      console.log("formulaire1 invalide");
      return;
    }
    //si tout ce passe bien
    this.isLoading = true;
    const formData = new FormData();
    //ajout des donnees dans le form date
    formData.append('vehicle_registration', '' + this.form.immatriculation.value);
    formData.append('vehicle_brand_id', '' + this.form.marque.value);
    formData.append('vehicle_model_id', '' + this.form.modele.value);
    formData.append('vehicle_type_id', '' + this.form.type.value);
    formData.append('vehicle_body_id', '' + this.form.carosserie.value);
    formData.append('number_frame', '' + this.form.numero_chassis.value);
    formData.append('owner', '' + this.form.proprietaire.value);
    formData.append('purchase_value', '' + this.form.valeur_achat.value);
    formData.append('date_circulation', '' + this.form.date_mise_en_circulation.value);
    formData.append('color', '' + this.form.couleur.value);
    formData.append('vehicle_condition', '' + this.form.etat_véhicule.value);
    formData.append('place_number', '' + this.form.nombre_de_place.value);
    formData.append('parking_place', '' + this.form.lieu_stationnement.value);
    if (this.vehicle_id == null) {
      this.autoservice.add(formData)
        .then(resp => {
          // this.translate.get('Contact.SubmitSuccess')
          //   .subscribe(val => this.notifService.success(val));
          this.isSubmitted = false;
          console.log("formulaire1 enregistré avec succes");
          console.log(resp);
          this.vehicle_id = resp.id;
          //reinitialisation
          /* this.firstFormGroup.reset(); 
            this.stepIndexSelected = 0;
            this.router.navigate(['/auto_add/all']); */
        })
        .catch(err => {
          console.log(err);
          console.log(this.vehicle_id);
          console.log("probleme insertion");
          //  this.translate.get('Contact.ErrorSubmit')
          //  .subscribe(val => this.notifService.danger(val));
        })
        .finally(() => this.isLoading = false);
    } else {
      this.autoservice.update(formData, this.vehicle_id)
        .then(resp => {
          // this.translate.get('Contact.SubmitSuccess')
          //   .subscribe(val => this.notifService.success(val));
          this.isSubmitted = false;
          console.log("formulaire1 modifié avec succes");
          console.log(resp);
          //reinitialisation
          /* this.firstFormGroup.reset(); 
            this.stepIndexSelected = 0;
            this.router.navigate(['/auto_add/all']); */
        })
        .catch(err => {
          console.log(err);
          console.log("probleme modification 1");
          //  this.translate.get('Contact.ErrorSubmit')
          //  .subscribe(val => this.notifService.danger(val));
        })
        .finally(() => this.isLoading = false);



    }
  }
  onSubmit2() {
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false
    // Si la validation a echoué, on arrete l'execution de la fonction
    //this.form.name.setValue(this.role_name);
    if (this.secondFormGroup.invalid) {
      this.translate.get('Contact.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      console.log("formulaire2 invalide");
      return;
    }
    //si tout ce passe bien
    this.isLoading = true;
    const formData = new FormData();
    //ajout des donnees dans le form date


    formData.append('length', '' + this.form1.Longueur.value);
    formData.append('width', '' + this.form1.Largeur.value);
    formData.append('height', '' + this.form1.Hauteur.value);
    formData.append('empty_weight', '' + this.form1.Poids_à_vide.value);
    formData.append('payload', '' + this.form1.cu.value);
    formData.append('total_load_weight', '' + this.form1.poids_total_charge.value);
    formData.append('power', '' + this.form1.Puissance.value);
    formData.append('volume', '' + this.form1.Volume.value);
    formData.append('number_semi_trailer_axles', '' + this.form1.nb_essieux.value);
    formData.append('fuel', '' + this.form1.Carburant.value);
    formData.append('milage', '' + this.form1.Kilométrage.value);
    this.isLoading = true;
    if (this.form1.conso_min.value > this.form1.conso_max.value) {
      this.toastr.success('Attention; la consomation minimale ne saurait  etre supérieure à la consommation maximale');
      // this.router.navigate(['/vehicles/list']); 
      console.log("Formulaire invalide");
      alert("Attention!!! la consomation minimale ne saurait  etre supérieure à la consommation maximale ");
      this.translate.get('Vehicle.ConsumptionError')
        .subscribe(val => this.notifService.danger(val));
      this.isLoading = false;
      return;
    } else {

      formData.append('min_consumption', '' + this.form1.conso_min.value);
      formData.append('max_consumption', '' + this.form1.conso_max.value);



      this.autoservice.update(formData, this.vehicle_id)
        .then(resp => {
          // this.translate.get('Contact.SubmitSuccess')
          //   .subscribe(val => this.notifService.success(val));
          this.isSubmitted = false;
          console.log("formulaire 2 ajouté avec succes");
          console.log(resp);

        })
        .catch(err => {
          console.log(err);
          console.log("probleme enregistrement formulaire 2");
          //  this.translate.get('Contact.ErrorSubmit')
          //  .subscribe(val => this.notifService.danger(val));
        })
        .finally(() => this.isLoading = false);
    }
  }
  onSubmit3() {
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false
    // Si la validation a echoué, on arrete l'execution de la fonction
    //this.form.name.setValue(this.role_name);
    if (this.thirdFormGroup.invalid) {
      //this.translate.get('Contact.SubmitError')
      //  .subscribe(val => this.notifService.danger(val));
      console.log("formulaire 3 invalide");
      return;
    }
    //si tout ce passe bien
    this.isLoading = true;
    const formData = new FormData();
    //ajout des donnees dans le form date

    formData.append('working_day', '' + this.form2.Jour_travail_requis.value);
    formData.append('required_working_time', '' + this.form2.Temps_travail_requis.value);
    formData.append('equipement', '' + this.form2.Equipement.value);
    formData.append('vehicle_use', '' + this.form2.Usage_vehicule.value);



    //recuperation  des images
    formData.append('photo_before_vehicle', this.Photo_avant);
    formData.append('photo_rear_vehicle', this.Photo_arriere);
    formData.append('photo_leftside_vehicle', this.Photo_gauche);
    formData.append('photo_rightside_vehicle', this.Photo_droit);
    formData.append('car_registration_photo', this.Photo_carte_grise);

    this.autoservice.update(formData, this.vehicle_id)
      .then(resp => {
        // this.translate.get('Contact.SubmitSuccess')
        //   .subscribe(val => this.notifService.success(val));
        this.toastr.success('Véhicule ajouté  avec success');
        this.isSubmitted = false;
        //reinitialisation
        this.firstFormGroup.reset();
        this.secondFormGroup.reset();
        this.thirdFormGroup.reset();
        this.stepIndexSelected = 0;
        //this.router.navigate(['/vehicles/list']);
        console.log("formulaire 3 ajouté avec succes")
        console.log(resp)
      })
      .catch(err => {
        console.log(err)
        //  this.translate.get('Contact.ErrorSubmit')
        //  .subscribe(val => this.notifService.danger(val));
        this.toastr.error('L Operation a echouée');
        console.log("probleme ajout du formulaire 3 ")
      })
      .finally(() => this.isLoading = false);
  }


  detectimage1(event) {
    this.Photo_avant = event.target.files[0];
    console.log(this.Photo_avant);
  }
  detectimage2(event) {
    this.Photo_arriere = event.target.files[0];
    console.log(this.Photo_arriere);
  }
  detectimage3(event) {
    this.Photo_gauche = event.target.files[0];
    console.log(this.Photo_gauche);
  }
  detectimage4(event) {
    this.Photo_droit = event.target.files[0];
    console.log(this.Photo_droit);
  }
  detectimage5(event) {
    this.Photo_carte_grise = event.target.files[0];
    console.log(this.Photo_carte_grise);
  }

  previous1() {
    this.stepIndexSelected = 0;
  }
  previous2() {
    this.stepIndexSelected = 1;
  }
  previous3() {
    this.stepIndexSelected = 2;
  }

  //validation de chaque etape
  validStep1() {
    if (this.firstFormGroup.invalid) {
      this.stepIndexSelected = 0;
      this.isSubmitted = true;
      // this.translate.get('Contact.SubmitError')
      //  .subscribe(val => this.notifService.danger(val));
    }
    else {
      this.isSubmitted = false;
      this.stepIndexSelected = 1;
    }
  }
  //step 2
  validStep2() {
    if (this.secondFormGroup.invalid) {
      this.stepIndexSelected = 1;
      this.isSubmitted = true;
      // this.translate.get('Contact.SubmitError')
      //  .subscribe(val => this.notifService.danger(val));
    }
    else {
      this.isSubmitted = false;
      this.stepIndexSelected = 2;
    }
  }
  //valid step 3
  validStep3() {
    if (this.secondFormGroup.invalid) {
      this.stepIndexSelected = 2;
      this.isSubmitted = true;
      //  this.translate.get('Contact.SubmitError')
      //   .subscribe(val => this.notifService.danger(val));
    }
    else {
      this.isSubmitted = false;
      this.stepIndexSelected = 3;
    }
  }


  addMark() {
   var markDialogef =  this.markDialog.open(AddMarkAutoComponent,{
      width: '700px',
      height: '700px',
      disableClose: true
    });

    markDialogef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      this.ngOnInit();
    });
  }
 
  addModel() {
    var modelDialogef =  this.modelDilog.open(AddModelAutoComponent,{
       width: '700px',
       height: '700px',
       disableClose: true
     });
 
     modelDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);
 
       this.ngOnInit();
     });
   }
  

   addType() {
    var typeDialogef =  this.typeDialog.open(AddTypeVehiculeAutoComponent,{
       width: '700px',
       height: '700px',
       disableClose: true
     });
 
     typeDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);
 
       this.ngOnInit();
     });
   }

   addClosure() {
    var closureDialogef =  this.closureDialog.open(AddCarosserieAutoComponent,{
       width: '700px',
       height: '700px',
       disableClose: true
     });
 
     closureDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);
 
       this.ngOnInit();
     });
   }
  
  onClose(){
    this.ref.closeAll();
  }
    

}
