import { Component, OnInit } from '@angular/core';
import { TypeInterventionService } from 'src/app/_services/maintenance/interventions/type-intervention.service';
import { InterventionService } from 'src/app/_services/maintenance/interventions/intervention.service';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';


@Component({
  selector: 'app-intervention-update',
  templateUrl: './intervention-update.component.html',
  styleUrls: ['./intervention-update.component.scss']
})
export class InterventionUpdateComponent implements OnInit {

  user:any;
  InterventionForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  page=1;
  type_interventions:any;
  intervention_id:any;
  Interventions:any;


  constructor(
    private InterventionService: InterventionService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private TypeInterventionService: TypeInterventionService,
  ) {}

  ngOnInit() {
    this.getTypeIntervention();
    this.user = this.authService.getUser()
    this.initForm();
    this.intervention_id = +this.route.snapshot.paramMap.get("id");
    this.InterventionService.find(this.intervention_id).then(
      data => {
        this.Interventions = data;
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('Interventions.'+error.error.code)
        .subscribe(val => this.notifService.danger(val));
      }
    )
  }
  initForm(withIntervention = false) {
    if(withIntervention){
      this.InterventionForm = this.formBuilder.group(
        {
          name: [this.Interventions.nom, [Validators.required]],
          typeIntervention: [this.Interventions.type_intervention_id, [Validators.required]],
          description: [this.Interventions.description],
        }
      );
      }
    else{
      this.InterventionForm = this.formBuilder.group(
        {
          name: ["", [Validators.required]],
          typeIntervention: ["", [Validators.required]],
          description: [""],
        }
      );
    }

  }
  get form() {
    return this.InterventionForm.controls;
  }
  getTypeIntervention() {
    this.InterventionService.getTypeIntervention().then((res) => {
      this.type_interventions = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;


    if (this.InterventionForm.invalid) {
      this.translate.get('Intervention.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('nom', ''+this.form.name.value);
    formData.append('type_intervention_id', ''+this.form.typeIntervention.value);
    formData.append('description', ''+this.form.description.value);

      console.log(formData);
      this.InterventionService.put(this.intervention_id, formData)
      .then(resp => {
        this.translate.get('Intervention.SubmitSuccess')
        .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.InterventionForm.reset();
        this.router.navigate(['/intervention/list'])
      })
      .catch(err => {
        console.log(err)
        this.translate.get('Intervention.SubmitErrorIntervention')
        .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }


}
