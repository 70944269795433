<div class="content-wrapper" style="min-height: 1200.88px;  margin-left: 1%; margin-right: 1%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>{{ "Chauffeur.Chauffeur" | translate }}</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                        <li class="breadcrumb-item "><a [routerLink]="['/chauffeur/all']">{{ "Chauffeur.Chauffeur" |
                                translate }}</a></li>
                        <li class="breadcrumb-item active">{{ "FileAriane.Detail" | translate }}</li>

                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="container">
        <div class="container-fluid">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <div class="position-relative mb-4">
                            <!-- <div class="chartjs-size-monitor">
                                <div class="chartjs-size-monitor-expand">
                                    <div class=""></div>
                                </div>
                                <div class="chartjs-size-monitor-shrink">
                                    <div class=""></div>
                                </div>
                            </div> -->
                            <div class="col-sm-12">
                                <img style="height:400px; margin-left:40%" class="img-fluid" src="{{driver?.avatar}}"
                                    alt="photo">
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <div class="row">

                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-header border-0">
                            <h3 class="card-title">Information personnel</h3>

                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-striped table-valign-middle">
                                <tbody>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>
                                        <td>{{ "Chauffeur.nameLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{driver?.name}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Chauffeur.emailLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{driver?.email}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Chauffeur.CNI_numberLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{driver?.CNI_number}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Chauffeur.picture_CNILabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);"><a
                                                href="{{driver?.picture_CNI}}">Parcourir</a></td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Chauffeur.sexeLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{driver?.sexe}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Chauffeur.birth_dateLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{driver?.birth_date}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Chauffeur.birth_placeLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{driver?.birth_place}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Chauffeur.phone_number_1Label" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{driver?.phone_number_1}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Chauffeur.phone_number_2Label" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{driver?.phone_number_2}}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-header border-0">
                            <h3 class="card-title">Information personnel</h3>

                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-striped table-valign-middle">
                                <tbody>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Chauffeur.marital_statusrLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{driver?.marital_status}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Chauffeur.childrenLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{driver?.children}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Chauffeur.residence_townLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{driver?.residence_town}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Chauffeur.contact_personLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{driver?.contact_person}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Chauffeur.emergency_phoneLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{driver?.emergency_phone}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Chauffeur.adressLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{driver?.adress}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Chauffeur.medical_fileLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);"><a
                                                href="{{driver?.medical_file}}">Parcourir</a></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

                <!-- /.col-md-6 -->
            </div>

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Information Permis</h3>
                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Chauffeur.LicenceNumberLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{driver?.licence_number}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Chauffeur.LicenceTypeLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{driver?.licence_type}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Chauffeur.EndDateLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{driver?.end_of_date}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Chauffeur.LicenceValidityFileLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);"><a
                                            href="{{driver?.licence_validity_file}}">Parcourir</a></td>
                                </tr>
                                <!-- <tr>
                                    <td>Avez-vous une formation autre que le permis?</td>

                                    <th>Oui</th>
                                    <th>Non</th>

                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</div>