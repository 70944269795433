<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>{{ "Pneu.Pneu" | translate }}</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
          <li class="breadcrumb-item "><a [routerLink]="['/pneu/all']">{{ "Pneu.Pneu" |
              translate }}</a></li>
          <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>

        </ol>
      </div>
    </div>
  </div>
</section>

<section class="container">
  <div class="row">
    <div class="col-md-12">
      <form (submit)="onSubmit()" [formGroup]="dataForm">
        <div class="card card-primary">

          <div class="card-header" style="background-color:#17a2b8;">
            <h3 class="card-title">{{ "Pneu.PneuUpdate" | translate }}</h3>
          </div>
          <div class="card-body" style="display: block;">
            <div class="row">
              <div class="form-group col-md-6">
                <label>{{ "Pneu.MarquesLabel" | translate }}
                  <span style="color: red">*</span>
                </label>
                <select formControlName="marque_id"
                  class="form-control {{ isSubmitted && form.marque_id.errors? 'is-invalid' : '' }}"
                  style="width: 100%;" tabindex="-1" aria-hidden="true">
                  <option value="">{{ "Pneu.LabelMarque" | translate }}</option>
                  <option *ngFor="let item of marque_pneus" value="{{item.id}}">{{item.name}}</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>{{ "Pneu.FournisseurLabel" | translate }}
                  <span style="color: red">*</span>
                </label>
                <select formControlName="fournisseur"
                  class="form-control {{ isSubmitted && form.fournisseur.errors? 'is-invalid' : '' }}"
                  style="width: 100%;" tabindex="-1" aria-hidden="true">
                  <option value="">{{ "Pneu.LabelFournisseur" | translate }}</option>
                  <option *ngFor="let item of intervening_elements?.data" value="{{item.id}}">{{item.name}}</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-6">
                <label for="exampleName1">{{ "Pneu.No_serieLabel" | translate }} <span
                    style="color: red;">*</span></label>
                <input type="text" formControlName="no_serie"
                  class="form-control {{ isSubmitted && form.no_serie.errors? 'is-invalid' : '' }}" id="exampleName1"
                  name="no_serie" placeholder="{{ 'Pneu.LabelNoserie' | translate }}" />
              </div>

              <div class="form-group col-md-6">
                <label for="exampleName1">{{ "Pneu.Etat_achatLabel" | translate }}<span style="color: red">*</span>
                </label>
                <input type="text" formControlName="etat_achat"
                  class="form-control {{ isSubmitted && form.etat_achat.errors? 'is-invalid' : '' }}" id="exampleName1"
                  name="etat_achat" placeholder="{{ 'Pneu.LabelEtatAchat' | translate }}" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="position-relative form-group">
                  <label for="exampleSelect" class="">{{ 'Pneu.Etat_actuelLabel' | translate }}</label>
                  <select name="etat_actuel" formControlName="etat_actuel"
                    class="form-control {{ isSubmitted && form.etat_actuel.errors ? 'is-invalid' : '' }}">
                    <option value="BON">BON ETAT</option>
                    <option value="MAUVAIS">MAUVAIS ETAT </option>
                    <option value="PASSABLE">ETAT PASSABLE</option>
                  </select>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="exampleName1">{{ "Pneu.Date_achatLabel" | translate }}<span style="color: red">*</span>
                </label>
                <input type="date" formControlName="date_achat"
                  class="form-control {{ isSubmitted && form.date_achat.errors ? 'is-invalid' : '' }}" id="exampleName1"
                  name="date_achat" placeholder="{{ 'Pneu.LabelDateAchat' | translate }}" />
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-6">
                <label for="exampleName1">{{ "Pneu.Date_fabricationLabel" | translate }}<span
                    style="color: red">*</span> </label>
                <input type="date" formControlName="date_fabrication"
                  class="form-control {{ isSubmitted && form.date_fabrication.errors ? 'is-invalid' : '' }}"
                  id="exampleName1" name="date_fabrication"
                  placeholder="{{ 'Pneu.LabelDateFabrication' | translate }}" />
              </div>
              <div class="form-group col-md-6">
                <label for="exampleName1">{{ "Pneu.DimensionsLabel" | translate }}<span style="color: red">*</span>
                </label>
                <input type="text" formControlName="dimensions"
                  class="form-control {{ isSubmitted && form.dimensions.errors ? 'is-invalid' : '' }}" id="exampleName1"
                  name="dimensions" placeholder="{{ 'Pneu.LabelDimenssions' | translate }}" />
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-6">
                <label for="exampleName1">{{ "Pneu.Valeur_achatLabel" | translate }}<span style="color: red">*</span>
                </label>
                <input type="number" formControlName="valeur_achat"
                  class="form-control {{ isSubmitted && form.valeur_achat.errors ? 'is-invalid' : '' }}"
                  id="exampleName1" name="valeur_achat" placeholder="{{ 'Pneu.LabelValeurAchat' | translate }}" />
              </div>
              <div class="form-group col-md-6">
                <label>{{ "Pneu.Duree_de_vieLabel" | translate }}</label>
                <input type="number" id="inputName"
                  class="form-control {{ isSubmitted && form.duree_de_vie.errors? 'is-invalid' : '' }}"
                  formControlName="duree_de_vie" placeholder="{{ 'Pneu.LabelDureeDeVie' | translate }}">
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-6">
                <label for="exampleName1">{{ "Pneu.Indice_de_chargeLabel" | translate }} </label>
                <input type="number" formControlName="indice_de_charge"
                  class="form-control {{ isSubmitted && form.indice_de_charge.errors ? 'is-invalid' : '' }}"
                  id="exampleName1" name="indice_de_charge"
                  placeholder="{{ 'Pneu.LabelIndiceDeCharge' | translate }}" />
              </div>
              <div class="form-group col-md-6">
                <label for="exampleName1">{{ "Pneu.Indice_de_vitesseLabel" | translate }} </label>
                <input type="number" formControlName="indice_de_vitesse"
                  class="form-control {{ isSubmitted && form.indice_de_vitesse.errors ? 'is-invalid' : '' }}"
                  id="exampleName1" name="indice_de_vitesse"
                  placeholder="{{ 'Pneu.LabelIndiceDeVitesse' | translate }}" />
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-6">
                <label for="exampleName1">{{ "Pneu.Type_achatLabel" | translate }} </label>
                <select formControlName="type_achat"
                  class="form-control {{ isSubmitted && form.type_achat.errors? 'is-invalid' : '' }}"
                  style="width: 100%;" tabindex="-1" aria-hidden="true">
                  <option value="">{{ "Pneu.LabelTypeAchat" | translate }}</option>
                  <option value="neuve">{{ "Pneu.Neuve" | translate }}</option>
                  <option value="occasion">{{ "Pneu.Occasion" | translate }}</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label for="exampleName1">{{ "Pneu.ObservationLabel" | translate }} </label>
                <textarea class="form-control" rows="3" placeholder="{{ 'Pneu.LabelObservation' | translate }}"
                  formControlName="observation"></textarea>
              </div>

            </div>

          </div>
          <div class="card-footer">
            <button [disabled]="isLoading" class="btn btn-primary" style="background-color:#17a2b8;">
              <p *ngIf="!isLoading">{{ "General.update" | translate }}</p>
              <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>

        </div>
      </form>

    </div>
  </div>
</section>