<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>{{ "Operation.Operation" | translate }}</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
          <li class="breadcrumb-item "><a [routerLink]="['/operation/all']">{{ "Operation.Operation" |
              translate }}</a></li>
          <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>

        </ol>
      </div>
    </div>
  </div><!-- /.container-fluid -->
</section>

<!-- Main content -->

<section class="container">
  <div class="row">
    <div class="col-md-12">
      <form (submit)="onSubmit()" [formGroup]="dataForm">
        <div class="card card-primary">

          <div class="card-header" style="background-color: #17a2b8">
            <h3 class="card-title">{{ "Operation.OperationAdd" | translate }}</h3>
          </div>
          <div class="card-body" style="display: block;">
            <div class="form-group">
              <label>{{ "Operation.VehiculeLabel" | translate }}
                <span style="color: red">*</span>
              </label>
              <select 
                class="form-control"
                formControlName="vehicule_id"
                style="width: 100%;" tabindex="-1" aria-hidden="true" (change)="PositionModel($event)">
               <option value="">{{ "Operation.vehiculeFiche" | translate }}</option>
               <option *ngFor="let item of vehicules" value="{{item.id}}">{{item.vehicle_registration}}</option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ "Operation.PneuLabel" | translate }}
                <span style="color: red">*</span></label>
              <select formControlName="pneu_id"
                class="form-control {{ isSubmitted && form.pneu_id.errors? 'is-invalid' : '' }}" style="width: 100%;"
                tabindex="-1" aria-hidden="true">
                <option value="">{{ "Operation.PneuFiche" | translate }}</option>
                <option *ngFor="let item of pneus" value="{{item.id}}">{{item.no_serie}}</option>
              </select>
            </div>
           
            <div class="form-group">
              <label>{{ "Operation.DateLabel" | translate }} <span style="color: red;">*</span>
              </label>
              <input type="date" id="inputName"
                class="form-control {{ isSubmitted && form.date.errors? 'is-invalid' : '' }}" formControlName="date"
                placeholder="{{ 'Operation.LabeldateFiche' | translate }}">
            </div>
            <div class="form-group">
              <label>{{ "Operation.IndexLabel" | translate }} <span style="color: red;">*</span>
              </label>
              <input type="number" id="inputName"
                class="form-control {{ isSubmitted && form.index_km.errors? 'is-invalid' : '' }}"
                formControlName="index_km" placeholder="{{ 'Operation.LabelIndex' | translate }}">
            </div>
            <div class="form-group">
              <label>{{ "MarquePneu.DescriptionLabel" | translate }}</label>
              <textarea class="form-control" rows="3" placeholder="{{ 'MarquePneu.LabelDescription' | translate }}"
                formControlName="description"></textarea>
            </div>
            <div class="form-group">
              <p>{{ "Operation.TacheLabel" | translate }}</p>
              <div data-toggle="modal" data-target="#positionList">
                <input type="checkbox" id="monter" formControlName="monter" (click)="position('monter')">
                <label (click)="position('monter')">{{ "Operation.MonterLabel" | translate }}</label>
              </div>
              <div data-toggle="modal" data-target="#positionList">
                <input type="checkbox" id="inputName" formControlName="demonter" (click)="position('demonter')">
                <label (click)="position('demonter')">{{ "Operation.DemonterLabel" | translate }}</label>
              </div>
              <div class="modal fade" id="positionList" >

                <div class="modal-dialog modal-dialog-centred modal-dialog-scrollable">
              
                    <div class="modal-content">
                        <div class="modal-header"> 
                            <h1 class="modal-title text-primary" id="exampleModalCenteredScrollableTitle">
                                choisir une position
                            </h1>
                            <button type="button" class="btn-close btn-circle" data-dismiss="modal"
                                aria-label="Close">&times;</button>
                        </div>
              
                        <div class="modal-body">
                          <div *ngIf="monter || demonter" class="col-md-12 card-body table-responsive p-0">
                            <table border="1">
                                <thead>
                                    <tr>
                                        <th>
                          
                                            1<sup>er</sup> ranger
                          
                                        </th>
                                        <th>
                          
                                            2<sup>eme</sup> ranger
                          
                                        </th>
                                        <th>
                          
                                            1<sup>er</sup> reserve
                          
                                        </th>
                          
                                        <th>
                          
                                            3<sup>eme</sup> ranger
                          
                                        </th>
                                        <th>
                          
                                            4<sup>eme</sup> ranger
                          
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of rows, let i = index">
                                        <td *ngFor="let col of columns, let j = index" (click)="onClick(i,j)">
                                            <div *ngIf="matched(i,j)" class="position" >
                                                <!-- Position({{j}}, {{i}}) -->
                                            </div>
                                        </td>           
                                    </tr>
                                </tbody>
                            </table>           
                          </div>
                        </div>
              
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">{{"Material.ButtonClose" | translate}}</button>
                        </div>
                    </div>
                </div>
              </div>
            </div>                      
          </div>
          <div class="card-footer">
            <button [disabled]="isLoading" type="submit" class="btn btn-primary" style="background-color: #17a2b8;">
              <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
              <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>





