<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-1">
          <div class="col-sm-6">
            <h1>
              <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">{{ "Position.Position" | translate }}</font>
              </font>
            </h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
              <li class="breadcrumb-item "><a [routerLink]="['/position/all']">{{ "Position.Position" | translate }}</a></li>
              <li class="breadcrumb-item active">{{ "FileAriane.List" | translate }}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  

    <section class="content">
      <div class="card">
        <div class="card-header" style="background-color: #17a2b8; color:white;">
          <h1 class="card-title">
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;" > {{ "Position.PositionAll" | translate }}</font>
            </font>
          </h1>
          <button [routerLink]="['/Position/add']" type="button" class="btn btn-primary float-right">
            <i class="fas fa-plus"></i> {{ "Forms.Marques.Ajouter" | translate }}
          </button>
        </div>
        
        <div class="card-body p-0">
          <table id="example1" class="table table-bordered table-striped">
            <thead>
              <tr>
                <th style="width: 1%">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      #
                    </font>
                  </font>
                </th>
  
                <th style="width: 15%">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ "Position.ModelLabel" | translate }}
                    </font>
                  </font>
                </th>

                <th style="width: 10%">
                    <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">
                        {{ "Position.ColoneLabel" | translate }}
                      </font>
                    </font>
                </th>

                <th style="width: 15%">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ "Position.LigneLabel" | translate }}
                    </font>
                  </font>
                </th>
                <th style="width: 15%;"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let affect of positions,let i = index" id="{{ affect.id }}">
                <td>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ i+1 }}
                    </font>
                  </font>
                </td>
                <td>
                  <a>
                    <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">
                        {{ affect.model }}
                      </font>
                    </font>
                  </a>
                </td>
                <td>
                    <a>
                      <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">
                          {{ affect.indice_colone }}
                        </font>
                      </font>
                    </a>
                </td>
                <td>
                    <div>
                        <a>
                            <font style="vertical-align: inherit;">
                              <font style="vertical-align: inherit;">
                                {{ affect.indice_ligne }}
                              </font>
                            </font>
                          </a>
                    </div>
                </td>
                
                <td class="project-actions text-right">
                  <a class="btn btn-primary btn-sm" href="javascript:void(0)" data-bs-placement="top" title="details"  (click)="detailsPosition(affect.id)"> 
                    <i class="fas fa-eye"></i>
                  </a>
                  &nbsp;
                  <a class="btn btn-info btn-sm" href="javascript:void(0)" data-bs-placement="top" title="modifier"  (click)="editPosition(affect.id)">
                    <i class="fas fa-pencil-alt">
                    </i>
                  </a>
                  &nbsp;
                  <a class="btn btn-danger btn-sm" href="javascript:void(0)" data-bs-placement="top" title="supprimer"  (click)="deletePosition(affect.id)">
                    <i class="fas fa-trash">
                    </i>
                  </a>
                </td>
              </tr>
  
            </tbody>
          </table>
          <div class="card-footer">
          </div>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
  
    </section>
    <!-- /.content -->
  </div>

