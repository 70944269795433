<span style="margin-left: 80%;"><a [routerLink]="['/official_all/',type.id]" type="button" style="color: blue;">{{ 'OFFICIALPAPER.AccueilLabel' | translate }}</a>        / Ajout</span>
<div class="row" style="margin-top: 3%;min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <div class="col-md-12" id="form">
        <div class="tabs-animation">
            <div class="card card-primary">
                <div class="card-header" style="background-color:#17a2b8;" >
                    <h3 class="card-title">
                        {{ 'OFFICIALPAPER.OfficialAddLabel' | translate }} {{type.name}}
                    </h3>
                </div>
                <form (submit)="onSubmit()" [formGroup]="OfficialPaperForm">
                    <div class="card-body">
                        <div class="position-relative form-group">
                            <label for="vehicule" class="">{{ 'OFFICIALPAPER.VehiculeLabel' | translate }}</label>
                            <select formControlName="vehicle_id" class="form-control {{ isSubmitted && form.vehicle_id.errors ? 'is-invalid' : '' }}" >
                                <option [value]="vehicle.id" *ngFor="let vehicle of vehicules" >{{vehicle.vehicle_registration}}</option>
                            </select>
                        </div>
                        <div class="position-relative form-group">
                            <label for="date1" class="">{{ 'OFFICIALPAPER.Start_DateLabel' | translate }}</label>
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <i class="fa fa-calendar-alt"></i>
                                </div>
                                <input  id="date1"  type="date" formControlName="validity_start_at"  class="form-control {{ isSubmitted && form.validity_start_at.errors ? 'is-invalid' : '' }}">
                            </div>
                        </div>
                        <div class="position-relative form-group">
                            <label for="date2" class="">{{ 'OFFICIALPAPER.End_DateLabel' | translate }}</label>
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <i class="fa fa-calendar-alt"></i>
                                </div>
                                <input  id="date2"  type="date" formControlName="validity_end_at"  class="form-control {{ isSubmitted && form.validity_end_at.errors ? 'is-invalid' : '' }}">
                            </div>
                        </div>
                        <div class="position-relative form-group">
                            <label for="numero2" class="">{{ 'OFFICIALPAPER.MontantLabel' | translate }}</label>
                            <input placeholder="veuillez entrer un nombre" id="numero2"  type="number" formControlName="amount"  class="form-control {{ isSubmitted && form.amount.errors ? 'is-invalid' : '' }}">
                        </div>
                        <div class="position-relative form-group">
                            <label for="numero1" class="">{{ 'OFFICIALPAPER.DoneByLabel' | translate }}</label>
                            <input placeholder="veuillez entrer un nom" id="numero1"  type="text" formControlName="done_by"  class="form-control {{ isSubmitted && form.done_by.errors ? 'is-invalid' : '' }}">
                        </div>
                        <div class="position-relative form-group">
                            <label for="numero2" class="">{{ 'OFFICIALPAPER.NumRecuLabel' | translate }}</label>
                            <input placeholder="veuillez entrer un nombre" id="numero2"  type="number" formControlName="receipt_number"  class="form-control {{ isSubmitted && form.receipt_number.errors ? 'is-invalid' : '' }}">
                        </div>
                        <div class="position-relative form-group">
                            <label for="exampleFile" class="">{{ 'OFFICIALPAPER.PieceLabel' | translate }}</label>
                            <input  id="exampleFile"  type="file" (change)=detectfile($event)  formControlName='attachment_file' class="form-control-file">
                        </div>
                        <div class="d-flex align-items-center" style="width: 20%;margin-left: 1%;margin-top: 5%;">
                            <button [disabled]="isLoading" class="btn btn-primary btn-lg"
                                style="width: 20%; justify-content: center; align-items: center; flex: 1;" style="background-color:#17a2b8;" >
                                <p *ngIf="!isLoading"
                                    style="font-size: 1.2em; padding: 0px; margin: 5px;">
                                    {{ 'OFFICIALPAPER.AjouterLabel' | translate }}</p>
                                    <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                            </button>
                        </div>
                    </div>                
                </form>
            </div>
        </div>
    </div>
   

</div>