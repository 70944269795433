<!-- <table class="table table-striped table-bordered table-sm row-border hover" datatable [dtOptions]="dtOptions">
    <thead>
        <tr>
            <th>#</th>
            <th>{{ "Forms.Marques.Nom" | translate }}</th>
            <th>{{ "Forms.Marques.Description" | translate }}</th>
            <th>{{ "Forms.Marques.Date" | translate }}</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let mark of data,let i = index" id="{{ mark.id }}">
            <td>{{i+1}}</td>
            <td>{{mark.name}} </td>
            <td>{{mark.description | slice:0:60}}</td>
            <td>{{mark.created_at | date: 'dd-mm-yyyy'}}</td>

            <td class="project-actions text-right">
                <a class="btn btn-primary btn-sm" (click)="detailsModel(mark)" href="javascript:void(0)">
                    <i class="fas fa-folder">
                    </i>
                    {{"Action.View" | translate }}
                </a>
                <a class="btn btn-info btn-sm" (click)="editModel(mark)" href="javascript:void(0)">
                    <i class="fas fa-pencil-alt">
                    </i>
                    {{"Action.Edit" | translate }}
                </a>
                <a class="btn btn-danger btn-sm" (click)="deleteModel(mark)" href="javascript:void(0)">
                    <i class="fas fa-trash">
                    </i>
                    {{"Action.Delete" | translate }}
                </a>
            </td>
        </tr>
    </tbody>
</table> -->
<div>
    <table id="example1" class="table table-bordered table-striped">
        <thead>
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Website</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let group of marks">
                <td>{{group.name}}</td>
                <td>{{group.email}}</td>
                <td>{{group.website}}</td>
                <td class="project-actions text-right">
                    <a class="btn btn-primary btn-sm" (click)="detailsModel(mark)" href="javascript:void(0)">
                        <i class="fas fa-folder">
                        </i>
                    </a>
                    <a class="btn btn-info btn-sm" (click)="editModel(mark)" href="javascript:void(0)">
                        <i class="fas fa-pencil-alt">
                        </i>
                    </a>
                    <a class="btn btn-danger btn-sm" (click)="deleteModel(mark)" href="javascript:void(0)">
                        <i class="fas fa-trash">
                        </i>
                    </a>
                </td>

            </tr>
        </tbody>
    </table>
</div>