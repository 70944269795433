<div class="row">
    <div class="col-md-4">
        <section class="content">
            <div class="container-fluid">
                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">{{"Profile.name" | translate}}</h3>
                    </div>
                    <div class="card-body box-profile">
                        <div class="text-center">
                            <img src="{{completedUser?.user?.avatar}}" class="profile-user-img img-fluid img-circle"
                                alt="User profile picture">
                        </div>

                        <h3 class="profile-username text-center"> {{completedUser?.user?.sexe == 'M' ? 'M' : 'Mme'}}
                            {{completedUser?.user.name}}</h3>

                        <!--                         <p class="text-muted text-center">{{user?.roles[0]?.display_name}}</p>
 -->
                        <ul class="list-group list-group-unbordered mb-3">
                            <li class="list-group-item">
                                <b>{{"Profile.birthInfo" | translate }}</b> <a class="float-right">
                                    {{completedUser?.user.birth_date}} <span
                                        *ngIf="completedUser?.user?.birth_place != null"> at
                                        {{completedUser?.user.birth_place}} </span> </a>
                            </li>
                            <li class="list-group-item">
                                <b>Join on </b> <a class="float-right">{{completedUser?.user?.created_at | date :
                                    'yyyy-mm-dd' }}</a>
                            </li>
                            <li class="list-group-item">
                                <b>{{"Profile.child" | translate }}</b> <a
                                    class="float-right">{{completedUser?.user.children}}</a>
                            </li>
                            <li class="list-group-item">
                                <b>Status matrimoniale</b> <a class="float-right">
                                    {{completedUser?.user.marital_status}}
                                </a>
                            </li>
                            <li class="list-group-item">
                                <b>{{"Profile.email" | translate }}</b> <a class="float-right">
                                    {{completedUser?.user.email}}
                                </a>
                            </li>
                            <li class="list-group-item">
                                <b>{{"Profile.location" | translate }}</b> <a class="float-right">
                                    {{completedUser?.user.residence_town}}
                                </a>
                            </li>
                            <li class="list-group-item">
                                <b>{{"Profile.contact_telephonique" | translate }}</b> <a class="float-right">
                                    {{completedUser?.user.phone_number_1}} {{completedUser?.user.phone_number_2}}
                                </a>
                            </li>
                            <li class="list-group-item">
                                <b>{{"Profile.emmergency" | translate }}</b> <a class="float-right">
                                    {{completedUser?.user.contact_person}}
                                </a>
                            </li>
                            <li class="list-group-item">
                                <b>{{"Profile.emergency_phone" | translate }}</b> <a class="float-right">
                                    {{completedUser?.user.emergency_phone}}
                                </a>
                            </li>
                            <li class="list-group-item">
                                <b>{{"Profile.ville" | translate }}</b> <a class="float-right">
                                    {{completedUser?.user.adress}}
                                </a>
                            </li>
                            <li class="list-group-item">
                                <b>{{"Profile.numero_CNI" | translate }}</b> <a class="float-right">
                                    {{completedUser?.user.CNI_number}}
                                </a>
                            </li>
                            <li class="list-group-item">
                                <b>{{"Profile.residence_town" | translate }}</b> <a class="float-right">
                                    {{completedUser?.user.residence_town}}
                                </a>
                            </li>

                        </ul>

                        <a href="#" class="btn btn-primary"><b>Follow</b></a>
                    </div>
                </div>
            </div>
        </section>
    </div>



    <!-- 
    <div class="col-md-8">
        <div class="menu-header-btn-pane pt-2">
            <div role="group" class="btn-group text-center">
                <div class="nav">
                    <button class="mb-2 mr-2 border-0 btn-transition btn btn-outline-secondary"
                        [routerLink]="['/users/update/'+Profile?.id]">{{ 'Profile.ProfileUpdate' | translate
                        }}
                    </button>
                    <button class="mb-2 mr-2 border-0 btn-transition btn btn-outline-secondary"
                        [routerLink]="['/update-password']">{{ 'Profile.PasswordUpdate' | translate }}
                    </button>
                    <button class="mb-2 mr-2 border-0 btn-transition btn btn-outline-secondary"
                        [routerLink]="['/contrat/all/'+Profile?.id]">{{ 'Profile.ProfileContrat' |
                        translate }}
                    </button>
                    <button class="mb-2 mr-2 border-0 btn-transition btn btn-outline-secondary"
                        [routerLink]="['/sanction/all/'+Profile?.id]">{{ 'Profile.ProfileSanction' |
                        translate }}
                    </button>
                </div>
            </div>
        </div>
    </div> -->

</div>