<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-1">
        <div class="col-sm-6">
          <h1>
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">{{ 'Role.Roles' | translate }}</font>
            </font>
          </h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/role-all']">{{ 'Role.Roles' | translate }}</a></li>
            <li class="breadcrumb-item active">{{ "FileAriane.List" | translate }}</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>
<section class="content" style="padding: 2%;">

    <!-- Default box -->
    <div class="card">
        <div class="card-header" style="background-color: #17a2b8; color: white;">
            <h1 class="card-title">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">{{ "Role.List" | translate }}</font>
                </font>
              </h1>
            <button *ngIf="canCreate" [routerLink]="['/role-add']" type="button" class="btn btn-primary float-right">
                <i class="fas fa-plus"></i>{{ "Role.AddElement" | translate }}
            </button>
        </div>
        <div class="card-body p-0">
            <table id="example1" class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{{ 'Role.NameLabel' | translate }}</th>
                        <th>{{ 'Role.LabelLabel' | translate }}</th>
                        <th>{{ 'Role.DescriptionLabel' | translate }}</th>
                        <th>{{ 'Role.CreatedAtLabel' | translate }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let role of roles,let i = index" id="{{ role.id }}">
                        <td>{{ i+1 }}</td>
                        <td style="width: 15%">{{ role.name }}</td>
                        <td style="width: 15%">{{ role.display_name }}</td>
                        <td style="width: 25%">{{ role.description }}</td>
                        <td style="width: 25%">{{ role.created_at | date:'dd/MM/yyyy' }}</td>
                        <td style="width: 15%" class="project-actions text-right">
                            <a class="btn btn-primary btn-sm" (click)="detailsRole(role.id)" href="javascript:void(0)" data-bs-placement="top" title="details" >
                                <i class="fas fa-eye">
                                </i> 
                            </a>
                            &nbsp;
                            <a *ngIf="canUpdate" class="btn btn-info btn-sm" (click)="editRole(role.id)" href="javascript:void(0)" data-bs-placement="top" title="modifier" >
                                <i class="fas fa-pencil-alt">
                                </i> 
                            </a>
                            &nbsp;
                            <a *ngIf="canDelete" class="btn btn-danger btn-sm" (click)="deleteRole(role.id)" href="javascript:void(0)" data-bs-placement="top" title="supprimer" >
                                <i class="fas fa-trash">
                          </i> 
                            </a>
                        </td>
                    </tr>

                </tbody>
                <tfoot *ngIf="loading && (!roles || !roles.length)">
                    <td colspan="7" class="text-center">
                        <div class="spinner-border spinner-sm">
                            <span class="sr-only">Patientez svp...</span>
                        </div>
                    </td>
                </tfoot>
                <tfoot *ngIf="loading && (!roles || roles.length)">
                    <td colspan="7" class="text-center">
                        Liste vide
                    </td>
                </tfoot>

            </table>
        </div>
    </div>
    <!-- /.card -->

</section>