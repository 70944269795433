import { AddMarkAutoComponent } from './../../mark/add-mark-auto/add-mark-auto.component';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ParamService } from 'src/app/_services/parc-service/param-service/param-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog'
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-add-model-auto',
  templateUrl: './add-model-auto.component.html',
  styleUrls: ['./add-model-auto.component.scss']
})
export class AddModelAutoComponent implements OnInit {


  form: any;
  modelForm: FormGroup;
  typemarks: any;
  forme = "Mark"
  isSubmitted: boolean = false;
  isLoading = false
  isClose = true;
  isMaterial = false;



  constructor(

    private translate: TranslateService,
    private paramService: ParamService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    private ref: MatDialog
    // private ref:

  ) { }

  ngOnInit(): void {
    this.initmodel();
    this.getForme(this.forme);
    this.isMaterial = '/setting/forms/model/add' != location.pathname;

  }



  public initmodel() {
    this.modelForm = this.formBuilder.group({
      description: [''],
      parent_id: ['', Validators.required],
      name: ['', Validators.required],
    });
  }

  get modelform() {
    return this.modelForm.controls;
  }


  onSubmitModel() {
    this.isSubmitted = true;
    this.isLoading = false
    if (this.modelForm.invalid) {
      this.toastrService.error('ERREUR', 'Les champs en rouge sont obligatoires!');
      return;
    }
    this.isLoading = true;
    const formData = new FormData();
    formData.append('parent_id', this.modelform.parent_id.value);
    formData.append('description', '' + this.modelform.description.value);
    formData.append('name', '' + this.modelform.name.value);
    formData.append('code', '' + 'MODELE');
    console.log(this.modelform.parent_id.value)
    this.paramService.addForm(formData)
      .then(resp => {
        this.toastrService.success('ajouter avec succes!', 'Succes!');
        this.modelForm.reset();
        this.modelForm = this.formBuilder.group({
          parent_id: ['', Validators.required],
          description: [''],
          name: ['', Validators.required],
        });
        this.isSubmitted = false;
      })
      .catch(err => {
        console.log(err)
        this.toastrService.error('Veuillez vérifier votre serveur ou Ce modèle de véhicule existe déjà', 'error!');
      })
      .finally(() => this.isLoading = false);
  }


  public getForme(forme) {

    this.paramService.selectForm(forme).then(
      response => {
        this.typemarks = response.reverse();

        console.log(this.typemarks)
      })
      .catch(
        error => {
          this.toastrService.error(error.error.message);
        })
      .finally(() => { })
  }

  //affichage du formulaire d'ajout d'un modele
  add() {
    var dialogRef = this.dialog.open(AddMarkAutoComponent, {
      width: '700px',
      height: '700px',
      disableClose: false
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      this.ngOnInit();
    });



  }

  close() {
    this.ref.closeAll();
  }
}











