import { Component, OnInit } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2'
import { InterventionService } from 'src/app/_services/maintenance/interventions/intervention.service';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
declare const $;



@Component({
  selector: 'app-intervention-all',
  templateUrl: './intervention-all.component.html',
  styleUrls: ['./intervention-all.component.scss']
})
export class InterventionAllComponent implements OnInit {

  interventions:any;
  loading: boolean = true;
  @BlockUI() blockUI: NgBlockUI;

  //SweetAlert Text
  areYouSure = '';
  warning = ''
  yes = '';
  no = '';
  deleted = '';
  deletedMessage = '';
  cancelled = '';
  cancelledMessage = '';
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  page=1;
  data:any;
  driverSelectId:any;
  idDriver;

  constructor(
    private InterventionService: InterventionService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService
  ) {
        this.translate.get(
          ['SweetAlert.AreYouSure', 'SweetAlert.Warning', 'SweetAlert.Yes', 'SweetAlert.No', 'SweetAlert.Deleted',
          'SweetAlert.DeletedMessage', 'SweetAlert.Cancelled', 'SweetAlert.CancelledMessage'],
          { data: "cette intervention " })
          .subscribe(val => {
            this.areYouSure = val['SweetAlert.AreYouSure'];
            this.warning = val['SweetAlert.Warning'];
            this.yes = val['SweetAlert.Yes'];
            this.no = val['SweetAlert.No'];
            this.deleted = val['SweetAlert.Deleted'];
            this.deletedMessage = val['SweetAlert.DeletedMessage'];
            this.cancelled = val['SweetAlert.Cancelled'];
            this.cancelledMessage = val['SweetAlert.CancelledMessage'];
          });
   }

   ngOnInit() {
    this.getPage(this.page);
    const permissionSuffix = 'interventions';
    this.canCreate = this.authService.hasPermission(`${permissionSuffix}-create`);
		this.canUpdate = this.authService.hasPermission(`${permissionSuffix}-update`);
		this.canDelete = this.authService.hasPermission(`${permissionSuffix}-delete`);
  }

  getPage(page) {
    this.loading = true;
    this.InterventionService.gets(page).then(
      response => {
        this.interventions = response;
        console.log(this.interventions);
        $(function () {
          //$("#example1").DataTable().fnDestroy()
          $("#example1").DataTable({
            "responsive": true, "lengthChange": false, "autoWidth": false,
            "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
          }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        });
      }
    ).catch(
      error => {
        this.notifService.danger(error.error.message)
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  editIntervention(id) {
    console.log('bonjour');
    this.router.navigate(['/intervention/update/' + id]);

  }
  details(id:any){
    Swal.fire({
      title: '<strong>'+id.nom+'</strong>',
      icon: 'info',
      html:
        id.nom_type_intervention+'<br>'+
        id.description,
      imageAlt: 'Custom image',
    })
  }
  deleteIntervention(id) {
    Swal.fire({
      title: this.areYouSure,
      text: this.warning,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.yes,
      cancelButtonText: this.no
    }).then((result) => {
      if (result.value) {
        this.blockUI.start('Loading...');
        this.InterventionService.delete(id).then(
          data => {
            this.blockUI.stop();
            Swal.fire(
              this.deleted,
              this.deletedMessage,
              'success'
            );
            this.getPage(1);

          }
        ).catch(
          error => {
            console.log(error);
            this.blockUI.stop();
            this.translate.get('Intervention.' + error.error.code)
            .subscribe(val => this.notifService.danger(val));
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.cancelledMessage,
          'error'
        );
      }
    });
  }

}
