import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';
import { OperationService } from 'src/app/_services/pneumatique/Operation/operation.service';

@Component({
  selector: 'app-operation-details',
  templateUrl: './operation-details.component.html',
  styleUrls: ['./operation-details.component.scss']
})
export class OperationDetailsComponent implements OnInit {

  operations: any;

  constructor(
    private OperationService: OperationService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    const operation_id = +this.route.snapshot.paramMap.get("id");
    console.log(operation_id);
    this.OperationService.find(operation_id).then(
      data => {
        this.operations = data;
        console.log(this.operations)
      }
    ).catch(
      error => {
        this.translate.get('operations.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/operation/all'])
      }
    )
  }

}
