import { Component, OnInit } from '@angular/core';
import { ProgrammeReparationService } from 'src/app/_services/maintenance-service/programme_reparation/programme-reparation.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-programme-reparation-add',
  templateUrl: './programme-reparation-add.component.html',
  styleUrls: ['./programme-reparation-add.component.scss']
})
export class ProgrammeReparationAddComponent implements OnInit {

  DataForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  user
  vehicules: any;
  mecaniciens: any;
  pannes: any;
  limite: 10000;
  

  constructor(
    private ProgrammeReparationService: ProgrammeReparationService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getPanne();
    this.getMecanicien();
    this.getVehicule();
    this.user = this.authService.getUser();

    this.DataForm = this.formBuilder.group({
      description: [''],
      date_debut: ['', [Validators.required]],
      date_fin: ['', [Validators.required]],
      panne_id: ['', [Validators.required]],
      vehicule_id: ['', [Validators.required]],
      mecanicien_id: ['', [Validators.required]],
    });
  }

  getPanne() {
    this.ProgrammeReparationService.getPanne().then(res => {
      this.pannes = res;
      console.log(this.pannes)
    }).catch(
      error => {
        this.notifService.danger(error.error.message)
      })
  }

  getMecanicien() {
    this.ProgrammeReparationService.getMecanicien().then(res => {
      this.mecaniciens = res;
      console.log(this.mecaniciens)
    }).catch(
      error => {
        this.notifService.danger(error.error.message)
      })
  }

  getVehicule() {
    this.ProgrammeReparationService.getVehicule().then((res) => {
      this.vehicules = res;
      console.log(this.vehicules)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  get form() {
    return this.DataForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;


    if (this.DataForm.invalid) {

      this.translate.get('ProgrammeReparation.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('panne_id', '' + this.form.panne_id.value);
    formData.append('vehicule_id', '' + this.form.vehicule_id.value);
    formData.append('mecanicien_id', '' + this.form.mecanicien_id.value);
    formData.append('description', '' + this.form.description.value);
    formData.append('date_debut', '' + this.form.date_debut.value);
    if (this.form.date_debut.value > this.form.date_fin.value) {
      console.log('Junie');
      this.translate.get('ProgrammeReparation.DateErreur')
        .subscribe(val => this.notifService.danger(val));
      this.isLoading = false;
      return;
    } else {
      formData.append('date_fin', '' + this.form.date_fin.value);
    }
    formData.append('create_id', '' + this.user.id);

    console.log(formData);
    this.ProgrammeReparationService.add(formData)
      .then(resp => {
        this.translate.get('ProgrammeReparation.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.DataForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('ProgrammeReparation.SubmitErrorProgrammeReparation')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }


}
