import { AuthService } from './../../../../../_services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { NoteCritiqueService } from 'src/app/_services/RH-service/note-critique/note-critique.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-note-critique-update',
  templateUrl: './note-critique-update.component.html',
  styleUrls: ['./note-critique-update.component.scss']
})
export class NoteCritiqueUpdateComponent implements OnInit {

  NoteCritiqueForm: FormGroup;
  users: any;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  image: File = null;
  noteCritique;
  user;

  constructor(
    private NoteCritiqueService: NoteCritiqueService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,


  ) { }

  ngOnInit() {
    this.user = this.authService.getUser();

    //this.getUsers();

    this.initForm();
    const note = +this.route.snapshot.paramMap.get("id");
    this.NoteCritiqueService.find(note).then(
      data => {
        this.noteCritique = data;
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('NoteCritique.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/note-critique/all'])

      }
    )
  }


  //initialisation du formulaire
  initForm(withContrat = false) {
    if (withContrat) {
      this.NoteCritiqueForm = this.formBuilder.group(
        {
          title: [this.noteCritique.title,[Validators.required]],
          content: [this.noteCritique.content],
          image: [this.noteCritique.image],
        }
      );
    } else {
      this.NoteCritiqueForm = this.formBuilder.group(
        {
          title: ['',[Validators.required]],
          content: [''],
          image: [''],
        }
      );
    }
  }

  getUsers() {
    this.NoteCritiqueService.getUser().then((res) => {
      this.users = res;
      console.log('theo NoteCritique')
      console.log(this.users)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  onSelectfile(event) {
    this.image = event.target.images[0];
  }

  get form() {
    return this.NoteCritiqueForm.controls;
  }
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.NoteCritiqueForm.invalid) {
      this.translate.get('NoteCritique.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('user_id', this.user.id);
    formData.append('title', '' + this.form.title.value);
    formData.append('content', '' + this.form.content.value);
    if (this.image != null)
      formData.append('image', this.image, this.image.name);


    console.log(formData);
    this.NoteCritiqueService.update(formData, this.noteCritique.id)
      .then(resp => {
        this.translate.get('NoteCritique.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.NoteCritiqueForm.reset();
        this.isLoading = false;
        this.router.navigate(['/note-critique/all'])

      })
      .catch(err => {
        console.log(err)
        this.translate.get('NoteCritique.SubmitErrorNoteCritique')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }

}
