<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ "TypeIntervenant.Title" | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">Home</a></li>
                    <li class="breadcrumb-item active"><a [routerLink]="['/intervening-type/list']">{{ "TypeIntervenant.Title" | translate }}</a></li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>
<section class="container">
    <div class="container-fluid">
        <div class="col-md-12">
            <!-- general form elements -->
            <div class="card card-primary">
                <div class="card-header" style="background-color: #17a2b8;">
                    <h3 class="card-title">{{ "TypeIntervenant.AddDescription" | translate }}</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form (submit)="onSubmit()" [formGroup]="dataForm" role="form">
                    <div class="card-body">
                        <div class="col-sm-12">
                            <!-- text input -->
                            <div class="form-group">
                                <label>{{ "TypeIntervenant.Name" | translate }} <span style="color: red;">*</span> </label>
                                <input type="text" class="form-control {{ isSubmitted && form.intervening_type_name.errors ? 'is-invalid' : '' }}" placeholder="Enter ..." formControlName="intervening_type_name">
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <!-- textarea -->
                            <div class="form-group">
                                <label>{{ "TypeIntervenant.Description" | translate }}</label>
                                <textarea class="form-control {{ isSubmitted && form.description.errors ? 'is-invalid' : '' }}" rows="3" placeholder="Enter ..." formControlName="description"></textarea>
                            </div>
                        </div>
                    </div>
                    <!-- /.card-body -->

                    <div class="card-footer">
                        <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                            <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                            <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                    </div>
                </form>
                <div  *ngIf="isMaterial" class="col-md-2" style="margin-left:85%"><button class="btn btn-danger" (click)="close()">
                    {{
                    'Material.ButtonClose' | translate }}
                  </button></div>
            </div>
            <!-- /.card -->
        </div>
    </div>
</section>
