<div class="content-wrapper" style="min-height: 1398.23px; margin-left: auto;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-1">
            <div class="col-sm-6">
              <h1>
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">{{ "TypeIntervenant.Title" | translate }}</font>
                </font>
              </h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                <li class="breadcrumb-item "><a [routerLink]="['/intervening-type/list']">{{ "TypeIntervenant.Title" | translate }}</a></li>
                <li class="breadcrumb-item active">{{ "FileAriane.List" | translate }}</li>
              </ol>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>

    <!-- Main content -->
    <section class="content">
                <!-- Default box -->
        <div class="card">
            <div class="card-header">
                <div class="card-header" style="background-color: #17a2b8;color: white;">
                    <h1 class="card-title">
                        <font style="vertical-align: inherit;">
                          <font style="vertical-align: inherit;">{{ "TypeIntervenant.List" | translate }}</font>
                        </font>
                      </h1>
                    <button *ngIf="canCreate" [routerLink]="['/intervening-type/add']" type="button" class="btn btn-primary float-right">
                        <i class="fas fa-plus"></i>{{ "TypeIntervenant.AddElement" | translate }}
                    </button>
                </div>
            </div>
            
            <div class="card-body p-0">
                <table id="example1" class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th style="width: 10%">
                                #
                            </th>
                            <th style="width: 35%">
                                {{ "TypeIntervenant.Name" | translate }}
                            </th>
                            <th style="width: 40%">
                                {{ "TypeIntervenant.Description" | translate }}
                            </th>
                            <th style="width: 15%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let element of data?.data,let i = index" id="{{ element.id }}">
                            <td>
                                {{ i+1 }}
                            </td>
                            <td>
                                <a>
                                    {{ element.intervening_type_name }}
                                </a>
                                <br>
                                <small>
                                    Created {{ element.created_at | date:'dd/MM/yyyy' }}
                                </small>
                            </td>
                            <td >{{ element.description == "null" ? '': element.description }}</td>
                            <td class="project-actions text-right">
                                <!-- <a class="btn btn-primary btn-sm" href="#">
                                    <i class="fas fa-eye">
                              </i> 
                                </a> -->
                                <a *ngIf="canUpdate" class="btn btn-info btn-sm" (click)="edit(element.id)" href="javascript:void(0)" data-bs-placement="top" title="modifier" >
                                    <i class="fas fa-pencil-alt">
                                    </i> 
                                </a>
                                &nbsp;
                                <a *ngIf="canDelete" class="btn btn-danger btn-sm" (click)="delete(element.id)" href="javascript:void(0)" data-bs-placement="top" title="supprimer" >
                                    <i class="fas fa-trash">
                                    </i> 
                                </a>
                            </td>
                        </tr>

                    </tbody>
                    <tfoot *ngIf="loading && (!data || !data.length)">
                        <td colspan="7" class="text-center">
                            <div class="spinner-border spinner-sm">
                                <span class="sr-only">Patientez svp...</span>
                            </div>
                        </td>
                    </tfoot>
                    <tfoot *ngIf="!loading && (!data || data.length)">
                        <td colspan="7" class="text-center">
                            Liste vide
                        </td>
                    </tfoot>

                </table>
            </div>
            <!-- /.card-body -->
            <!-- /.card-body -->
            <div class="card-footer">
                <nav aria-label="Navigation dans la page des contacts">
                    <!-- <ul class="pagination justify-content-center m-0">
                        <li class="page-item">
                            <a (click)="getPage(data?.prev_page_url)" class="page-link btn">
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">{{ 'Affectation.Previous' | translate }}
                                    </font>
                                </font>
                            </a>
                        </li>
                        <li class="page-item">
                            <a (click)="getPage(data?.next_page_url)" class="page-link btn">
                                <font style="vertical-align: inherit;">
                                    <font style="vertical-align: inherit;">{{ 'Affectation.next' | translate }}</font>
                                </font>
                            </a>
                        </li>
                    </ul> -->
                </nav>
            </div>

        </div>
        <!-- /.card -->

    </section>
    <!-- /.content -->
</div>