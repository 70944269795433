import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ParamService } from 'src/app/_services/parc-service/param-service/param-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NotifService } from 'src/app/_services/notif.service';


@Component({
  selector: 'app-update-carosserie-auto',
  templateUrl: './update-carosserie-auto.component.html',
  styleUrls: ['./update-carosserie-auto.component.scss']
})
export class UpdateCarosserieAutoComponent implements OnInit {

 
  carosseries: any;
  modelForm: FormGroup;
  typemarks: any;
  isSubmitted: boolean = false;
  isLoading = false;
  
  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private paramService: ParamService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private notifService: NotifService
  ) { }
  
  async ngOnInit() {
    const mod_id = +this.route.snapshot.paramMap.get("id");
    this.paramService.findForm(mod_id).then(
      data => {
        this.carosseries = data;
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('marks.'+error.error.code)
        .subscribe();
      }
    )
    this.initForm();
  }

  initForm(withmodel = false) {
    if(withmodel) {
      this.modelForm = this.formBuilder.group({
        name: [this.carosseries.name, [Validators.required]],
        description: [this.carosseries.description]
        
      });
    }else {
      this.modelForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        description: [''],
      }); 
    }
  }

  get form() {
    return this.modelForm.controls;
  }

  onSubmitCarosserie() {
    this.isSubmitted = true;
    this.isLoading = false;
    if (this.modelForm.invalid) {
      this.toastrService.error('ERREUR', 'Les champs en rouge sont invalides!');
      return;
    }
    this.isLoading = true;
    const formData = new FormData();
    formData.append('name', '' + this.form.name.value);
    formData.append('code', '' + 'CAROSSERIE');
    formData.append('description', '' + this.form.description.value);
    this.paramService.updateForm(formData, this.carosseries.id)
      .then(resp => {

        this.translate.get('TypeVehicule.UpdatingSuccess')
        .subscribe(val => this.notifService.success(val));
        this.router.navigate(['setting/forms/carosserie/list'])

      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => this.isLoading = false);
  }


}


