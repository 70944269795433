<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ "ElementIntervenant.Title" | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">Home</a></li>
                    <li class="breadcrumb-item active"><a [routerLink]="['/intervening-element/list']">{{ "ElementIntervenant.Title" | translate }}</a></li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>
<section class="container">
    <div class="container-fluid">
        <div class="col-md-12">
            <!-- general form elements -->
            <div class="card card-primary">
                <div class="card-header" style="background-color: #17a2b8;">
                    <h3 class="card-title">{{ "ElementIntervenant.UpdateDescription" | translate }}</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form (submit)="onSubmit()" [formGroup]="dataForm" role="form">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <!-- text input -->
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.Name" | translate }} <span style="color: red;">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Enter ..." formControlName="name">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.IntervenantType" | translate }} <span style="color: red;">*</span> </label>
                                    <select class="custom-select" formControlName="intervening_type_id">
                                        <option *ngFor="let type of element?.data" value="{{ type.id }}"
                                        title="{{ type.description }}">{{ type.intervening_type_name }}</option>
                                      </select> </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <!-- text input -->
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.Email" | translate }} <span style="color: red;">*</span> </label>
                                    <input type="email" class="form-control" placeholder="Email" formControlName="email">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.Location" | translate }} <span style="color: red;">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Enter ..." formControlName="location">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <!-- text input -->
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.Phone" | translate }} 1 <span style="color: red;">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Enter ..." formControlName="tel1">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.Phone" | translate }} 2 <span style="color: red;">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Enter ..." formControlName="tel2">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <!-- textarea -->
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.Description" | translate }}</label>
                                    <textarea class="form-control" rows="3" placeholder="Enter ..." formControlName="description"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <!-- text input -->
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.ContactPerson" | translate }} <span style="color: red;">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Enter ..." formControlName="contact_person">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.TelPerson" | translate }} <span style="color: red;">*</span> </label>
                                    <input type="text" class="form-control" placeholder="Enter ..." formControlName="tel_person">
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col sm-12">
                                <div class="form-group">
                                    <label for="exampleInputFile">File input</label>
                                    <div class="custom-file">
                                        <input type="file" name="intervening_file" (change)="detectfile($event)" formControlName="intervening_file">
                                        </div>
                                </div>
                            </div>
                        </div> -->

                    </div>
                    <!-- /.card-body -->


                    <div class="card-footer">
                        <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                            <p *ngIf="!isLoading">{{ "General.update" | translate }}</p>
                            <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </button>
                    </div>
                </form>
            </div>
            <!-- /.card -->
        </div>
    </div>
</section>