<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>{{ "GammeEntretien.GammeEntretien" | translate }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
              <li class="breadcrumb-item "><a [routerLink]="['/gamme-entretien/all']">{{ "GammeEntretien.GammeEntretien" | translate }}</a></li>
              <li class="breadcrumb-item active">{{ "FileAriane.Update" | translate }}</li>
  
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
  
    <!-- Main content -->
  
    <section class="container">
      <div class="row">
        <div class="col-md-12">
          <form (submit)="onSubmit()" [formGroup]="GammeEntretientForm">
            <div class="card card-primary">
  
              <div class="card-header " style="background-color: #17a2b8;">
                <h3 class="card-title">{{ "GammeEntretien.GammeEntretienUpdate" | translate }}</h3>
  
              </div>
  
              <div class="card-body" style="display: block;">
                <div class="form-group">
                  <label>{{ "GammeEntretien.TypeEntretientLabel" | translate }} <span
                    style="color: red;">*</span></label>
                  <select formControlName="type_entretien_id" class="form-control {{ isSubmitted && form.type_entretien_id.errors? 'is-invalid' : '' }}"
                    style="width: 100%;" tabindex="-1" aria-hidden="true">
                    <option value="">{{ "GammeEntretien.TypeEntretientGamme" | translate }}</option>
                    <option *ngFor="let item of typeEntretiens?.data" value="{{item.id}}">{{item.nom}}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>{{ "GammeEntretien.InterventionLabel" | translate }} <span
                    style="color: red;">*</span></label>
                  <select formControlName="intervention_id" class="form-control {{ isSubmitted && form.intervention_id.errors? 'is-invalid' : '' }}"
                    style="width: 100%;" tabindex="-1" aria-hidden="true">
                    <option value="">{{ "GammeEntretien.InterventionGammeEntretien" | translate }}</option>
                    <option *ngFor="let item of interventions?.data" value="{{item.id}}">{{item.nom}}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>{{ "GammeEntretien.MecanicienLabel" | translate }} <span
                    style="color: red;">*</span></label>
                  <select formControlName="mecanicien_id" class="form-control {{ isSubmitted && form.mecanicien_id.errors? 'is-invalid' : '' }}"
                    style="width: 100%;" tabindex="-1" aria-hidden="true">
                    <option value="">{{ "GammeEntretien.MecanicienGamme" | translate }}</option>
                    <option *ngFor="let item of mecaniciens?.data" value="{{item.id}}">{{item.mecanicien_name}}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="inputName">{{ "GammeEntretien.NameLabel" | translate }} <span
                    style="color: red;">*</span></label>
                  <input type="text" id="inputName" class="form-control {{ isSubmitted && form.nom.errors? 'is-invalid' : '' }}"
                    formControlName="nom" placeholder="{{ 'GammeEntretien.LabelNameGammeEntretien' | translate }}">
                </div>
                <div class="form-group">
                  <label for="inputName">{{ "GammeEntretien.FrequenceLabel" | translate }} <span
                    style="color: red;">*</span></label>
                  <input type="text" id="inputName" class="form-control {{ isSubmitted && form.frequence.errors? 'is-invalid' : '' }}"
                    formControlName="frequence" placeholder="{{ 'GammeEntretien.LabelFrequenceGammeEntretien' | translate }}">
                </div>
                <div class="form-group">
                  <label for="inputName">{{ "GammeEntretien.PriceLabel" | translate }} <span
                    style="color: red;">*</span></label>
                  <input type="number" id="inputName" class="form-control {{ isSubmitted && form.prix.errors? 'is-invalid' : '' }}"
                    formControlName="prix" placeholder="{{ 'GammeEntretien.LabelFrequenceGammeEntretien' | translate }}">
                </div>
              </div>
              <div class="card-footer">
                <button [disabled]="isLoading" class="btn" style="background-color: #17a2b8; color: white;">
                  <p *ngIf="!isLoading">{{ "General.update" | translate }}</p>
                  <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
              <!-- /.card-body -->
            </div>
          </form>
          <!-- /.card -->
        </div>
      </div>
    </section>
    <!-- /.content -->
  </div>
