import { Component, OnInit } from '@angular/core';
import { FormationService } from 'src/app/_services/RH-service/formation/formation.service';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-formation-detail',
  templateUrl: './formation-detail.component.html',
  styleUrls: ['./formation-detail.component.scss']
})
export class FormationDetailComponent implements OnInit {

  formation:any;
  url;
  
  constructor(
    private FormationService: FormationService,
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,

  ) { }

  async ngOnInit() {
    const formationId = +this.route.snapshot.paramMap.get("id");
     console.log(formationId);
    this.FormationService.find(formationId).then(
      data => {
        this.formation = data;
        console.log(this.formation)
      }
    ).catch(
      error => {
        this.translate.get('Formation.'+error.error.code)
        .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/formation/all'])
      }
    )
  }
}
