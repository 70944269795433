<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ "Position.Position" | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                    <li class="breadcrumb-item "><a [routerLink]="['/setting/forms/model/list']">{{
                            "Forms.Models.Models" |
                            translate }}</a></li>
                    <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>

                </ol>
            </div>
        </div>
    </div><!-- /.container-fluid -->
</section>


<!-- <form method="post" action="">
    <input #inputColor (change)="changeRow(inputColor)" type="text" id="titre" placeholder="Entrer le nombre de line" />
</form> -->

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">


                </h3>

                <div class="row">
                    <div class="col-6">
                        <label for="">Modifier le nombre de ligne</label>
                        <input #inputColor (change)="changeRow(inputColor)" type="number" id="titre"
                            placeholder="Entrer le nombre de line" class="form-control" />
                    </div>
                    <!--   <div class="col-6">
                        <label for="">Modifier le nombre de colone</label>

                        <input #inputColor (change)="changeCol(inputColor)" type="number" id="titre"
                            placeholder="Entrer le nombre de colone" class="form-control" />
                    </div> -->
                </div>

                <div class="card-tools">
                    <div class="input-group input-group-sm" style="width: 150px;">
                        <button [disabled]="isLoading" (click)="onSubmit()" class="btn btn-primary"
                            style="background-color: #17a2b8;">
                            <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                            <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0 ">
                <table class="" border="1">
                    <thead>
                        <tr>
                            <th>

                                1<sup>er</sup> ranger

                            </th>
                            <th>

                                2<sup>eme</sup> ranger

                            </th>
                            <th>

                                1<sup>er</sup> reserve

                            </th>
                            <!--   <th>

                                2<sup>eme</sup> reserve

                            </th> -->
                            <th>

                                3<sup>eme</sup> ranger

                            </th>
                            <th>

                                4<sup>eme</sup> ranger

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of rows, let i = index">

                            <td *ngFor="let col of columns, let j = index">
                                <div class="custom-checkbox custom-control">
                                    <input type="checkbox" class="custom-control-input" (change)="selected($event)"
                                        id="{{i}}{{j}}">
                                    <label class="custom-control-label position" for="{{i}}{{j}}"
                                        (click)="onClick(i,j)">
                                        {{i}}{{j}}
                                    </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->
    </div>
</div>