import { Component, OnInit } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2'
import { TypeSanctionService } from 'src/app/_services/RH-service/sanction/type-sanction.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
declare const $;

@Component({
  selector: 'app-type-sanction-all',
  templateUrl: './type-sanction-all.component.html',
  styleUrls: ['./type-sanction-all.component.scss']
})
export class TypeSanctionAllComponent implements OnInit {

  type_Sanctions:any;
  loading: boolean = true;
  @BlockUI() blockUI: NgBlockUI;

  //SweetAlert Text
  areYouSure = '';
  warning = ''
  yes = '';
  no = '';
  deleted = '';
  deletedMessage = '';
  cancelled = '';
  cancelledMessage = '';
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  page=1;
  data:any;
  driverSelectId:any;
  idDriver;

  constructor(
    private TypeSanctionService: TypeSanctionService,
    private authService: AuthService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
  ) {
        this.translate.get(
          ['SweetAlert.AreYouSure', 'SweetAlert.Warning', 'SweetAlert.Yes', 'SweetAlert.No', 'SweetAlert.Deleted',
          'SweetAlert.DeletedMessage', 'SweetAlert.Cancelled', 'SweetAlert.CancelledMessage'],
          { data: "ce type de sanction" })
          .subscribe(val => {
            this.areYouSure = val['SweetAlert.AreYouSure'];
            this.warning = val['SweetAlert.Warning'];
            this.yes = val['SweetAlert.Yes'];
            this.no = val['SweetAlert.No'];
            this.deleted = val['SweetAlert.Deleted'];
            this.deletedMessage = val['SweetAlert.DeletedMessage'];
            this.cancelled = val['SweetAlert.Cancelled'];
            this.cancelledMessage = val['SweetAlert.CancelledMessage'];
          });
   }

   ngOnInit() {
    this.getTypeSanction(this.page);
    const permissionSuffix = 'sanction_types';
		this.canCreate = this.authService.hasPermission(`${permissionSuffix}-create`);
		this.canUpdate = this.authService.hasPermission(`${permissionSuffix}-update`);
		this.canDelete = this.authService.hasPermission(`${permissionSuffix}-delete`);
  }

  getTypeSanction(page) {
    this.loading = true;
    this.TypeSanctionService.gets(page).then(
      response => {
        this.type_Sanctions = response;
        console.log(this.type_Sanctions);
        $(function () {
          //$("#example1").DataTable().fnDestroy()
          $("#example1").DataTable({
            "responsive": true, "lengthChange": false, "autoWidth": false,
            "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
          }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        });
      }
    ).catch(
      error => {
        this.notifService.danger(error.error.message)
      }
    ).finally(
      () => {
        this.loading = false;
      }
    )
  }


  getPage(url) {
    if (url) {
      this.TypeSanctionService.get(url).then((res) => {
        this.type_Sanctions = res;
        console.log(this.type_Sanctions)
      }).catch(
        error => {
          this.notifService.danger(error.error.message)
        }
      ).finally(
        () => {
          this.loading = false;
        }
      )
    }
  }

  editTypeSanction(id) {
    console.log('bonjour');
    this.router.navigate(['/type-sanction/update/' + id]);

  }
  deleteTypeSanction(id) {
    Swal.fire({
      title: this.areYouSure,
      text: this.warning,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.yes,
      cancelButtonText: this.no
    }).then((result) => {
      if (result.value) {
        this.blockUI.start('Loading...');
        this.TypeSanctionService.delete(id).then(
          data => {
            this.blockUI.stop();
            Swal.fire(
              this.deleted,
              this.deletedMessage,
              'success'
            )
            this.getTypeSanction(1);

          }
        ).catch(
          error => {
            console.log(error);
            this.blockUI.stop();
            this.translate.get('TypeSanction.'+error.error.code)
            .subscribe(val => this.notifService.danger(val));
          }
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.cancelledMessage,
          'error'
        )
      }
    })
  }
}
