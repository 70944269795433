import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { GammeEntretienService } from 'src/app/_services/maintenance-service/gamme-entretient/gamme-entretien.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-gamme-entretient-detail',
  templateUrl: './gamme-entretient-detail.component.html',
  styleUrls: ['./gamme-entretient-detail.component.scss']
})
export class GammeEntretientDetailComponent implements OnInit {

gamme_entretient: any;

  constructor(
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private GammeEntretienService: GammeEntretienService,
  ) { }

  async ngOnInit() {
    const gamme_id = +this.route.snapshot.paramMap.get("id");
    this.GammeEntretienService.find(gamme_id).then(
      data => {
        this.gamme_entretient = data;
        console.log(this.gamme_entretient)
      }
    ).catch(
      error => {
        this.translate.get('gamme_entretient.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/gamme-entretien/all'])
      }
    )
  }

}