import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { NotifService } from 'src/app/_services/notif.service';
import Swal from 'sweetalert2';
import { InterveningElementService } from 'src/app/_services/transport-element/intervening-element/intervening-element.service';
import { AuthService } from 'src/app/_services/auth/auth.service';
declare const $;
@Component({
  selector: 'app-intervening-element-list',
  templateUrl: './intervening-element-list.component.html',
  styleUrls: ['./intervening-element-list.component.scss']
})
export class InterveningElementListComponent implements OnInit {

  data: any;
  loading: boolean = true;
  file: any;
  @BlockUI() blockUI: NgBlockUI;

  //SweetAlert Text
  areYouSure = '';
  warning = ''
  yes = '';
  no = '';
  deleted = '';
  deletedMessage = '';
  cancelled = '';
  cancelledMessage = '';
  canCreate = false;
  canUpdate = false;
  canDelete = false;
  page = 1;

  constructor(
    private dataService: InterveningElementService,
    private authService: AuthService,
    private translate: TranslateService,
    private notifService: NotifService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.translate.get(
      ['SweetAlert.AreYouSure', 'SweetAlert.Warning', 'SweetAlert.Yes', 'SweetAlert.No', 'SweetAlert.Deleted',
        'SweetAlert.DeletedMessage', 'SweetAlert.Cancelled', 'SweetAlert.CancelledMessage'],
      { data: ' ce element' })
      .subscribe(val => {
        this.areYouSure = val['SweetAlert.AreYouSure2'];
        this.warning = val['SweetAlert.Warning'];
        this.yes = val['SweetAlert.Yes'];
        this.no = val['SweetAlert.No'];
        this.deleted = val['SweetAlert.Deleted'];
        this.deletedMessage = val['SweetAlert.DeletedMessagePro'];
        this.cancelled = val['SweetAlert.Cancelled'];
        this.cancelledMessage = val['SweetAlert.CancelledMessage'];
      });
  }

  ngOnInit(): void {
    this.gets(this.page);
    const permissionSuffix = 'intervening_elements';
    this.canCreate = this.authService.hasPermission(`${permissionSuffix}-create`);
    this.canUpdate = this.authService.hasPermission(`${permissionSuffix}-update`);
    this.canDelete = this.authService.hasPermission(`${permissionSuffix}-delete`);
  }

  gets(page) {
    this.loading = true;
    this.dataService.gets(this.page).then(
      response => {
        this.data = response;
        console.log(this.data);
        $(function () {
          $("#example1").DataTable({
            "responsive": true, "lengthChange": false, "autoWidth": false,
            "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
          }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        });
      }
    )
      .catch(
        error => {
          this.toastr.error(error.error.message)
        }
      ).finally(
        () => this.loading = false
      )
  }

  getPage(url) {
    this.loading = true;
    if (url) {
      this.dataService.get(url).then(
        (res) => {
          this.data = res;
          console.log(res);
        }
      ).catch(
        error => {
          this.toastr.error(error.error.message)
        }
      ).finally(
        () => {
          this.loading = false;
        }
      )
    }
  }

  delete(id) {
    Swal.fire({
      title: this.areYouSure,
      text: this.warning,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.yes,
      cancelButtonText: this.no
    }).then((result) => {
      if (result.value) {
        this.blockUI.start('Loading...');
        this.dataService.delete(id).then(
          data => {
            this.blockUI.stop();
            Swal.fire(
              this.deleted,
              this.deletedMessage,
              'success'
            )
            this.gets(this.page);
          }
        ).catch(
          error => {
            console.log(error)
            this.blockUI.stop();
            this.translate.get('element.' + error.error.code)
              .subscribe(val => this.notifService.danger(val));
          }
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.cancelledMessage,
          'error'
        )
      }
    })
  }

  edit(id) {
    this.router.navigate(['intervening-element/update/' + id])
  }

  view(id) {
    this.router.navigate(['intervening-element/detail/' + id])
  }


  computeDescription(description: string): string {
    return (description.length < 50) ? description : (description.substr(0, 50) + '...');
  }

}
