<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-1">
                <div class="col-sm-6">
                    <h1>
                        {{ "Mission.Missions" | translate }}

                    </h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                        <li class="breadcrumb-item "><a [routerLink]="['/mission/all']">{{
                                "Mission.Missions" | translate }}</a></li>
                        <li class="breadcrumb-item active">{{ "FileAriane.List" | translate }}</li>
                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">

        <!-- Default box -->


        <div class="card">
            <div class="card-header" style="background-color: #17a2b8; color:white;">
                <h1 class="card-title">
                    {{ "Mission.MissionAll" | translate }}

                </h1>
                <button [routerLink]="['/mission/add']" type="button" class="btn btn-primary float-right">
                    <i class="fas fa-plus"></i> {{ "Forms.Marques.Ajouter" | translate }}
                  </button>
            </div><br><br>

            <div class="card-body p-0">
                <table id="example1" class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th style="width: 5%">

                                #

                            </th>

                            <th style="width: 10%">

                                {{ "Mission.FournisseurMissionLabel" | translate }}

                            </th>

                            <th style="width: 10%">

                                {{ "Mission.ClientMissionLabel" | translate }}

                            </th>

                            <th style="width: 10%">

                                {{ "Mission.VehiculMissionLabel" | translate }}

                            </th>
                            <th style="width: 15%">

                                {{ "Mission.ChaufMissionLabel" | translate }}

                            </th>
                            <!--  <th style="width: 10%">

                                {{ "Mission.ConvoyeurMissionLabel" | translate }}

                            </th> -->


                            <th style="width: 10%">

                                {{ "Mission.DestinationMissionLabel" | translate }}

                            </th>
                            <th style="width: 10%">

                                {{ "Mission.LieuDepartMissionLabel" | translate }}

                            </th>
                            <th style="width: 10%">

                                {{ "Mission.DateDepartMissionLabel" | translate }}

                            </th>
                            <!-- <th style="width: 10%">

                                {{ "Mission.IndexDepartMissionLabel" | translate }}

                            </th> -->
                            <th style="width: 15%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let mission of missions,let i = index" id="{{ mission.id }}">
                            <td>

                                {{ i+1 }}

                            </td>
                            <td>
                                <a>

                                    {{ mission?.element_intervenant_id2?.name }}

                                </a>
                            </td>
                            <td>
                                <a>

                                    {{ mission?.element_intervenant_id1.name }}

                                </a>
                            </td>
                            <td>
                                <a>

                                    {{ mission.vehicule_id.vehicle_registration }}

                                </a>
                            </td>
                            <td>
                                <a>

                                    {{ mission.chauffeur_id.user_id.name }}

                                </a>
                            </td>
                            <!--  <td>
                                <a>

                                    {{ mission.convoyeur_id.convoyeur_name }}

                                </a>
                            </td> -->
                            <td>
                                <a>

                                    {{ mission.destination }}

                                </a>
                            </td>
                            <td>
                                <a>

                                    {{ mission.lieu_depart }}

                                </a>
                            </td>
                            <td>
                                <a>

                                    {{ mission.date_depart }}

                                </a>
                            </td>
                            <!--  <td>
                                <a>

                                    {{ mission.index_depart }}

                                </a>
                            </td> -->

                            <td class="project-actions text-right">

                                <a class="btn btn-primary btn-sm" href="javascript:void(0)" data-bs-placement="top"
                                    title="details" (click)="details(mission.id)">
                                    <i class="fas fa-eye"></i>
                                </a>
                                &nbsp;
                                <a class="btn btn-info btn-sm" href="javascript:void(0)" data-placement="top"
                                    title="modifier" (click)="edit(mission.id)">
                                    <i class="fas fa-pencil-alt">
                                    </i>
                                </a>
                                &nbsp;
                                <a class="btn btn-danger btn-sm" href="javascript:void(0)" data-placement="top"
                                    title="supprimer" (click)="delete(mission.id)">
                                    <i class="fas fa-trash">
                                    </i>
                                </a>
                            </td>
                        </tr>


                    </tbody>
                </table>

            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->

    </section>
    <!-- /.content -->
</div>