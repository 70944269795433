<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>{{ "NoteCritique.NoteCritique" | translate }}</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                        <li class="breadcrumb-item "><a [routerLink]="['/note-critique/all']">{{
                                "NoteCritique.NoteCritique" |
                                translate }}</a></li>
                        <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>

                    </ol>
                </div>
            </div>
        </div>
        <!-- /.container-fluid -->
    </section>

    <!-- Main content -->

    <section class="container">
        <div class="row">
            <div class="col-md-12">
                <form (submit)="onSubmit()" [formGroup]="noteInterneForm">
                    <div class="card card-primary">

                        <div class="card-header" style="background-color:#17a2b8;">
                            <h3 class="card-title">{{ "NoteCritique.NoteCritiqueAdd" | translate }}</h3>
                        </div>
                        <div class="card-body" style="display: block;">

                            <div class="form-group">
                                <label for="inputName">{{ "NoteCritique.TitleLabel" | translate }}</label>
                                <input type="text" id="inputName" class="form-control" formControlName="title"
                                    placeholder="{{ 'NoteCritique.TitleLabel' | translate }}">
                            </div>
                            <div class="form-group">
                                <label for="inputName">{{ "NoteCritique.ContentLabel" | translate }}</label>
                                <textarea cols="30" rows="10" id="inputName" class="form-control"
                                    formControlName="content"
                                    placeholder="{{ 'NoteCritique.ContentLabel' | translate }}"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="inputName">{{ "NoteCritique.ImageLabel" | translate }}</label>
                                <input type="file" id="inputName" (change)=onSelectfile($event) class="form-control"
                                    formControlName="image" placeholder="{{ 'NoteCritique.ImageLabel' | translate }}">
                            </div>

                            <div class="card-footer">
                                <button [disabled]="isLoading" class="btn btn-primary"
                                    style="background-color:#17a2b8;">
                                    <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                                    <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</div>