import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { DatePipe } from '@angular/common';
import { PanneServices } from 'src/app/_services/main-service/panne-services/panne-services';


@Component({
  selector: 'app-panne-auto-detail',
  templateUrl: './panne-auto-detail.component.html',
  styleUrls: ['./panne-auto-detail.component.scss']
})
export class PanneAutoDetailComponent implements OnInit {
 panne:any;
 Users:any;
  constructor(
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private PanneServices: PanneServices,
  ) { }

  ngOnInit(): void {
    const panneId = +this.route.snapshot.paramMap.get("id");
    console.log(panneId);
    this.PanneServices.find(panneId).then(
      data => {
        this.panne = data;
        console.log(this.panne)
      }
      ).catch(
        error => {
          this.translate.get('panne.' + error.error.code)
            .subscribe(val => this.notifService.danger(val));
          this.router.navigate(['/panne-all'])
        }
      )
  }

}
