import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DocumentService } from "src/app/_services/document/document.service";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as DecoupledEditor from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-generating-document-add',
  templateUrl: './generating-document-add.component.html',
  styleUrls: ['./generating-document-add.component.scss']
})
export class GeneratingDocumentAddComponent implements OnInit {
  dataForm : FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  myfile: File = null;
  public Edito = ClassicEditor;
  public Editor = DecoupledEditor;

  public onReady( editor ) {
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
  }

  constructor(
    private translate: TranslateService,
    private dataService: DocumentService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(){
    this.dataForm = this.formBuilder.group({
      file_name : ['', Validators.required],
      terms : ['', Validators.required]
    })
  }

  get form(){
    return this.dataForm.controls
  }

  onSubmit(){
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;
    console.log('c bon')
    console.log(this.dataForm.value)

    if (this.dataForm.invalid) {
      this.toastrService.error('ERREUR', 'Les champs en rouge sont obligatoires!');
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    const data = this.form;
    for (const k in data) {
      if (k) {
        formData.append(k + '', data[k].value);
      }
    }
    
    this.dataService.generate(formData).then(resp => {
      this.toastrService.success('votre fichier a ete generer avec success');
      this.isSubmitted = false;
      this.dataForm.reset();
    })
    .catch(
      err => {
        console.log(err);
        this.toastrService.error('l\'operation a echouer');
      }
    )
    .finally(() => this.isLoading = false);
  }

}
