
  <div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;" >
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-1">
          <div class="col-sm-6">
            <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ "TypeIncident.TypeIncident" | translate }}</font></font></h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
              <li class="breadcrumb-item "><a [routerLink]="['/type-incident/all']">{{ "TypeIncident.TypeIncident" | translate }}</a></li>
              <li class="breadcrumb-item active">{{ "FileAriane.List" | translate }}</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">

      <!-- Default box -->


      <div class="card">
        <div class="card-header" style="background-color:#17a2b8;color:#fff;">
          <h3 class="card-title"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> {{ "TypeIncident.TypeIncidentAll" | translate }}</font></font></h3>
          <button [routerLink]="['/type-incident/add']" type="button" class="btn btn-primary float-right" style="margin-right: 1%; margin-bottom: 1%; margin-top: 1%;">
            <i class="fas fa-plus" ></i> {{ "Forms.Marques.Ajouter" | translate }}
        </button>
        </div>
        <div class="card-body p-0">
          <table id="example1" class="table table-bordered table-striped">
              <thead>
                  <tr>
                      <th style="width: 15%">#</th>
                      <th style="width: 30%">
                        {{ "TypeIncident.NameLabel" | translate }}
                      </th>
                      <th style="width: 30%">
                        {{ "TypeIncident.DescriptionLabel" | translate }}
                      </th>
                      <th style="width: 25%">
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let item of type_incidents?.data,let i = index" id="{{ item.id }}">
                      <td>{{ i+1 }}</td>
                      <td>
                        <a>{{ item.nom }}</a>
                    </td>
                      <td>
                        <a>{{ item.description }}</a>
                    </td>

                      <td class="project-actions text-right">
                        <a class="btn btn-info btn-sm" href="javascript:void(0)" data-bs-placement="top" title="modifier"  (click)="editTypeIncident(item.id)">
                            <i class="fas fa-pencil-alt">
                            </i>
                        </a>
                        &nbsp;
                        <a class="btn btn-danger btn-sm" href="javascript:void(0)" data-bs-placement="top" title="supprimer"  (click)="deleteTypeIncident(item.id)">
                            <i class="fas fa-trash">
                            </i>
                        </a>
                    </td>
                  </tr>
                  
              </tbody>
          </table>
          <div class="card-footer">
          </div>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->               

    </section>
    <!-- /.content -->
  </div>