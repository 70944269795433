<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ "ElementIntervenant.Title" | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">Home</a></li>
                    <li class="breadcrumb-item active"><a [routerLink]="['/intervening-element/list']">{{ "ElementIntervenant.Title" | translate }}</a></li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>
<section class="container">
    <div class="container-fluid">
        <div class="col-md-12">
            <!-- general form elements -->
            <div class="card card-primary">
                <div class="card-header" style="background-color: #17a2b8;">
                    <h3 class="card-title">{{ "ElementIntervenant.AddDescription" | translate }}</h3>
                </div>
                <!-- /.card-header -->
                <!-- form start -->
                <form (submit)="onSubmit()" [formGroup]="dataForm" role="form">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <!-- text input -->
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.Name" | translate }}<span style="color: red;">*</span> </label>
                                    <input type="text" class="form-control {{ isSubmitted && form.name.errors ? 'is-invalid' : '' }}"
                                        placeholder="{{ 'ElementIntervenant.NomLabel' | translate }}" formControlName="name">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.IntervenantType" | translate }} <span style="color: red;">*</span> </label>
                                    <select class="form-control {{ isSubmitted && form.intervening_type_id.errors ? 'is-invalid' : '' }}" formControlName="intervening_type_id">
                                        <option value="">{{ "ElementIntervenant.TypeIntervenant" | translate }}</option>
                                        <option *ngFor="let type of data?.data" value="{{ type.id }}"
                                        title="{{ type.description }}">{{ type.intervening_type_name }}</option>
                                    </select>
                                    <label>Ajouter un type d'element <a href="javascript:void(0)" (click)="addType()">cliquant
                                        ici</a></label> 
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <!-- text input -->
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.Email" | translate }} <span style="color: red;">*</span> </label>
                                    <input type="email" class="form-control {{ isSubmitted && form.name.errors ? 'is-invalid' : '' }}"
                                        placeholder="{{ 'ElementIntervenant.EmailLabel' | translate }}" formControlName="email">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.Location" | translate }} <span style="color: red;">*</span> </label>
                                    <input type="text" class="form-control {{ isSubmitted && form.location.errors ? 'is-invalid' : '' }}"
                                        placeholder="{{ 'ElementIntervenant.LocationLabel' | translate }}" formControlName="location">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <!-- text input -->
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.Phone" | translate }} 1 <span style="color: red;">*</span> </label>
                                    <input type="tel" #tel1 (blur)="isCameroonianPhoneNumber(tel1)" class="form-control {{ isSubmitted && form.tel1.errors ? 'is-invalid' : '' }}"
                                        placeholder="{{ 'ElementIntervenant.PhoneLabel' | translate }}" formControlName="tel1">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.Phone" | translate }} 2 </label>
                                    <input #tel2 (blur)="isCameroonianPhoneNumber(tel2)" type="tel" class="form-control {{ isSubmitted && form.tel2.errors ? 'is-invalid' : '' }}"
                                        placeholder="{{ 'ElementIntervenant.PhoneLabel' | translate }}" formControlName="tel2">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <!-- text input -->
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.ContactPerson" | translate }} <span style="color: red;">*</span> </label>
                                    <input type="text" class="form-control {{ isSubmitted && form.contact_person.errors ? 'is-invalid' : '' }}"
                                        placeholder="{{ 'ElementIntervenant.ContactPersonLabel' | translate }}" formControlName="contact_person">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>{{ "ElementIntervenant.TelPerson" | translate }} <span style="color: red;">*</span> </label>
                                    <input type="tel"  #tel_person (blur)="isCameroonianPhoneNumber(tel_person)" class="form-control {{ isSubmitted && form.tel_person.errors ? 'is-invalid' : '' }}"
                                        placeholder="{{ 'ElementIntervenant.PhoneLabel' | translate }}" formControlName="tel_person">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col sm-12">
                                <div class="form-group">
                                    <label for="exampleInputFile">{{ "ElementIntervenant.InterveningFile" | translate }} </label>
                                    <div class="custom-file">
                                        <input type="file" name="intervening_file" id="exampleInputFile" (change)="detectfile($event)" formControlName="intervening_file">
                                        <!-- <input type="file" id="exampleInputFile" (change)="detectfile($event)" formControlName="intervening_file"> -->
                                        <label class="custom-file-label" for="exampleInputFile">Choose file</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                              <!-- textarea -->
                              <div class="form-group">
                                  <label>{{ "ElementIntervenant.Description" | translate }} </label>
                                  <textarea class="form-control" rows="3"
                                      placeholder="{{ 'ElementIntervenant.DescriptionLabel' | translate }}" formControlName="description"></textarea>
                              </div>
                          </div>
                      </div>

                    </div>
                    <!-- /.card-body -->


                    <div class="card-footer">
                        <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                            <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                            <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                    </div>
                </form>
                <div *ngIf="isMaterial" class="col-md-2" style="margin-left:85%"><button class="btn btn-danger" (click)="close()">
                    {{
                    'Material.ButtonClose' | translate }}
                  </button>
                </div>
            </div>
            <!-- /.card -->
        </div>
    </div>
</section>
