import { Component, OnInit } from '@angular/core';
import { PositionService } from 'src/app/_services/pneumatique/Position/position.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-position-add',
  templateUrl: './position-add.component.html',
  styleUrls: ['./position-add.component.scss']
})
export class PositionAddComponent implements OnInit {

  dataForm: FormGroup;
  isSubmitted: boolean = false;
  isLoading = false;
  isError = false;
  isSuccess = false;
  models:any;
  user;

  constructor(
    private PositionService: PositionService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    //private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getModel();
    this.user = this.authService.getUser();

    this.dataForm = this.formBuilder.group({
      indice_colone: ['', [Validators.required]],
      indice_ligne: ['', [Validators.required]],
      modele_id: ['', [Validators.required]],
    });
  }

  getModel() {
    this.PositionService.getModel().then((res) => {
      this.models = res;
      console.log(this.models)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
 
  get form() {
    return this.dataForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.dataForm.invalid) {

      this.translate.get('Position.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('indice_colone', '' + this.form.indice_colone.value);
    formData.append('indice_ligne', '' + this.form.indice_ligne.value);
    formData.append('modele_id', '' + this.form.modele_id.value);
    formData.append('create_id', '' + this.user.id);

    console.log(formData);
    this.PositionService.add(formData)
      .then(resp => {
        this.translate.get('Position.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.dataForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('Position.SubmitErrorOperation')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }


}
