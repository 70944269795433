import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-type-vehicule-auto',
  templateUrl: './detail-type-vehicule-auto.component.html',
  styleUrls: ['./detail-type-vehicule-auto.component.scss']
})
export class DetailTypeVehiculeAutoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
