<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>{{ "Forms.Breakdown.Breakdown" | translate }}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <!--    <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item">Forms</li>
              <li class="breadcrumb-item active">ajouter</li> -->
  
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/panne/list']">{{ "Forms.Breakdown.Breakdown" |
              translate }}</a></li>
            <li class="breadcrumb-item active">{{ "FileAriane.Update" | translate }}</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- /.container-fluid -->
  </section>
  
  <!-- Ajouter une panne -->
  <div class="container-fluid">
    <!-- Main content -->
    <section class="container">
      <div class="card card-primary">
        <div class="card-header" style="background-color: #17a2b8;">
          <h1 class="card-title">{{ "Forms.Breakdown.UpdateBreakdown" | translate }}</h1>
        </div>
        <!-- /.card -->
        <div class="row">
          <!-- cette div gere les pannes -->
          <div class="card-body">
            <div class="row">
              <!-- left column -->
              <div class="col-md-12">
                <!-- general form elements -->
                <div class="card card-primary">
                  <!-- form start -->
                  <form (submit)="onSubmit()" [formGroup]="dataForm">
                    <div class="card-body">
                      <div class="form-group">
                        <label> {{ "Forms.Breakdown.Vehicules" | translate }} <span style="color: red;">*</span> </label>
                        <select class="form-control {{ isSubmitted && form.vehicule_id.errors ? 'is-invalid' : '' }}"
                          formControlName="vehicule_id">
                          <option  *ngFor="let item of vehicules " value="{{item.id}}">
                            {{item.vehicle_registration}}</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label> {{ "Forms.Breakdown.Missions" | translate }} <span style="color: red;">*</span> </label>
                        <select class="form-control {{ isSubmitted && form.mission_id.errors ? 'is-invalid' : '' }}"
                          formControlName="mission_id">
                          <option   value="">{{ "Forms.Breakdown.MissionLabel" | translate }}</option>
                          <option  *ngFor="let item of missions " value="{{item.id}}">
                            {{item.destination}}</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="exampleSelect" class="">{{ 'Forms.Breakdown.Gerer' | translate }}</label>
                          <select name="etat_actuel" formControlName="gerer"  class="form-control {{ isSubmitted && form.gerer.errors ? 'is-invalid' : '' }}" >
                              <option value="oui">OUI</option>
                              <option value="non" selected>NON </option>
                          </select>
                      </div>
                      <div class="form-group">
                        <label for="exampleName1">{{ "Forms.Breakdown.Nature" | translate }} <span style="color: red;">*</span></label>
                        <input type="text" formControlName="nature"
                          class="form-control {{ isSubmitted && form.nature.errors ? 'is-invalid' : '' }}"
                          id="exampleName1" placeholder="Nature de la Panne ..." />
                      </div>
                      <div class="form-group">
                        <label for="exampleName1">{{ "Forms.Breakdown.Lieu" | translate }} <span style="color: red;">*</span></label>
                        <input type="text" formControlName="lieu"
                          class="form-control {{ isSubmitted && form.lieu.errors ? 'is-invalid' : '' }}"
                           placeholder="Lieu de la panne ..." />
                      </div>
                      <div class="form-group">
                        <label>{{ "Forms.Breakdown.Description" | translate }}</label>
                        <textarea
                          class="form-control {{ isSubmitted && form.description.errors ? 'is-invalid' : '' }}"
                          formControlName="description" rows="3" placeholder="Description ..."></textarea>
                      </div>
                      <div class="form-group">
                        <label for="exampleName1">{{ "Forms.Breakdown.Files" | translate }}</label>
                        <input type="file" formControlName="files" (change)=detectFile($event)
                          class="form-control "
                          id="exampleName1" name="files" placeholder="photo de la Panne ..." multiple />
                      </div>
                    </div>
                    <!-- /.card-body -->
  
                    <div class="card-footer">
                      <button [disabled]="isLoading" style="background-color: #17a2b8;">
                        <p *ngIf="!isLoading">{{ "General.update" | translate }}</p>
                        <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
                  </form>
                </div>
                <!-- /.card -->
              </div>
              <!--/.col (left) -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12"></div>
        </div>
        <!-- /.card-body -->
      </div>
    </section>
    <!-- /.content -->
  </div>
