import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { InterveningElementService } from "src/app/_services/transport-element/intervening-element/intervening-element.service";

@Component({
  selector: 'app-intervening-element-detail',
  templateUrl: './intervening-element-detail.component.html',
  styleUrls: ['./intervening-element-detail.component.scss']
})
export class InterveningElementDetailComponent implements OnInit {

  element:any;
  constructor(
    private translate: TranslateService,
    private dataService: InterveningElementService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) { }

  async ngOnInit() {
    const element_id = +this.route.snapshot.paramMap.get("id");
    this.dataService.find(element_id).then(
      data => {
        this.element = data;
        console.log(this.element);
      }
    ).catch(
      error => {
        this.translate.get("une erreurs est survenu")
          .subscribe(val => this.toastrService.error(val));
        
      }
    )

  }
}
