import { HttpClient } from '@angular/common/http';
import * as Routes from 'src/Routes';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PneuService {

  constructor(private http: HttpClient) { }

  add(formData: FormData): Promise<any> {
    return this.http.post<any>(Routes.PNEU, formData).toPromise();
  }

  update(formData: FormData, id): Promise<any> {
    return this.http.post<any>(`${Routes.PNEU}/${id}`, formData).toPromise();
  }

  delete(id: number) {
    return this.http.delete(`${Routes.PNEU}/${id}`).toPromise();

  }

  find(id: number): Promise<any> {
    return this.http.get<any>(`${Routes.PNEU}/${id}`).toPromise();
  }
  public gets() {
    return this.http.get<any>(`${Routes.PNEU}`)
      .toPromise();
  }

  public get(url) {
    return this.http.get<any>(`${url}`)
      .toPromise();
  }

  public getFournisseur() {
    return this.http.get<any>(`${Routes.ELEMENTINTERVENANT}`)
      .toPromise();
  }

  public getMarque() {
    return this.http.get<any>(`${Routes.MARQUE_PNEU}`)
      .toPromise();
  }


}
