import { Component, OnInit } from '@angular/core';
import { DocService } from '../../../../../../_services/parc-service/doc-service/doc-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router ,ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-official-paper-add',
  templateUrl: './official-paper-add.component.html',
  styleUrls: ['./official-paper-add.component.scss']
})
export class OfficialPaperAddComponent implements OnInit {

  type :any ;
  vehicules : any;
  OfficialPaperForm : FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  myfile: File = null;

  constructor(
    private docService: DocService,
    //private notifService: NotifService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    //private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const paper_id =+ this.route.snapshot.paramMap.get("id")
    this.docService.findType(paper_id).then(
      data => {
        this.type = data;
  }).catch(
    error => {
      this.toastr.warning('opertion echoue');
    }
  )
    this.OfficialPaperForm =this.formBuilder.group({
      vehicle_id: ['', Validators.required],
      validity_start_at: [''],
      validity_end_at: [''],
      amount: [''],
      done_by: [''],
      receipt_number: [''],
      attachment_file:[''],
    });
    this.getVehicule();null
  }

  get form() {
    return this.OfficialPaperForm.controls;
  }

  getVehicule() {
    this.docService.getVehicule().then((res) => {
      this.vehicules = res.data;
      console.log(res)
    }, (error) => {
      this.toastr.warning('Aucun Vehicule Disponible');
    });
  }

  detectfile(event) {
    this.myfile = event.target.files[0];
    console.log(this.myfile)
  }


  onSubmit(){
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false
    // Si la validation a echoué, on arrete l'execution de la fonction
    if (this.OfficialPaperForm.invalid) {
      this.toastr.warning('Le Formulaire est mal remplit');
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('vehicle_id', '' + this.form.vehicle_id.value);
    formData.append('paper_type_id', '' +this.type.id);
    formData.append('validity_start_at', '' + this.form.validity_start_at.value);
    formData.append('validity_end_at', '' + this.form.validity_end_at.value);
    formData.append('amount', '' + this.form.amount.value);
    formData.append('done_by', '' + this.form.done_by.value);
    formData.append('receipt_number', '' + this.form.receipt_number.value);
    formData.append('attachment_file',  this.myfile);

    this.docService.addOfficialPaper(formData)
      .then(resp => {
        this.toastr.success('L Operation effectue avec success');
        this.isSubmitted = false;
        this.OfficialPaperForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.toastr.error('L Operation a echoue');
      })
      .finally(() => this.isLoading = false);
  }
}
