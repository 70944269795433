import { AuthService } from './../../../../../_services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { NoteCritiqueService } from 'src/app/_services/RH-service/note-critique/note-critique.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-note-critique-add',
  templateUrl: './note-critique-add.component.html',
  styleUrls: ['./note-critique-add.component.scss']
})
export class NoteCritiqueAddComponent implements OnInit {

  noteInterneForm: FormGroup;
  users;
  user;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  file: File = null;

  constructor(
    private NoteCritiqueService: NoteCritiqueService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService,

  ) { }

  ngOnInit() {
    this.user = this.authService.getUser();
    console.log(this.user.id);

    this.noteInterneForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      content: [''],
      image: ['']

    });
  }

  onSelectfile(event) {
    this.file = event.target.files[0];
  }

  get form() {
    return this.noteInterneForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

      if (this.noteInterneForm.invalid) {
         this.translate.get('NoteCritique.SubmitError')
           .subscribe(val => this.notifService.danger(val));
         return;
      }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('user_id', this.user.id);
    formData.append('title', '' + this.form.title.value);
    formData.append('content', '' + this.form.content.value);
    if (this.file != null)
      formData.append('image', this.file, this.file.name);
    //formData.append('image', this.file, this.file.name);


    console.log(formData);
    this.NoteCritiqueService.post(formData)
      .then(resp => {
        this.translate.get('NoteCritique.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.noteInterneForm.reset();
        this.isLoading = false;
      })
      .catch(err => {
        console.log(err)
        this.translate.get('NoteCritique.SubmitErrorNoteCritique')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }
}
