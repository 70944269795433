<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 1%; margin-right: 1%;">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-1">
        <div class="col-sm-6">
          <h1>
            <font style="vertical-align: inherit;">
              <font style="vertical-align: inherit;">{{ "Chauffeur.Chauffeur" | translate }}</font>
            </font>
          </h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/chauffeur/all']">{{ "Chauffeur.Chauffeur" | translate
                }}</a></li>
            <li class="breadcrumb-item active">{{ "FileAriane.List" | translate }}</li>
          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>

  <!-- Main content -->
  <section class="content">

    <!-- Default box -->


    <div class="card">
      <div class="card-header" style="background-color:#17a2b8; color:white;">
        <h3 class="card-title">
          <font style="vertical-align: inherit;">
            <font style="vertical-align: inherit;"> {{ "Chauffeur.ChauffeurAll" | translate }}</font>
          </font>
        </h3>
        <button *ngIf="canCreate" [routerLink]="['/chauffeur/add']" type="button" class="btn btn-primary float-right">
          <i class="fas fa-plus"></i> {{ "Forms.Marques.Ajouter" | translate }}
        </button>
      </div>
      <div class="card-body p-0">
        <table id="example1" class="table table-bordered table-striped">
          <thead>
            <tr>
              <th style="width: 5%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    #
                  </font>
                </font>
              </th>
              <th style="width: 10%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Chauffeur.PhotoLabel" | translate }}
                  </font>
                </font>
              </th>
              <th style="width: 15%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Chauffeur.UserContratLabel" | translate }}
                  </font>
                </font>
              </th>
              <th style="width: 10%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Chauffeur.LicenceNumberLabel" | translate }}
                  </font>
                </font>
              </th>
              <th style="width: 15%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Chauffeur.LicenceTypeLabel" | translate }}
                  </font>
                </font>
              </th>
              <th style="width: 15%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Chauffeur.DateIssueLabel" | translate }}
                  </font>
                </font>
              </th>
              <th style="width: 15%">
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ "Chauffeur.EndDateLabel" | translate }}
                  </font>
                </font>
              </th>
              <th style="width: 15%"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let affect of Chauffeurs?.data,let i = index" id="{{ affect.id }}">
              <td>
                <font style="vertical-align: inherit;">
                  <font style="vertical-align: inherit;">
                    {{ i+1 }}
                  </font>
                </font>
              </td>
              <td>
                <a>
                  <font style="vertical-align: inherit;">
                      <font style="vertical-align: inherit;">
                        <a (click)="show(affect?.driver_photo)">
                          <img height="50" src="{{ affect.driver_photo }}" alt="">
                        </a>
                      </font>
                  </font>
                </a>
              </td>
              <td>
                <a>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ affect.driver_name }}
                    </font>
                  </font>
                </a>
              </td>
              <td>
                <a>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ affect.licence_number }}
                    </font>
                  </font>
                </a>
              </td>
              <td>
                <a>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ affect.licence_type }}
                    </font>
                  </font>
                </a>
              </td>
              <td>
                <a>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ affect.date_of_issue }}
                    </font>
                  </font>
                </a>
              </td>

              <td>
                <a>
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">
                      {{ affect.end_of_date }}
                    </font>
                  </font>
                </a>
              </td>

              <td class="project-actions text-right">

                <a class="btn btn-primary btn-sm" href="javascript:void(0)" data-bs-placement="top" title="details" (click)="detailleChauffeur(affect.id)">
                  <i class="fas fa-eye"></i>
                </a>
                &nbsp;
                <a *ngIf="canUpdate" class="btn btn-info btn-sm" href="javascript:void(0)" data-bs-placement="top" title="modifier" (click)="editChauffeur(affect.id)">
                  <i class="fas fa-pencil-alt">
                  </i>
                </a>
                &nbsp;
                <a *ngIf="canDelete" class="btn btn-danger btn-sm" href="javascript:void(0)" data-bs-placement="top" title="supprimer" (click)="deleteChauffeur(affect.id)">
                  <i class="fas fa-trash">
                  </i>
                </a>
              </td>
            </tr>

          </tbody>
        </table>
        <div class="card-footer">
          <!-- <nav aria-label="Navigation dans la page des contacts">
            <ul class="pagination justify-content-center m-0">
              <li class="page-item"><a (click)="getPage(Chauffeurs.prev_page_url)" class="page-link">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">{{ 'General.Previous' | translate }}</font>
                  </font>
                </a></li>
              <li class="page-item"><a (click)="getPage(Chauffeurs.next_page_url)" class="page-link">
                  <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">{{ 'General.next' | translate }}</font>
                  </font>
                </a></li>
            </ul>
          </nav> -->
        </div>
      </div>
      <!-- /.card-body -->
    </div>
    <!-- /.card -->

  </section>
  <!-- /.content -->
</div>
