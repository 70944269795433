import { Component, OnInit } from '@angular/core';
import { DocService } from '../../../../../../_services/parc-service/doc-service/doc-service';
//import { NotifService } from 'src/app/_services/notif.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//import { TranslateService } from '@ngx-translate/core';
import { Router , ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-official-paper-aupdate',
  templateUrl: './official-paper-aupdate.component.html',
  styleUrls: ['./official-paper-aupdate.component.scss']
})
export class OfficialPaperAUpdateComponent implements OnInit {

     
  assureurs :any ;
  vehicules : any;
  OfficialPaperForm : FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  myfile: File = null;
  paper : any ;


  constructor(
    private docService: DocService,
    //private notifService: NotifService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.initForm();
    const paper_id = +this.route.snapshot.paramMap.get("id");
    this.docService.findOfficialPapaer(paper_id).then(
      data => {
        this.paper = data;
        console.log(this.paper)
        this.initForm(true);
  }).catch(
    error => {
      this.toastr.warning('opertion echoue');
    }
  )
}

initForm(Withpaper = false) {
  if(Withpaper) {
    this.OfficialPaperForm = this.formBuilder.group({
      vehicle_id: [this.paper.vehicle_id, Validators.required],
      validity_start_at: [this.paper.validity_start_at],
      validity_end_at: [this.paper.validity_end_at],
      amount: [this.paper.amount],
      done_by: [this.paper.done_by],
      receipt_number: [this.paper.receipt_number],
      
    });
  }else {
    this.OfficialPaperForm = this.formBuilder.group({
      vehicle_id: ['', Validators.required],
      validity_start_at: [''],
      validity_end_at: [''],
      amount: [''],
      done_by: [''],
      receipt_number: [''],
    });
  }
  this.getVehicule();
}

get form() {
  return this.OfficialPaperForm.controls;
}

getVehicule() {
  this.docService.getVehicule().then((res) => {
    this.vehicules = res.data;
  }, (error) => {
    this.toastr.warning('Aucun Vehicule Disponible');
  });
}

detectfile(event) {
  this.myfile = event.target.files[0];
  console.log(this.myfile)
}


onSubmit() {
  this.isSubmitted = true;
  this.isError = false;
  this.isSuccess = false;
  this.isLoading = false
  
  if (this.OfficialPaperForm.invalid) {
    this.toastr.warning('Le Formulaire est mal remplit');
    return;
  }

  this.isLoading = true;
  const formData = new FormData();
  formData.append('paper_type_id', '' +this.paper.paper_type_id);
  formData.append('vehicle_id', '' + this.form.vehicle_id.value);
  formData.append('validity_start_at', '' + this.form.validity_start_at.value);
  formData.append('validity_end_at', '' + this.form.validity_end_at.value);
  formData.append('done_by', '' + this.form.done_by.value);
  formData.append('amount', '' + this.form.amount.value);
  formData.append('receipt_number', '' + this.form.receipt_number.value);
  formData.append('file',  this.myfile);

  this.docService.updateOfficialPaper(formData, this.paper.id)
    .then(resp => {
      this.toastr.success('L Operation effectue avec success');
      this.router.navigate(['official_all/' , this.paper.paper_type_id])

    })
    .catch(err => {
      console.log(err)
      this.toastr.error('L Operation a echoue');
    })
    .finally(() => this.isLoading = false);
}
}
