<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ 'ProgrammeReparation.ProgrammeReparation' | translate }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a [routerLink]="['']">{{ "FileAriane.Home" | translate }}</a></li>
                    <li class="breadcrumb-item active"><a [routerLink]="['/programme-reparation/all']">{{ 'ProgrammeReparation.ProgrammeReparation' | translate }}</a>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<div class="container">
    <div class="container-fluid">
        <div class="row">

            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Informations Detaillées sur un programme de reparation</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "ProgrammeReparation.VehiculeLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{programme_reparations?.immatriculation}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "ProgrammeReparation.MecanicienLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{programme_reparations?.mecanicien}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "ProgrammeReparation.PanneLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{programme_reparations?.panne}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Informations Detaillées sur un programme de reparation</h3>

                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "ProgrammeReparation.DescriptionLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{programme_reparations?.description}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "ProgrammeReparation.DateDebutLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{programme_reparations?.date_debut}}</td>
                                </tr>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "ProgrammeReparation.DateFinLabel" | translate }}</td>
                                    <td style="color: rgb(0, 89, 255);">{{programme_reparations?.date_fin}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <!-- /.col-md-6 -->
        </div>

    </div>
    <!-- /.container-fluid -->
</div>

