import { Component, OnInit } from '@angular/core';
import { FormationService } from 'src/app/_services/RH-service/formation/formation.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-formation-add',
  templateUrl: './formation-add.component.html',
  styleUrls: ['./formation-add.component.scss']
})
export class FormationAddComponent implements OnInit {


  FormationForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  file: File = null;
  user;
  users;

  constructor(
    private FormationService: FormationService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getusers();

    this.FormationForm = this.formBuilder.group({
      user_id: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description: [''],
      date_debut: [''],
      date_fin: [''],
      file: [''],

    });
  }

  getusers() {
    this.FormationService.getUser().then((res) => {
      this.users = res;
      console.log('theo formation')
      console.log(this.users)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  onSelectfile(event) {
    this.file = event.target.files[0];
  }

  get form() {
    return this.FormationForm.controls;
  }
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.FormationForm.invalid) {
      this.translate.get('Formation.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('user_id', '' + this.form.user_id.value);
    formData.append('name', '' + this.form.name.value);
    formData.append('description', '' + this.form.description.value);
    formData.append('date_debut', '' + this.form.date_debut.value);
    formData.append('date_fin', '' + this.form.date_fin.value);
    if (this.file != null)
      formData.append('file', this.file, this.file.name);


    console.log(formData);
    this.FormationService.post(formData)
      .then(resp => {
        this.translate.get('Formation.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.FormationForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('Formation.SubmitErrorFormation')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }

}
