import { Component, OnInit } from '@angular/core';
import { DocService } from '../../../_services/parc-service/doc-service/doc-service';
import { error } from 'selenium-webdriver';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AuthService } from '../../../_services/auth/auth.service';
import {NotifService} from '../../../_services/notif.service';
import {UserService} from '../../../_services/users/user.service';



@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  paperTypes:any;
  user:any;
  completedUser:any;

  constructor(
    private docService: DocService,
    private toastr: ToastrService,
    private router: Router, 
    private authService: AuthService ,
    private notifService: NotifService,
    private userService:UserService


  ) { }

  ngOnInit(): void {
    this.getPaperType();
    this.user = this.authService.getUser();
    this.findUser(this.user.id);
  
    
  }

  getPaperType(){
    this.docService.allType().then((res) =>{
      this.paperTypes = res.data;
      console.log(this.paperTypes)
    },(error) =>{
      this.toastr.warning('Aucun papier Disponible');
    });
  }

  findUser(id) {
    this.userService.userInfo(id).then(
      response => {
        this.completedUser = response;
        console.log(this.completedUser);
        
       
      }
    ).catch(
      error => {
        this.notifService.danger(error.message)
      }
    )
  }


  consulter(id){
    this.router.navigate(['official_all/' , id]);
  }

  assurance(){
    this.router.navigate(['all_assurrance']);
  }

  navigateForm() {
    this.router.navigate(['setting/forms/add/'+"Model"])
  } 

  listType(id){

    this.router.navigate(['official_all/' + id]);
  }
}
