<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>{{ "ProgrammeReparation.ProgrammeReparation" | translate }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
              <li class="breadcrumb-item "><a [routerLink]="['/programme-reparation/all']">{{ "ProgrammeReparation.ProgrammeReparation" | translate }}</a></li>
              <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>
  
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>
  
    <!-- Main content -->
  
    <section class="container">
      <div class="row">
        <div class="col-md-12">
          <form (submit)="onSubmit()" [formGroup]="DataForm">
            <div class="card card-primary">
  
              <div class="card-header" style="background-color: #17a2b8;">
                <h3 class="card-title">{{ "ProgrammeReparation.ProgrammeReparationAdd" | translate }}</h3>
  
                <!-- <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                    title="Effondrer">
                    <i class="fas fa-minus"></i></button>
                </div> -->
              </div>
  
              <div class="card-body" style="display: block;">
                <div class="form-group">
                  <label>{{ "ProgrammeReparation.VehiculeLabel" | translate }} <span
                    style="color: red;">*</span></label>
                  <select formControlName="vehicule_id" class="form-control {{ isSubmitted && form.vehicule_id.errors? 'is-invalid' : '' }}"
                    style="width: 100%;" tabindex="-1" aria-hidden="true">
                    <option value="">{{ "ProgrammeReparation.LabelVehicule" | translate }}</option>
                    <option *ngFor="let item of vehicules" value="{{item.id}}">{{item.vehicle_registration}}</option>
                  </select>
                </div>
                <div class="form-group">
                    <label>{{ "ProgrammeReparation.MecanicienLabel" | translate }} <span
                      style="color: red;">*</span></label>
                    <select formControlName="mecanicien_id" class="form-control {{ isSubmitted && form.mecanicien_id.errors? 'is-invalid' : '' }}"
                      style="width: 100%;" tabindex="-1" aria-hidden="true">
                      <option value="">{{ "ProgrammeReparation.LabelMecanicien" | translate }}</option>
                      <option *ngFor="let item of mecaniciens?.data" value="{{item.id}}">{{item.mecanicien_name}}</option>
                    </select>
                  </div>
                <div class="form-group">
                  <label>{{ "ProgrammeReparation.PanneLabel" | translate }} <span
                    style="color: red;">*</span></label>
                  <select formControlName="panne_id" class="form-control {{ isSubmitted && form.panne_id.errors? 'is-invalid' : '' }}"
                    style="width: 100%;" tabindex="-1" aria-hidden="true">
                    <option value=""> {{ "ProgrammeReparation.LabelPanne" | translate }}</option>
                    <option *ngFor="let item of pannes?.data" value="{{item.id}}">{{item.nature}}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>{{ "ProgrammeReparation.DescriptionLabel" | translate }} </label>
                  <textarea class="form-control" rows="5" placeholder="{{ 'ProgrammeReparation.LabelDescription' | translate }}"
                    formControlName="description">
                  </textarea>
                </div>
                <div class="form-group">
                  <label for="inputName">{{ "ProgrammeReparation.DateDebutLabel" | translate }} <span
                    style="color: red;">*</span></label>
                  <input type="date" id="inputName" class="form-control {{ isSubmitted && form.date_debut.errors? 'is-invalid' : '' }}"
                    formControlName="date_debut" placeholder="{{ 'ProgrammeReparation.LabelDateDebut' | translate }}">
                </div>
                <div class="form-group">
                  <label for="inputName">{{ "ProgrammeReparation.DateFinLabel" | translate }} <span
                    style="color: red;">*</span></label>
                  <input type="date" id="inputName" class="form-control {{ isSubmitted && form.date_fin.errors? 'is-invalid' : '' }}"
                    formControlName="date_fin" placeholder="{{ 'ProgrammeReparation.LabelDateFin' | translate }}">
                </div>
              </div>
              <div class="card-footer">
                <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                  <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                  <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
              <!-- /.card-body -->
            </div>
          </form>
          <!-- /.card -->
        </div>
      </div>
    </section>
    <!-- /.content -->
  </div>