import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ParamService } from 'src/app/_services/parc-service/param-service/param-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-add-mark-auto',
  templateUrl: './add-mark-auto.component.html',
  styleUrls: ['./add-mark-auto.component.scss']
})
export class AddMarkAutoComponent implements OnInit {



  markForm: FormGroup;
  isSubmitted: boolean = false;
  isLoading = false;
  isMaterial = false;
  // isClose = false;

  constructor(
    private dialoge: MatDialog,
    private translate: TranslateService,
    private http: HttpClient,
    private paramService: ParamService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    // initialisation de type vehicule
    /*  let returnUrl = this.route.snapshot.queryParams["returnUrl"];
     console.log(returnUrl)
     if (returnUrl) {
       this.isClose = false
     } */
     this.isMaterial = '/setting/forms/marque/add' != location.pathname;

    this.inittypevehicule();
  }



  public inittypevehicule() {
    this.markForm = this.formBuilder.group({
      description: [''],
      name: ['', Validators.required],
    });
  }


  get markform() {
    return this.markForm.controls;
  }


  onSubmitMark() {
    this.isLoading = false;
    this.isSubmitted = true;
    if (this.markForm.invalid) {
      this.toastrService.error('ERREUR', 'Les champs en rouge sont invalides!');
      return;
    }
    this.isLoading = true;
    const formData = new FormData();
    formData.append('description', '' + this.markform.description.value);
    formData.append('name', '' + this.markform.name.value);
    formData.append('code', '' + 'MARK');
    this.paramService.addForm(formData)
      .then(resp => {
        this.toastrService.success('ajouter avec succes!', 'Succes!');
        this.markForm.reset();
        this.markForm = this.formBuilder.group({
          description: [''],
          name: ['', Validators.required],
        });
        this.isSubmitted = false;
      })
      .catch(err => {
        console.log(err)
        this.toastrService.error('Veuillez vérifier votre serveur ou Cette marque de véhicule existe déjà', 'error!');

      })
      .finally(() => this.isLoading = false);
  }
  close() {
    this.dialoge.closeAll();
    // location.reload();
  }
}












