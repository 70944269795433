
<mat-horizontal-stepper  >
    <mat-step label="Etape 1" [stepControl]="firstFormGroup">
                           
               <div class="row">
                    <div class="Mode" style="margin-bottom: 5%;">
                        <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 contant_form">
                                <div  class="form1" style="margin-top: 5%;">
                                <h1>{{ 'Vehicle.StepAddTitle' | translate }}</h1>
                                </div><br>
                                <div  class="form1">
                                    <h2>{{ 'Vehicle.step1' | translate }}{{ 'Vehicle.subtitlestep1' | translate }} </h2><br>
                                    <h3 style="color: red;"> <i>{{ 'Vehicle.notestep1' | translate }}</i></h3>
                                </div>

                            <div class="center">
                                <div class="form_section"  class="form1">

                                    <form class="col-md-5 contant_form" #f1="ngForm" [formGroup]="firstFormGroup"   class="Mode">
                                        <fieldset class="card">
                                            <div class="row">
                                                <table  class="Mode">
                                                    <tr>
                                                        <td><label for="immatri" class="Mode">{{ 'Vehicle.VehicleRegistration' | translate }}   </label></td>
                                                        <td>
                                                           
                                                            <input  class="field_custom" id="immatri" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  formControlName="immatriculation" class="Mode" placeholder="valeur" type="text" required  /> 
                                                       
                                                        </td>  
                                                    </tr>
                                                    
                                                    <tr>      
                                                        <td><label class="Mode">{{ 'Vehicle.vehicleBrand' | translate }} </label></td>
                                                        <td>
                                                            
                                                                <select name="marque"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  formControlName="marque" class="Mode" required="">
                                                                    <option *ngFor="let marque of marques" value="{{marque.id}}">{{marque.name}}</option>
                                                                 </select>
                                                        </td>  
                                                    </tr>
                                                    <tr>
                                                        <td><label for="marque">{{ 'Vehicle.vehiclemodel' | translate }} </label></td>
                                                        <td  class="Mode">
                                                            <select name="Modele"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  formControlName="modele" class="Mode" required >
                                                                <option *ngFor="let modele of modeles" value="{{modele.id}}">{{modele.name}} </option>
                                                            </select>
                                                        </td>   
                                                    </tr>
                                                    <tr>
                                                        <td><label>{{ 'Vehicle.vehicleType' | translate }}  </label></td>
                                                        <td class="Mode">
                                                            <select name="Type"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  formControlName="type"  class="Mode" required>
                                                                <option *ngFor="let type of types" value="{{type.id}}" class="Mode">{{type.name}}</option>
                                                            </select>
                                                        </td>   
                                                    </tr>
                                                    <tr>
                                                        <td><label >{{ 'Vehicle.vehicle_body' | translate }} </label></td>
                                                        <td class="Mode">
                                                            <select name="Carosserie"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  formControlName="carosserie" class="Mode" required>
                                                                <option *ngFor="let carosserie of carosseries"  value="{{carosserie.id}}">{{carosserie.name}}</option>
                                                            </select>
                                                        </td>     
                                                    </tr>
                                                    <tr>
                                                        <td><label for="Numéro_de_chassis"  class="Mode">{{ 'Vehicle.number_frame' | translate }} </label></td>
                                                        <td>
                                                            
                                                                <input   class="field_custom" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  formControlName="numero_chassis" required class="Mode" id="Numéro_de_chassis" placeholder="valeur" type="text"/>  
                                                         
                                                        </td> 
                                                     </tr>
                                                    <tr>
                                                        <td><label for="Propriétaire">{{ 'Vehicle.owner' | translate }} </label></td>
                                                        <td>
                                                               
                                                                <input class="field_custom" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  formControlName="proprietaire" required class="Mode" id="Propriétaire" placeholder="valeur" type="text" />
                                                         
                                                        </td>    
                                                     </tr>
                                                    <tr>
                                                        <td><label for="Valeur_achat"  class="Mode">{{ 'Vehicle.purchase_value' | translate }} </label></td>
                                                        <td>
                                                              
                                                                <input class="field_custom" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"   formControlName="valeur_achat" required  class="Mode" id="Valeur_achat" placeholder="Valeur" type="number" />   
                                                         
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><label for="Date_mise_en_circulation">{{ 'Vehicle.date_circulation' | translate }}</label></td>
                                                        <td>
                                                               
                                                                <input class="field_custom" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  required class="Mode" formControlName="date_mise_en_circulation" id="Date_mise_en_circulation" placeholder="dd/mm/yyyy" type="date" />  
                                                         
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td><label for="Couleur">{{ 'Vehicle.color' | translate }}  </label></td>
                                                        <td>
                                                              
                                                                <input class="field_custom" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  formControlName="couleur" required class="Mode" id="Couleur" placeholder="valeur" type="text"  /> 
                                                         
                                                        </td>  
                                                    </tr>
                                                    <tr>
                                                        <td><label for="Etat_véhicule">{{ 'Vehicle.vehicle_condition' | translate }} </label></td>
                                                        <td class="Mode">
                                                            <select name="Etat du véhicule" required  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  class="Mode" formControlName="etat_véhicule">
                                                                <option value="{{ 'Vehicle.OutOfOder' | translate }} ">{{ 'Vehicle.OutOfOder' | translate }} </option>
                                                                <option value="{{ 'Vehicle.UnderReparation' | translate }} ">{{ 'Vehicle.UnderReparation' | translate }} </option>
                                                                <option value="{{ 'Vehicle.OnMission' | translate }} ">{{ 'Vehicle.OnMission' | translate }} </option>
                                                                <option value="{{ 'Vehicle.AtRest' | translate }} ">{{ 'Vehicle.AtRest' | translate }} </option>
                                                                <option value="{{ 'Vehicle.InMaintenance' | translate }} ">{{ 'Vehicle.InMaintenance' | translate }} </option>
                                                            </select>
                                                        </td>  
                                                    </tr>
                                                    <tr>
                                                        <td><label for="Nombre_de_place">{{ 'Vehicle.place_number' | translate }}</label></td>
                                                        <td>
                                                                    
                                                                <input class="field_custom" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  formControlName="nombre_de_place" required class="Mode" id="Nombre_de_place" placeholder="valeur" type="number"  />  
                                                         
                                                        </td>  
                                                    </tr>
                                                    <tr>
                                                        <td><label for="Lieu_stationnement">{{ 'Vehicle.parking_place' | translate }}  </label></td>
                                                        <td>
                                                                
                                                                <input class="field_custom" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  formControlName="lieu_stationnement" class="Mode" required id="Lieu_stationnement" placeholder="valeur" type="text"  />  
                                                         
                                                        </td> 
                                                    </tr>    
                                                    
                                                </table> 
                                                        <div style="border-radius:50%;margin-left:82% ;" class="btn btn-primary btn-lg" ><button mat-button  matStepperNext [disabled]="!f1.valid" (click)="onSubmit1()"   style="border-radius:50%;" class="btn btn-primary btn-lg" href="#">  {{ 'Vehicle.next' | translate }}</button></div>
                                            
                                            </div>
                                        </fieldset>
                                    </form>
                             
                                </div>
                            </div>
                        </div>
                </div>    
                    
                </div>
    </mat-step>
        
    <mat-step label="Etape 2" [stepControl]="secondFormGroup"><!-- second stepper --> 
 

                <div class="row" >
                    <div class="Mode" style="margin-bottom: 5%;">
                        <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 contant_form">
                                <div  class="form1">
                                <h1>{{ 'Vehicle.StepAddTitle' | translate }}</h1>
                                </div><br>
                                <div  class="form1">
                                    <h2>{{ 'Vehicle.step2' | translate }} {{ 'Vehicle.subtitle_step2' | translate }}</h2> <br>
                                    <h3 style="color: red;"> <i>{{ 'Vehicle.notestep1' | translate }}</i></h3> 
                                </div>

                            <div class="center">  
                                <div class="form_section"  class="form1">

                                    <form class=" col-md-5 contant_form" #f2="ngForm" [formGroup]="secondFormGroup"   class="Mode">
                                        <fieldset class="card">
                                            <div class="row">
                                                <table  class="Mode">
                                                    <tr>
                                                        <td><label for="Longueur">{{ 'Vehicle.length' | translate }} </label></td>
                                                        <td>
                                                            
                                                                <input formControlName="Longueur"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  class="Mode"  class="field_custom" id="Longueur" class="Mode" placeholder="valeur" type="number" required  />  
                                                         
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td><label for="Largeur">{{ 'Vehicle.width' | translate }}</label></td>
                                                        <td>
                                                            
                                                                <input formControlName="Largeur" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"   class="field_custom" id="Largeur" class="Mode" placeholder="valeur" type="number" required /> 
                                                         
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td><label for="Hauteur">{{ 'Vehicle.height' | translate }}  </label></td>
                                                        <td>
                                                             
                                                                <input  formControlName="Hauteur" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"   class="field_custom" id="Hauteur" class="Mode" placeholder="valeur" type="number" required />   
                                                         
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><label for="Poids_à_vide">{{ 'Vehicle.empty_weight' | translate }}  </label></td>
                                                        <td>
                                                            
                                                                <input formControlName="Poids_à_vide" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  class="field_custom" id="Poids_à_vide" class="Mode" placeholder="valeur" type="number" required />  
                                                         
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><label for="cu">{{ 'Vehicle.payload' | translate }} </label></td>
                                                        <td>
                                                                
                                                                <input formControlName="cu" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  class="field_custom" id="cu" class="Mode" placeholder="valeur" type="number" required /> 
                                                         
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td><label for="poids_total_charge"  class="Mode">{{ 'Vehicle.total_load_weight' | translate }}</label></td>
                                                        <td>
                                                               
                                                                <input formControlName="poids_total_charge" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"   class="field_custom"   class="Mode" id="poids_total_charge" placeholder="valeur" type="number" required/>   
                                                         
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><label for="Puissance">{{ 'Vehicle.power' | translate }}</label></td>
                                                        <td>
                                                            
                                                                <input formControlName="Puissance" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  class="field_custom"   class="Mode" id="Puissance" placeholder="valeur" type="number" required />  
                                                         
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td><label for="Volume"  class="Mode">{{ 'Vehicle.volume' | translate }}</label></td>
                                                        <td>
                                                             
                                                                <input formControlName="Volume" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  class="field_custom"    class="Mode" id="Volume" placeholder="valeur" type="number" required  />  
                                                         
                                                        </td>  
                                                    </tr>
                                                    <tr>
                                                        <td><label for="nb_essieux">{{ 'Vehicle.number_semi_trailer_axles' | translate }}</label></td>
                                                        <td>   
                                                      
                                                                <input formControlName="nb_essieux" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  class="field_custom" class="Mode"  id="nb_essieux" placeholder="valeur" type="number" required />  
                                                         
                                                        </td> 
                                                    </tr>
                            
                                                    <tr>  
                                                        <td><label >{{ 'Vehicle.fuel' | translate }}</label></td>
                                                        <td class="Mode">
                                                            <select formControlName="Carburant"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  name=" "  class="Mode">
                                                                <option value="{{ 'Vehicle.Fuel' | translate }}">{{ 'Vehicle.Fuel' | translate }}</option>
                                                                <option value="{{ 'Vehicle.DieselFuel' | translate }}">{{ 'Vehicle.DieselFuel' | translate }}</option>
                                                                <option value="{{ 'Vehicle.Super' | translate }}">{{ 'Vehicle.Super' | translate }}</option>  

                                                            </select>
                                                        </td>  
                                                    </tr>
                                                    <tr>
                                                        <td><label for="Kilométrage">{{ 'Vehicle.milage' | translate }} </label></td>
                                                        <td>
                                                            
                                                                <input formControlName="Kilométrage" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  class="field_custom"  class="Mode" id="Kilométrage" placeholder="valeur" type="text"  required />  
                                                         
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td><label for="conso_min">{{ 'Vehicle.min_consumption' | translate }} </label></td>
                                                        <td>
                                                            
                                                                <input formControlName="conso_min" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  class="field_custom" class="Mode" id="conso_min" placeholder="valeur" type="number" required  />  
                                                         
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td><label for="conso_max">{{ 'Vehicle.max_consumption' | translate }}</label></td>
                                                        <td>
                                                             
                                                                <input formControlName="conso_max" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  class="field_custom" class="Mode" id="conso_max" placeholder="valeur" type="number" required  /> 
                                                         
                                                        </td> 
                                                    </tr>
                                                    
                                                </table>
                                                 <div style="display:inline-flex;width: 100%;margin-top: 3%;">
                                                        <div  style="border-radius:50%;" class="btn btn-primary btn-lg" ><button mat-button  matStepperPrevious (click)="previous1()"  style="border-radius:50%;" class="btn btn-primary btn-lg" href="#"> {{ 'General.previous' | translate }}</button></div>
                                                        <div  style="border-radius:50%;margin-left:62% ;" class="btn btn-primary btn-lg" ><button mat-button  matStepperNext [disabled]="!f2.valid"  (click)="onSubmit2()" style="border-radius:50%;" class="btn btn-primary btn-lg" href="#">{{ 'General.next' | translate }}</button></div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>

                                </div>
                            </div>
                        </div>
                </div>    
                    
                </div>     
    </mat-step>

    <mat-step label="Etape 3"  [stepControl]="thirdFormGroup">     <!-- troisieme stepper -->  

 

                <div class="row" >
                    <div class="Mode" style="margin-bottom: 5%;">
                        <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 contant_form">
                                <div  class="form1">
                                <h1>{{ 'Vehicle.StepAddTitle' | translate }}</h1>
                                </div><br>
                                <div  class="form1">
                                    <h2>{{ 'Vehicle.step3' | translate }} {{ 'Vehicle.subtitle_step3' | translate }}</h2><br>
                                    <h3 style="color: red;"> <i>{{ 'Vehicle.notestep1' | translate }}</i></h3>
                                </div>

                            <div class="center">
                                <div class="form_section"  class="form1">
                                   
                                    <form class=" col-md-5 contant_form" #f3="ngForm"  [formGroup]="thirdFormGroup"   class="Mode">
                                        <fieldset class="card">
                                            <div class="row">
                                                <table  class="Mode">
                                                    <tr>
                                                        <td><label for="Jour_travail_requis">{{ 'Vehicle.working_day' | translate }} </label></td>
                                                        <td>
                                                            
                                                                <input formControlName="Jour_travail_requis" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  class="field_custom" id="Jour_travail_requis" class="Mode" placeholder="valeur" type="texte" required />  
                                                         
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td><label for="Temps_travail_requis">{{ 'Vehicle.required_working_time' | translate }}</label></td>
                                                        <td>
                                                             
                                                                <input formControlName="Temps_travail_requis" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  class="field_custom" id="Temps_travail_requis" class="Mode" placeholder="valeur" type="text" required />   
                                                         
                                                        </td>
                                                    </tr>
                                                    <tr>  
                                                        <td><label for="Equipement">{{ 'Vehicle.equipement' | translate }}</label></td>
                                                        <td>
                                                            
                                                                <input formControlName="Equipement" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"  class="field_custom" id="Equipement" class="Mode" placeholder="valeur" type="text" required />  
                                                         
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td><label for="Usage_vehicule ">{{ 'Vehicle.vehicle_use' | translate }}</label></td>
                                                        <td>
                                                            
                                                                <input formControlName="Usage_vehicule" class="Mode"  class="form-control {{ isSubmitted && form.insurer_id.errors ? 'is-invalid' : '' }}"   class="field_custom" id="Usage_vehicule" class="Mode" placeholder="valeur" type="text" required /> 
                                                         
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td><label >{{ 'Vehicle.photo_before_vehicle' | translate }}</label></td>
                                                        <td>
                                                             
                                                                <input  class="field_custom"    class="Mode"  class="form-control-file" type="file" class="form-control-file" (change)=detectimage1($event) required /> 
                                                          
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td><label >{{ 'Vehicle.photo_rear_vehicle' | translate }} </label></td>
                                                        <td>
                                                          
                                                                <input   class="field_custom"  class="Mode"  class="form-control-file" type="file" class="form-control-file" (change)=detectimage2($event) required />  
                                                          
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td><label>{{ 'Vehicle.photo_leftside_vehicle' | translate }} </label></td>
                                                        <td>
                                                             
                                                                <input    class="field_custom"  class="Mode"  class="form-control-file" type="file" class="form-control-file" (change)=detectimage3($event) required />  
                                                          
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td><label >{{ 'Vehicle.photo_rightside_vehicle' | translate }}</label></td>
                                                        <td>
                                                            
                                                               <input  class="field_custom"  class="Mode"  class="form-control-file" type="file" class="form-control-file" (change)=detectimage4($event) required /> 
                                                          
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td><label>{{ 'Vehicle.car_registration_photo' | translate }}  </label></td>
                                                        <td>
                                                             
                                                                <input   class="field_custom"   class="Mode"  class="form-control-file" type="file" class="form-control-file" (change)=detectimage5($event) required  />   
                                                          
                                                        </td>
                                                    </tr> 

                                                    
                                                </table>
                                                <div style="display:inline-flex;width: 100%;margin-top: 3%;">
                                                        <div  style="border-radius:50%;" class="btn btn-primary btn-lg" ><button mat-button  matStepperPrevious   style="border-radius:50%;" class="btn btn-primary btn-lg" href="#"> {{ 'Vehicle.previous' | translate }}</button></div>
                                                        <div  style="border-radius:50%;margin-left:62% ;" class="btn btn-primary btn-lg"  ><button mat-button  matStepperNext [disabled]="!f3.valid" (click)="onSubmit3()" style="border-radius:50%;" class="btn btn-primary btn-lg" href="#">{{ 'Vehicle.done' | translate }}</button></div>
                                                        
                                                </div>
                                            </div>
                                        </fieldset>  
                                    </form>

                                </div>
                            </div>
                        </div>
                </div>    
                    
            </div>
    </mat-step>
</mat-horizontal-stepper>
