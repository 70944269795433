import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotifService } from 'src/app/_services/notif.service';
import { DocService } from 'src/app/_services/parc-service/doc-service/doc-service';

@Component({
  selector: 'app-assurance-detail',
  templateUrl: './assurance-detail.component.html',
  styleUrls: ['./assurance-detail.component.scss']
})
export class AssuranceDetailComponent implements OnInit {

  assurance: any;
  constructor(private docService: DocService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private notifService: NotifService,

    ) { }

  ngOnInit(): void {

    const assuranceId = +this.route.snapshot.paramMap.get("id");
    console.log(assuranceId);
    this.docService.findAssurance(assuranceId).then(
      data => {
        this.assurance = data;
        console.log(this.assurance)
      }
    ).catch(
      error => {
        this.translate.get('issurance.' + error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/all_assurrance'])
      }
    )
  }

 

}
