import { Component, OnInit } from '@angular/core';
import { DocService } from '../../../../../_services/parc-service/doc-service/doc-service';
//import { NotifService } from 'src/app/_services/notif.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//import { TranslateService } from '@ngx-translate/core';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-type-fiche-detail',
  templateUrl: './type-fiche-detail.component.html',
  styleUrls: ['./type-fiche-detail.component.scss']
})
export class TypeFicheDetailComponent implements OnInit {

  typefiche : any ;

  constructor(
    private docService: DocService,
    //private notifService: NotifService,
    private formBuilder: FormBuilder,
    //private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    const typefiche_id = +this.route.snapshot.paramMap.get("id");
    this.docService.findType(typefiche_id).then(
      data => {
        this.typefiche = data;
  }).catch(
    error => {
      // this.translate.get('Licensetype.'+error.error.code)
      // .subscribe(val => this.notifService.danger(val));
    }
  )
  }

  cancel(){
    this.router.navigate(['type_fiche_all'])
  }

}
