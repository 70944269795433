import { HttpClient } from '@angular/common/http';
import * as Routes from 'src/Routes';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TypeIncidentService {

  constructor(private http: HttpClient) { }

  add(formData: FormData): Promise<any> {
    return this.http.post<any>(Routes.TYPE_INCIDENT, formData).toPromise();
  }

  public async update(id: number, formData): Promise<any> {
    return await this.http
      .post(`${Routes.TYPE_INCIDENT}/${id}`, formData)
      .toPromise();
  }
  
  delete(id: number) {
    return this.http.delete(`${Routes.TYPE_INCIDENT}/${id}`).toPromise();

  }
  
  find(id: number): Promise<any> {
    return this.http.get<any>(`${Routes.TYPE_INCIDENT}/${id}`).toPromise();
  }
  public gets(page) {
    return this.http.get<any>(`${Routes.TYPE_INCIDENT}?page=${page}`)
      .toPromise();
  }

  public get(url) {
    return this.http.get<any>(`${url}`)
      .toPromise();
  }

  public getUser() {
    return this.http.get<any>(`${Routes.USER}`)
      .toPromise();
  }

}
