import { Component, OnInit } from '@angular/core';
import { TypeDocumentMissionService } from 'src/app/_services/movement-service/type-document-mission.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-type-document-add',
  templateUrl: './type-document-add.component.html',
  styleUrls: ['./type-document-add.component.scss']
})
export class TypeDocumentAddComponent implements OnInit {

  DataForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;

  constructor(
    private TypeDocumentMissionService: TypeDocumentMissionService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.DataForm = this.formBuilder.group({
      nom:['',[Validators.required]],
      description:['']   
    });
  }

  get form() {
    return this.DataForm.controls;
  }
  
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;
    

    if (this.DataForm.invalid) {
    
      this.translate.get('TypeDocumentMission.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('nom', ''+this.form.nom.value);
    formData.append('description', ''+this.form.description.value);

      console.log(formData);
      this.TypeDocumentMissionService.add(formData)
      .then(resp => {
        this.translate.get('TypeDocumentMission.SubmitSuccess')
        .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.DataForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('TypeDocumentMission.SubmitErrorTypeDocumentMission')
        .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }

}
