<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>{{ "TypeSanction.TypeSanction" | translate }}</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                        <li class="breadcrumb-item "><a [routerLink]="['/type-sanction/all']">{{ "TypeSanction.TypeSanction" |
                translate }}</a></li>
                        <li class="breadcrumb-item active">{{ "FileAriane.Add" | translate }}</li>

                    </ol>
                </div>
            </div>
        </div>
        <!-- /.container-fluid -->
    </section>

    <!-- Main content -->

    <section class="container">
        <div class="row">
            <div class="col-md-12">
                <form (submit)="onSubmit()" [formGroup]="TypeSanctionForm">
                    <div class="card card-primary" >

                        <div class="card-header" style="background-color: #17a2b8;">
                            <h3 class="card-title">{{ "TypeSanction.TypeSanctionAdd" | translate }}</h3>
                        </div>
                        <div class="card-body" style="display: block;">
                            <div class="form-group">
                                <label for="inputName">{{ "TypeSanction.NameLabel" | translate }} <span style="color: red;">*</span></label>
                                <input type="text" id="inputName" class="form-control {{ isSubmitted && form.name.errors? 'is-invalid' : '' }}" 
                                formControlName="name" placeholder="{{ 'TypeSanction.LabelNameTypeSanction' | translate }}">
                            </div>
                            <div class="form-group">
                                <label for="inputName">{{ "TypeSanction.DescriptionLabel" | translate }}</label>
                                <input type="text" id="inputName" class="form-control" formControlName="description" placeholder="{{ 'TypeSanction.LabelDescriptionTypeSanction' | translate }}">
                            </div>
                        </div>
                        <div class="card-footer">
                            <button [disabled]="isLoading" class="btn btn-primary" style="background-color: #17a2b8;">
                <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
                <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
                        </div>
                        <!-- /.card-body -->
                    </div>
                </form>

                <div *ngIf="isMaterial" class="col-md-2" style="margin-left:85%"><button class="btn btn-danger" (click)="close()">
          {{
          'Material.ButtonClose' | translate }}
        </button></div>
                <!-- /.card -->
            </div>
        </div>
    </section>
    <!-- /.content -->
</div>
