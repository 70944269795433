import { Component, OnInit } from '@angular/core';
import { SanctionService } from 'src/app/_services/RH-service/sanction/sanction.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { TypeSanctionAddComponent } from '../../type-sanction/type-sanction-add/type-sanction-add.component';


 
@Component({
  selector: 'app-sancton-add',
  templateUrl: './sancton-add.component.html',
  styleUrls: ['./sancton-add.component.scss']
})
export class SanctonAddComponent implements OnInit {


  SanctionForm: FormGroup;
  typeSanctions: any;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  logo: File = null;
  user;
  users;

  constructor(
    private SanctionService: SanctionService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private userDialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getTypeSanction();
    this.getUsers();
    this.user = this.authService.getUser();

    this.SanctionForm = this.formBuilder.group({
      user_id: ['', [Validators.required]],
      sanction_type_id: ['', [Validators.required]],
      reason_penalty: ['', [Validators.required]],
      start_date: ['', [Validators.required]],
      end_date: ['', [Validators.required]]

    });
  }
  getUsers() {
    this.SanctionService.getUser().then((res) => {
      this.users = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  getTypeSanction() {
    this.SanctionService.getTypeSanction().then((res) => {
      this.typeSanctions = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  get form() {
    return this.SanctionForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    let pipe = new DatePipe('en-US');
    let date = new Date();
    let currentDate = pipe.transform(date, 'yyyy-MM-dd');

    if (this.SanctionForm.invalid) {
      this.translate.get('Sanction.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('user_id', '' + this.form.user_id.value);
    formData.append('sanction_type_id', '' + this.form.sanction_type_id.value);
    formData.append('reason_penalty', '' + this.form.reason_penalty.value);
    if (currentDate > this.form.start_date.value) {
      this.translate.get('Sanction.StartDateError')
        .subscribe(val => this.notifService.danger(val));
      this.isLoading = false;
      return;
    } else {
      formData.append('start_date', '' + this.form.start_date.value);
    }

    if (this.form.start_date.value > this.form.end_date.value) {
      this.translate.get('Sanction.EndDateError')
        .subscribe(val => this.notifService.danger(val));
      this.isLoading = false;
      return;
    } else {
      formData.append('end_date', '' + this.form.end_date.value);
    }


    console.log(formData);
    this.SanctionService.post(formData)
      .then(resp => {
        this.translate.get('Sanction.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.SanctionForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('Sanction.SubmitErrorSanction')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }

  addSanctiontype() {
    var modelDialogef =  this.userDialog.open(TypeSanctionAddComponent,{
       width: '1000px',
       height: '700px',
       disableClose: true
     });
 
     modelDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);
 
       this.ngOnInit();
     });
   }


}
 