import { Component, OnInit } from '@angular/core';

import { AutoService } from '../../../../../_services/parc-service/auto-service/auto-service';
//import { NotifService } from 'src/app/_services/notif.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Vehicle } from 'src/app/_models/vihicles.model';

@Component({
  selector: 'app-technical-fact-sheet-vehicle',
  templateUrl: './technical-fact-sheet-vehicle.component.html',
  styleUrls: ['./technical-fact-sheet-vehicle.component.scss']
})
export class TechnicalFactSheetVehicleComponent implements OnInit {
  vehicle_id: any=null;
  vehicle:any ;
  //gestion des steppers
    step1Selected: boolean = true;
    step2Selected: boolean = false;
    step3Selected: boolean = false;
    stepIndexSelected = 0;
    isLinear = false;
    //gestion des formgroup
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;
    //
    isLoading = false;
    isError = false;
    isSuccess = false;
    isSubmitted = false;
  //variable pour la recuperation de image
  Photo_avant:File=null;
  Photo_arriere:File=null;
  Photo_gauche:File=null;
  Photo_droit:File=null;
  Photo_carte_grise:File=null;
  
  
  constructor(  
    private autoservice:AutoService,
    // private notifService: NotifService,
     private formBuilder: FormBuilder,
    // private translate: TranslateService,
    private route: ActivatedRoute,
     private router: Router) { }

  ngOnInit(): void {
    this.initform();
    this.initform1();
    this.initform2(); 
    this.vehicle_id = +this.route.snapshot.paramMap.get("id");
    console.log(this.vehicle_id);
    this.autoservice.find(this.vehicle_id).then(
      data => {
        this.vehicle = data;
        this.initFormWithData();
        this.initForm1WithData();
        this.initForm2WithData();
      }
    ).catch(
      error => {
        //this.translate.get('Contact.' + error.error.code)
       //   .subscribe(val => this.notifService.danger(val));
       alert("Objet introuvable")
        this.router.navigate(['/vehicles/list'])
      }
    )
  }
     
  get form() {
    return this.firstFormGroup.controls;
  }
  get form1() {

    return this.secondFormGroup.controls;
  }
  get form2() {
    return this.thirdFormGroup.controls;
  }

  //init form
  initform() {
   // let phone_patern = "^((\\+[0-9]{3}-?))?[0-9]{8}$";
    this.firstFormGroup = this.formBuilder.group({
      immatriculation:'', 
      marque: '',
      modele:'', 
      type:'', 
      carosserie:'', 
      numero_chassis:'', 
      proprietaire:'', 
      valeur_achat:'', 
      date_mise_en_circulation:'', 
      couleur:'',
      etat_véhicule:'', 
      nombre_de_place:'', 
      lieu_stationnement:'',   
    });
  }

  
  initFormWithData() {
    this.firstFormGroup = this.formBuilder.group(
      {
        immatriculation: [this.vehicle.vehicle_registration, [Validators.required]],
        marque: [this.vehicle.vehicle_brand_id, [Validators.required]],
        modele: [this.vehicle.vehicle_model_id, [Validators.required]],
        type: [this.vehicle.vehicle_type_id, [Validators.required]],
        carosserie: [this.vehicle.vehicle_body_id, [Validators.required]],
        numero_chassis: this.vehicle.number_frame,
        proprietaire: [this.vehicle.owner, [Validators.required]],
        valeur_achat: [this.vehicle.purchase_value, [Validators.required]],
        date_mise_en_circulation: [this.vehicle.date_circulation, [Validators.required]],
        couleur: [this.vehicle.color, [Validators.required]],
        etat_véhicule: [this.vehicle.vehicle_condition, [Validators.required]],
        nombre_de_place: this.vehicle.place_number,
        lieu_stationnement: [this.vehicle.parking_place, [Validators.required]],
      }
    )
  }
 
  initform1() {
    this.secondFormGroup = this.formBuilder.group({
     Longueur: '',
     Largeur: '',
     Hauteur: '',
     Poids_à_vide: '',
     cu: '',
     poids_total_charge: '',
     Puissance: '',
     Volume: '',
     nb_essieux: '',
     Carburant: '',
     Kilométrage: '',
     conso_min: '',
     conso_max: '', 
    });
  }

  initForm1WithData() {
    this.secondFormGroup = this.formBuilder.group(
      {
        Longueur:this.vehicle.length, 
        Largeur:this.vehicle.width, 
        Hauteur:this.vehicle.height, 
        Poids_à_vide:this.vehicle.empty_weight, 
        cu:this.vehicle.payload, 
        poids_total_charge: this.vehicle.total_load_weight,
        Puissance:this.vehicle.power, 
        Volume:this.vehicle.volume, 
        nb_essieux:this.vehicle.number_semi_trailer_axles, 
        Carburant:this.vehicle.fuel, 
        Kilométrage:this.vehicle.milage, 
        conso_min: this.vehicle.min_consumption,
        conso_max:this.vehicle.max_consumption, 
        
      }
    )
  }
 
  
  initform2() {
    // let phone_patern = "^((\\+[0-9]{3}-?))?[0-9]{8}$";
     this.thirdFormGroup = this.formBuilder.group({
       Jour_travail_requis: '',
       Temps_travail_requis: '',
       Equipement: '',
       Usage_vehicule: '',
     });
   }
  
   
  initForm2WithData() {
    this.thirdFormGroup = this.formBuilder.group(
      {   

        Jour_travail_requis:this.vehicle.working_day, 
        Temps_travail_requis:this.vehicle.required_working_time, 
        Equipement:this.vehicle.equipement, 
        Usage_vehicule:this.vehicle.vehicle_use, 
        Photo_avant:this.vehicle.photo_before_vehicle, 
        Photo_arriere:this.vehicle.photo_rear_vehicle, 
        Photo_gauche:this.vehicle.photo_leftside_vehicle, 
        Photo_droit: this.vehicle.photo_rightside_vehicle,
        Photo_carte_grise:this.vehicle.car_registration_photo,  
      }
    )
  }
 
  //submit the form
  onSubmit1() {
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false
    console.log("bonjour");
    console.log(this.form.immatriculation.value);
    console.log(this.form.marque.value);
    console.log(this.form.valeur_achat.value);
    console.log(this.form.marque.value);
    // Si la validation a echoué, on arrete l'execution de la fonction
    //this.form.name.setValue(this.role_name);
    if (this.firstFormGroup.invalid ) {
      //this.translate.get('Contact.SubmitError')
      //  .subscribe(val => this.notifService.danger(val));
      console.log("formulaire1 invalide");
      return;
    }
    //si tout ce passe bien
    this.isLoading = true;
    const formData = new FormData();    
    //ajout des donnees dans le form date

    formData.append('vehicle_registration', '' + this.form.immatriculation.value);
    formData.append('vehicle_brand_id', '' + this.form.marque.value);
    formData.append('vehicle_model_id', '' + this.form.modele.value);
    formData.append('vehicle_type_id', '' + this.form.type.value);
    formData.append('vehicle_body_id', '' + this.form.carosserie.value);
    formData.append('number_frame', '' + this.form.numero_chassis.value);
    formData.append('owner', '' + this.form.proprietaire.value);
    formData.append('purchase_value', '' + this.form.valeur_achat.value);
    formData.append('date_circulation', '' + this.form.date_mise_en_circulation.value);
    formData.append('color', '' + this.form.couleur.value);
    formData.append('vehicle_condition', '' + this.form.etat_véhicule.value);
    formData.append('place_number', '' + this.form.nombre_de_place.value);
    formData.append('parking_place', '' + this.form.lieu_stationnement.value);
    if(this.vehicle_id==null){
            this.autoservice.add(formData) 
              .then(resp => {
              // this.translate.get('Contact.SubmitSuccess')
              //   .subscribe(val => this.notifService.success(val));
                this.isSubmitted = false;
                console.log("formulaire1 enregistré avec succes");
                console.log(resp);
                this.vehicle_id=resp.id;
                //reinitialisation
              /* this.firstFormGroup.reset(); 
                this.stepIndexSelected = 0;
                this.router.navigate(['/auto_add/all']); */
              })
              .catch(err => {
                console.log(err);
                console.log("probleme insertion");
              //  this.translate.get('Contact.ErrorSubmit')
                //  .subscribe(val => this.notifService.danger(val));
              })
              .finally(() => this.isLoading = false);
            }else{
              this.autoservice.update(formData,this.vehicle_id) 
              .then(resp => {
              // this.translate.get('Contact.SubmitSuccess')
              //   .subscribe(val => this.notifService.success(val));
                this.isSubmitted = false;
                console.log("formulaire1 modifié avec succes");
                console.log(resp);
                //reinitialisation
              /* this.firstFormGroup.reset(); 
                this.stepIndexSelected = 0;
                this.router.navigate(['/auto_add/all']); */
              })
              .catch(err => {
                console.log(err);
                console.log("probleme modification 1");
              //  this.translate.get('Contact.ErrorSubmit')
                //  .subscribe(val => this.notifService.danger(val));
              })
              .finally(() => this.isLoading = false);
           


             }
  }
  onSubmit2() {
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;
    console.log(this.form1.Longueur.value);
    // Si la validation a echoué, on arrete l'execution de la fonction
    //this.form.name.setValue(this.role_name);
    if ( this.secondFormGroup.invalid ) {
      //this.translate.get('Contact.SubmitError')
      //  .subscribe(val => this.notifService.danger(val));
      console.log("formulaire2 invalide");
      return;
    }
    //si tout ce passe bien
    this.isLoading = true;
    const formData = new FormData();
    //ajout des donnees dans le form date
    console.log(this.form1.Largeur.value);
    formData.append('length', '' + this.form1.Longueur.value); 
    formData.append('width', '' + this.form1.Largeur.value);
    formData.append('height', '' + this.form1.Hauteur.value);
    formData.append('empty_weight', '' + this.form1.Poids_à_vide.value);
    formData.append('payload', '' + this.form1.cu.value);
    formData.append('total_load_weight', '' + this.form1.poids_total_charge.value);
    formData.append('power', '' + this.form1.Puissance.value);
    formData.append('volume', '' + this.form1.Volume.value);
    formData.append('number_semi_trailer_axles', '' + this.form1.nb_essieux.value);
    formData.append('fuel', '' + this.form1.Carburant.value);
    formData.append('milage', '' + this.form1.Kilométrage.value);
    formData.append('min_consumption', '' + this.form1.conso_min.value);
    formData.append('max_consumption', '' + this.form1.conso_max.value);

    this.autoservice.update(formData,this.vehicle_id) 
      .then(resp => {
       // this.translate.get('Contact.SubmitSuccess')
       //   .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        console.log("formulaire 2 modifié avec succes");
        console.log(resp);
      /*  //reinitialisation
        this.firstFormGroup.reset();
        this.secondFormGroup.reset();
        this.thirdFormGroup.reset();
        this.stepIndexSelected = 0;
        this.router.navigate(['/auto_add/all']);
        */
      })
      .catch(err => {
        console.log(err);
        console.log("probleme modification formulaire 2");
      //  this.translate.get('Contact.ErrorSubmit')
        //  .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }
  onSubmit3() {
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false
    // Si la validation a echoué, on arrete l'execution de la fonction
    //this.form.name.setValue(this.role_name);
    if (this.thirdFormGroup.invalid) {
      //this.translate.get('Contact.SubmitError')
      //  .subscribe(val => this.notifService.danger(val));
      console.log("formulaire 3 invalide");
      return;
    }
    //si tout ce passe bien
    this.isLoading = true;
    const formData = new FormData();
    //ajout des donnees dans le form date
    console.log(this.form2.Jour_travail_requis.value);
    formData.append('working_day', '' + this.form2.Jour_travail_requis.value);
    formData.append('required_working_time', '' + this.form2.Temps_travail_requis.value);
    formData.append('equipement', '' + this.form2.Equipement.value);
    formData.append('vehicle_use', '' + this.form2.Usage_vehicule.value);
        //recuperation  des images
    formData.append('photo_before_vehicle', this.Photo_avant);
    formData.append('photo_rear_vehicle', this.Photo_arriere);
    formData.append('photo_leftside_vehicle', this.Photo_gauche);
    formData.append('photo_rightside_vehicle', this.Photo_droit);
    formData.append('car_registration_photo', this.Photo_carte_grise); 

    this.autoservice.update(formData,this.vehicle_id) 
      .then(resp => {
       // this.translate.get('Contact.SubmitSuccess')
       //   .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        //reinitialisation
       /* this.firstFormGroup.reset();
        this.secondFormGroup.reset();
        this.thirdFormGroup.reset();
        this.stepIndexSelected = 0;
        this.router.navigate(['/auto_add/all']); */
        console.log("formulaire 3 modifié avec succes")
        console.log(resp)
      })
      .catch(err => {
        console.log(err)
      //  this.translate.get('Contact.ErrorSubmit')
        //  .subscribe(val => this.notifService.danger(val));
        console.log("probleme modification du formulaire 3 ")
      })
      .finally(() => this.isLoading = false);
  }

  
  detectimage1(event) {
    this.Photo_avant = event.target.files[0];
    console.log(this.Photo_avant);
  }
  detectimage2(event) {
    this.Photo_arriere = event.target.files[0];
    console.log(this.Photo_arriere);
  }
  detectimage3(event) {
    this.Photo_gauche = event.target.files[0];
    console.log(this.Photo_gauche);
  }
  detectimage4(event) {
    this.Photo_droit = event.target.files[0];
    console.log(this.Photo_droit);
  }
  detectimage5(event) {
    this.Photo_carte_grise = event.target.files[0];
    console.log(this.Photo_carte_grise);
  }

  previous1() {
    this.stepIndexSelected = 0;
  }
  previous2() {
    this.stepIndexSelected = 1;
  }
  previous3() {
    this.stepIndexSelected = 2;
  }

   //validation de chaque etape
   validStep1() {
    if (this.firstFormGroup.invalid) {
      this.stepIndexSelected = 0;
      this.isSubmitted = true;
     // this.translate.get('Contact.SubmitError')
      //  .subscribe(val => this.notifService.danger(val));
    }
    else {
      this.isSubmitted = false;
      this.stepIndexSelected = 1;
    }
  }
  //step 2
  validStep2() {
    if (this.secondFormGroup.invalid) {
      this.stepIndexSelected = 1;
      this.isSubmitted = true;
     // this.translate.get('Contact.SubmitError')
      //  .subscribe(val => this.notifService.danger(val));
    }
    else {
      this.isSubmitted = false;
      this.stepIndexSelected = 2;
    }
  }
  //valid step 3
  validStep3() {
    if (this.secondFormGroup.invalid) {
      this.stepIndexSelected = 2;
      this.isSubmitted = true;
    //  this.translate.get('Contact.SubmitError')
     //   .subscribe(val => this.notifService.danger(val));
    }
    else {
      this.isSubmitted = false;
      this.stepIndexSelected = 3;
    }
  }
  afficheDetail() {
    console.log("retour entammé")
    this.router.navigate(['/vehicles/detail/' + this.vehicle_id ])
  }


 

}
  