import { Component, OnInit } from '@angular/core';
import { GammeEntretienService} from 'src/app/_services/maintenance-service/gamme-entretient/gamme-entretien.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotifService } from 'src/app/_services/notif.service';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MecanicienAddComponent } from '../../../human-R/mecanicien/mecanicien-add/mecanicien-add.component';
import { InterventionAddComponent } from '../../interventions/intervention/intervention-add/intervention-add.component';
import { TypeEntretientAddComponent } from '../../type-entretient/type-entretient-add/type-entretient-add.component';

@Component({
  selector: 'app-gamme-entretien-add',
  templateUrl: './gamme-entretien-add.component.html',
  styleUrls: ['./gamme-entretien-add.component.scss']
})
export class GammeEntretienAddComponent implements OnInit {

  GammeEntretienForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  typeEntretiens: any;
  mecaniciens: any;
  interventions: any;
  limite: 10000;
  user;
  isMaterial: boolean = false;
  

  constructor(
    private GammeEntretienService: GammeEntretienService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
    private userDialog: MatDialog,
    private ref: MatDialog,
  ) { }

  ngOnInit(): void {

    this.getTypeEntretien();
    this.getMecanicien();
    this.getIntervention();
    this.user = this.authService.getUser();
    this.isMaterial = '/gamme-entretien/add' != location.pathname;

    this.GammeEntretienForm = this.formBuilder.group({
      type_entretien_id:['',[Validators.required]],
      intervention_id:['',[Validators.required]],
      mecanicien_id:['',[Validators.required]],
      prix:['',[Validators.required]],
      frequence:['',[Validators.required]],
      nom:['',[Validators.required]],  
    });
  }

  getTypeEntretien() {
    this.GammeEntretienService.getTypeEntretien().then(res => {
      this.typeEntretiens = res;
      console.log(this.typeEntretiens)
    }).catch( 
      error => {
        this.notifService.danger(error.error.message)
    })
  }

  getMecanicien() {
    this.GammeEntretienService.getMecanicien().then(res => {
      this.mecaniciens = res;
      console.log(this.mecaniciens)
    }).catch(
      error => {
      this.notifService.danger(error.error.message)
    })
  }

  getIntervention() {
    this.GammeEntretienService.getIntervention().then((res) => {
      this.interventions = res;
      console.log(this.interventions)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  } 

  get form() {
    return this.GammeEntretienForm.controls;
  }
  
  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;
    

    if (this.GammeEntretienForm.invalid) {
    
      this.translate.get('GammeEntretien.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('type_entretien_id', ''+this.form.type_entretien_id.value);
    formData.append('intervention_id', ''+this.form.intervention_id.value);
    formData.append('mecanicien_id', ''+this.form.mecanicien_id.value);
    formData.append('prix', ''+this.form.prix.value);
    formData.append('frequence', ''+this.form.frequence.value);
    formData.append('nom', ''+this.form.nom.value);
    formData.append('create_id', ''+this.user.id);
  
      console.log(formData);
      this.GammeEntretienService.add(formData)
      .then(resp => {
        this.translate.get('GammeEntretien.SubmitSuccess')
        .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.GammeEntretienForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('GammeEntretien.SubmitErrorGammeEntretien')
        .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }
  onClose() {
    this.ref.closeAll();
    // location.reload();
  }
  addMecano() {
    var modelDialogef =  this.userDialog.open(MecanicienAddComponent,{
       width: '1000px',
       height: '700px',
       disableClose: true
     });
 
     modelDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);
 
       this.ngOnInit();
     });
   }
   addInterv() {
    var modelDialogef =  this.userDialog.open(InterventionAddComponent,{
       width: '1000px',
       height: '700px',
       disableClose: true
     });
 
     modelDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);
 
       this.ngOnInit();
     });
   }
   addTypeEnt() {
    var modelDialogef =  this.userDialog.open(TypeEntretientAddComponent,{
       width: '1000px',
       height: '700px',
       disableClose: true
     });
 
     modelDialogef.afterClosed().subscribe(result => {
       console.log(`Dialog result: ${result}`);
 
       this.ngOnInit();
     });
   }


}
