import { MessageService } from 'src/app/_services/RH-service/message/message.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-message-update',
  templateUrl: './message-update.component.html',
  styleUrls: ['./message-update.component.scss']
})
export class MessageUpdateComponent implements OnInit {

  MessageForm: FormGroup;
  user: any;
  messages: any;
  isSubmitted = false;
  isError = false;
  isLoading = false;
  isSuccess = false;
  users;
  file: File = null;


  constructor(

    private authService: AuthService,
    private MessageService: MessageService,
    private notifService: NotifService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,


  ) { }

  ngOnInit(): void {

    this.getUsers();
    this.initForm();

    this.user = this.authService.getUser();


    const message_id = +this.route.snapshot.paramMap.get("id");
    console.log(message_id)
    this.MessageService.find(message_id).then(
      data => {
        this.messages = data;
        console.log(this.messages)
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('Message.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/message/all'])

      }
    )

  }

//initialisation du formulaire
initForm(withmessage = false) {
  if (withmessage) {
    console.log('jose');

    this.MessageForm = this.formBuilder.group(
      {
        
        user_id_2: [this.messages.user_id_2, [Validators.required]],
        suject: [this.messages.suject, [Validators.required]],
        content: [this.messages.content, [Validators.required]],
        file: [this.messages.file],
      }
    );
  }
  else {
    console.log('jose2');

    this.MessageForm = this.formBuilder.group(
      {
        user_id_2: ['', [Validators.required]],
        suject: ['', [Validators.required]],
        content: ['', [Validators.required]],
        file: [this.messages.file],
        
      }
    );
  }

}


  getUsers() {
    this.MessageService.getUser().then((res) => {
      this.users = res; 
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  onSelectfile(event) {
    this.file = event.target.files[0];
  }
  get form() {
    return this.MessageForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    console.log('on submit1');

    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    if (this.MessageForm.invalid) {
      console.log('on submit1');

      this.translate.get('Message.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('user_id_1', this.user.id);
    formData.append('user_id_2', '' + this.form.user_id_2.value);
    formData.append('suject', '' + this.form.suject.value);
    formData.append('content', '' + this.form.content.value);
    if (this.file != null)
      formData.append('file', this.file, this.file.name);

    console.log(formData);
    this.MessageService.update(formData, this.messages.id)
      .then(resp => {
        this.translate.get('Message.UpdateSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.MessageForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('Message.SubmitErrorMessage')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);


  }

}

