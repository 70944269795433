
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>{{ 'Vehicle.ListVehicle' | translate }}</h1>
        <p>{{ 'Vehicle.caracteristics' | translate }}</p>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
          <li class="breadcrumb-item "><a [routerLink]="['/vehicles/list']">{{ 'Vehicle.list' | translate }}</a></li>
          <li class="breadcrumb-item active">{{ "FileAriane.Detail" | translate }}</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-5">
            <div class="card">
              <div class="card-header" style="height:58px;" >
                <h3 class="card-title"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ 'Vehicle.pictures' | translate }}</font></font></h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body" >
                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                  <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1" class=""></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1" class=""></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1" class=""></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2" class=""></li>
                  </ol>
                  <div class="carousel-inner">
                    <div class="carousel-item active" >
                         <img class="d-block w-100" src="{{vehicle.photo_before_vehicle }}" style=" height:80% ;"  alt="Première diapositive"  >
                      <div style="margin-top:4%;">{{ 'Vehicle.PhotoBefore' | translate }}</div>
                    </div>
                    <div class="carousel-item ">
                      <img class="d-block w-100" src="{{vehicle.photo_rear_vehicle }}" style=" height:80% ;" alt="Deuxième diapositive">
                      <div style="margin-top:4%;">{{ 'Vehicle.PhotoRear' | translate }}</div>
                    </div>
                    <div class="carousel-item ">
                      <img class="d-block w-100" src="{{vehicle.photo_leftside_vehicle }}" style=" height:80% ;" alt="Troisième diapositive">
                      <div style="margin-top:4%;">{{ 'Vehicle.PhotoLeft' | translate }}</div>
                    </div>
                    <div class="carousel-item ">
                        <img class="d-block w-100" src="{{vehicle.photo_rightside_vehicle }}" style=" height:80% ;"  alt="Troisième diapositive">
                        <div style="margin-top:4%;">{{ 'Vehicle.PhotoRight' | translate }}</div>
                      </div>
                      <div class="carousel-item ">
                        <img class="d-block w-100" src="{{vehicle.car_registration_photo }}" style=" height:80% ;" alt="Troisième diapositive">
                        <div style="margin-top:4%;">{{ 'Vehicle.PhotoCarRegistration' | translate }}</div>
                      </div>
                  </div>
                  
                  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ 'Vehicle.previous' | translate }}</font></font></span>
                  </a>
                  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ 'Vehicle.next' | translate }}</font></font></span>
                  </a>
                </div>
              </div>
              <button (click)="affichageFiche()">{{ 'Vehicle.PostTechnicalBill' | translate }}</button>
              <!-- /.card-body 
              <div class="filtr-item col-sm-2" data-category="3, 4" data-sort="red sample" style="opacity: 1; transform: scale(1) translate3d(342px, 0px, 0px); backface-visibility: hidden; perspective: 1000px; transform-style: preserve-3d; position: absolute; width: 167.5px; transition: all 0.5s ease-out 0ms, width 1ms ease 0s;">
                <a href="https://via.placeholder.com/1200/FF0000/FFFFFF.png?text=3" data-toggle="lightbox" data-title="sample 3 - red">
                  <img src="https://via.placeholder.com/300/FF0000/FFFFFF?text=3" class="img-fluid mb-2" alt="échantillon rouge">
                </a>
              </div>
                -->
            </div>   
            <!-- /.card -->
          </div><!-- /.col -->
        <div class="col-md-7">
          <div class="card">
            <div class="card-header p-2">
              <ul class="nav nav-pills">
                <li class="nav-item"><a class="nav-link" href="#activity" data-toggle="tab"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ 'Vehicle.Identification' | translate }}</font></font></a></li>   
                <li class="nav-item"><a class="nav-link" href="#timeline" data-toggle="tab"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> {{ 'Vehicle.Technical' | translate }}</font></font></a></li>
                <li class="nav-item"><a class="nav-link active" href="#settings" data-toggle="tab"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ 'Vehicle.OtherCaracteristics' | translate }}</font></font></a></li>
              </ul>
            </div><!-- /.card-header -->
            <div class="card-body">
              <div class="tab-content">
                <div class="tab-pane" id="activity">
                  <!-- Post -->
                 
                  <section class="content">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ 'Vehicle.IdentificationCaracteristics' | translate }}</font></font></h3>
                      </div>
                      <table  class="Mode" border="1">
                        <tr>
                            <td><label for="immatri">{{ 'Vehicle.VehicleRegistration' | translate }}  </label></td>
                            <td> {{vehicle.vehicle_registration }}  </td>  
                        </tr>
                        
                        <tr>
                            <td><label class="Mode">{{ 'Vehicle.vehicleBrand' | translate }}  </label></td>
                            <td>
                                {{mark.name }} 
                            </td>  
                        </tr>
                        <tr>
                            <td><label for="marque">{{ 'Vehicle.vehiclemodel' | translate }} </label></td>
                            <td  class="Mode">
                                {{model.name }}  
                            </td>   
                        </tr>
                        <tr>
                            <td><label>{{ 'Vehicle.vehicleType' | translate }}  </label></td>
                            <td class="Mode">
                                {{type.name }}  
                            </td>   
                        </tr>
                        <tr>
                            <td><label >{{ 'Vehicle.vehicle_body' | translate }} </label></td>
                            <td class="Mode">
                                {{body.name }} 
                            </td>     
                        </tr>
                        <tr>
                            <td><label for="Numéro_de_chassis"  class="Mode">{{ 'Vehicle.number_frame' | translate }} </label></td>
                            <td> {{vehicle.number_frame }}  </td>  
                        </tr>
                        <tr>
                            <td><label for="Propriétaire">{{ 'Vehicle.owner' | translate }}  </label></td>
                            <td>{{vehicle.owner }}   </td>  
                        </tr>
                        <tr>
                            <td><label for="Valeur_achat"  class="Mode">{{ 'Vehicle.purchase_value' | translate }}  </label></td>
                            <td>{{vehicle.purchase_value }}   </td>  
                        </tr>
                        <tr>
                            <td><label for="Date_mise_en_circulation">{{ 'Vehicle.date_circulation' | translate }} </label></td>
                            <td>{{vehicle.date_circulation }}  </td>  
                        </tr>
                        <tr>
                            <td><label for="Couleur">{{ 'Vehicle.color' | translate }} </label></td>
                            <td>{{vehicle.color }}   </td>  
                        </tr>
                        <tr>
                            <td><label for="Etat_véhicule ">{{ 'Vehicle.vehicle_condition' | translate }} </label></td>
                            <td class="Mode">
                                {{vehicle.vehicle_condition }}  
                            </td>  
                        </tr>
                        <tr>
                            <td><label for="Nombre_de_place">{{ 'Vehicle.place_number' | translate }}</label></td>
                            <td> {{vehicle.place_number }} </td>  
                        </tr>
                        <tr>
                            <td><label for="Lieu_stationnement">{{ 'Vehicle.parking_place' | translate }}  </label></td>
                            <td>{{vehicle.parking_place }}  </td>  
                        </tr>    
                        
                    </table> 
                    
                    </div>
                    <!-- /.card -->
                  </section>
                </div>

                <!-- /.tab-pane -->
                <div class="tab-pane" id="timeline">
                  <!-- The timeline -->
                  <section class="content">
                    <div class="card">
                      <div class="card-header">
                        <h3 class="card-title"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ 'Vehicle.TechnicalCaracteistics' | translate }} </font></font></h3>
                      </div>
                      <table  class="Mode" border="1">
                        <tr>
                            <td><label>{{ 'Vehicle.length' | translate }}   </label></td>
                            <td> {{vehicle.length }}  </td>  
                        </tr>
                        
                        <tr>
                            <td><label class="Mode">{{ 'Vehicle.width' | translate }} </label></td>
                            <td>
                                {{vehicle.width }} 
                            </td>  
                        </tr>
                        <tr>
                            <td><label >{{ 'Vehicle.height' | translate }} </label></td>
                            <td  class="Mode">
                                {{vehicle.height }}  
                            </td>   
                        </tr>
                        <tr>
                            <td><label>{{ 'Vehicle.empty_weight' | translate }}  </label></td>
                            <td class="Mode">
                                {{vehicle.empty_weight }}  
                            </td>   
                        </tr>
                        <tr>
                            <td><label >{{ 'Vehicle.payload' | translate }} </label></td>
                            <td class="Mode">
                                {{vehicle.payload }} 
                            </td>     
                        </tr>
                        <tr>
                            <td><label for="Numéro_de_chassis"  class="Mode">{{ 'Vehicle.total_load_weight' | translate }}  </label></td>
                            <td> {{vehicle.total_load_weight }}  </td>  
                        </tr>
                        <tr>
                            <td><label for=" ">{{ 'Vehicle.power' | translate }}  </label></td>
                            <td>{{vehicle.power }}   </td>  
                        </tr>
                        <tr>
                            <td><label for=""  class="Mode">{{ 'Vehicle.volume' | translate }} </label></td>
                            <td>{{vehicle.volume }}   </td>  
                        </tr>
                        <tr>
                            <td><label >{{ 'Vehicle.number_semi_trailer_axles' | translate }}  </label></td>
                            <td>{{vehicle.number_semi_trailer_axles }}  </td>  
                        </tr>
                        <tr>
                            <td><label >{{ 'Vehicle.fuel' | translate }}  </label></td>
                            <td>{{vehicle.fuel }}   </td>  
                        </tr>
                        <tr>
                            <td><label >{{ 'Vehicle.milage' | translate }}  </label></td>
                            <td class="Mode">
                                {{vehicle.milage }}  
                            </td>  
                        </tr>
                        <tr>
                            <td><label >{{ 'Vehicle.min_consumption' | translate }} </label></td>
                            <td> {{vehicle.min_consumption }} </td>  
                        </tr>
                        <tr>
                            <td><label >{{ 'Vehicle.max_consumption' | translate }}  </label></td>
                            <td>{{vehicle.max_consumption }}  </td>  
                        </tr>       
                        
                    </table> 
                    
                    </div>
                    <!-- /.card -->
                  </section>
                </div>
                <!-- /.tab-pane -->

                <div class="tab-pane active" id="settings">
                    <section class="content">
                        <div class="card">
                          <div class="card-header">
                            <h3 class="card-title"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ 'Vehicle.OtherCaracteristics' | translate }}  </font></font></h3>
                          </div>
                          <table  class="Mode" border="1">
                            <tr>
                                <td><label >{{ 'Vehicle.working_day' | translate }}  </label></td>
                                <td> {{vehicle.working_day }}  </td>  
                            </tr>
                            
                            <tr>
                                <td><label class="Mode">{{ 'Vehicle.required_working_time' | translate }} </label></td>
                                <td>
                                    {{vehicle.required_working_time }} 
                                </td>  
                            </tr>
                            <tr>
                                <td><label >{{ 'Vehicle.equipement' | translate }}  </label></td>
                                <td  class="Mode">
                                    {{vehicle.equipement }}  
                                </td>   
                            </tr>
                            <tr>
                                <td><label>{{ 'Vehicle.vehicle_use' | translate }} </label></td>
                                <td class="Mode">
                                    {{vehicle.vehicle_use }}  
                                </td>   
                            </tr>
                            
                        </table> 
                        
                        </div>
                        <!-- /.card -->
                      </section>
                </div>
                <!-- /.tab-pane -->
              </div>
              <!-- /.tab-content -->
            </div><!-- /.card-body -->
          </div>
          <!-- /.nav-tabs-custom -->
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div><!-- /.container-fluid -->
  </section>