import { MissionService } from './../../../../../_services/movement-service/mission-service/mission-service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-mission-detail',
  templateUrl: './mission-detail.component.html',
  styleUrls: ['./mission-detail.component.scss']
})
export class MissionDetailComponent implements OnInit {

  mission: any;
  documents: any;

  active: boolean = true;
  document: boolean = false;
  pannes: boolean = false;
  receptions: boolean = false;
  incidents: boolean = false;
  remarques: boolean = false;
  transfers: boolean = false;
  vehicule: boolean = false;
  convoyeur: boolean = false;
  chauffeur: boolean = false;


  constructor(
    private notifService: NotifService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private missionService: MissionService,

  ) { }

  async ngOnInit() {
    const mis_id = +this.route.snapshot.paramMap.get("id");
    this.missionService.find(mis_id).then(
      data => {
        this.mission = data;
        //this.documents = data['documents'];
        console.log(this.mission)
      }
    ).catch(
      error => {
        this.translate.get('mission.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
        this.router.navigate(['/mission/all'])
      }
    )
  }

  open(elt: any) {
    if (elt == 'document') {
      this.document = true;
      this.pannes = false;
      this.receptions = false;
      this.remarques = false;
      this.transfers = false;
      this.vehicule = false;
      this.convoyeur = false;
      this.chauffeur = false;
      this.active = false;
      this.incidents = false;


    } else if (elt == 'pannes') {
      this.document = false;
      this.pannes = true;
      this.receptions = false;
      this.remarques = false;
      this.transfers = false;
      this.vehicule = false;
      this.convoyeur = false;
      this.chauffeur = false;
      this.incidents = false;
      this.active = false;

    }
    else if (elt == 'receptions') {
      this.document = false;
      this.pannes = false;
      this.receptions = true;
      this.remarques = false;
      this.transfers = false;
      this.vehicule = false;
      this.convoyeur = false;
      this.chauffeur = false;
      this.incidents = false;
      this.active = false;

    }
    else if (elt == 'incidents') {
      this.incidents = true;
      this.document = false;
      this.pannes = false;
      this.receptions = false;
      this.remarques = false;
      this.transfers = false;
      this.vehicule = false;
      this.convoyeur = false;
      this.chauffeur = false;
      this.active = false;
    }
    else if (elt == 'remarques') {
      this.document = false;
      this.pannes = false;
      this.receptions = false;
      this.remarques = true;
      this.transfers = false;
      this.vehicule = false;
      this.convoyeur = false;
      this.incidents = false;
      this.chauffeur = false;
      this.active = false;
    }
    else if (elt == 'transfers') {
      this.document = false;
      this.pannes = false;
      this.receptions = false;
      this.remarques = false;
      this.transfers = true;
      this.incidents = false;
      this.vehicule = false;
      this.convoyeur = false;
      this.chauffeur = false;
      this.active = false;
    }
    else if (elt == 'vehicule') {
      this.document = false;
      this.pannes = false;
      this.receptions = false;
      this.remarques = false;
      this.transfers = false;
      this.vehicule = true;
      this.incidents = false;
      this.convoyeur = false;
      this.chauffeur = false;
      this.active = false;
    }
    else if (elt == 'convoyeur') {
      this.document = false;
      this.pannes = false;
      this.receptions = false;
      this.remarques = false;
      this.transfers = false;
      this.vehicule = false;
      this.incidents = false;
      this.convoyeur = true;
      this.chauffeur = false;
      this.active = false;
    }
    else if (elt == 'chauffeur') {
      this.document = false;
      this.pannes = false;
      this.receptions = false;
      this.remarques = false;
      this.transfers = false;
      this.vehicule = false;
      this.incidents = false;
      this.convoyeur = false;
      this.chauffeur = true;
      this.active = false;
    }
    else if (elt == 'active') {
      this.document = false;
      this.pannes = false;
      this.receptions = false;
      this.remarques = false;
      this.transfers = false;
      this.vehicule = false;
      this.incidents = false;
      this.convoyeur = false;
      this.chauffeur = false;
      this.active = true;
    }
  }

}

