<div class="content-wrapper" style="min-height: 1200.88px;  margin-left: 1%; margin-right: 1%;">
    <!-- Content Header (Page header) -->
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>{{ "Contrat.Contrat" | translate }}</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
                        <li class="breadcrumb-item "><a [routerLink]="['/contrat/all']">{{ "Contrat.Contrat" | translate
                                }}</a></li>
                        <li class="breadcrumb-item active">{{ "FileAriane.Detail" | translate }}</li>

                    </ol>
                </div>
            </div>
        </div><!-- /.container-fluid -->
    </section>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="container">
        <div class="container-fluid">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <div class="position-relative mb-4">
                            <!-- <div class="chartjs-size-monitor">
                                <div class="chartjs-size-monitor-expand">
                                    <div class=""></div>
                                </div>
                                <div class="chartjs-size-monitor-shrink">
                                    <div class=""></div>
                                </div>
                            </div> -->
                            <div class="col-sm-12">
                                <img style="height:400px; margin-left:40%" class="img-fluid" src="{{contrat?.avatar}}"
                                    alt="photo">
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <div class="row">

                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-header border-0">
                            <h3 class="card-title">Information personnel</h3>

                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-striped table-valign-middle">
                                <tbody>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Contrat.nameLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{contrat?.name}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Contrat.TypeContratLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{contrat?.type_name}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Contrat.DepartmentContratLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{contrat?.department}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Contrat.NonTaxableBonusContratLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{contrat?.non_taxable_bonus}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Contrat.StartDateContratLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{contrat?.start_date}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Contrat.EndDateContratLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{contrat?.end_date}}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-header border-0">
                            <h3 class="card-title">Information personnel</h3>

                        </div>
                        <div class="card-body table-responsive p-0">
                            <table class="table table-striped table-valign-middle">
                                <tbody>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Contrat.BasicSalaryContratLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{contrat?.basic_salary}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Contrat.ExtraCostPerHourContratLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{contrat?.extra_cost_per_hour}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Contrat.SupervisorContratLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{contrat?.supervisor}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Contrat.PrimeBonusContratLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{contrat?.prime_bonus}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Contrat.bank_nameLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{contrat?.bank_name}}</td>
                                    </tr>
                                    <tr>
                                        <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                        <td>{{ "Contrat.bank_account_numberLabel" | translate }}</td>
                                        <td style="color: rgb(0, 89, 255);">{{contrat?.bank_account_number}}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

                <!-- /.col-md-6 -->
            </div>

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Information Permis</h3>
                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <tbody>
                                <tr>
                                    <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>{{ "Contrat.medical_fileLabel" | translate }}</td>
                                    <td><a href="{{contrat?.contract_file}}"><button>Voir</button></a></td>
                                    <td><a href="{{contrat?.contract_file}}"
                                            download="dossier de {{contrat?.user?.name}}"><button>Telecharger</button></a>
                                    </td>
                                </tr>
                                <!-- <tr>
                                                                            <td class="mailbox-star"><a><i class="fas fa-star text-warning"></i></a></td>

                                    <td>Avez-vous une formation autre que le permis?</td>

                                    <th>Oui</th>
                                    <th>Non</th>

                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</div>