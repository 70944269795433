import { MissionDetailComponent } from './modules/application/mouvement/mission/mission-detail/mission-detail.component';
import { MissionListComponent } from './modules/application/mouvement/mission/mission-list/mission-list.component';
import { MissionUpdateCompoment } from './modules/application/mouvement/mission/mission-update/mission-update.component';
import { MissionAddComponent } from './modules/application/mouvement/mission/mission-add/mission-add.component';
import { PositionComponent } from './modules/application/pneumatique/operation/position/position.component';
import { TestDataTableComponent } from './test-data-table/test-data-table.component';
import { MecanicienDetailComponent } from './modules/application/human-R/mecanicien/mecanicien-detail/mecanicien-detail.component';
import { MecanicienAllComponent } from './modules/application/human-R/mecanicien/mecanicien-all/mecanicien-all.component';
import { MecanicienUpdateComponent } from './modules/application/human-R/mecanicien/mecanicien-update/mecanicien-update.component';
import { MecanicienAddComponent } from './modules/application/human-R/mecanicien/mecanicien-add/mecanicien-add.component';
import { ConvoyeurDetailComponent } from './modules/application/human-R/convoyeur/convoyeur-detail/convoyeur-detail.component';
import { ConvoyeurAllComponent } from './modules/application/human-R/convoyeur/convoyeur-all/convoyeur-all.component';
import { ConvoyeurUpdateComponent } from './modules/application/human-R/convoyeur/convoyeur-update/convoyeur-update.component';
import { ConvoyeurAddComponent } from './modules/application/human-R/convoyeur/convoyeur-add/convoyeur-add.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from './_guards/auth.guard';

import { HomeComponent } from './modules/general/home/home.component';
import { SigninComponent } from './modules/general/signin/signin.component';
import { SettingComponent } from './modules/general/setting/setting/setting.component';
import { AddComponent } from './modules/general/setting/form-auto/add/add.component';
import { DetailComponent } from './modules/general/setting/form-auto/detail/detail.component';
import { ListingComponent } from './modules/general/setting/form-auto/list/listing.component';
import { UpdateComponent } from './modules/general/setting/form-auto/update/update.component';
import { NotFoundComponent } from './modules/general/not-found/not-found.component';
import { AutoAddComponent } from './modules/application/auto-parc/auto/auto-add/auto-add.component';
import { AutoDetailComponent } from './modules/application/auto-parc/auto/auto-detail/auto-detail.component';
import { AutoListComponent } from './modules/application/auto-parc/auto/auto-list/auto-list.component';
import { AutoUpdateComponent } from './modules/application/auto-parc/auto/auto-update/auto-update.component';
import { TechnicalFactSheetVehicleComponent } from './modules/application/auto-parc/auto/technical-fact-sheet-vehicle/technical-fact-sheet-vehicle.component';

import { TypeFicheAddComponent } from './modules/application/auto-parc/typeFiche/type-fiche-add/type-fiche-add.component';
import { TypeFicheUpdateComponent } from './modules/application/auto-parc/typeFiche/type-fiche-update/type-fiche-update.component';
import { TypeFicheAllComponent } from './modules/application/auto-parc/typeFiche/type-fiche-all/type-fiche-all.component';
import { TypeFicheDetailComponent } from './modules/application/auto-parc/typeFiche/type-fiche-detail/type-fiche-detail.component';
import { OfficialPaperAddComponent } from './modules/application/auto-parc/ficheAdministrative/officialPaper/official-paper-add/official-paper-add.component';
import { OfficialPaperAUpdateComponent } from './modules/application/auto-parc/ficheAdministrative/officialPaper/official-paper-aupdate/official-paper-aupdate.component';
import { AssuranceAddComponent } from './modules/application/auto-parc/ficheAdministrative/assurance/assurance-add/assurance-add.component';
import { AssuranceUpdateComponent } from './modules/application/auto-parc/ficheAdministrative/assurance/assurance-update/assurance-update.component';
import { AssuranceAllComponent } from './modules/application/auto-parc/ficheAdministrative/assurance/assurance-all/assurance-all.component';
import { AssuranceDetailComponent } from './modules/application/auto-parc/ficheAdministrative/assurance/assurance-detail/assurance-detail.component';
import { OfficialPaperAllComponent } from './modules/application/auto-parc/ficheAdministrative/officialPaper/official-paper-all/official-paper-all.component';

import { AddAffectationComponent } from './modules/general/setting/affectation/add-affectation/add-affectation.component';
import { AllAffectationComponent } from './modules/general/setting/affectation/all-affectation/all-affectation.component';
import { UpdateAffectationComponent } from './modules/general/setting/affectation/update-affectation/update-affectation.component';
import { DetailleAffectationComponent } from './modules/general/setting/affectation/detaille-affectation/detaille-affectation.component';



import { AddMarkAutoComponent } from './modules/general/setting/mark/add-mark-auto/add-mark-auto.component';
import { UpdateMarkAutoComponent } from './modules/general/setting/mark/update-mark-auto/update-mark-auto.component';
import { DetailMarkAutoComponent } from './modules/general/setting/mark/detail-mark-auto/detail-mark-auto.component';
import { ListMarkAutoComponent } from './modules/general/setting/mark/list-mark-auto/list-mark-auto.component';


import { AddModelAutoComponent } from './modules/general/setting/modele/add-model-auto/add-model-auto.component';
import { UpdateModelAutoComponent } from './modules/general/setting/modele/update-model-auto/update-model-auto.component';
import { DetailModelAutoComponent } from './modules/general/setting/modele/detail-model-auto/detail-model-auto.component';
import { ListModelAutoComponent } from './modules/general/setting/modele/list-model-auto/list-model-auto.component';


import { AddCarosserieAutoComponent } from './modules/general/setting/carosserie/add-carosserie-auto/add-carosserie-auto.component';
import { UpdateCarosserieAutoComponent } from './modules/general/setting/carosserie/update-carosserie-auto/update-carosserie-auto.component';
import { DetailCarosserieAutoComponent } from './modules/general/setting/carosserie/detail-carosserie-auto/detail-carosserie-auto.component';
import { ListCarosserieAutoComponent } from './modules/general/setting/carosserie/list-carosserie-auto/list-carosserie-auto.component';


import { AddTypeVehiculeAutoComponent } from './modules/general/setting/type-vehicule/add-type-vehicule-auto/add-type-vehicule-auto.component';
import { UpdateTypeVehiculeAutoComponent } from './modules/general/setting/type-vehicule/update-type-vehicule-auto/update-type-vehicule-auto.component';
import { DetailTypeVehiculeAutoComponent } from './modules/general/setting/type-vehicule/detail-type-vehicule-auto/detail-type-vehicule-auto.component';
import { ListTypeVehiculeAutoComponent } from './modules/general/setting/type-vehicule/list-type-vehicule-auto/list-type-vehicule-auto.component';
import { InterveningTypeAddComponent } from './modules/general/setting/intervening-types/intervening-type-add/intervening-type-add.component';
import { InterveningTypeUpdateComponent } from './modules/general/setting/intervening-types/intervening-type-update/intervening-type-update.component';
import { InterveningTypeListComponent } from './modules/general/setting/intervening-types/intervening-type-list/intervening-type-list.component';
import { InterveningElementAddComponent } from './modules/general/setting/intervening-elements/intervening-element-add/intervening-element-add.component';
import { InterveningElementUpdateComponent } from './modules/general/setting/intervening-elements/intervening-element-update/intervening-element-update.component';
import { InterveningElementListComponent } from './modules/general/setting/intervening-elements/intervening-element-list/intervening-element-list.component';
import { InterveningElementDetailComponent } from './modules/general/setting/intervening-elements/intervening-element-detail/intervening-element-detail.component';
import { GeneratingDocumentAddComponent } from './modules/general/setting/generating-documents/generating-document-add/generating-document-add.component';
import { GeneratingDocumentListComponent } from './modules/general/setting/generating-documents/generating-document-list/generating-document-list.component';
import { GeneratingDocumentUploadComponent } from './modules/general/setting/generating-documents/generating-document-upload/generating-document-upload.component';
import { AddRoleComponent } from './modules/general/setting/roles/add-role/add-role.component';
import { AllRolesComponent } from './modules/general/setting/roles/all-roles/all-roles.component';
import { UpdateRoleComponent } from './modules/general/setting/roles/update-role/update-role.component';
import { DetailsRoleComponent } from './modules/general/setting/roles/details-role/details-role.component';
import { CreateUsersComponent } from './modules/application/human-R/users/create-users/create-users.component';
import { ListUsersComponent } from './modules/application/human-R/users/list-users/list-users.component';
import { UpdateUsersComponent } from './modules/application/human-R/users/update-users/update-users.component';
import { ListdetailUserComponent } from './modules/application/human-R/users/listdetail-user/listdetail-user.component';

import { SanctonAddComponent } from './modules/application/human-R/sanctions/sanction/sancton-add/sancton-add.component';
import { SanctonAllComponent } from './modules/application/human-R/sanctions/sanction/sancton-all/sancton-all.component';
import { SanctonUpdateComponent } from './modules/application/human-R/sanctions/sanction/sancton-update/sancton-update.component';
import { SanctonDetailComponent } from './modules/application/human-R/sanctions/sanction/sancton-detail/sancton-detail.component';
import { TypeSanctionAddComponent } from './modules/application/human-R/sanctions/type-sanction/type-sanction-add/type-sanction-add.component';
import { TypeSanctionUpdateComponent } from './modules/application/human-R/sanctions/type-sanction/type-sanction-update/type-sanction-update.component';
import { TypeContratAddComponent } from './modules/application/human-R/contrats/type-contrat/type-contrat-add/type-contrat-add.component';
import { TypeContratUpdateComponent } from './modules/application/human-R/contrats/type-contrat/type-contrat-update/type-contrat-update.component';
import { ContratAllComponent } from './modules/application/human-R/contrats/contrat/contrat-all/contrat-all.component';
import { ContratDetailComponent } from './modules/application/human-R/contrats/contrat/contrat-detail/contrat-detail.component';
import { ContratAddComponent } from './modules/application/human-R/contrats/contrat/contrat-add/contrat-add.component';
import { ContratUpdateComponent } from './modules/application/human-R/contrats/contrat/contrat-update/contrat-update.component';

import { ChauffeurAddComponent } from './modules/application/human-R/chauffeurs/chauffeur-add/chauffeur-add.component';
import { ChauffeurAllComponent } from './modules/application/human-R/chauffeurs/chauffeur-all/chauffeur-all.component';
import { ChauffeurDetailComponent } from './modules/application/human-R/chauffeurs/chauffeur-detail/chauffeur-detail.component';
import { ChauffeurUpdateComponent } from './modules/application/human-R/chauffeurs/chauffeur-update/chauffeur-update.component';
import { FormationAddComponent } from './modules/application/human-R/formations/formation-add/formation-add.component';
import { FormationAllComponent } from './modules/application/human-R/formations/formation-all/formation-all.component';
import { FormationDetailComponent } from './modules/application/human-R/formations/formation-detail/formation-detail.component';
import { FormationUpdateComponent } from './modules/application/human-R/formations/formation-update/formation-update.component';
import { NoteCritiqueAddComponent } from './modules/application/human-R/note-critiques/note-critique-add/note-critique-add.component';
import { NoteCritiqueAllComponent } from './modules/application/human-R/note-critiques/note-critique-all/note-critique-all.component';
import { NoteCritiqueUpdateComponent } from './modules/application/human-R/note-critiques/note-critique-update/note-critique-update.component';
import { NoteCritiqueDetailComponent } from './modules/application/human-R/note-critiques/note-critique-detail/note-critique-detail.component';
import { TypeContratAllComponent } from './modules/application/human-R/contrats/type-contrat/type-contrat-all/type-contrat-all.component';
import { TypeSanctionAllComponent } from './modules/application/human-R/sanctions/type-sanction/type-sanction-all/type-sanction-all.component';
import { MessageAddComponent } from './modules/application/human-R/messages/message-add/message-add.component';
import { MessageAllComponent } from './modules/application/human-R/messages/message-all/message-all.component';
import { ThComponent } from './th/th.component';
import { MessageDetailComponent } from './modules/application/human-R/messages/message-detail/message-detail.component';
import { MessageUpdateComponent } from './modules/application/human-R/messages/message-update/message-update.component';
import { ProfileUpdateComponent } from './modules/application/human-R/profile/profile-update/profile-update.component';
import { ProfileAllComponent } from './modules/application/human-R/profile/profile-all/profile-all.component';

import { TypeEntretientAddComponent } from './modules/application/maintenance/type-entretient/type-entretient-add/type-entretient-add.component';
import { TypeEntretientAllComponent } from './modules/application/maintenance/type-entretient/type-entretient-all/type-entretient-all.component';
import { TypeEntretientUpdateComponent } from './modules/application/maintenance/type-entretient/type-entretient-update/type-entretient-update.component';

import { GammeEntretienAddComponent } from './modules/application/maintenance/gamme-entretien/gamme-entretien-add/gamme-entretien-add.component';
import { GammeEntretienUpdateComponent } from './modules/application/maintenance/gamme-entretien/gamme-entretien-update/gamme-entretien-update.component';
import { GammeEntretienAllComponent } from './modules/application/maintenance/gamme-entretien/gamme-entretien-all/gamme-entretien-all.component';

import { TypeInterventionAddComponent } from './modules/application/maintenance/interventions/type-intervention/type-intervention-add/type-intervention-add.component';
import { TypeInterventionUpdateComponent } from './modules/application/maintenance/interventions/type-intervention/type-intervention-update/type-intervention-update.component';
import { TypeInterventionAllComponent } from './modules/application/maintenance/interventions/type-intervention/type-intervention-all/type-intervention-all.component';
import { TypeInterventionDetailComponent } from './modules/application/maintenance/interventions/type-intervention/type-intervention-detail/type-intervention-detail.component';
import { FicheInterventionAddComponent } from './modules/application/maintenance/interventions/fiche intervention/fiche-intervention-add/fiche-intervention-add.component';
import { FicheInterventionUpdateComponent } from './modules/application/maintenance/interventions/fiche intervention/fiche-intervention-update/fiche-intervention-update.component';
import { FicheInterventionAllComponent } from './modules/application/maintenance/interventions/fiche intervention/fiche-intervention-all/fiche-intervention-all.component';
import { FicheInterventionDetailComponent } from './modules/application/maintenance/interventions/fiche intervention/fiche-intervention-detail/fiche-intervention-detail.component';
import { InterventionAddComponent } from './modules/application/maintenance/interventions/intervention/intervention-add/intervention-add.component';
import { InterventionUpdateComponent } from './modules/application/maintenance/interventions/intervention/intervention-update/intervention-update.component';
import { InterventionAllComponent } from './modules/application/maintenance/interventions/intervention/intervention-all/intervention-all.component';
import { ProgrammeReparationAddComponent } from './modules/application/maintenance/programme-reparation/programme-reparation-add/programme-reparation-add.component';
import { ProgrammeReparationUpdateComponent } from './modules/application/maintenance/programme-reparation/programme-reparation-update/programme-reparation-update.component';
import { ProgrammeReparationAllComponent } from './modules/application/maintenance/programme-reparation/programme-reparation-all/programme-reparation-all.component';
import { ProgrammeReparationDetailsComponent } from './modules/application/maintenance/programme-reparation/programme-reparation-details/programme-reparation-details.component';
//import { PanneAutoUpdateComponent } from './modules/application/maintenance/panne/panne-auto-update/panne-auto-update.component';
import { PanneAutoAddComponent } from './modules/application/maintenance/panne/panne-auto-add/panne-auto-add.component';
import { PanneAutoUpdateComponent } from './modules/application/maintenance/panne/panne-auto-update/panne-auto-update.component';
import { PanneAutoDetailComponent } from './modules/application/maintenance/panne/panne-auto-detail/panne-auto-detail.component';
import { PanneAutoListComponent } from './modules/application/maintenance/panne/panne-auto-list/panne-auto-list.component';
import { ProgrammesEntretientAddComponent } from './modules/application/maintenance/entretients/programmes-entretient/programmes-entretient-add/programmes-entretient-add.component';
import { ProgrammesEntretientUpdateComponent } from './modules/application/maintenance/entretients/programmes-entretient/programmes-entretient-update/programmes-entretient-update.component';
import { ProgrammesEntretientAllComponent } from './modules/application/maintenance/entretients/programmes-entretient/programmes-entretient-all/programmes-entretient-all.component';
import { ProgrammesEntretientDetailsComponent } from './modules/application/maintenance/entretients/programmes-entretient/programmes-entretient-details/programmes-entretient-details.component';
import { GammeEntretientDetailComponent } from './modules/application/maintenance/gamme-entretien/gamme-entretient-detail/gamme-entretient-detail.component'
import { MarkPneuAddComponent } from './modules/general/setting/mark_pneu/mark-pneu-add/mark-pneu-add.component';
import { MarkPneuUpdateComponent } from './modules/general/setting/mark_pneu/mark-pneu-update/mark-pneu-update.component';
import { MarkPneuAllComponent } from './modules/general/setting/mark_pneu/mark-pneu-all/mark-pneu-all.component';
import { MarkPneuDetailComponent } from './modules/general/setting/mark_pneu/mark-pneu-detail/mark-pneu-detail.component';
import { OperationAddComponent } from './modules/application/pneumatique/operation/operation-add/operation-add.component';
import { OperationUpdateComponent } from './modules/application/pneumatique/operation/operation-update/operation-update.component';
import { OperationAllComponent } from './modules/application/pneumatique/operation/operation-all/operation-all.component';
import { OperationDetailsComponent } from './modules/application/pneumatique/operation/operation-details/operation-details.component';
import { PositionAddComponent } from './modules/application/pneumatique/position/position-add/position-add.component';
import { PositionUpdateComponent } from './modules/application/pneumatique/position/position-update/position-update.component';
import { PositionAllComponent } from './modules/application/pneumatique/position/position-all/position-all.component';
import { PositionDetailsComponent } from './modules/application/pneumatique/position/position-details/position-details.component';
import { PneuAddComponent } from './modules/application/pneumatique/pneu/pneu-add/pneu-add.component';
import { PneuUpdateComponent } from './modules/application/pneumatique/pneu/pneu-update/pneu-update.component';
import { PneuAllComponent } from './modules/application/pneumatique/pneu/pneu-all/pneu-all.component';
import { PneuDetailsComponent } from './modules/application/pneumatique/pneu/pneu-details/pneu-details.component';
import { InventairesComponent } from './modules/application/pneumatique/operation/inventaires/inventaires.component';
import { TypeDocumentAddComponent } from './modules/application/mouvement/type-document/type-document-add/type-document-add.component';
import { TypeDocumentUpdateComponent } from './modules/application/mouvement/type-document/type-document-update/type-document-update.component';
import { TypeDocumentAllComponent } from './modules/application/mouvement/type-document/type-document-all/type-document-all.component';
import { TypeIncidentAddComponent } from './modules/application/mouvement/type-incident/type-incident-add/type-incident-add.component';
import { TypeIncidentUpdateComponent } from './modules/application/mouvement/type-incident/type-incident-update/type-incident-update.component';
import { TypeIncidentAllComponent } from './modules/application/mouvement/type-incident/type-incident-all/type-incident-all.component';
import { TranferAddComponent } from './modules/application/mouvement/mission/tranfer-add/tranfer-add.component';

const routes: Routes = [
  { path: 'login', component: SigninComponent },
  { path: 'home', component: HomeComponent },
  //{ path: 'test', component: TestDataTableComponent },
  //route de module application
  //gestion du user
  { path: 'user-add', component: CreateUsersComponent, canActivate: [AuthGuard], data: { permissions: ['users-create'] } },
  { path: 'user-all', component: ListUsersComponent, canActivate: [AuthGuard], data: { permissions: ['users-read'] } },
  { path: 'user-update/:id', component: UpdateUsersComponent, canActivate: [AuthGuard], data: { permissions: ['users-update'] } },
  { path: 'user-detail/:id', component: ListdetailUserComponent, canActivate: [AuthGuard], data: { permissions: ['users-read'] } },
  //route de module parc-auto
  { path: 'type_fiche_add', component: TypeFicheAddComponent, canActivate: [AuthGuard], data: { permissions: ['paper_types-create'] } },
  { path: 'type_fiche_update/:id', component: TypeFicheUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['paper_types-update'] } },
  { path: 'type_fiche_all', component: TypeFicheAllComponent, canActivate: [AuthGuard], data: { permissions: ['paper_types-read'] } },
  { path: 'official_add/:id', component: OfficialPaperAddComponent, canActivate: [AuthGuard], data: { permissions: ['official_papers-create'] } },
  { path: 'assurance_add', component: AssuranceAddComponent, canActivate: [AuthGuard], data: { permissions: ['insurances-create'] } },
  { path: 'official_all/:id', component: OfficialPaperAllComponent, canActivate: [AuthGuard], data: { permissions: ['official_papers-read'] } },
  { path: 'all_assurrance', component: AssuranceAllComponent, canActivate: [AuthGuard], data: { permissions: ['insurances-read'] } },
  { path: 'type_fiche_detail/:id', component: TypeFicheDetailComponent, canActivate: [AuthGuard], data: { permissions: ['paper_types-read'] } },
  { path: 'official_update/:id', component: OfficialPaperAUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['official_papers-update'] } },
  { path: 'assurance_update/:id', component: AssuranceUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['insurances-update'] } },
  { path: 'assurance_detail/:id', component: AssuranceDetailComponent, canActivate: [AuthGuard], data: { permissions: ['insurances-read'] } },

  { path: 'vehicles/add', component: AutoAddComponent, canActivate: [AuthGuard], data: { permissions: ['vehicles-create'] } },
  { path: 'vehicles/detail/:id', component: AutoDetailComponent, canActivate: [AuthGuard], data: { permissions: ['vehicles-read'] } },
  { path: 'vehicles/list', component: AutoListComponent, canActivate: [AuthGuard], data: { permissions: ['vehicles-read'] } },
  { path: 'vehicles/update/:id', component: AutoUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['vehicles-update'] } },
  { path: 'vehicles/detail/technical_fact_sheet/:id', component: TechnicalFactSheetVehicleComponent, canActivate: [AuthGuard], data: { permissions: ['vehicles-read'] } },

  //route de module general
  //{ path: 'setting/settings', component: SettingComponent, canActivate: [AuthGuard], data: { permissions: ['formes-read'] } },
  { path: 'setting/forms/add', component: AddComponent, canActivate: [AuthGuard], data: { permissions: ['formes-create'] } },
  { path: 'setting/forms/detail', component: DetailComponent, canActivate: [AuthGuard], data: { permissions: ['formes-read'] } },
  { path: 'setting/forms/list', component: ListingComponent, canActivate: [AuthGuard], data: { permissions: ['formes-read'] } },
  { path: 'setting/forms/update', component: UpdateComponent, canActivate: [AuthGuard], data: { permissions: ['formes-update'] } },

  { path: 'affectations/all', component: AllAffectationComponent, canActivate: [AuthGuard], data: { permissions: ['affectations-read'] } },
  { path: 'affectations/add', component: AddAffectationComponent, canActivate: [AuthGuard], data: { permissions: ['affectations-create'] } },
  { path: 'affectations/update/:id', component: UpdateAffectationComponent, canActivate: [AuthGuard], data: { permissions: ['affectations-update'] } },
  { path: 'affectations/detaille/:id', component: DetailleAffectationComponent, canActivate: [AuthGuard], data: { permissions: ['affectations-read'] } },

  //parametrage du vehicule

  //  Model
  { path: 'setting/forms/model/add', component: AddModelAutoComponent, canActivate: [AuthGuard], data: { permissions: ['formes-create'] } },
  { path: 'setting/forms/model/detail/:id', component: DetailModelAutoComponent, canActivate: [AuthGuard], data: { permissions: ['formes-read'] } },
  { path: 'setting/forms/model/list', component: ListModelAutoComponent, canActivate: [AuthGuard], data: { permissions: ['formes-read'] } },
  { path: 'setting/forms/model/update/:id', component: UpdateModelAutoComponent, canActivate: [AuthGuard], data: { permissions: ['formes-update'] } },

  // carosserie
  { path: 'setting/forms/carosserie/add', component: AddCarosserieAutoComponent, canActivate: [AuthGuard], data: { permissions: ['formes-create'] } },
  { path: 'setting/forms/carosserie/detail/:id', component: DetailCarosserieAutoComponent, canActivate: [AuthGuard], data: { permissions: ['formes-read'] } },
  { path: 'setting/forms/carosserie/list', component: ListCarosserieAutoComponent, canActivate: [AuthGuard], data: { permissions: ['formes-read'] } },
  { path: 'setting/forms/carosserie/update/:id', component: UpdateCarosserieAutoComponent, canActivate: [AuthGuard], data: { permissions: ['formes-update'] } },

  // type de vehicelu
  { path: 'setting/forms/type-vehicule/add', component: AddTypeVehiculeAutoComponent, canActivate: [AuthGuard], data: { permissions: ['formes-create'] } },
  { path: 'setting/forms/type-vehicule/detail/:id', component: DetailTypeVehiculeAutoComponent, canActivate: [AuthGuard], data: { permissions: ['formes-read'] } },
  { path: 'setting/forms/type-vehicule/list', component: ListTypeVehiculeAutoComponent, canActivate: [AuthGuard], data: { permissions: ['formes-read'] } },
  { path: 'setting/forms/type-vehicule/update/:id', component: UpdateTypeVehiculeAutoComponent, canActivate: [AuthGuard], data: { permissions: ['formes-update'] } },

  // marque
  { path: 'setting/forms/marque/add', component: AddMarkAutoComponent, canActivate: [AuthGuard], data: { permissions: ['formes-create'] } },
  { path: 'setting/forms/marque/detail/:id', component: ListMarkAutoComponent, canActivate: [AuthGuard], data: { permissions: ['formes-read'] } },
  { path: 'setting/forms/marque/list', component: ListMarkAutoComponent, canActivate: [AuthGuard], data: { permissions: ['formes-read'] } },
  { path: 'setting/forms/marque/update/:id', component: UpdateMarkAutoComponent, canActivate: [AuthGuard], data: { permissions: ['formes-update'] } },

  //type intervenant
  { path: 'intervening-type/add', component: InterveningTypeAddComponent, canActivate: [AuthGuard], data: { permissions: ['intervening_types-create'] } },
  { path: 'intervening-type/update/:id', component: InterveningTypeUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['intervening_types-update'] } },
  { path: 'intervening-type/list', component: InterveningTypeListComponent, canActivate: [AuthGuard], data: { permissions: ['intervening_types-read'] } },

  //element intervenant
  { path: 'intervening-element/add', component: InterveningElementAddComponent, canActivate: [AuthGuard], data: { permissions: ['intervening_elements-create'] } },
  { path: 'intervening-element/update/:id', component: InterveningElementUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['intervening_elements-update'] } },
  { path: 'intervening-element/list', component: InterveningElementListComponent, canActivate: [AuthGuard], data: { permissions: ['intervening_elements-read'] } },
  { path: 'intervening-element/detail/:id', component: InterveningElementDetailComponent, canActivate: [AuthGuard], data: { permissions: ['intervening_elements-read'] } },

  //types interventions by gilles
  { path: 'intervention-type/add', component: TypeInterventionAddComponent, canActivate: [AuthGuard], data: { permissions: ['type_interventions-create'] } },
  { path: 'intervention-type/update/:id', component: TypeInterventionUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['type_interventions-update'] } },
  { path: 'intervention-type/list', component: TypeInterventionAllComponent, canActivate: [AuthGuard], data: { permissions: ['type_interventions-read'] } },
  { path: 'intervention-type/detail/:id', component: TypeInterventionDetailComponent, canActivate: [AuthGuard], data: { permissions: ['type_interventions-read'] } },

  //interventions by gilles
  { path: 'intervention/add', component: InterventionAddComponent, canActivate: [AuthGuard], data: { permissions: ['interventions-create'] } },
  { path: 'intervention/update/:id', component: InterventionUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['interventions-update'] } },
  { path: 'intervention/list', component: InterventionAllComponent, canActivate: [AuthGuard], data: { permissions: ['interventions-read'] } },

  //Programme Entretien
  { path: 'programme-entretient/add', component: ProgrammesEntretientAddComponent, canActivate: [AuthGuard], data: { permissions: ['programme_entretiens-create'] } },
  { path: 'programme-entretient/update/:id', component: ProgrammesEntretientUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['programme_entretiens-update'] } },
  { path: 'programme-entretient/list', component: ProgrammesEntretientAllComponent, canActivate: [AuthGuard], data: { permissions: ['programme_entretiens-read'] } },
  { path: 'programme-entretient/details/:id', component: ProgrammesEntretientDetailsComponent, canActivate: [AuthGuard], data: { permissions: ['programme_entretiens-read'] } },

  //sanction
  { path: 'type-sanction/add', component: TypeSanctionAddComponent, canActivate: [AuthGuard], data: { permissions: ['sanction_types-create'] } },
  { path: 'type-sanction/update/:id', component: TypeSanctionUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['sanction_types-update'] } },
  { path: 'type-sanction/all', component: TypeSanctionAllComponent, canActivate: [AuthGuard], data: { permissions: ['sanction_types-read'] } },

  { path: 'sanction/add', component: SanctonAddComponent, canActivate: [AuthGuard], data: { permissions: ['sanctions-create'] } },
  { path: 'sanction/update/:id', component: SanctonUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['sanctions-update'] } },
  { path: 'sanction/all', component: SanctonAllComponent, canActivate: [AuthGuard], data: { permissions: ['sanctions-read'] } },
  { path: 'sanction/detail/:id', component: SanctonDetailComponent, canActivate: [AuthGuard], data: { permissions: ['sanctions-read'] } },

  //sanction
  { path: 'type-contrat/add', component: TypeContratAddComponent, canActivate: [AuthGuard], data: { permissions: ['contract_types-create'] } },
  { path: 'type-contrat/update/:id', component: TypeContratUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['contract_types-update'] } },
  { path: 'type-contrat/all', component: TypeContratAllComponent, canActivate: [AuthGuard], data: { permissions: ['contract_types-read'] } },

  { path: 'contrat/add', component: ContratAddComponent, canActivate: [AuthGuard], data: { permissions: ['contracts-create'] } },
  { path: 'contrat/update/:id', component: ContratUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['contracts-update'] } },
  { path: 'contrat/all', component: ContratAllComponent, canActivate: [AuthGuard], data: { permissions: ['contracts-read'] } },
  { path: 'contrat/detail/:id', component: ContratDetailComponent, canActivate: [AuthGuard], data: { permissions: ['contracts-read'] } },

  //formation
  { path: 'formation/add', component: FormationAddComponent, canActivate: [AuthGuard], data: { permissions: ['formations-create'] } },
  { path: 'formation/update/:id', component: FormationUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['formations-update'] } },
  { path: 'formation/all', component: FormationAllComponent, canActivate: [AuthGuard], data: { permissions: ['formations-read'] } },
  { path: 'formation/detail/:id', component: FormationDetailComponent, canActivate: [AuthGuard], data: { permissions: ['formations-read'] } },

  //chauffeur
  { path: 'chauffeur/add', component: ChauffeurAddComponent, canActivate: [AuthGuard], data: { permissions: ['drivers-create'] } },
  { path: 'chauffeur/update/:id', component: ChauffeurUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['drivers-update'] } },
  { path: 'chauffeur/all', component: ChauffeurAllComponent, canActivate: [AuthGuard], data: { permissions: ['drivers-read'] } },
  { path: 'chauffeur/detail/:id', component: ChauffeurDetailComponent, canActivate: [AuthGuard], data: { permissions: ['drivers-read'] } },

  //note-critique
  { path: 'note-critique/add', component: NoteCritiqueAddComponent, canActivate: [AuthGuard], data: { permissions: ['internal_notes-create'] } },
  { path: 'note-critique/update/:id', component: NoteCritiqueUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['internal_notes-update'] } },
  { path: 'note-critique/all', component: NoteCritiqueAllComponent, canActivate: [AuthGuard], data: { permissions: ['internal_notes-read'] } },
  { path: 'note-critique/detail/:id', component: NoteCritiqueDetailComponent, canActivate: [AuthGuard], data: { permissions: ['internal_notes-read'] } },

  //gestion de role
  { path: 'role-add', component: AddRoleComponent, canActivate: [AuthGuard], data: { permissions: ['roles-create'] } },
  { path: 'role-all', component: AllRolesComponent, canActivate: [AuthGuard], data: { permissions: ['roles-read'] } },
  { path: 'role-update/:id', component: UpdateRoleComponent, canActivate: [AuthGuard], data: { permissions: ['roles-update'] } },
  { path: 'role-detail/:id', component: DetailsRoleComponent, canActivate: [AuthGuard], data: { permissions: ['roles-read'] } },

  //Messages
  { path: 'message/add', component: MessageAddComponent, canActivate: [AuthGuard], data: { permissions: ['messages-create'] } },
  { path: 'message/update/:id', component: MessageUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['messages-update'] } },
  { path: 'message/all', component: MessageAllComponent, canActivate: [AuthGuard], data: { permissions: ['messages-read'] } },
  { path: 'message/detail/:id', component: MessageDetailComponent, canActivate: [AuthGuard], data: { permissions: ['messages-read'] } },

  //convoyeur
  { path: 'convoyeur/add', component: ConvoyeurAddComponent, canActivate: [AuthGuard], data: { permissions: ['convoyeurs-create'] } },
  { path: 'convoyeur/update/:id', component: ConvoyeurUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['convoyeurs-update'] } },
  { path: 'convoyeur/all', component: ConvoyeurAllComponent, canActivate: [AuthGuard], data: { permissions: ['convoyeurs-read'] } },
  { path: 'convoyeur/detail/:id', component: ConvoyeurDetailComponent, canActivate: [AuthGuard], data: { permissions: ['convoyeurs-read'] } },

  //mecanicien
  { path: 'mecanicien/add', component: MecanicienAddComponent, canActivate: [AuthGuard], data: { permissions: ['mecaniciens-create'] } },
  { path: 'mecanicien/update/:id', component: MecanicienUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['mecaniciens-update'] } },
  { path: 'mecanicien/all', component: MecanicienAllComponent, canActivate: [AuthGuard], data: { permissions: ['mecaniciens-read'] } },
  { path: 'mecanicien/detail/:id', component: MecanicienDetailComponent, canActivate: [AuthGuard], data: { permissions: ['mecaniciens-read'] } },

  //  Panne
  { path: 'panne/add', component: PanneAutoAddComponent, canActivate: [AuthGuard], data: { permissions: ['pannes-create'] } },
  { path: 'panne/update/:id', component: PanneAutoUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['pannes-create'] } },
  { path: 'panne/detail/:id', component: PanneAutoDetailComponent, canActivate: [AuthGuard], data: { permissions: ['pannes-create'] } },
  { path: 'panne/list', component: PanneAutoListComponent, canActivate: [AuthGuard], data: { permissions: ['pannes-create'] } },


  //profile
  { path: 'profile/all', component: ProfileAllComponent, canActivate: [AuthGuard], data: { permissions: ['users-read'] } },
  //{ path: 'profile/update/:id', component: ProfileUpdateComponent },

  //Produire document
  { path: 'document-production/add', component: GeneratingDocumentAddComponent, canActivate: [AuthGuard], data: { permissions: ['documents-create'] } },
  { path: 'document-production/upload', component: GeneratingDocumentUploadComponent, canActivate: [AuthGuard], data: { permissions: ['documents-read'] } },
  { path: 'document-production/list', component: GeneratingDocumentListComponent, canActivate: [AuthGuard], data: { permissions: ['documents-read'] } },



  //Type d'entretient
  { path: 'type-entretient/add', component: TypeEntretientAddComponent },
  { path: 'type-entretient/update/:id', component: TypeEntretientUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['type_entretiens-update'] } },
  { path: 'type-entretient/all', component: TypeEntretientAllComponent, canActivate: [AuthGuard], data: { permissions: ['type_entretiens-read'] } },
  //{ path: 'type-entretient/detail/:id', component: MecanicienDetailComponent },

  //Gamme d'entretient
  { path: 'gamme-entretien/add', component: GammeEntretienAddComponent, canActivate: [AuthGuard], data: { permissions: ['gamme_entretiens-create'] } },
  { path: 'gamme-entretien/update/:id', component: GammeEntretienUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['gamme_entretiens-update'] } },
  { path: 'gamme-entretien/all', component: GammeEntretienAllComponent, canActivate: [AuthGuard], data: { permissions: ['gamme_entretiens-read'] } },
  { path: 'gamme-entretient/detail/:id', component: GammeEntretientDetailComponent, canActivate: [AuthGuard], data: { permissions: ['gamme_entretiens-read'] } },

  //Fiche Intervention
  { path: 'fiche-intervention/add', component: FicheInterventionAddComponent, canActivate: [AuthGuard], data: { permissions: ['fiche_interventions-create'] } },
  { path: 'fiche-intervention/update/:id', component: FicheInterventionUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['fiche_interventions-update'] } },
  { path: 'fiche-intervention/all', component: FicheInterventionAllComponent, canActivate: [AuthGuard], data: { permissions: ['fiche_interventions-read'] } },
  { path: 'fiche-intervention/detail/:id', component: FicheInterventionDetailComponent, canActivate: [AuthGuard], data: { permissions: ['fiche_interventions-read'] } },

  //Programme Reparation
  { path: 'programme-reparation/add', component: ProgrammeReparationAddComponent, canActivate: [AuthGuard], data: { permissions: ['programme_reparations-create'] } },
  { path: 'programme-reparation/update/:id', component: ProgrammeReparationUpdateComponent, canActivate: [AuthGuard], data: { permissions: ['programme_reparations-update'] } },
  { path: 'programme-reparation/all', component: ProgrammeReparationAllComponent, canActivate: [AuthGuard], data: { permissions: ['programme_reparations-read'] } },
  { path: 'programme-reparation/detail/:id', component: ProgrammeReparationDetailsComponent, canActivate: [AuthGuard], data: { permissions: ['programme_reparations-read'] } },

  //Marque de pneu
  { path: 'mark-pneu/add', component: MarkPneuAddComponent },
  { path: 'mark-pneu/update/:id', component: MarkPneuUpdateComponent },
  { path: 'mark-pneu/all', component: MarkPneuAllComponent },
  { path: 'mark-pneu/detail/:id', component: MarkPneuDetailComponent },

  //Operation
  { path: 'operation/add', component: OperationAddComponent },
  { path: 'operation/update/:id', component: OperationUpdateComponent },
  { path: 'operation/all', component: OperationAllComponent },
  { path: 'operation/detail/:id', component: OperationDetailsComponent },
  { path: 'position/:id', component: PositionComponent },
  { path: 'inventaire', component: InventairesComponent },

  /* //Position
  { path: 'position/add', component: PositionAddComponent },
  { path: 'position/update/:id', component: PositionUpdateComponent },
  { path: 'position/all', component: PositionAllComponent },
  { path: 'position/detail/:id', component: PositionDetailsComponent }, */

  //Pneu
  { path: 'pneu/add', component: PneuAddComponent },
  { path: 'pneu/update/:id', component: PneuUpdateComponent },
  { path: 'pneu/all', component: PneuAllComponent },
  { path: 'pneu/detail/:id', component: PneuDetailsComponent },

  //Mission
  { path: 'mission/add', component: MissionAddComponent },
  { path: 'mission/update/:id', component: MissionUpdateCompoment },
  { path: 'mission/all', component: MissionListComponent },
  { path: 'mission/detail/:id', component: MissionDetailComponent },
  { path: 'transfer/add', component: TranferAddComponent },

  //Type de document d'une mission
  { path: 'type-document-mission/add', component: TypeDocumentAddComponent },
  { path: 'type-document-mission/update/:id', component: TypeDocumentUpdateComponent },
  { path: 'type-document-mission/all', component: TypeDocumentAllComponent },

  //Type de document d'une mission
  { path: 'type-incident/add', component: TypeIncidentAddComponent },
  { path: 'type-incident/update/:id', component: TypeIncidentUpdateComponent },
  { path: 'type-incident/all', component: TypeIncidentAllComponent },


  //route du not found
  { path: '**', canActivate: [AuthGuard], component: NotFoundComponent },




];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
