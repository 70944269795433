import { Component, OnInit } from '@angular/core';

import { TypeContratService } from 'src/app/_services/RH-service/contrat/type-contrat.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-type-contrat-add',
  templateUrl: './type-contrat-add.component.html',
  styleUrls: ['./type-contrat-add.component.scss']
})
export class TypeContratAddComponent implements OnInit {
  TypeContratForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  isMaterial: boolean = false;

  constructor(
    private TypeContratService: TypeContratService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private ref: MatDialog,

  ) {}

  ngOnInit() {
    this.isMaterial = '/type-contrat/add' != location.pathname;
    this.TypeContratForm = this.formBuilder.group({
      name:['',[Validators.required]],
      description:[''],
    });

  }

  get form() {
    return this.TypeContratForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;


    if (this.TypeContratForm.invalid) {
      this.translate.get('TypeContrat.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('name', ''+this.form.name.value);
    formData.append('description', ''+this.form.description.value);

      console.log(formData);
      this.TypeContratService.post(formData)
      .then(resp => {
        this.translate.get('TypeContrat.SubmitSuccess')
        .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.TypeContratForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('TypeContrat.SubmitErrorTypeContrat')
        .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }


  close() {
    this.ref.closeAll();
    // location.reload();
  }

}
