import { Component, OnInit } from '@angular/core';
import { FicheInterventionService } from 'src/app/_services/maintenance-service/fiche_intervention/fiche-intervention.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { NotifService } from 'src/app/_services/notif.service';

@Component({
  selector: 'app-fiche-intervention-add',
  templateUrl: './fiche-intervention-add.component.html',
  styleUrls: ['./fiche-intervention-add.component.scss']
})
export class FicheInterventionAddComponent implements OnInit {

  DataForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  user
  vehicules: any;
  mecaniciens: any;
  typeInterventions: any;
  limite: 10000;
  file: File = null;
  myFiles: string[] = [];



  constructor(
    private FicheInterventionService: FicheInterventionService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getTypeIntervention();
    this.getMecanicien();
    this.getVehicule();
    this.user = this.authService.getUser();

    this.DataForm = this.formBuilder.group({
      compteur: ['', [Validators.required]],
      degre_urgence: ['', [Validators.required]],
      date_debut: ['', [Validators.required]],
      date_fin: ['', [Validators.required]],
      temps_alloue: ['', [Validators.required]],
      temps_indisponibilite: ['', [Validators.required]],
      travail_effectuer: ['', [Validators.required]],
      type_intervention_id: ['', [Validators.required]],
      vehicule_id: ['', [Validators.required]],
      mecanicien_id: ['', [Validators.required]],
    });
  }


  getTypeIntervention() {
    this.FicheInterventionService.getTypeIntervention().then(res => {
      this.typeInterventions = res;
      console.log(this.typeInterventions)
    }).catch(
      error => {
        this.notifService.danger(error.error.message)
      })
  }

  getMecanicien() {
    this.FicheInterventionService.getMecanicien().then(res => {
      this.mecaniciens = res;
      console.log(this.mecaniciens)
    }).catch(
      error => {
        this.notifService.danger(error.error.message)
      })
  }

  getVehicule() {
    this.FicheInterventionService.getVehicule().then((res) => {
      this.vehicules = res;
      console.log(this.vehicules)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  /* 
    onSelectfile(event) {
      this.file = event.target.files[0];
    }  */


  onSelectfile(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
  }

  get form() {
    return this.DataForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;


    if (this.DataForm.invalid) {

      this.translate.get('FicheIntervention.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('type_intervention_id', '' + this.form.type_intervention_id.value);
    formData.append('vehicule_id', '' + this.form.vehicule_id.value);
    formData.append('mecanicien_id', '' + this.form.mecanicien_id.value);
    formData.append('compteur', '' + this.form.compteur.value);
    formData.append('degre_urgence', '' + this.form.degre_urgence.value);
    formData.append('date_debut', '' + this.form.date_debut.value);
    if (this.form.date_debut.value > this.form.date_fin.value) {
      console.log('Junie');
      this.translate.get('FicheIntervention.DateErreur')
        .subscribe(val => this.notifService.danger(val));
      this.isLoading = false;
      return;
    } else {
      formData.append('date_fin', '' + this.form.date_fin.value);
    }
    //formData.append('date_fin', ''+this.form.date_fin.value);
    formData.append('temps_alloue', '' + this.form.temps_alloue.value);
    formData.append('temps_indisponibilite', '' + this.form.temps_indisponibilite.value);
    /* if (this.file != null)
      formData.append('fiche_intervention', this.file, this.file.name);
  */
    for (var i = 0; i < this.myFiles.length; i++) {
      formData.append("fiche_intervention[]", this.myFiles[i]);
    }
    formData.append('travail_effectuer', '' + this.form.travail_effectuer.value);
    formData.append('create_id', '' + this.user.id);

    console.log(formData);
    this.FicheInterventionService.add(formData)
      .then(resp => {
        this.translate.get('FicheIntervention.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.DataForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('FicheIntervention.SubmitErrorFicheIntervention')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }

}
