import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hr-add',
  templateUrl: './hr-add.component.html',
  styleUrls: ['./hr-add.component.scss']
})
export class HRAddComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
