import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AffectationService } from 'src/app/_services/affectation/affectation.service';

@Component({
  selector: 'app-update-affectation',
  templateUrl: './update-affectation.component.html',
  styleUrls: ['./update-affectation.component.scss']
})
export class UpdateAffectationComponent implements OnInit {

  AffectationForm: FormGroup;
  Affectations: any;
  drivers: any;
  vehicles: any;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  limit = 1000000;
  convoyeurs;

  constructor(
    private AffectationService: AffectationService,
    private notifService: NotifService,

    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.initForm();
    this.getDrivers(this.limit);
    this.getVehicles();
    this.getConvoyeurs(this.limit);

    const Affectation_id = +this.route.snapshot.paramMap.get("id");
    this.AffectationService.findaff(Affectation_id).then(
      data => {
        this.Affectations = data;
        this.initForm(true);
      }
    ).catch(
      error => {
        this.translate.get('Affectation.' + error.error.code)
          .subscribe(val => this.notifService.danger(val));
      }
    )
  }
  /**
   * @author jiozangtheophane@gmail.com
   * @param withVacation
   */
  //initialisation du formulaire
  initForm(withAffectation = false) {
    if (withAffectation) {
      this.AffectationForm = this.formBuilder.group(
        {
          driver_id: [this.Affectations.driver_id, [Validators.required]],
          convoyeur_id: [this.Affectations.convoyeur_id, [Validators.required]],
          vehicle_id: [this.Affectations.vehicle_id, [Validators.required]],
          date_of_affectation: [this.Affectations.date_of_affectation, [Validators.required]],
          end_date: [this.Affectations.end_date],
          reason: [this.Affectations.raison, [Validators.required]],
          //end_date: [this.Affectations.end_date, [Validators]],

        }
      );
    }
    else {
      this.AffectationForm = this.formBuilder.group(
        {
          convoyeur_id: ['', [Validators.required]],
          driver_id: ["", [Validators.required]],
          vehicle_id: ["", [Validators.required]],
          date_of_affectation: ["", [Validators.required]],
          reason: ["", [Validators.required]],
          end_date: [""],

        }
      );
    }

  }

  getDrivers(limit) {
    this.AffectationService.getdriver(limit).then((res) => {
      this.drivers = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  getVehicles() {
    this.AffectationService.getvehicle().then((res) => {
      this.vehicles = res;
      console.log(res)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }
  getConvoyeurs(limit) {
    this.AffectationService.getConvoyeur(limit).then((res) => {
      this.convoyeurs = res;
      console.log(this.convoyeurs)
    }, (error) => {
      this.notifService.danger(error.error.message)

    });
  }

  get form() {
    return this.AffectationForm.controls;
  }

  onSubmit() {
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;

    // Si la validation a echoué, on arrete l'execution de la fonction

    if (this.AffectationForm.invalid) {
      this.translate.get('Affectation.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('convoyeur_id', this.form.convoyeur_id.value);
    formData.append('driver_id', this.form.driver_id.value);
    formData.append('vehicle_id', '' + this.form.vehicle_id.value);
    formData.append('date_of_affectation', '' + this.form.date_of_affectation.value);
    formData.append('end_date', '' + this.form.end_date.value);
    formData.append('raison', '' + this.form.reason.value);


    this.AffectationService.updateaff(formData, this.Affectations.id)
      .then(resp => {
        this.translate.get('Affectation.SubmitUpdateSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.AffectationForm.reset();
        this.router.navigate(['/affectations/all']);

      })
      .catch(err => {
        console.log(err)
        this.translate.get('Affectation.SubmitErrorAffectation')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }
}
