import { HttpClient } from '@angular/common/http';
import * as Routes from 'src/Routes';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TypeDocumentMissionService {



  constructor(private http: HttpClient) { }

  async add(formData): Promise<any> {
    return await this.http.post(Routes.TYPE_DOCUMENT_MISSION, formData).toPromise();
  }

  public async update(id: number, formData): Promise<any> {
    return await this.http
      .post(`${Routes.TYPE_DOCUMENT_MISSION}/${id}`, formData)
      .toPromise();
  }

  public gets() {
    return this.http.get<any>(`${Routes.TYPE_DOCUMENT_MISSION}`)
      .toPromise();
  }


  public async delete(id) {
    return await this.http
      .delete(`${Routes.TYPE_DOCUMENT_MISSION}/${id}`)
      .toPromise();
  }

  find(id: number): Promise<any> {
    return this.http.get<any>(`${Routes.TYPE_DOCUMENT_MISSION}/${id}`).toPromise();
  }

  public get(url) {
    return this.http.get<any>(`${url}`)
      .toPromise();
  }

}
