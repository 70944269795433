<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>{{ "ProgrammesEntretient.ProgrammesEntretient" | translate }}</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
          <li class="breadcrumb-item "><a [routerLink]="['/programme-entretient/list']">{{ "ProgrammesEntretient.ProgrammesEntretient" | translate }}</a></li>
        </ol>
      </div>
    </div>
  </div>
</section>


<section class="container">
  <div class="row">
    <div class="col-md-12">
      <form (submit)="onSubmit()" [formGroup]="ProgrammesEntretientForm">
        <div class="card card-primary">

          <div class="card-header" style="background-color:#17a2b8;">
            <h3 class="card-title">{{ "ProgrammesEntretient.ProgrammesEntretientAdd" | translate }}</h3>
          </div>
          <div class="card-body" style="display: block;">
            <div class="row">
              <div class="form-group col-md-6">
               <label>{{ "ProgrammesEntretient.CompteurLabel" | translate }}
                  <span style="color: red;">*</span>
                </label>
                <input class="form-control {{ isSubmitted && form.compteur.errors ? 'is-invalid' : '' }}" placeholder="{{ 'ProgrammesEntretient.Compteur' | translate }}"
                formControlName="compteur">
              </div>
              <div class="form-group col-md-6">
                <label>{{ "ProgrammesEntretient.DateEntretientLabel" | translate }}
                  <span style="color: red;">*</span>
                </label>
                <input type="date" class="form-control {{ isSubmitted && form.date_entretient.errors ? 'is-invalid' : '' }}" placeholder="{{ 'ProgrammesEntretient.DateEntretient' | translate }}"
                formControlName="date_entretient">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
               <label>{{ "ProgrammesEntretient.KmPrevuLabel" | translate }}
                  <span style="color: red;">*</span>
                </label>
                <input type="text" class="form-control {{ isSubmitted && form.km_prevu.errors ? 'is-invalid' : '' }}" placeholder="{{ 'ProgrammesEntretient.KmPrevu' | translate }}"
                formControlName="km_prevu">
              </div>
              <div class="form-group col-md-6">
                <label>{{ "ProgrammesEntretient.HeurePrevuLabel" | translate }}
                  <span style="color: red;">*</span>
                </label>
                <input type="time" class="form-control {{ isSubmitted && form.heure_prevu.errors ? 'is-invalid' : '' }}" placeholder="{{ 'ProgrammesEntretient.HeurePrevu' | translate }}"
                formControlName="heure_prevu">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label>{{ "ProgrammesEntretient.CoutLabel" | translate }}
                  <span style="color: red;">*</span>
                </label>
                <input type="number" min="0" class="form-control {{ isSubmitted && form.cout.errors ? 'is-invalid' : '' }}" placeholder="{{ 'ProgrammesEntretient.Cout' | translate }}"
                formControlName="cout">
              </div>
              <div class="form-group col-md-6">
                <label for="gamme_entretient">{{ "ProgrammesEntretient.GammeEntretientLabel" | translate }}
                  <span style="color: red;">*</span>
                </label>
                <select formControlName="gamme_entretient" name="gamme_entretient" id="inputForme_id"
                  class="form-control {{ isSubmitted && form.gamme_entretient.errors ? 'is-invalid' : '' }}">
                  <option value="" disabled>{{ "ProgrammesEntretient.GammeEntretient" | translate }}</option>
                  <option *ngFor="let gamme_entretient of gammes_entretient?.data" value="{{gamme_entretient.id}}">{{gamme_entretient.nom}}</option>
                </select>
                <label>Ajouter une gamme d'entretient en <a href="javascript:void(0)" (click)="addGamme()">cliquant ici</a></label>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label for="mecanicien">{{ "ProgrammesEntretient.MecanicienLabel" | translate }}
                  <span style="color: red;">*</span>
                </label>
                <select formControlName="mecanicien" name="mecanicien" id="mecanicien"
                  class="form-control {{ isSubmitted && form.mecanicien.errors ? 'is-invalid' : '' }}">
                  <option  value="" disabled>{{ "ProgrammesEntretient.Mecanicien" | translate }}</option>
                  <option *ngFor="let mecanicien of mecaniciens?.data" value="{{mecanicien.id}}">{{mecanicien.mecanicien_name}}</option>
                </select>
                <label>Ajouter un mécanicien en <a href="javascript:void(0)" (click)="addMecano()">cliquant ici</a></label>
               </div>
              <div class="form-group col-md-6">
                <label for="vehicule">{{ "ProgrammesEntretient.VehiculeLabel" | translate }}
                  <span style="color: red;">*</span>
                </label>
                <select formControlName="vehicule" name="vehicule" id="inputForme_id"
                  class="form-control {{ isSubmitted && form.vehicule.errors ? 'is-invalid' : '' }}">
                  <option  value="" disabled>{{ "ProgrammesEntretient.Vehicule" | translate }}</option>
                  <option *ngFor="let vehicule of vehicules?.data" value="{{vehicule.id}}">{{vehicule.vehicle_registration}}</option>
                </select>
                <label>Ajouter un véhicule en <a href="javascript:void(0)" (click)="addAuto()">cliquant ici</a></label>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label>{{ "ProgrammesEntretient.NextDateEntretientLabel" | translate }}</label>
                <input type="date" value="" class="form-control {{ isSubmitted && form.next_date_entretient.errors ? 'is-invalid' : '' }}" placeholder="{{ 'ProgrammesEntretient.DateEntretient' | translate }}"
                formControlName="next_date_entretient">
              </div>
              <div class="form-group col-md-6">
                <label>{{ "ProgrammesEntretient.NextKmPrevuLabel" | translate }}</label>
                <input type="text" class="form-control {{ isSubmitted && form.next_km_prevu.errors ? 'is-invalid' : '' }}" placeholder="{{ 'ProgrammesEntretient.KmPrevu' | translate }}"
                formControlName="next_km_prevu">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label>{{ "ProgrammesEntretient.NextHeurePrevuLabel" | translate }}</label>
                <input type="time" value="" class="form-control {{ isSubmitted && form.next_heure_prevu.errors ? 'is-invalid' : '' }}" placeholder="{{ 'ProgrammesEntretient.NextHeurePrevu' | translate }}"
                formControlName="next_heure_prevu">
              </div>
              <div class="form-group col-md-6">
                <label for="description">{{ "ProgrammesEntretient.DescriptionLabel" | translate }}</label>
                  <textarea formControlName="description" name="description" id="description"
                    placeholder="{{ 'ProgrammesEntretient.Description' | translate }}"
                    class="form-control">
                  </textarea>
              </div>
            </div>

          </div>
          <div class="card-footer">
            <button [disabled]="isLoading" class="btn btn-primary" style="background-color:#17a2b8;">
              <p *ngIf="!isLoading">{{ "Action.Create" | translate }}</p>
              <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
          <!-- /.card-body -->
        </div>
      </form>
      <!-- /.card -->
    </div>
    <div  *ngIf="isMaterial"  class="col-md-2" style="margin-left:85%">
      <button class="btn btn-danger" (click)="onClose()">
            {{
            'Material.ButtonClose' | translate }}
        </button>
      </div>
  </div>
</section>
