<div class="content-wrapper" style="min-height: 1416.81px; margin-left: 4%; margin-right: 4%;">
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1>{{ "FicheIntervention.FicheIntervention" | translate }}</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">{{ "FileAriane.Home" | translate }}</a></li>
            <li class="breadcrumb-item "><a [routerLink]="['/fiche-intervention/all']">{{ "FicheIntervention.FicheIntervention" | translate }}</a></li>
            <li class="breadcrumb-item active">{{ "FileAriane.Update" | translate }}</li>

          </ol>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>

  <!-- Main content -->

  <section class="container">
      <div class="col-md-12">
          <div class="card card-primary">
              <div class="card-header" style="background-color: #17a2b8;">
                  <h3 class="card-title">
                      <font style="vertical-align: inherit;">
                          <font style="vertical-align: inherit;">{{ "FicheIntervention.FicheInterventionUpdate" | translate }}</font>
                      </font>
                  </h3>
              </div>
              <form role="form" (submit)="onSubmit()" [formGroup]="DataForm">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">{{ "FicheIntervention.TypeInterventionLabel" | translate }}
                                </font>
                            </font>
                            <span style="color: red">*</span>
                          </label>
                          <select formControlName="type_intervention_id" class="form-control {{ isSubmitted && form.type_intervention_id.errors? 'is-invalid' : '' }}"
                            style="width: 100%;" tabindex="-1" aria-hidden="true">
                            <option value="">{{ "FicheIntervention.TypeInterventionFiche" | translate }}</option>
                            <option *ngFor="let item of typeInterventions?.data" value="{{item.id}}">{{item.nom}}</option>
                          </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">{{ "FicheIntervention.MecanicienLabel" | translate }}
                                </font>
                            </font>
                            <span style="color: red">*</span>
                        </label>
                        <select formControlName="mecanicien_id" class="form-control {{ isSubmitted && form.mecanicien_id.errors? 'is-invalid' : '' }}"
                          style="width: 100%;" tabindex="-1" aria-hidden="true">
                          <option value="">{{ "FicheIntervention.MecanicienFiche" | translate }}</option>
                          <option *ngFor="let item of mecaniciens?.data" value="{{item.id}}">{{item.mecanicien_name}}</option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">{{ "FicheIntervention.VehiculeLabel" | translate }}
                                </font>
                            </font>
                            <span style="color: red">*</span>
                        </label>
                        <select formControlName="vehicule_id" class="form-control {{ isSubmitted && form.vehicule_id.errors? 'is-invalid' : '' }}"
                          style="width: 100%;" tabindex="-1" aria-hidden="true">
                          <option value="">{{ "FicheIntervention.vehiculeFiche" | translate }}</option>
                          <option *ngFor="let item of vehicules" value="{{item.id}}">{{item.vehicle_registration}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputFile">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">{{ "FicheIntervention.CompteurLabel" | translate }} <span
                                  style="color: red;">*</span>
                                </font>
                            </font>
                        </label>
                        <input type="number" id="inputName" class="form-control {{ isSubmitted && form.compteur.errors? 'is-invalid' : '' }}"
                          formControlName="compteur" placeholder="{{ 'FicheIntervention.LabelCompteurFiche' | translate }}">
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">{{ "FicheIntervention.DegreUregenceLabel" | translate }} <span
                                  style="color: red;">*</span>
                                </font>
                            </font>
                        </label>
                        <select formControlName="degre_urgence" class="form-control {{ isSubmitted && form.degre_urgence.errors? 'is-invalid' : '' }}"
                          style="width: 100%;" tabindex="-1" aria-hidden="true">
                          <option value="">{{ "FicheIntervention.LabelDegreUregence" | translate }}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputFile">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">{{ "FicheIntervention.TravailEffectuerLabel" | translate }} <span
                                  style="color: red;">*</span>
                                </font>
                            </font>
                        </label>
                        <input type="text" id="inputName" class="form-control {{ isSubmitted && form.travail_effectuer.errors? 'is-invalid' : '' }}"
                          formControlName="travail_effectuer" placeholder="{{ 'FicheIntervention.LabelTravailEffectuerFiche' | translate }}">
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">{{ "FicheIntervention.DateDebutLabel" | translate }} <span
                                  style="color: red;">*</span>
                                </font>
                            </font>
                        </label>
                        <input type="date" id="inputName" class="form-control {{ isSubmitted && form.date_debut.errors? 'is-invalid' : '' }}"
                          formControlName="date_debut" placeholder="{{ 'FicheIntervention.LabelDateDebutFiche' | translate }}">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">{{ "FicheIntervention.DateFinLabel" | translate }} <span
                                  style="color: red;">*</span>
                                </font>
                            </font>
                        </label>
                        <input type="date" id="inputName" class="form-control {{ isSubmitted && form.date_fin.errors? 'is-invalid' : '' }}"
                          formControlName="date_fin" placeholder="{{ 'FicheIntervention.LabelDateFinFiche' | translate }}">
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">{{ "FicheIntervention.TempsAlloueLabel" | translate }} <span
                                  style="color: red;">*</span></font>
                            </font>
                        </label>
                        <input type="text" id="inputName" class="form-control {{ isSubmitted && form.temps_alloue.errors? 'is-invalid' : '' }}"
                          formControlName="temps_alloue" placeholder="{{ 'FicheIntervention.LabelTempsAlloueFiche' | translate }}">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="exampleInputEmail1">
                            <font style="vertical-align: inherit;">
                                <font style="vertical-align: inherit;">{{ "FicheIntervention.TempsIndisponibiliteLabel" | translate }} <span
                                  style="color: red;">*</span></font>
                            </font>
                        </label>
                        <input type="text" id="inputName" class="form-control {{ isSubmitted && form.temps_indisponibilite.errors? 'is-invalid' : '' }}"
                          formControlName="temps_indisponibilite" placeholder="{{ 'FicheIntervention.LabelTempsIndisponibiliteFiche' | translate }}">
                    </div>
                </div>
                      <div class="row">

                          <div class="form-group col-md-12">
                              <label for="exampleInputEmail1">
                                  <font style="vertical-align: inherit;">{{ "FicheIntervention.FicheInterventionLabel" | translate }}</font>
                              </label>
                              <input type="file" id="inputName" class="form-control"
                                 multiple (change)=onSelectfile($event) placeholder="{{ 'FicheIntervention.LabelFicheIntervention' | translate }}">
                          </div>
                      </div>
                  </div>
                  <div class="card-footer">
                    <button [disabled]="isLoading" class="btn btn-primary" style="background-color:#17a2b8;">
                      <p *ngIf="!isLoading">{{ "General.update" | translate }}</p>
                      <div *ngIf="isLoading" class="spinner-border m-1" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>

              </form>
          </div>
      </div>

  </section>
</div>
