import { Component, OnInit } from '@angular/core';
import { DocService } from '../../../../../../_services/parc-service/doc-service/doc-service';
//import { NotifService } from 'src/app/_services/notif.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//import { TranslateService } from '@ngx-translate/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AutoAddComponent } from '../../../auto/auto-add/auto-add.component';
import { MatDialog } from '@angular/material/dialog';
import { CreateUsersComponent } from 'src/app/modules/application/human-R/users/create-users/create-users.component';
import { InterveningElementAddComponent } from 'src/app/modules/general/setting/intervening-elements/intervening-element-add/intervening-element-add.component';



@Component({
  selector: 'app-assurance-update',
  templateUrl: './assurance-update.component.html',
  styleUrls: ['./assurance-update.component.scss']
})
export class AssuranceUpdateComponent implements OnInit {


  assureurs :any ;
  vehicules : any;
  assuranceForm : FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;
  myfile: File = null;
  assurance : any ;

  constructor(
    private docService: DocService,
    //private notifService: NotifService,
    private formBuilder: FormBuilder,
    //private translate: TranslateService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private AutoDialog: MatDialog,
    private intervenant: MatDialog
  ) { }

  ngOnInit(): void {
    this.initForm();
    const assurance_id = +this.route.snapshot.paramMap.get("id");
    this.docService.findAssurance(assurance_id).then(
      data => {
        this.assurance = data;
        console.log(this.assurance)
        this.initForm(true);
  }).catch(
    error => {
      this.toastr.error('Impossible obtenir cette element');
    }
  )
}

initForm(WithAssurance = false) {
  if(WithAssurance) {
    this.assuranceForm = this.formBuilder.group({
      insurer_id: [this.assurance.insurer_id, Validators.required],
      vehicle_id: [this.assurance.vehicle_id, Validators.required],
      validity_start_at: [this.assurance.validity_start_at],
      validity_end_at: [this.assurance.validity_end_at],
      insurance_policy_value: [this.assurance.insurance_policy_value],
      insurance_policy_number: [this.assurance.insurance_policy_number],

    });
  }else {
    this.assuranceForm = this.formBuilder.group({
      insurer_id: ['', Validators.required],
      vehicle_id: ['', Validators.required],
      validity_start_at: [''],
      validity_end_at: [''],
      insurance_policy_value: [''],
      insurance_policy_number: [''],
    });
  }
  this.getElementIntervenant();
  this.getVehicule();
}

get form() {
  return this.assuranceForm.controls;
}

getElementIntervenant() {
  this.docService.getElementIntervenant().then((res) => {
    this.assureurs = res.data;
    console.log(res)
  }, (error) => {
    this.toastr.warning('Aucun Intervenant Disponible');
  });
}

getVehicule() {
  this.docService.getVehicule().then((res) => {
    this.vehicules = res.data;
    console.log(res)
  }, (error) => {
    this.toastr.warning('Aucun Vehicule Disponible');
  });
}

detectfile(event) {
  this.myfile = event.target.files[0];
  console.log(this.myfile)
}


onSubmit() {
  this.isSubmitted = true;
  this.isError = false;
  this.isSuccess = false;
  this.isLoading = false

  if (this.assuranceForm.invalid) {
     this.toastr.warning('Le Formulaire est mal remplit');
    return;
  }

  this.isLoading = true;
  const formData = new FormData();
  formData.append('insurer_id', '' + this.form.insurer_id.value);
  formData.append('vehicle_id', '' + this.form.vehicle_id.value);
  formData.append('validity_start_at', '' + this.form.validity_start_at.value);
  formData.append('validity_end_at', '' + this.form.validity_end_at.value);
  formData.append('insurance_policy_value', '' + this.form.insurance_policy_value.value);
  formData.append('insurance_policy_number', '' + this.form.insurance_policy_number.value);
  formData.append('attachment_file',  this.myfile);

  this.docService.updateAssurance(formData, this.assurance.id)
    .then(resp => {
      this.toastr.success('L Operation effectue avec success');
      this.router.navigate(['all_assurrance'])

    })
    .catch(err => {
      console.log(err)
      this.toastr.error('L Operation a echoue');
    })
    .finally(() => this.isLoading = false);
}
addAuto() {
  var modelDialogef =  this.AutoDialog.open(AutoAddComponent,{
     width: '1000px',
     height: '700px',
     disableClose: true
   });

   modelDialogef.afterClosed().subscribe(result => {
     console.log(`Dialog result: ${result}`);

     this.ngOnInit();
   });
 }

 addAssureur() {
  var modelDialogef =  this.intervenant.open(InterveningElementAddComponent,{
     width: '1200px',
     height: '700px',
     disableClose: true
   });

   modelDialogef.afterClosed().subscribe(result => {
     console.log(`Dialog result: ${result}`);

     this.ngOnInit();
   });
 }

}
