import { Component, OnInit } from '@angular/core';
import { TypeInterventionService } from 'src/app/_services/maintenance/interventions/type-intervention.service';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifService } from 'src/app/_services/notif.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';

@Component({
  selector: 'app-type-intervention-add',
  templateUrl: './type-intervention-add.component.html',
  styleUrls: ['./type-intervention-add.component.scss']
})
export class TypeInterventionAddComponent implements OnInit {

  user: any;
  TypeInterventionForm: FormGroup;
  isLoading = false;
  isError = false;
  isSuccess = false;
  isSubmitted = false;


  constructor(
    private TypeInterventionService: TypeInterventionService,
    private notifService: NotifService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.TypeInterventionForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
    }),
      this.user = this.authService.getUser()
  }

  get form() {
    return this.TypeInterventionForm.controls;
  }

  onSubmit() {
    console.log('on submit');
    this.isSubmitted = true;
    this.isError = false;
    this.isSuccess = false;
    this.isLoading = false;


    if (this.TypeInterventionForm.invalid) {
      this.translate.get('TypeIntervention.SubmitError')
        .subscribe(val => this.notifService.danger(val));
      return;
    }

    this.isLoading = true;
    const formData = new FormData();
    formData.append('nom', '' + this.form.name.value);
    formData.append('description', '' + this.form.description.value);
    formData.append('create_id', '' + this.user.id);

    console.log(formData);
    this.TypeInterventionService.post(formData)
      .then(resp => {
        this.translate.get('TypeIntervention.SubmitSuccess')
          .subscribe(val => this.notifService.success(val));
        this.isSubmitted = false;
        this.TypeInterventionForm.reset();
      })
      .catch(err => {
        console.log(err)
        this.translate.get('TypeIntervention.SubmitErrorTypeIntervention')
          .subscribe(val => this.notifService.danger(val));
      })
      .finally(() => this.isLoading = false);
  }


}
