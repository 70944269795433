import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-asside',
  templateUrl: './asside.component.html',
  styleUrls: ['./asside.component.scss']
})
export class AssideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
